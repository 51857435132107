import { TEST, testAction } from '../../actions/test';

import * as api from '../../services/api';
import { fetchEntity } from '../saga';
import { call, fork, takeEvery } from 'redux-saga/effects';

const getTestEntity = fetchEntity.bind(null, testAction, api.testEndpoint);

function* callTestAction(action: any) {
  yield call(
    getTestEntity,
    {},
    {
      onSuccess: (success: any) => {},
      onFailure: (failure: any) => {
        // alert("false");
      },
    }
  );
}

function* watchTestRequest() {
  yield takeEvery(TEST.REQUEST, callTestAction);
}

export default function* root() {
  /// self
  yield fork(watchTestRequest);
}
