/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React,{useEffect,useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import { CircularProgress, Drawer, Grid, Typography } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../../reducers";
import Actions from '../../../actions/filter';
import { Autocomplete } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { FilterData } from '../../../types/filter';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1)
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }),
);

const caseStatusEN = ['new','assigned','inProgress','waitConclusion','finish','renew'];
const risklevel = ['low','medium','high','victim','other','normal'];
const severityType = ["ทางร่างกาย","ทางเพศ","ทางจิตใจ","ละเลยทอดทิ้ง","ล่อลวง/บังคับหาประโยชน์"];
const sexs = ['ชาย','หญิง'];

interface IProps{
  open: boolean;
  type?: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  search: (filter: Record<string, any>) => Promise<void>;
  setFiltered?: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

const Filter=(props:IProps)=>{
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
  const changwatOptions = globalState.filter.changwat;
  const ampurOptions = globalState.filter.ampur;
  const tambonOptions = globalState.filter.tambon;
  const villageOptions = globalState.filter.village;
  const agencyOptions = globalState.filter.agency;
  const role = globalState.auth.user.response?.user.role;
  const changwatData = globalState.auth.user.response?.user.changwat;
  const amphurData = globalState.auth.user.response?.user.amphur;
  const {open, setOpen} = props;
  const initialFilterValue: FilterData = {
    caseStatus: [],
    risklevel: [],
    severityType: [],
    sex: [],
    age: [1,20],
    changwat: {id:changwatData.changwatcode, label:changwatData.changwatname, value:changwatData},
    ampur: {id:amphurData.amphur_id, label:amphurData.amphur_name, value:amphurData},
    tambon: null,
    village: null,
    agency: null
  }
  const [filterData,setFilterData] = useState<FilterData>(initialFilterValue);
  const getCaseStatusLabel = (name:string)=>{
    const caseStatusTH: Array<Record<string,string>> = [
      {'new':'รายใหม่'},
      {'assigned':'มอบหมายแล้ว'},
      {'inProgress':'ระหว่างดูแล'},
      {'waitConclusion':'รอสรุป'},
      {'finish':'เสร็จสิ้น'},
      {'renew':'เริ่มกระบวนการใหม่'}
    ];
    let statusTH = "";
    caseStatusTH.forEach((item:any)=>{
      if(name===Object.keys(item)[0]){statusTH = item[name];}
    });
    return statusTH;
  }
  const handleChangeMultiple = ( event: React.ChangeEvent<{name?: string | undefined;value: unknown;}>) => {
    const { name, value } = event.target as HTMLSelectElement;
    setFilterData({...filterData,[name]:value});
  };
  const ageChange = (event: any, newValue: number | number[]) => {
    setFilterData({...filterData,age: newValue as number[]});
  };
  const autocompleteChange=(data:any)=>{
    // console.log("autocompleteChange",data);
    if(data.value!==null){
      if(data.name==="changwat"){
        dispatch(Actions.ampur.request({data:data.value.id}));
        setFilterData({
          ...filterData,
          changwat:data.value,
          ampur: null,
          tambon: null,
          village: null,
          agency: null
        });
      }else if(data.name==="ampur"){
        dispatch(Actions.tambon.request({data:data.value.id}));
        setFilterData({
          ...filterData,
          ampur: data.value,
          tambon: null,
          village: null,
          agency: null
        });
      }else if(data.name==="tambon"){
        dispatch(Actions.village.request({data:data.value.id}));
        setFilterData({
          ...filterData,
          tambon: data.value,
          village: null,
          agency: null
        });
      }else if(data.name==="village"){
        dispatch(Actions.agency.request({data:data.value.id}));
        setFilterData({
          ...filterData,
          village: data.value,
          agency: null
        });
      }else{
        setFilterData({...filterData,agency: null});
      }
    }else{
      setFilterData({...filterData,[data.name]:null});
    }
  }
  const clearFillter=()=>{
    console.log("clearFillter",initialFilterValue)
    setFilterData(initialFilterValue);
    if(props.setFiltered){
      props.setFiltered(initialFilterValue);
    }else{
      props.search(initialFilterValue);
    }
    // props.search(initialFilterValue);
  }
  const searchSubmit=()=>{
    if(props.setFiltered){
      props.setFiltered(filterData);
    }else{
      props.search(filterData);
    }
  }

  useEffect(()=>{
    if(open){
      if(changwatOptions.data.length===0){
        dispatch(Actions.changwat.request({}));
      }
      if(tambonOptions.data.length===0){
        if(["CM","AdminTeams"].includes(role as string)){
          autocompleteChange({name:"ampur",value:{id:amphurData.amphur_id, label:amphurData.amphur_name, value:amphurData}});
        }
      }
    }
  },[open]);
  
  return (
    <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
      <div style={{padding:'1.5rem'}}>
        <Grid container spacing={2} style={{width:(!isMobile)?"400px":"auto"}} >
          <Grid item xs={12}><Typography variant="h4">ตัวกรอง</Typography></Grid>
          <Grid item xs={12}>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                select
                label="สถานะเคส"
                name="caseStatus"
                variant="outlined"
                size={(isMobile)?"small":"small"}
                SelectProps={{
                  multiple: true,
                  value: filterData.caseStatus,
                  onChange: handleChangeMultiple,
                  renderValue: (selected) => (
                    <div className={classes.chips} style={{paddingLeft:"6px"}}>
                      {(selected as string[]).map((value) => (
                        <Chip key={value} label={getCaseStatusLabel(value)} className={classes.chip} />
                      ))}
                    </div>
                  )
                }}
                >
                {caseStatusEN.map((name) => (
                  <MenuItem key={name} value={name} >
                    { getCaseStatusLabel(name) }
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                select
                label="ระดับความเสี่ยง"
                name="risklevel"
                size={(isMobile)?"small":"small"}
                variant="outlined"
                SelectProps={{
                  multiple: true,
                  value: filterData.risklevel,
                  onChange: handleChangeMultiple,
                  renderValue: (selected) => (
                    <div className={classes.chips} style={{paddingLeft:"6px"}}>
                      {(selected as string[]).map((value) => (
                        <Chip key={value} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )
                }}
                >
                {risklevel.map((name) => (<MenuItem key={name} value={name} >{name}</MenuItem>))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                select
                label="ระดับความรุนแรง"
                name="severityType"
                variant="outlined"
                size={(isMobile)?"small":"small"}
                SelectProps={{
                  multiple: true,
                  value: filterData.severityType,
                  onChange: handleChangeMultiple,
                  renderValue: (selected) => (
                    <div className={classes.chips} style={{paddingLeft:"6px"}}>
                      {(selected as string[]).map((value) => (
                        <Chip key={value} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )
                }}
                >
                {severityType.map((name) => (<MenuItem key={name} value={name} >{name}</MenuItem>))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                select
                label="เพศ"
                name="sex"
                variant="outlined"
                size={(isMobile)?"small":"small"}
                SelectProps={{
                  multiple: true,
                  fullWidth: true,
                  value: filterData.sex,
                  onChange: handleChangeMultiple,
                  renderValue: (selected) => (
                    <div className={classes.chips}>
                      {(selected as string[]).map((value) => (
                        <Chip key={value} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )
                }}
                
              >
                {sexs.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div style={{border: '1px solid #ccc',borderRadius: '4px', padding:'7px 16px 0 14px'}}>
            <FormControl fullWidth className={classes.formControl}>
              <Typography id="age-slider">อายุ</Typography>
              <Slider
                value={filterData.age}
                max={20}
                min={1}
                marks
                onChange={ageChange}
                valueLabelDisplay="auto"
                style={{marginLeft:'5px',marginRight:'5px',width:'98%'}}
                getAriaValueText={(value)=>value+" ปี"}
              />
            </FormControl>
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth className={classes.formControl}>
              <Autocomplete
                id="changwat"
                fullWidth
                size={(isMobile)?"small":"small"}
                freeSolo={true}
                options={changwatOptions.data}
                value={filterData.changwat}
                loading={changwatOptions.isFetching}
                disabled={(["CM","AdminTeams","Teams"].includes(role as string))}
                getOptionLabel={(option: any) => option.label }
                onChange={(_, value: any) => {
                  autocompleteChange({name:"changwat",value: value});
                }}
                style={{ height: "100%" }}
                renderInput={
                  (params) => 
                  <TextField {...params} 
                    label={changwatOptions.isFetching?<CircularProgress size={16}/>:"จังหวัด"} 
                    variant="outlined" 
                    fullWidth
                    />
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth className={classes.formControl}>
              <Autocomplete
                id="ampur"
                fullWidth
                size={(isMobile)?"small":"small"}
                freeSolo={true}
                options={ampurOptions.data}
                value={filterData.ampur}
                loading={ampurOptions.isFetching}
                disabled={(["CM","AdminTeams","Teams"].includes(role as string))}
                getOptionLabel={(option: any) => option.label }
                onChange={(_, value: any) => {
                  autocompleteChange({name:"ampur",value: value});
                }}
                style={{ height: "100%" }}
                renderInput={
                  (params) => 
                  <TextField {...params} 
                    label={ampurOptions.isFetching?<CircularProgress size={16}/>:"อำเภอ"} 
                    variant="outlined" 
                    fullWidth
                    />
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth className={classes.formControl}>
              <Autocomplete
                id="tambon"
                fullWidth
                size={(isMobile)?"small":"small"}
                freeSolo={true}
                options={tambonOptions.data}
                value={filterData.tambon}
                loading={tambonOptions.isFetching}
                disabled={tambonOptions.isFetching}
                getOptionLabel={(option: any) => option.label }
                onChange={(_, value: any) => {
                  autocompleteChange({name:"tambon",value: value});
                }}
                style={{ height: "100%" }}
                renderInput={
                  (params) => 
                  <TextField {...params} 
                    label={tambonOptions.isFetching?<CircularProgress size={16}/>:"ตำบล"} 
                    variant="outlined" 
                    fullWidth
                    />
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth className={classes.formControl}>
              <Autocomplete
                id="village"
                fullWidth
                size={(isMobile)?"small":"small"}
                freeSolo={true}
                options={villageOptions.data}
                value={filterData.village}
                loading={villageOptions.isFetching}
                disabled={villageOptions.isFetching}
                getOptionLabel={(option: any) => option.label }
                onChange={(_, value: any) => {
                  autocompleteChange({name:"village",value: value});
                }}
                style={{ height: "100%" }}
                renderInput={
                  (params) => 
                  <TextField {...params} 
                    label={villageOptions.isFetching?<CircularProgress size={16}/>:"หมู่"} 
                    variant="outlined" 
                    fullWidth
                    />
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth className={classes.formControl}>
              <Autocomplete
                id="agency"
                fullWidth
                size={(isMobile)?"small":"small"}
                freeSolo={true}
                options={agencyOptions.data}
                value={filterData.agency}
                loading={agencyOptions.isFetching}
                disabled={agencyOptions.isFetching}
                getOptionLabel={(option: any) => option.label }
                onChange={(_, value: any) => {
                  autocompleteChange({name:"agency",value: value});
                }}
                style={{ height: "100%" }}
                renderInput={
                  (params) => 
                  <TextField {...params} 
                    label={agencyOptions.isFetching?<CircularProgress size={16}/>:"หน่วยงาน"} 
                    variant="outlined" 
                    fullWidth
                    />
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} >
            <Button 
              fullWidth 
              size={(isMobile)?"small":"medium"}
              variant="contained" 
              color="primary"
              startIcon={<RemoveCircleIcon />} //SearchIcon
              style={{ height: "100%" }}
              onClick={clearFillter}>
                ล้าง
            </Button>
          </Grid>
          <Grid item xs={6} >
            <Button 
              fullWidth 
              size={(isMobile)?"small":"medium"}
              variant="contained" 
              color="primary"
              startIcon={<SearchIcon />} //SearchIcon
              style={{ height: "100%" }}
              onClick={searchSubmit}>
                ค้นหา
            </Button>
          </Grid>
          <Grid item xs={12} >
            <Button 
              fullWidth 
              size={(isMobile)?"small":"medium"}
              variant="outlined" 
              color="primary"
              // startIcon={<SearchIcon />}
              style={{ height: "100%" }}
              onClick={()=>setOpen(false)}>
                ปิด
            </Button>
          </Grid>
        </Grid>
      </div>
    </Drawer>
  );
}

export default Filter;