/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import {
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';
import useGlobal from "../../store";
import { getCurrentUser } from "../../services/auth.service";
import * as API from "../../services/UniversalAPI";
import * as SI from "../../services/SaveIntervention";
import { 
  MdRemoveRedEye as MdRemoveRedEyeIcon,
  MdAssignment as MdAssignmentIcon
} from 'react-icons/md';
import { 
  Typography, 
  Grid,
  Backdrop,
  CircularProgress,
  Button,
} from '@material-ui/core';

import UCard from "../../services/universal/UniversalCard";
import { useTranslation } from "react-i18next";
import ConsentServiceSlot from "../consent/ConsentServiceSlot";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 2000,
    color: "#fff",
  },
}));

export default function DashBoard(props) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const userinfo = getCurrentUser();
  const [globalState, globalActions] = useGlobal();
  
  const [osccPersonData, setOsccPersonData] = useState({});
  const [planServiceData, setPlanServiceData] = useState([]);
  const [evaluationData, setEvaluationData] = useState([]);
  const [personData, setPersonData] = useState({});
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [hcode, setHcode] = useState(globalState.currentUser.user.department.hcode);
  const [hname, setHname] = useState(globalState.currentUser.user.department.hos_name);
  const [workCompleteCount, setWorkCompleteCount] = useState(0);
  const [workCount, setWorkCount] = useState(0);

  const [openConsent, setOpenConsent] = useState(false);
  const [informData, setInformData] = useState({});
  const [consentData, setConsentData] = useState({});
  const [focusedActivity, setFocusedActivity] = useState({});
  const [planService, setPlanService] = useState([]);
  const [dontHaveUnder, setDontHaveUnder] = useState(false);


  const goQuestionnaire = async (informData, consentData, thisActivity) => {
    let isNotConsent=false;
    if (typeof thisActivity === 'undefined') {
      if (consentData.result==="notConsent") { 
        isNotConsent=true;
      }
      else {
        isNotConsent=false;
      }
    }
    else {
      isNotConsent=false;
    }

    if (isNotConsent) {
      let a = JSON.parse(JSON.stringify(focusedActivity));
      let response = await API.get(a.serviceslotId,"serviceslots");
      if (response.status === 200) { 
        if  (response.data) {
          let ssdata = response.data;
          let answerData_blank={};
          answerData_blank['answerer']={ answererId: a.answererId, answererName: a.answererName };
          answerData_blank['consent']=consentData;
          answerData_blank['id']=a.questionnaireId;
          answerData_blank['inform']=informData;
          answerData_blank['oscctem']={ teamCode: a.teamCode, teamName: a.teamName };
          answerData_blank['planServiceId']=a.planServiceId;
          answerData_blank['questionnaire_code']=a.questionnaire.questionnaire_code;
          answerData_blank['questionnaire_name']=a.questionnaire.questionnaire_name;
          answerData_blank['questionnaire_type']=a.questionnaire.questionnaire_type;
          answerData_blank['questionsList']=a.questionnaire.questionsList;
          answerData_blank['sequenceId']=a.sequenceId;
          answerData_blank['sumScore']=0;

          // console.log(osccPersonData);
          // console.log(planService);
          // console.log(answerData_blank);
          // console.log(globalState.currentUser.user.id);
          // console.log(globalState.currentUser.user.fullname);
          // console.log(ssdata.resultTemplate[0].osccteam.hcode||'');
          // console.log(ssdata.resultTemplate[0].osccteam.teamName||'');

          // saveInterventions(personData, planservice_data,param_answerData,param_userId,param_userFullName, param_hcode, param_hname) 
          await SI.saveInterventions(
            // osccPersonData, 
            personData,
            planService,
            answerData_blank,
            globalState.currentUser.user.id,
            globalState.currentUser.user.fullname,
            ssdata.resultTemplate[0].osccteam.hcode||'',
            ssdata.resultTemplate[0].osccteam.teamName||'',
            getOsccPersonData()
          );
          // window.location.reload();

        }
      }
    }
    else {
      // console.log('---------------B02');
      let i = JSON.parse(JSON.stringify(focusedActivity));
      if (typeof thisActivity !== 'undefined') {
        i = thisActivity;
      }
      let questionData = {
        returnAnswerTo: '/',
        veiwType: 'recordable',
        id: i.questionnaireId,
        questionnaire: i.questionnaire,
        planServiceId: i.planServiceId,
        interventionId: (typeof i.interventionId === 'undefined' ? null : (i.interventionId === '' ? null : i.interventionId)),
        inform: informData,
        consent: consentData,
        sequenceId: i.sequenceId
      };
      // console.log(questionData);
      history.push({
        pathname: '/questionnaireview',
        state: {
          questionData: questionData,
          caseData: osccPersonData,
          personData: personData
        }
      });
    }
  }

  const getOsccPersonData = async (cid) => {
    let filter={ filter: { where: { cid: cid } } };
    let response = await API.getAll(filter,"osccpeople");
    if (response.status === 200) { 
      if  (response.data.length>0) {
        setOsccPersonData(response.data[0]);
        getPersonData(cid, response.data[0].id);
      }
    }
  };

  const getPersonData = async (cid, osccPersonId) => {
    let filter={ filter: { where: { cid: cid } } };
    let response = await API.getAll(filter,"people");
    if (response.status === 200) { 
      if (response.data.length>0) {
        let d=response.data[0];
        setPersonData(d);
        if (parseInt(userinfo.user.userType.userTypeId)===1) {
          getPlanServiceData(d, osccPersonId);
        }
        else if (parseInt(userinfo.user.userType.userTypeId)===2) {
          let filter={ filter: { where: { cid: cid } } };
          let responseCG = await API.getAll(filter,"osccpeople");
          if (responseCG.status === 200) { 
            // console.log(responseCG.data[0]);
            if (responseCG.data.length>0) {
              getPlanServiceData(d, responseCG.data[0].id);
            }
          }
        }
      }
    }
  };

  const getUnderCareGiver = async (cid) => {
    let filter={ filter: { where: { "careGiver.cid": cid } } };
    let response = await API.getAll(filter,"osccpeople");
    if (response.status === 200) { 
      if (response.data.length>0) {
        getOsccPersonData(response.data[0].cid);
      }
      else {
        setDontHaveUnder(true);
      }
    }
  };
  
  const getPlanServiceData = async (person_data, osccPersonId) => {
    let filter = {
      filter: {
        include: {
          relation: 'serviceslot'
        },
        where: {
          osccPersonId: osccPersonId
        }
      }
    };
    // console.log(filter);
    let response = await API.getAll(filter, "planservices");
    if (response.status === 200) {
      if (response.data) {
        setPlanService(response.data);
        let x = response.data;
        // console.log(x);
        let ssID = [];
        x.forEach(ep => {
          ep.serviceslotList.forEach(es => {
            if (ssID.indexOf(es.serviceslotId)<0) {
              ssID.push(es.serviceslotId);
            }
          });
        });
        // console.log(ssID);
        let where_ssID = [];
        ssID.forEach(i => {
          where_ssID.push({ id: i });
        });
        // console.log(where_ssID);
        let allSS = [];
        let evaluation = [];

        let filterB = {
          filter: {
            where: {
              or: where_ssID
            }
          }
        };
        // console.log(JSON.stringify(filterB));
        let responseB = await API.getAll(filterB, "serviceslots");
        if (responseB.status === 200) {
          if (responseB.data) {
            allSS = responseB.data;
            let interventionIdList = [];
            x.forEach(i => {
              i.serviceslotList.forEach(ev => {
                if (typeof ev.interventionId !== 'undefined') {
                  if (ev.interventionId !== null && ev.interventionId !== '') {
                    interventionIdList.push(ev.interventionId);
                  }
                }
                let ed = ev;
                let questionnaireId = null;
                let questionnaire = null;
                let inform = null;
                let consent = null;
                allSS.forEach(s => {
                  if (s.id === ev.serviceslotId) {
                    questionnaireId = s.resultTemplate[0].id;
                    questionnaire = s.resultTemplate[0];
                    inform = s.inform;
                    consent = s.consent;
                  }
                });
                ed['planServiceId'] = i.id;
                ed['questionnaireId'] = questionnaireId;
                ed['questionnaire'] = questionnaire;
                ed['inform'] = inform;
                ed['consent'] = consent;
                evaluation.push(ed);
              });
            });

            if (interventionIdList.length > 0) {
              let filterC_or = [];
              interventionIdList.forEach(iil => {
                filterC_or.push({ id: iil });
              });
              let filterC = {
                filter: {
                  where: {
                    or: filterC_or
                  }
                }
              };
              let responseC = await API.getAll(filterC, "interventions");
              if (responseC.status === 200) {
                if (responseC.data) {
                  responseC.data.forEach(i1 => {
                    i1.activities.homeservice.forEach(i2 => {
                      if (i2.serviceType==="oscc") {
                        i2.questionnaire.forEach(i3 => {
                          evaluation.forEach(i4 => {
                            if (i4.interventionId===i1.id && i4.planServiceId===i3.planServiceId && i4.sequenceId===i3.sequenceId) {
                              i4.inform=i3.inform;
                              i4.consent=i3.consent;
                              i4['interventionData']=i1;
                            }
                          });
                        });
                      }
                    });
                  });
                  setEvaluationData(evaluation);
                }
              }
            }
            else {
              setEvaluationData(evaluation);
            }

            let workCount=0;
            let workCompleteCount=0;
            evaluation.forEach(i => {
              if (parseInt(userinfo.user.userType.userTypeId)===parseInt(i.answererId)) {
                ++workCount;
                if (typeof i.interventionId !== 'undefined') {
                  ++workCompleteCount;
                }
              }
            });
            setWorkCount(workCount);
            setWorkCompleteCount(workCompleteCount);

            if (typeof props.location.state != 'undefined') {
              // console.log(props.location.state.answerData);
              if (typeof props.location.state.answerData != 'undefined') {
                if (Object.keys(props.location.state.answerData).length > 0) {
                  clickSave(person_data, response.data, allSS);
                }
              }
            }

          }
        }
      }
    }
    return null;
  }

  const clickSave = async (person_data, planservice_data, allServiceSlot) => {
    setOpenBackdrop(true);

    let thisServiceSlotId = '';
    planservice_data.forEach(i1 => {
      if (i1.id===props.location.state.answerData.planServiceId) {
        i1.serviceslotList.forEach(i2 => {
          if (parseInt(i2.sequenceId)===parseInt(props.location.state.answerData.sequenceId)) {
            thisServiceSlotId=i2.serviceslotId;
          }
        });
      }
    });

    let hcode='';
    let hos_name='';
    allServiceSlot.forEach(i => {
      if (i.id===thisServiceSlotId) {
        hcode=i.resultTemplate[0].osccteam.hcode||'';
        hos_name=i.resultTemplate[0].osccteam.teamName||'';
      }
    });

    // SI.saveInterventions(personData, planservice_data,param_answerData,param_userId,param_userFullName, param_hcode, param_hname)
    await SI.saveInterventions(
      person_data,
      planservice_data,
      props.location.state.answerData,
      globalState.currentUser.user.id,
      globalState.currentUser.user.fullname,
      hcode,
      hos_name,
    );

    // console.log(person_data);
    // console.log(planservice_data);
    // console.log(props.location.state.answerData);
    // console.log(globalState.currentUser.user.id);
    // console.log(globalState.currentUser.user.fullname);
    // console.log(hcode);
    // console.log(hos_name);

    let stateData = props.location.state;
    delete stateData['answerData'];
    history.replace('/', stateData);
    getOsccPersonData();
    closeBackdrop();
  }

  const closeBackdrop = () => {
    setTimeout(() => {
      setOpenBackdrop(false);
    }, 1000);
  }

  const mkRows = useMemo(() => {
    // console.log(evaluationData);
    let r = [];
    if (evaluationData.length > 0) {
      let n = 0;
      let rowColor = "#FFFFFF";
      let status = false;
      let score = null;
      let ss = evaluationData;
      ss.sort(function (a, b) {
        // b-a เรียงมากไปน้อย
        // a-b เรียงน้อยไปมาก
        let dateA = a.startDate;
        let dateB = b.startDate;
        dateA = (typeof dateA === 'undefined'?'0000-00-00':dateA);
        dateB = (typeof dateB === 'undefined'?'0000-00-00':dateB);
        dateA = (dateA === null|dateA===''?'0000-00-00':dateA);
        dateB = (dateB === null|dateB===''?'0000-00-00':dateB);
        let ax = parseInt((new Date(dateA).getTime()).toString());
        let bx = parseInt((new Date(dateB).getTime()).toString());
        ax = (isNaN(ax) ? 0 : ax);
        bx = (isNaN(bx) ? 0 : bx);
        return ax - bx;
        // return bx - ax;
      });
      // evaluationData.forEach(i => {
      ss.forEach(i => {
        // console.log(userinfo.user);
        // console.log(i);
        if (parseInt(userinfo.user.userType.userTypeId)===parseInt(i.answererId)) {
          // console.log(i);
          ++n;
          if (n % 2 === 0) {
            rowColor = "#EEEEEE";
          }
          else {
            rowColor = "#FFFFFF";
          }

          if (typeof i.status != 'undefined') {
            if (i.status === 'completed') {
              status = i.status
            }
          }

          if (typeof i.score !== 'undefined') {
            if (i.score !== null && i.score.toString().trim() !== '') {
              score = parseInt(i.score);
            }
          }

          let workStatus="ยังไม่ทำ";
          let workStatusColor="#FF7A00";
          if (typeof i.interventionId !== 'undefined') {
            workStatus="ทำแล้ว";
            workStatusColor="#47C10D";
          }

          r.push(
            <Grid key={n} container justify="flex-start" direction={isDesktop ? "row" : "column-reverse"} style={{ border: 'solid 1px #000000', borderRadius: 7, marginTop: n>1?10:0, padding: 10, backgroundColor: rowColor }}>
              <Grid item xs={12} sm={10} style={{ marginTop: isDesktop ? 0 : -25 }}>
                <Grid container>
                  <Grid item xs={12} sm={7}>
                    {isDesktop ? '' : <b>แบบสอบถาม/กิจกรรม: <br /></b>}
                    {n}). {i.serviceslotName}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {isDesktop ? '' : <b>ช่วงวันที่: </b>}
                    {API.dateTHs(i.startDate)} - {API.dateTHs(i.endDate)}
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    {isDesktop ? '' : <b>สถานะ: </b>}
                    <div style={{ backgroundColor: workStatusColor, color: '#FFFFFF', borderRadius: 15, display: 'inline-block', paddingLeft: 10, paddingRight: 10, height: 29 }}>{workStatus}</div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Grid container justify="flex-end">

                  {workStatus === "ยังไม่ทำ" ? (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<MdAssignmentIcon style={{ marginLeft: 11, marginTop: -2 }} />}
                      style={{ height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white', marginRight: 0 }}
                      onClick={() => {
                        // console.log(i);
                        setInformData(i.inform);
                        setConsentData(i.consent);
                        setOpenConsent(true);
                        setFocusedActivity(i);
                      }}
                    />
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<MdAssignmentIcon style={{ marginLeft: 11, marginTop: -2 }} />}
                        style={{ height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white', marginRight: 0 }}
                        onClick={() => {
                          // console.log(i);
                          setInformData(i.inform);
                          setConsentData(i.consent); 
                          // คอมโพเน้น ConsentServiceSlot จะเช็คถ้ามี consent.result จะแสดงหน้าต่าง consent ในโหลดแก้ไข
                          setOpenConsent(true);
                          setFocusedActivity(i);
                        }}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<MdRemoveRedEyeIcon style={{ marginLeft: 11, marginTop: -2 }} />}
                        style={{ height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white', marginRight: 0 }}
                        onClick={() => {
                          // console.log(i);
                          setFocusedActivity(i);
                          goQuestionnaire(i.inform, i.consent, i);
                        }}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
          
        }
      });
    }
    return r;
  },[evaluationData,isDesktop]);

  useEffect(() => {
    if (parseInt(userinfo.user.userType.userTypeId)===1) {
      getOsccPersonData(userinfo.user.cid);
      // getPersonData(userinfo.user.cid);
    }
    else if (parseInt(userinfo.user.userType.userTypeId)===2) {
      getUnderCareGiver(userinfo.user.cid);
    }
  }, []);
  
  const collection = {
    caseInfo: {
      fields: {
        fullname: { show: true, title: 'ชื่อสกุล', data_type: 'string', format: null },
        cid: { show: true, title: 'เลขบัตรประชาชน', data_type: 'string', format: null },
        // mobile: { show: true, title: 'ประเภทความรุนแรง', data_type: 'string', format: null },
      }
    },
  }

  return (
    <div>

      <Grid style={{ marginBottom: 100 }}>

        <Backdrop className={classes.backdrop} open={openBackdrop} >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} style={{ border: 'solid 1px #000000', borderRadius: 7, padding: 0, background: '#FFFFFF' }}>
            <UCard structure={collection.caseInfo} display_style="inline" data={userinfo.user} />
          </Grid>

          <Grid item xs={12} sm={12} style={{ border: 'solid 1px #000000', borderRadius: 7, marginTop: 10, padding: 5, color: '#0054ff', background: '#FFFFFF' }}>
            <Typography variant="h6">
              {dontHaveUnder?(
                <div style={{ color: 'red',textAlign: 'center', paddingTop: 60, height: 200 }}>ไม่พบข้อมูลเด็กหรือผู้อยู่ในการดูแลของท่าน กรุณาติดต่อเจ้าหน้าที่ค่ะ</div>
              ):(
                <div>
                  {workCount===0&&(
                    <div>
                      ยังไม่มีแบบสอบถาม/กิจกรรมให้ทำ
                    </div>
                  )}
                  {workCount>0&&workCompleteCount===workCount?(
                    <div>
                      ทำแบบสอบถาม/กิจกรรมครบหมดแล้ว
                    </div>
                  ):(
                    <div>
                      มีแบบสอบถาม/กิจกรรมที่ยังไม่ทำ จำนวน {workCount-workCompleteCount} รายการ
                    </div>
                  )}
                </div>
              )}
            </Typography>
          </Grid>

          {(!dontHaveUnder&&isDesktop) && (
            <Grid container justify="flex-start" style={{ border: 'solid 0px #666666', borderRadius: 7, backgroundColor: '#00a8ff', color: '#FFFFFF', marginTop: 10 }}>
              <Grid item xs={12} sm={10} >
                <Grid container>
                  <Grid item xs={12} sm={7}>
                  &nbsp;&nbsp;แบบสอบถาม/กิจกรรม
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    ช่วงวันที่
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    สถานะ
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={2}>
                &nbsp;&nbsp;
              </Grid>
            </Grid>
          )}

          {!dontHaveUnder && (
            <Grid item xs={12} sm={12} style={{ border: 'solid 1px #C0C0C0', borderRadius: 7, marginTop: 10, padding: 2, background: '#FFFFFF' }}>
              {mkRows}
            </Grid>
          )}

        </Grid>
      </Grid>

      <ConsentServiceSlot dialogOpen={openConsent} setDialogOpen={setOpenConsent} userTypeId={''} informData={informData} consentData={consentData} t={t} goQuestionnaire={goQuestionnaire} activityData={focusedActivity} />

    </div>
  )
}