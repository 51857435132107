import React, { createContext, Dispatch } from 'react';

interface IProps {
  currentPage: number;
  setCurrentPage: Dispatch<React.SetStateAction<number>>;
  count: number;
  children: any;
}

let CurrentPageContext = createContext<Record<string,any>>({ currentPage:0, setCurrentPage:(page:number)=>{}, count:0 });

const CurrentPageProvider = (props:IProps) => {
  const {currentPage, setCurrentPage, count} = props;

  return (
    <CurrentPageContext.Provider value={{ currentPage:currentPage, setCurrentPage:setCurrentPage, count:count}}>
      {props.children}
    </CurrentPageContext.Provider>
  );
}

export { CurrentPageContext, CurrentPageProvider };