const changeLoginStatus = (store, status) => {
  store.setState({ loginStatus: status });
};
const setIsLineLogin = (store, status) => {
  store.setState({ isLineLogin: status });
};
const setCurrentUser = async (store, userinfo) => {
  store.setState({ currentUser: userinfo });
};
const setUserRole = (store, role) => {
  store.setState({ userRole: role });
  return true;
};
const setDrawerOpen = (store, open) => {
  store.setState({ drawerOpen: open });
};
const setAnchorEl = (store, open) => {
  store.setState({ anchorEl: open });
};
const setAnchorNotification = (store, open) => {
  store.setState({ anchorNotification: open });
};
const setNotificationBadge = (store, value) => {
  store.setState({ notificationBadge: value });
};
const setLanguage = (store, value) => {
  store.setState({ language: value });
};
// const setMobileView = (store, view) => {
//   store.setState({ mobileView: view });
// };
const setFocusedCaseStatus = (store, status) => {
  store.setState({ focusedCaseStatus: status });
};
const setDashboardFilter = (store, value) => {
  store.setState({ dashboardFilter: value });
};

const resetGlobalState = (store) => {
  store.setState({
    currentUser: {},
    userRole: 'noRole',
    loginStatus: false,
    isLineLogin: false,
    anchorEl: null,
    drawerOpen: false,
    mobileView: false,
    focusedCaseStatus:'',
    dashboardFilter:{
      changwat:{changwatcode:"",changwatname:""},
      amphur:{amphur_id:"",amphur_name:""},
      hospital:{hos_id:"",hos_fullname:"",hos_name:""}
    }
  });
};
export {
  changeLoginStatus,
  setIsLineLogin,
  setCurrentUser,
  setUserRole,
  setDrawerOpen,
  setAnchorEl,
  setAnchorNotification,
  setNotificationBadge,
  setFocusedCaseStatus,
  setDashboardFilter,
  resetGlobalState,
  setLanguage
}