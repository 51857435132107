import { GET_CASESEXCEL } from "../../actions";
import { AnyAction } from "redux";
import { Cases } from "../../types/activity";

const initialState:Cases = {
  isFetching: false,
  isSuccess: false,
  count: 0,
  data: []
};

export default function getCaseExcel(
  state = initialState,
  action: AnyAction
): Cases {
  switch (action.type) {
    case GET_CASESEXCEL.REQUEST:{
      return {
        ...state,
        isFetching: true,
        isSuccess: false,
        data:[]
      };
    }
    case GET_CASESEXCEL.SUCCESS: {
      const caseData = action.data.rows.map((item:any)=>{
        if(item.osccperson_assign && item.osccperson_assign.length===0){
          delete item.osccperson_assign;
          item["inform"] = false;
        }else{
          if(item.osccperson_assign[0].osccperson_groups.inform && item.osccperson_assign[0].osccperson_groups.inform.length>0){
            item["inform"] = true;
          }
        }
        return item
      });
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        count: parseInt(action.data.count),
        data: caseData
      };
    }
    case GET_CASESEXCEL.FAILURE:
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
        data: [],
      };
    default:
      return state;
  }
}
