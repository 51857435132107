/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { GET_ASSIGNGROUP } from "../index";

const getCase = {
  request: (payload:any) => action(GET_ASSIGNGROUP.REQUEST, {data:payload}),
  success: (response: any) => action(GET_ASSIGNGROUP.SUCCESS, { data: response }),
  failure: (error: any) => action(GET_ASSIGNGROUP.FAILURE, { error }),
};

export default getCase;