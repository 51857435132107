import React from 'react';
import { TextField } from "@material-ui/core";
import { Control, UseFormRegister, UseFormSetValue } from "react-hook-form";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

interface IProps {
  data: {
    key: number;
    name: any;
    answersList?: Array<Record<string,any>>;
    disable: boolean;
    uForm: {
      register: UseFormRegister<any>;
      setValue: UseFormSetValue<any>;
      control: Control<any, any>;
    };
  },
  rows?: number;
}

export default function GenTextarea(props: IProps){
  const { name, disable, uForm } = props.data;
  return <TextareaAutosize 
        {...uForm.register(name)}
        rowsMin={props.rows||5}
        style={{
          width:"100%",
          borderRadius:"4px",
          border: "1px solid rgb(191 191 191)"
        }}
        />;
}