/* eslint-disable no-unused-vars */
import axios from "axios";
import { getAll } from "./UniversalAPI";
import { getHospitalInfo } from "./api";
import { API_URL_FULL, PORT, SYSTEM_TOKEN, getToken } from "./auth-header";
import { getTeamsID } from "./api/index";
const API_URL = API_URL_FULL;
const register = async (param) => {
  let res = {};
  await axios.post(API_URL + "teamusers", param).then((response) => {
    res = response;
  }).catch(function (error) {
    if (error.response) {
      res = { status: error.response.status, message: error.response.data.error.message }
    }
  });
  return res;
};
const editUser = async (param) => {
  let res = {};
  await axios.post(API_URL + "teamusers", param).then((response) => {
    res = response;
  }).catch(function (error) {
    if (error.response) {
      res = { status: error.response.status, message: error.response.data.error.message }
    }
  });
  return res;
};
const changePassword = async (params) => {
  let res = {};
  let token = getToken();
  let url = API_URL + "teamusers/change-password?access_token=" + token.Authorization
  let header = { headers: token }
  await axios.post(url, params, header).then((response) => {
    res = response;
  }).catch(function (error) {
    if (error.response) {
      res = { status: error.response.status, message: error.response.data.error.message }
    }
  });
  return res;
}

const login = async (param, res) => {
  let _returnData = {response: null, isLoginError: true};
  try {
    const userinfo = await axios.post(API_URL + "teamusers/login?include=User", param);
    const user = userinfo.data.user;
    const params = {
      "where":{"hos_id": user.department.hcode},
      "include":{
        "relation":"tambol",
        "scope":{"fields":{"addressid":true, "name":true}}
      }
    };
    const token = getToken();
    const hospitalInfo = await getHospitalInfo("",params);
    // console.log("getHospitalInfo",hospitalInfo);
    if(hospitalInfo.success){
      if(hospitalInfo.response.data[0].tambol){
        user["tambon"]={
          tambon_id: hospitalInfo.response.data[0].tambol.addressid,
          tambon_name: hospitalInfo.response.data[0].tambol.name
        }
      }
    }
    const responseGetTeamsID = await getTeamsID(token);
    // console.log("getTeamsID",responseGetTeamsID);
    const allTeamsID = (responseGetTeamsID.success) ? responseGetTeamsID.response?.data.map((t)=>t.id) : [];
    let osccTeam = await getTeamIdNew(user);
    // console.log("getTeamIdNew",osccTeam);
    user["teams"] = osccTeam;
    // console.log("userinfo.data.user.teams",userinfo.data.user.teams);
    user["osccteams"] = (user.osccteams && user.osccteams.length>0)?user.osccteams.filter((t)=>allTeamsID.includes(t.teamCode)):[];
    // console.log("userinfo.data.user.osccteams",userinfo.data.user.osccteams);
    const roleName = await getAuthorize(userinfo.data);
    // console.log("getAuthorize",roleName);
    if (param.picture) { user["picture"] = param.picture; }
    if (roleName && roleName !== 'noRole') {
      user.role = roleName;
      if (typeof userinfo.data !== 'undefined') {
        localStorage.setItem("OSCC", JSON.stringify(userinfo.data));
        _returnData = { response: userinfo.data, isLoginError: false };
      } else {
        _returnData = { response: userinfo.data, isLoginError: true };
      }
    } else { // noRole 
      _returnData = { response: null, isLoginError: true };
    }
    return _returnData;
  } catch (err) {
    return { err, isLoginError: true };
  };
}
const logout = async () => {
    let token = await getToken();
    return await axios.post(API_URL + 'teamusers/logout?access_token=' + token.Authorization, {}, { headers: token });
};
const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("OSCC"));
};
const getAuthorize = async (userinfo) => {
  let auth = '';
  if (typeof userinfo.user !== 'undefined') {
    if (typeof userinfo.user.roleId !== 'undefined') { // กรณี Users มี roleId
      // console.log("Teamuser have roleId");
      let role = await axios.get(`${API_URL}/Roles`, {
        headers: { Authorization: userinfo.id },
        params: { filter: { where: { "id": userinfo.user.roleId } } }
      });
      auth = role.data[0].name;
    } else { // กรณี Users ไม่มี roleId ใช้ id ไปหาใน RoleMappings, Role
      // console.log("Teamuser don't have roleId");
      let roleMapping = await axios.get(`${API_URL}/RoleMappings`, {
        headers: { Authorization: userinfo.id },
        params: { filter: { where: { "principalId": userinfo.user.id } } }
      });
      if (typeof roleMapping.data[0] !== 'undefined') {
        let roleId = roleMapping.data[0].roleId;
        let role = await axios.get(`${API_URL}/Roles`, {
          headers: { Authorization: userinfo.id },
          params: { filter: { where: { "id": roleId } } }
        });
        // console.log("roleMapping : ", roleId);
        // console.log("role : ", role.data[0].name);
        auth = role.data[0].name;
      } else {
        auth = 'noRole';
      }
    }
  } else {
    console.log("Don't have userinfo.user ");
  }
  return auth;
};
const getRole = () => {
  let userinfo = getCurrentUser();
  if (typeof userinfo !== 'undefined' && userinfo != null) {
    let role = (typeof userinfo.user.role !== 'undefined') ? userinfo.user.role : 'noRole';
    return role;
  } else {
    return 'noRole';
  }
}
const sendEmail = async (params) => {
  let url = API_URL + "/Emails?access_token=" + SYSTEM_TOKEN;
  let header = {
    headers: { Authorization: SYSTEM_TOKEN },
    params: params
  };
  let returnData = await axios.get(url, header);
  return returnData;
}
const getTeamIdNew = async (u) => {
  let roles = [];
  let teams = u.osccteams;
  if (teams) {
    teams.forEach(osccteams => {
      roles.push(
        {
          ...osccteams,
          role: osccteams.memberTypeName,
          hospcode: u.department.hcode,
        }
      );
    });
  }
  return roles;
}
const getRegisterType = async (email) => {
  let url = API_URL + "/teamusers";
  let params = {
    filter: {
      fields: { id: true, email: true, lineRegister: true },
      where: { email: email }
    }
  };
  let header = {
    headers: { Authorization: SYSTEM_TOKEN },
    params: params
  };
  let response;
  if (typeof email !== 'undefined' && email !== "" && email != null) {
    response = await axios.get(url, header);
    if (response.data.length > 0) {
      response = response.data[0];
    } else {
      response = { error: "ไม่พบอีเมลล์ของท่านในระบบ" };
    }
  } else {
    response = { error: "กรุณาระบุอีเมลล์" };
  }
  return response;
}
export {
  register,
  login,
  logout,
  changePassword,
  getCurrentUser,
  getAuthorize,
  getRole,
  editUser,
  sendEmail,
  getRegisterType
};
