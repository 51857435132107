/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import UDataTable from "../../services/universal/UniversalDataTable";
import {
  Grid,
  Select
} from '@material-ui/core';
import useGlobal from "../../store";

export default function Questionnaire(props) {
  const [qtype, setQtype] = useState('PLH2');
  const [refresh, setRefresh] = useState(false);
  const [globalState, globalActions] = useGlobal();
  const [collection, setCollection] = useState({
    collection_name: 'questionnaires',
    title: 'แบบสอบถาม',
    port: '3009',
    // create_document: true,
    // update_document: true,
    // delete_document: true,
    forward_path: '/questionnaireedit',
    forward_props: 'document',
    primary_key: 'id',
    query_where: {questionnaire_type: 'PLH2' },
    rows_per_page: 50, // 10 20 30 40 50 60 70 80 90 100
    // search_field:['hos_id','hos_name'],
    userRole: globalState.userRole,
    fields: {
      questionnaire_code: { show: true, title: 'รหัสแบบสอบถาม', data_type: 'string' },
      questionnaire_name: { show: true, title: 'ชื่อแบบสอบถาม', data_type: 'string', format: null, input_type: 'textbox', value_length_min: null, value_length_max: null, icon: 'MdSms' },
      questionnaire_answerer: { show: true, title: 'ผู้ตอบคำถาม', data_type: 'string', format: null, input_type: 'textbox', value_length_min: null, value_length_max: null, icon: 'MdWc' },
      questionnaire_place: { show: true, title: 'สถานที่', data_type: 'string', format: null, input_type: 'select', input_select_source_type: 'json', input_select_source_name: 'questionnaire_place', input_select_source_json: [{ questionnaire_place_id: 1, questionnaire_place_name: 'ชุมชน/บ้านผู้ป่วย' }, { questionnaire_place_id: 2, questionnaire_place_name: 'รพ.สต.' }, { questionnaire_place_id: 3, questionnaire_place_name: 'โรงพยาบาล' }], icon: 'MdRoom',input_select_source_key: 'questionnaire_place_id', input_select_source_value: 'questionnaire_place_name' },
    }
  });
  const qTypeChange = (e)=>{
    setQtype(e.target.value);
    setCollection({...collection, query_where: {questionnaire_type: e.target.value } })
  }
  return (
    <div style={{marginBottom:100}}>
      <Grid container>
        <Grid item xs={12} sm={10}><h2>รายการแบบสอบถาม</h2></Grid>
        <Grid item xs={12} sm={2}>
          <Grid container justify="flex-end">
            {/* <Button variant="contained" color="primary" style={{ marginRight: 5 }} onClick={() => { history.goBack() }}>
              กลับ
            </Button> */}
              <Select fullWidth={true} variant="outlined" native
                onChange={qTypeChange}
                inputProps={{ name: "typeName", id: "typeId", }}
                value={qtype}
              >
                <option value="">ทุกประเภท</option>
                <option value="PLH1">PLH1</option>
                <option value="PLH2">PLH2</option>
              </Select>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <UDataTable structure={collection}/>
        </Grid>
      </Grid>
    </div>
  )
}
