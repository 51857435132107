const authHeader = () => {
  const LS = JSON.parse(localStorage.getItem('OSCC') || '{}');
  const TOKEN = LS?.id || '';
  if (TOKEN) {
    return { Authorization: 'Bearer ' + TOKEN };
  } else {
    return {};
  }
};

const getToken = async () => {
  const LS = JSON.parse(localStorage.getItem('OSCC') || '{}');
  const TOKEN = LS?.id || '';
  return { Authorization: TOKEN };
};


const setHeader = () => {
  const LS = JSON.parse(localStorage.getItem('OSCC') || '{}');
  const TOKEN = LS?.id || '';
  return { Authorization: TOKEN, 'content-type': 'multipart/form-data' };
};

const API_URL = process.env.REACT_APP_API_URL;
const PORT = process.env.REACT_APP_API_PORT;
const API_URL_FULL = API_URL + ':' + PORT + '/api/';
const SYSTEM_TOKEN = process.env.REACT_APP_SYSTEM_TOKEN;
export { authHeader, getToken, setHeader, API_URL, PORT, API_URL_FULL, SYSTEM_TOKEN };
