import { fork, call, takeEvery, put, all } from 'redux-saga/effects';
import actions from '../../actions/activity';
import { GET_CASES } from '../../actions';
import * as API from '../../services/api';
import { fetchEntity } from '../saga';
import { push } from 'connected-react-router';
import { snackbarFailure,snackbarSuccess } from '../../actions/notification';
import moment from "moment";

const formatDuration = (duration: moment.Duration) => {
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  let result = '';
  if (years === 1) {
      result += '1 ปี ';
  } else if (years > 1) {
      result += years + ' ปี ';
  }
  if (months === 1) {
      result += '1 เดือน ';
  } else if (months > 1) {
      result += months + ' เดือน ';
  }
  return {years: years, months: months, result:result};
};
const getCases = fetchEntity.bind(null, actions.GetCases, API.getCases);
const getPerson = fetchEntity.bind(null, actions.GetCases, API.getPerson);
const insertOsccperson = fetchEntity.bind(null, actions.InsertOsccperson, API.insertOsccperson);

function* firstAction(action: any) {
  yield call(
    getCases, { ...action },
    {
      onSuccess: (data: any) => {
        // console.log("onSuccess",action);
        if(data.length===0){
          if(action.data.where.and){
            action.data.where = { cid : action.data.where.and[1].or[2].cid.regexp};
            console.log("ไม่พบข้อมูลใน osccperson ให้ไปหาต่อใน person");
            return call(actionGetPerson,action);
          }else{
            
          }
        }
      },
      onFailure: (error:any) => {
        console.log("onFailure",error);
        return put(snackbarFailure('getCases failed',1500));
      }
    }
  );
}
function* actionGetPerson(action: any) {
  yield call(
    getPerson, { ...action },
    {
      onSuccess: (data: any) => {  
        if(data.length>0){ // ถ้าพบข้อมูลเคสใน person ให้ recheck osccperson อีกรอบ
          // console.log("พบข้อมูลใน person แต่ต้อง recheck osccperson อีกรอบก่อน");
          return call(recheckOsccperson,action,data);
        }
      },
      onFailure: () => put(snackbarFailure('getPerson failed',1500))
    }
  );
}

function* recheckOsccperson(action: any,personData:any) {
  yield call(
    getCases, { ...action },
    {
      onSuccess: (data: any) => {
        if(data.length===0){ // ถ้า recheck ด้วย cid แล้วไม่พบจริงๆ ให้ insert เพิ่มใน osccperson
          const osccpersonData = personData.map((m:any)=>{
            if(typeof m.birthdate!=="undefined"){
              const ageFromBirthDate = formatDuration(moment.duration(moment().diff(m.birthdate)));
              m["age"] = ageFromBirthDate.years;
              m["ageYM"]=ageFromBirthDate.result;
            }else{
              m["ageYM"]=m.age+" ปี";
            }
            return {
              "cid": m.cid,
              "fname": m.fname,
              "lname": m.lname,
              "birthdate": m.birthdate,
              "sex": m.sex,
              "age": m.age,
              "hn": m.hn,
              "hcode": m.hcode,
              "province_name": m.province_name,
              "amphur_id": m.amphur_id,
              "amphur_name": m.amphur_name,
              "tambol_id": m.tambol_id,
              "tambol_name": m.tambol_name,
              "villagecode": m.villagecode,
              "riskLevel": "normal",
              "caseStatus": "new",
              "import_no": moment().format("YYYYMMDD")
            }
          });
          // console.log("recheck แล้วไม่พบจริงๆ ให้เพิ่มข้อมูลนี้เข้า osccperson");
          return call(actionInsertPerson, osccpersonData);
        }
      },
      onFailure: () => put(snackbarFailure('recheckOsccperson failed',1500))
    }
  );
}

function* actionInsertPerson(osccpersonData: any) {
  yield call(
    insertOsccperson, osccpersonData,
    {
      onSuccess: (response: any) => {
        // console.log("เพิ่มข้อมูลเข้า osccperson เรียบร้อย");
      },
      onFailure: () => put(snackbarFailure('insertOsccperson failed',1500))
    }
  );
}

function* watchFirstRequest() {
  yield takeEvery(GET_CASES.REQUEST, firstAction);
}

export default function* root() {
  yield fork(watchFirstRequest);
}
