/* eslint-disable react-hooks/exhaustive-deps */
import React,{useCallback, useEffect,useMemo,useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../reducers";
import Actions from '../../actions/activity';
import { Button, Grid,Typography } from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import GroupDialog from './groupDialogAdd';
import GroupList from './groupsList';
import useDeepCompareEffect from 'use-deep-compare-effect';

const Groups=()=>{
  const history = useHistory();
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
  const userinfo = globalState.auth.user.response.user;
  // const teamCodes = userinfo.osccteams.map((item:any)=>item.teamCode);
  const groupsList = globalState.activity.groupListTeams;
  const role = globalState.auth.user.response?.user.role||"";
  const [showModalGroup, setShowModalGroup] = useState(false);

  const teamCodes = useMemo(()=>userinfo.osccteams.map((item:any)=>item.teamCode),[userinfo.osccteams]);
  
  const getGroupList=useCallback(()=>{
    if(teamCodes && teamCodes.length>0){
      const params = {teamCodes:teamCodes};
      // console.log("dispatch GetGroupList");
      dispatch(Actions.GetGroupList.request(params));
    }else{
      alert("คุณยังไม่ได้อยู่ในทีมใด กรุณาติดต่อผู้ดูแลกลุ่มของคุณ");
    }
  },[dispatch, teamCodes]);

  const [startEffect,setStartEffect]=useState(true);
  useEffect(() => {
    if(startEffect){getGroupList();}
    return ()=>{setStartEffect(false);}
  },[getGroupList, startEffect]);

  useDeepCompareEffect(() => { // ✅ ถ้าข้อมูลกลุ่ม มีการเปลี่ยนแปลงให้ปิด Dialog
    if(globalState.activity.groupListTeams.data.length>0){
      // console.log("useDeepCompareEffect",globalState.activity.groupListTeams.data);
      setShowModalGroup(false);
    }
  },[globalState.activity.groupListTeams.data]);
  useDeepCompareEffect(() => { // ✅ ถ้าข้อมูลกลุ่ม มีการเปลี่ยนแปลงให้ปิด Dialog
    if(groupsList.data.length>0){
      setShowModalGroup(false);
    }
  },[groupsList.data]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} style={{display:'flex',justifyContent:'start'}}>
        <Typography variant="h4" style={{marginBottom:0}}>จัดการกลุ่ม</Typography>
      </Grid>
      <Grid item xs={12} md={6} style={{display:'flex',justifyContent:'end'}}>
        <Button variant="outlined" color="primary" onClick={ ()=>history.push({pathname:"/activity/case"})}>กลับ</Button>
        {
          (role!=="" && ["CM","AdminTeams","AdminR8"].includes(role)) &&
            <Button variant="contained" color="primary" onClick={()=>setShowModalGroup(true)}>สร้างกลุ่ม</Button>
        }
      </Grid>
      <Grid item xs={12}>
        <GroupList />
      </Grid>
      {(showModalGroup)&&<GroupDialog open={showModalGroup} setOpen={setShowModalGroup} />}
    </Grid>
  );
}

export default Groups;