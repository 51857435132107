/* eslint-disable no-unused-vars */
import React, { useRef} from 'react';
import { Chart, Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.defaults.animation = false;
Chart.register(ChartDataLabels); // เพิ่ม plugin การจัดการ labels
Chart.register({ // เพิ่ม plugin การสร้าง lebel กลาง Doughnut
  id: "centerText",
  beforeDraw: function (chart, d) {
    if (chart.config._config.options.elements.center) {
      // Get ctx from string
      var ctx = chart.ctx;
      // radiusLength
      // Get options from the center object in options
      var centerConfig = chart.config.options.elements.center;
      var fontStyle = centerConfig.fontStyle || 'Arial';
      var txt = centerConfig.text;
      var color = centerConfig.color || '#000';
      var maxFontSize = centerConfig.maxFontSize || 75;
      var sidePadding = centerConfig.sidePadding || 20;
      var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
      // Start with a base font of 30px
      ctx.font = "30px " + fontStyle;

      // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      var stringWidth = ctx.measureText(txt).width;
      var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      // console.log(elementWidth, elementHeight, stringWidth);
      var widthRatio = elementWidth / stringWidth;
      var newFontSize = Math.floor(30 * widthRatio);
      var elementHeight = (chart.innerRadius * 2);

      // Pick a new font size so it will not be larger than the height of label.
      // console.log(newFontSize, elementHeight, maxFontSize);
      var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
      var minFontSize = centerConfig.minFontSize;
      var lineHeight = centerConfig.lineHeight || 25;
      var wrapText = true;

      if (minFontSize === undefined) {
        minFontSize = 20;
      }
      // console.log(minFontSize, fontSizeToUse)
      if (minFontSize && fontSizeToUse < minFontSize) {
        fontSizeToUse = minFontSize;
        wrapText = true;
      }

      // Set font settings to draw it correctly.
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
      ctx.font = fontSizeToUse + "px " + fontStyle;
      ctx.fillStyle = color;
      // console.log(wrapText);
      if (!wrapText) {
        // centerY -= 30;
        ctx.fillText(txt, centerX, centerY);
        return;
      }

      var lines = [];
      // if(typeof txt!=="undefined"){
        var chunks = txt.split('\n');
        for (var m = 0; m < chunks.length; m++) {
          var words = chunks[m].split(' ');
          var line;

          // Break words up into multiple lines if necessary
          for (var n = 0; n < words.length; n++) {
            var testLine = (n === 0) ? words[n] : line + ' ' + words[n];
            var metrics = ctx.measureText(testLine);
            var testWidth = metrics.width;
            // console.log(testWidth, elementWidth);
            if (testWidth > elementWidth && n > 0) {
              lines.push(line);
              line = words[n];
            } else {
              line = testLine;
            }
          }
          lines.push(line);
        }
      // }
      // Move the center up depending on line height and number of lines
      centerY -= ((lines.length - 1) / 2) * lineHeight;

      // All but last line
      for (var nn = 0; nn < lines.length; nn++) {
        ctx.fillText(lines[nn], centerX, centerY);
        centerY += lineHeight;
      }
    }
  }
});
const PieChart = (props) => {
  const chartRef = useRef(null);
  let data = {
    labels: (typeof props.chartData!=='undefined') ? props.chartData.labels:[],
    datasets: [
      {
        label: 'Red',
        data: (typeof props.chartData!=='undefined') ? props.chartData.data:[],
        backgroundColor: (typeof props.chartData!=='undefined') ? props.chartData.backgroundColor:[],
        datalabels: {
          anchor: 'center',
          backgroundColor: null,
          borderWidth: 0
        }
      },
    ]
  };
  const options = {
    responsive: true,
    plugins: {
      title: { // กำหนด properties ชื่อกราฟ
        display: true,
        text: (typeof props.chartData!=='undefined') ? props.chartData.title:"",
        font: { size: 20 },
        padding: { bottom: 20 }
      },
      legend: { // กำหนด properties legend ด้านขวา
        display: true,
        position: function (context) {
          var width = context.chart.width;
          return (width < 400) ? 'bottom' : 'right';
        },
        align: 'center',
        label: {
          fontSize: function (context) {
            var width = context.chart.width;
            var size = Math.round(width / 32);
            return {
              weight: 'bold',
              size: size
            };
          },
        }
      },
      tooltip: false, // กำหนด properties tooltip เมื่อเมาส์ hover
      datalabels: { // กำหนด properties ของ label
        color: 'white',
        padding: 6,
        font: function (context) {
          var width = context.chart.width;
          var size=0;
          if(width>500){
            size = Math.round(width / 32);
          }else if(width>400 && width<500){
            size = Math.round(width / 28);
          }else if(width>290 && width<500){
            size = Math.round(width / 20);
          }
          return {
            weight: 'bold',
            size: size
          };
        },
        borderColor: null,
        borderRadius: 0,
        borderWidth: 0,
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        // display:true,
        display: function (context) { // กำหนดวิธีแสดงผล label 
          var dataset = context.dataset;
          var count = dataset.data.length;
          var value = dataset.data[context.dataIndex];
          return value > 0;
        },
        formatter: function (value, ctx) { // กำหนดรูปแบบการแสดงผล label เป็น %
          return value;
        },
      },
      beforeDraw: function (chart) {
        console.log(chart);
      }
    },
    aspectRatio: function (context) {
      var width = context.chart.width;
      return (width < 400) ? 3 / 4 : 4 / 3;
    },
    layout: { padding: 5 }
  };
  function total(chart) {
    let data = chart.chart.data.data;
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    var total = data.reduce(reducer);
    return total;
  }
  return (
    (Object.keys(props.chartData).length > 0)&&<Pie data={data} options={options} ref={chartRef} /> 
  )
}

export default PieChart;