/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { CircularProgress, makeStyles, withStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    height: "100%",
  },
}));

const styles = (theme: any) => ({
  button: {
    height: "100%"
  },
});

const LoadingButton = (props: any) => {
  const { loading, children, color, variant, type, onClick, disabled,fullwidth } = props;
  const classes = useStyles();
  if (loading) {
    return (
      <Button 
        className={classes.button}
        fullWidth={fullwidth}
        color={color}
        variant={variant}
        disabled 
        startIcon={<CircularProgress size={18}/>}
        >
        {children}
      </Button>
    );
  } else {
    return (
      <Button
        className={classes.button}
        fullWidth={fullwidth}
        color={color}
        variant={variant}
        disabled={disabled}
        type={(type && type!=="")?type:"button"}
        onClick={onClick}
        >
        {children}
      </Button>
    );
  }
};

LoadingButton.defaultProps = {
  loading: false,
  fullWidth: false,
};

LoadingButton.propTypes = {
  fullwidth: PropTypes.bool,
  color: PropTypes.string,
  variant: PropTypes.string,
  classes: PropTypes.object,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool
};

export default withStyles(styles)(LoadingButton);
