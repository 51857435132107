/* eslint-disable react-hooks/exhaustive-deps */
import React,{ FC, useEffect } from 'react';
import { Box,  IconButton } from '@material-ui/core';
import { DataGrid, GridColDef, GridCellParams, GridRowData } from "@material-ui/data-grid";
import DeleteIcon from '@material-ui/icons/Delete';

interface IProps{
  casesList:Array<Record<any,any>>
  removeGroupCase: (id: string) => void;
}

const GroupCasesList:FC<IProps> = (props) => {
  const columns: GridColDef[] = [
    { 
      field: 'cid', 
      headerName: 'เลชบัตรประชาชน', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.12,
      // renderCell:(params: GridCellParams)=> {
      //   return params.row.cid.replace(params.row.cid.substring(3,10),"xxxxxxx");
      // }
    },
    { 
      field: 'fname', 
      headerName: 'ชื่อ', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'left',
      flex: 0.12
    },
    { 
      field: 'lname', 
      headerName: 'สกุล', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'left',
      flex: 0.12
    },
    { 
      field: 'sex', 
      headerName: 'เพศ', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.07
    },
    { 
      field: 'age', 
      headerName: 'อายุ', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.07
    },
    { 
      field: 'riskLevel', 
      headerName: 'ระดับความรุนแรง', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.1
    },
    { 
      field: 'manage', 
      headerName: 'นำออก', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center', 
      flex: 0.07,
      renderCell:(params: GridCellParams)=> {
        return (
          <>
            <IconButton 
              title='นำออก'
              onClick={()=>{props.removeGroupCase(params.row.id)}}>
              <DeleteIcon />
            </IconButton>
          </>
        )
      }
    }
  ];
  // useEffect(()=>{
  //   console.log(props.casesList);
  // },[props.casesList]);

  return (
    <Box style={{
      marginTop:3,
      width: '100%',
    }}>
      <DataGrid
        rows={props.casesList}
        columns={columns}
        autoHeight
        pageSize={8}
        rowsPerPageOptions={[8]}
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        disableDensitySelector
      />
    </Box>
  );
}

export default GroupCasesList;