/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {makeStyles} from '@material-ui/core/styles';
import * as UAPI from "../../services/UniversalAPI";
import * as ICONS from 'react-icons/md';
import { useHistory } from 'react-router-dom'
import {
  Button,
  TextField,
  CircularProgress,
  Backdrop,
  Box,
  Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { getCurrentUser } from "../../services/auth.service";

const useStyles = makeStyles((theme) => ({
  // const useStyles = makeStyles({
  TextField: {
    minHeight: 30,
    padding: 0,
    paddingLeft: 5,
  },
  TextFieldScore: {
    minHeight: 30,
    padding: 0,
    paddingLeft: 0,
    textAlign: 'center'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  tdQDetail: {
    verticalAlign: 'top',
  },
  thead: {
    backgroundColor: '#cdedff'
  },
  trA: {
    backgroundColor: 'transparent'
  },
  trB: {
    backgroundColor: '#efefef'
  }
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function QuestionnaireEdit(props) {
  const history = useHistory();
  const classes = useStyles();
  const [data, setData] = useState({});
  const [questionsList, setQuestionsList] = useState([]);
  const [returnedData, setReturnedData] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [newQuestionType, setNewQuestionType] = useState(1);
  const [scoreAnswer, setScoreAnswer] = useState(0);
  const [tempAnswer, setTempAnswer] = useState('');
  const [newAnswersList, setNewAnswersList] = useState([]);
  const [qCount, setQCount] = useState(0);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [focusQuestionIndex, setFocusQuestionIndex] = useState(null);

  const [tabValue, setTabValue] = React.useState(0);

  const userinfo = getCurrentUser();
  const [settingData, setSettingData] = React.useState([]);
  const [hospitalData, setHospitalData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [hospitalTeamData, setHospitalTeamData] = useState([]);
  const [answererData, setAnswererData] = useState([
    { answererId: 1, answererName: 'ผู้รับบริการ' },
    { answererId: 2, answererName: 'ญาติ/ผู้ดูแล' },
    { answererId: 3, answererName: 'เจ้าหน้าที่' },
  ]);
  const [newSettingAnswerer, setNewSettingAnswerer] = React.useState('');
  const [newSettingHospital, setNewSettingHospital] = React.useState('');
  const [newSettingTeam, setNewSettingTeam] = React.useState('');
  const [hCount, setHCount] = useState(0);
  const [saveButtonText, setSaveButtonText] = useState('เพิ่ม');
  const [notSaveYet, setNotSaveYet] = useState(false);

  const changeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const checkObjectData = (x) => {
    let r = [];
    if (typeof x !== 'undefined') {
      if (x !== null) {
        r = x;
      }
    }
    return r;
  }

  const checkArrayData = (x) => {
    let r = [];
    if (typeof x !== 'undefined') {
      if (x !== null) {
        r = x;
      }
    }
    return r;
  }

  const clickSave = () => {
    setOpenBackdrop(true);
    let x = data;
    x['questionsList'] = questionsList;
    handleSave(x);
  }

  const handleSave = async (x) => {
    let response = await UAPI.update(x.id, x, 'questionnaires', '3009');
    if (response.status === 200) {
      if (response.data) {
        setData(response.data);
        setTimeout(() => {
          setNotSaveYet(false);
          setOpenBackdrop(false);
        }, 500);
      }
    }
  }

  const closeBackdrop = () => {
    setOpenBackdrop(false);
  }

  const questionTypeName = (x) => {
    let n = parseInt(x);
    let r = '';
    switch (n) {
      case 1:
        r = 'ข้อความ';
        break;

      case 2:
        r = 'เลือกหนึ่งข้อ';
        break;

      case 3:
        r = 'เลือกหลายข้อ';
        break;

      default:
        break;
    }
    return r;
  }

  const DynIcon = (props) => {
    const { icon, ...other } = props;
    const X = ICONS[icon];
    return (
      <span {...other}>
        <X size={20} />
      </span>
    );
  }

  const clickDeleteQuestion = (e, i) => {
    setNotSaveYet(true);
    let x = [...questionsList];
    let index = x.indexOf(i);
    if (index > -1) {
      x.splice(index, 1);
    }
    setQuestionsList(x);
    setQCount(x.length);
  }

  const clickEditQuestion = (e, i) => {
    setNotSaveYet(true);
    setSaveButtonText('แก้ไข');
    let x = [...questionsList];
    let index = x.indexOf(i);
    setFocusQuestionIndex(index);

    let z = JSON.parse(JSON.stringify(i)); // การ CLONE ที่ดีกว่า {...i}
    setNewQuestion(z.question);
    setNewQuestionType(z.questionType);
    setNewAnswersList(z.answersList);
    if (parseInt(z.questionType) === 2 || parseInt(z.questionType) === 3) {
      setInputDisabled(false);
    }
    else {
      setInputDisabled(true);
    }
  }

  const mkQuestionsList = () => {
    let q = [...questionsList];
    let qList = [];
    qList.push(<tr key={0}><td colSpan={7} style={{ height: 0 }}></td></tr>);
    let n = 0;
    q.forEach(i => {
      ++n;
      let tr = [];
      let aList = [];
      let aList_n = 0;
      let trClass = (n % 2 === 0 ? classes.trA : classes.trB);
      if (typeof i.answersList !== 'undefined' && typeof i.answersList !== 'string') {
        i.answersList.forEach(a => {
          ++aList_n;
          aList.push(<div key={aList_n}>{a.score}: {a.answer}</div>);
        });
      }
      tr.push(<td key={'l'} style={{ borderTopLeftRadius: 20, borderBottomLeftRadius: 20, width: 20 }}></td>);
      tr.push(<td className={classes.tdQDetail} key={'n'}>{n}</td>);
      tr.push(<td className={classes.tdQDetail} key={'question'} width="50%">{i.question}</td>);
      tr.push(<td className={classes.tdQDetail} key={'questionType'}>{questionTypeName(i.questionType)}</td>);
      tr.push(<td className={classes.tdQDetail} key={'answersList'}>{aList}</td>);
      tr.push(
        <td className={classes.tdQDetail} key={'action'}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white' }}
            startIcon={<DynIcon icon='MdEdit' style={{ marginLeft: 11, marginTop: -2 }} />}
            onClick={(e) => clickEditQuestion(e, i)}
          />
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white' }}
            startIcon={<DynIcon icon='MdDelete' style={{ marginLeft: 11, marginTop: -2 }} />}
            onClick={(e) => clickDeleteQuestion(e, i)}
          />
        </td>
      );
      tr.push(<td key={'r'} style={{ borderTopRightRadius: 20, borderBottomRightRadius: 20, width: 20 }}></td>);
      qList.push(<tr key={n} className={trClass}>{tr}</tr>);
    });
    qList.push(<tr key={n + 1}><td colSpan={7} style={{ height: 0 }}></td></tr>);
    qList.push(<tr key={n + 2}><td colSpan={7} style={{ textAlign: 'right', padding: 0, paddingRight: 10, paddingBottom: 15, borderTop: 'solid 1px #A0A0A0' }}>คำถามทั้งหมด {qCount} ข้อ</td></tr>);
    return qList;
  }

  const ClickDeleteAnswer = (e, i) => {
    let x = [...newAnswersList];
    let index = x.indexOf(i);
    if (index > -1) {
      x.splice(index, 1);
    }
    setNewAnswersList(x);
  }

  const editScoreAnswer = (e, i) => {
    let v = (e.target.value === '' ? '' : parseInt(e.target.value));
    let x = [...newAnswersList];
    let index = x.indexOf(i);
    x[index].score = v;
    setNewAnswersList(x);
  }

  const editTempAnswer = (e, i) => {
    let x = [...newAnswersList];
    let index = x.indexOf(i);
    x[index].answer = e.target.value;
    setNewAnswersList(x);
  }

  const mkNewAnswersList = () => {
    let d = [];
    d.push(<div key={'0'}>คะแนน/คำตอบ</div>);
    let n = 0;
    if (newAnswersList) {
      if (newAnswersList.length > 0) {
        let x = [...newAnswersList];
        x.forEach(i => {
          ++n;
          d.push(
            <div key={n}>
              <TextField
                value={i.score}
                variant="outlined"
                style={{ marginBottom: 8, width: 30 }}
                InputProps={{ classes: { input: classes.TextFieldScore } }}
                onChange={(e) => editScoreAnswer(e, i)}
              />
              <TextField
                value={i.answer}
                variant="outlined"
                style={{ marginBottom: 8 }}
                InputProps={{ classes: { input: classes.TextField } }}
                onChange={(e) => editTempAnswer(e, i)}
              />
              <Button
                onClick={(e) => ClickDeleteAnswer(e, i)}
                variant="contained"
                color="primary"
                style={{ marginLeft: 1, height: 30, minWidth: '40px', maxWidth: '40px', color: 'white' }}
              >
                -
              </Button>
            </div>
          );
        });
      }
    }
    return (<div>{d}</div>);
  }

  const clickAddQuestion = () => {
    setNotSaveYet(true);
    if (focusQuestionIndex === null) {
      let n = {
        question: newQuestion,
        questionType: parseInt(newQuestionType),
        answersList: newAnswersList
      };
      let x = questionsList;
      x.push(n);
      setQuestionsList(x);
      setQCount(x.length);
    }
    else {
      let x = [...questionsList];
      x[focusQuestionIndex].question = newQuestion;
      x[focusQuestionIndex].questionType = newQuestionType;
      x[focusQuestionIndex].answersList = newAnswersList;
      setQuestionsList(x);
    }
    setNewQuestion('');
    setNewQuestionType(1);
    setNewAnswersList([]);
    setFocusQuestionIndex(null);
    setSaveButtonText('เพิ่ม');
  }

  const changeNewQuestion = (e) => {
    setNewQuestion(e.target.value);
  }

  const changeNewQuestionType = (e) => {
    setNewQuestionType(e.target.value);
    if (parseInt(e.target.value) === 2 || parseInt(e.target.value) === 3) {
      setInputDisabled(false);
    }
    else {
      setInputDisabled(true);
    }
  }

  const changeTempAnswer = (e) => {
    setTempAnswer(e.target.value);
  }

  const changeScoreAnswer = (e) => {
    setScoreAnswer(e.target.value);
  }

  const ClickAddAnswer = () => {
    if (tempAnswer) {
      if (tempAnswer !== '') {
        let score = 0;
        if (scoreAnswer !== '') {
          score = scoreAnswer;
        }
        let x = [...newAnswersList];
        x.push({ answer: tempAnswer, score: parseInt(score) });
        setNewAnswersList(x);

        let d = [];
        let n = 0;
        x.forEach(i => {
          ++n;
          d.push(<div key={n}>{i.score}: {i.answer}</div>);
        });
        setScoreAnswer(0);
        setTempAnswer('');
      }
    }
  }

  const mkHospitalList = () => {
    // console.log(settingData);
    let q = [...settingData];
    let qList = [];
    qList.push(<tr key={0}><td colSpan={6} style={{ height: 0 }}></td></tr>);
    let n = 0;
    q.forEach(i => {
      ++n;
      let tr = [];
      let trClass = (n % 2 === 0 ? classes.trA : classes.trB);
      tr.push(<td key={'l'} style={{ borderTopLeftRadius: 20, borderBottomLeftRadius: 20, width: 20 }}></td>);
      tr.push(<td className={classes.tdQDetail} key={'n'}>{n}</td>);
      tr.push(<td className={classes.tdQDetail} key={'hcode'}>{findNameHospital(i.hcode)}</td>);
      tr.push(<td className={classes.tdQDetail} key={'teamCode'}>{findNameTeam(i.teamCode)}</td>);
      tr.push(<td className={classes.tdQDetail} key={'answererId'}>{findNameAnswerer(i.answererId)}</td>);
      tr.push(
        <td className={classes.tdQDetail} key={'action'}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white' }}
            startIcon={<DynIcon icon='MdEdit' style={{ marginLeft: 11, marginTop: -2 }} />}
            onClick={(e) => clickEditHospital(e, i)}
          />
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white' }}
            startIcon={<DynIcon icon='MdDelete' style={{ marginLeft: 11, marginTop: -2 }} />}
            onClick={(e) => clickDeleteHospital(e, i)}
          />
        </td>
      );
      tr.push(<td key={'r'} style={{ borderTopRightRadius: 20, borderBottomRightRadius: 20, width: 20 }}></td>);
      qList.push(<tr key={n} className={trClass}>{tr}</tr>);
    });
    qList.push(<tr key={n + 1}><td colSpan={6} style={{ height: 0 }}></td></tr>);
    qList.push(<tr key={n + 2}><td colSpan={6} style={{ textAlign: 'right', padding: 0, paddingRight: 10, paddingBottom: 15, borderTop: 'solid 1px #A0A0A0' }}>หน่วยงานที่ถูกตั้งค่า {hCount} แห่ง</td></tr>);
    return qList;
  }

  const findNameHospital = (x) => {
    let n = x;
    hospitalData.forEach(i => {
      if (x === i.hos_id) {
        n = "[" + i.hos_id + "] " + i.hos_name;
      }
    });
    return n;
  }

  const findNameTeam = (x) => {
    let n = x;
    teamData.forEach(i => {
      if (x === i.teamCode) {
        n = i.teamName;
      }
    });
    return n;
  }

  const findNameAnswerer = (x) => {
    let n = x;
    answererData.forEach(i => {
      if (x === i.answererId) {
        n = i.answererName;
      }
    });
    return n;
  }

  const getHospitalData = async () => {
    let xParams = {
      filter: {
        limit: 40,
        where: {
          and: [
            { hos_ampid: userinfo.user.amphur.amphur_id },
            { hos_type_id: { in: ["2", "3", "4", "6"] } },
          ]
        },
        order: "hos_type_id ASC, hos_id ASC"
      }
    };

    let response = await UAPI.getAll(xParams, 'hospitals', '3009');
    if (response.status === 200) {
      if (response.data) {
        // console.log(response.data);
        setHospitalData(response.data);
      }
    }
  }

  const getTeamData = async () => {
    let xParams = {
      filter: {
        // limit: 20,
        where: {
          and: [
            { 'amphur.amphur_id': userinfo.user.amphur.amphur_id },
            { "application": { "like": "OSCC", "options": "i" } }
          ]
        },
        order: "department.hcode DESC"
      }
    };
    let response = await UAPI.getAll(xParams, 'teamusers', '3009');
    if (response.status === 200) {
      if (response.data) {
        // console.log(response.data);
        let t = [];
        response.data.forEach(i => {
          if (typeof i.osccteams !== 'undefined') {
            i.osccteams.forEach(z => {
              let n = 0;
              t.forEach(v => {
                if (v.teamCode === z.teamCode) {
                  n = 1;
                }
              });
              let headHcode = '';
              if (parseInt(z.memberTypeId) === 1) {
                headHcode = i.department.hcode;
              }
              let teamData = { teamCode: z.teamCode, teamName: z.teamName, headHcode: headHcode, anyHcode: i.department.hcode };
              if (n === 0) {
                t.push(teamData);
              }
            });
          }
        });
        let tt = [];
        t.forEach(i => {
          if (i.headHcode === '') {
            tt.push({ teamCode: i.teamCode, teamName: i.teamName, hcode: i.headHcode });
          }
          else {
            tt.push({ teamCode: i.teamCode, teamName: i.teamName, hcode: i.anyHcode });
          }
        });
        // console.log(tt);
        setTeamData(tt);
      }
    }
  }

  const changeNewSettingHospital = (e) => {
    setNewSettingHospital(e.target.value);
    // console.log(e.target.value);
    let x = [];
    teamData.forEach(t => {
      if (e.target.value === t.hcode) {
        // console.log(t);
        x.push(t);
      }
    });
    setHospitalTeamData(x);
  }

  const changeNewSettingTeam = (e) => {
    setNewSettingTeam(e.target.value);
  }

  const changeNewSettingAnswerer = (e) => {
    setNewSettingAnswerer(e.target.value);
  }

  const clickAddHospital = () => {
    let x = JSON.parse(JSON.stringify(settingData));
    let new_x = [];
    x.forEach(i => {
      if (i.hcode !== newSettingHospital) {
        new_x.push(i);
      }
    });
    new_x.push({ answererId: parseInt(newSettingAnswerer), hcode: newSettingHospital, teamCode: newSettingTeam });
    // console.log(new_x);
    setSettingData(new_x);
    setHCount(new_x.length);

    setNewSettingHospital('');
    setNewSettingTeam('');
    setNewAnswersList('');
  }

  const clickSaveSetting = async () => {
    setOpenBackdrop(true);
    let x = JSON.parse(JSON.stringify(data));
    x.defaultSetting=settingData;
    let response = await UAPI.update(x.id, x, 'questionnaires', '3009');
    if (response.status === 200) {
      if (response.data) {
        let r=response.data;
        setData(r);
        let z = checkArrayData(r.questionsList);
        setQuestionsList(z);
        setQCount(z.length);
    
        if (typeof r.defaultSetting !== 'undefined') {
          setSettingData(r.defaultSetting);
          getHospitalData();
          getTeamData();
          setHCount(r.defaultSetting.length);
        }

        setNewSettingHospital('');
        setNewSettingTeam('');
        setNewAnswersList('');
        
        setTimeout(() => {
          setOpenBackdrop(false);
        }, 500);
      }
    }
  }

  const clickDeleteHospital = (e,i) => {
    let x = JSON.parse(JSON.stringify(settingData));
    let new_x=[];
    x.forEach(z => {
      if (z.hcode!==i.hcode) {
        new_x.push(z);
      }
    });
    setSettingData(new_x);
    setHCount(new_x.length);
  }

  const clickEditHospital = (e,i) => {
    setNewSettingHospital(i.hcode);
    setNewSettingAnswerer(i.answererId.toString());
    
    let x = [];
    teamData.forEach(t => {
      if (i.hcode === t.hcode) {
        x.push(t);
      }
    });
    setHospitalTeamData(x);
    setNewSettingTeam(i.teamCode);
  }

  
  useEffect(() => {
    let x = checkObjectData(props.location.state);
    setData(x);
    let z = checkArrayData(x.questionsList);
    setQuestionsList(z);
    setQCount(z.length);

    getHospitalData();
    getTeamData();
    if (typeof x.defaultSetting !== 'undefined') {
      setSettingData(x.defaultSetting);
      setHCount(x.defaultSetting.length);
    }

  }, [props]); 

  return (
    <div style={{ marginBottom: 100 }}>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Backdrop className={classes.backdrop} open={openBackdrop} onClick={closeBackdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
        <Grid item xs={12} sm={12} style={{margin:"1.5rem 0"}}>
          <Grid container>
            <Grid item xs={12} sm={9}>
              <h4>
                แก้ไขคำถาม :&nbsp;
                {props.location.state.questionnaire_code ? '['+props.location.state.questionnaire_code+']' : ''}&nbsp;
                {props.location.state.questionnaire_name ? props.location.state.questionnaire_name : ''}
              </h4>
            </Grid>
            <Grid item xs={12} sm={3} style={{ display: 'flex', justifyContent: 'space-between'}}>
              <Button variant="outlined" color="primary" fullWidth style={{ marginRight: 5 }} onClick={() => { history.goBack() }}>
                กลับ
              </Button>
              <Button variant="contained" color="primary" fullWidth style={{ marginRight: 5 }}
                disabled={notSaveYet}
                onClick={() => {
                  history.push({
                    pathname: '/questionnaireview',
                    state: {
                      questionData: {
                        returnAnswerTo: '/questionnaireedit',
                        veiwType: 'readonly',
                        id: data.id,
                        // planServiceId: i.planServiceId,
                        // interventionId: (typeof i.interventionId === 'undefined'?null:(i.interventionId === ''?null:i.interventionId))
                      },
                      // caseData: props.location.state,
                    }
                  });
                }}
              >
                ทดสอบ
              </Button>
              <Button variant="contained" color="primary" fullWidth onClick={clickSave}>
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <table cellSpacing={0} cellPadding={4} border={0} width="100%">
            <thead>
              <tr className={classes.thead}>
                <td style={{ borderTopLeftRadius: 20, borderBottomLeftRadius: 20, width: 20 }}></td>
                <td>ลำดับ</td>
                <td>คำถาม</td>
                <td>ประเภทคำถาม</td>
                <td>คำตอบ</td>
                <td><br /></td>
                <td style={{ borderTopRightRadius: 20, borderBottomRightRadius: 20, width: 20 }}></td>
              </tr>
            </thead>
            <tbody>
              {mkQuestionsList()}
              <tr>
                <td><br /></td>
                <td><br /></td>
                <td style={{ verticalAlign: 'top' }}>
                  <div>คำถาม</div>
                  <TextField
                    // label={field_config.title}
                    // name={field_key}
                    // defaultValue={newData[field_key]}
                    value={newQuestion}
                    InputProps={{ classes: { input: classes.TextField } }}
                    variant="outlined"
                    style={{ marginBottom: 8, width: 250 }}
                    onChange={changeNewQuestion}
                    helperText={''}
                  // FormHelperTextProps={{ className: classes.helperText }}
                  />
                </td>
                <td style={{ verticalAlign: 'top' }}>
                  <div>ประเภทคำถาม</div>
                  <select
                    // style={{ height: 25 }} 
                    // value={getYear(date)} 
                    value={newQuestionType}
                    onChange={changeNewQuestionType}
                  >
                    <option value={1}>ข้อความ</option>
                    <option value={2}>เลือกหนึ่งข้อ</option>
                    <option value={3}>เลือกหลายข้อ</option>
                  </select>
                </td>
                <td style={{ verticalAlign: 'top' }}>
                  <div>
                    <div>
                      {mkNewAnswersList()}
                    </div>
                    <div>
                      <TextField
                        disabled={inputDisabled}
                        value={scoreAnswer}
                        variant="outlined"
                        style={{ marginBottom: 8, width: 30 }}
                        InputProps={{ classes: { input: classes.TextFieldScore } }}
                        onChange={changeScoreAnswer}
                        helperText={''}
                      // FormHelperTextProps={{ className: classes.helperText }}
                      />
                      <TextField
                        disabled={inputDisabled}
                        value={tempAnswer}
                        variant="outlined"
                        style={{ marginBottom: 8 }}
                        InputProps={{ classes: { input: classes.TextField } }}
                        onChange={changeTempAnswer}
                        helperText={''}
                      // FormHelperTextProps={{ className: classes.helperText }}
                      />
                      <Button
                        disabled={inputDisabled}
                        onClick={ClickAddAnswer}
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: 1, height: 30, minWidth: '40px', maxWidth: '40px', color: 'white' }}
                      >
                        +
                      </Button>
                    </div>
                  </div>
                </td>
                <td style={{ verticalAlign: 'top' }}>
                  <div><br /></div>
                  <Button variant="contained" color="primary"
                    onClick={clickAddQuestion}
                  >
                    {/* เพิ่ม/แก้ไข */}
                    {saveButtonText}
                  </Button>
                </td>
                <td><br /></td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>
    </div>
  )
}
