/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect } from "react";
import * as types from "../../../actions";
import * as XLSX from "xlsx";
import { Grid, createStyles, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Ajv from "ajv";
import { ScriptCheckID } from "../../../utils/cidCheck";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers";

import ajvErrors from "ajv-errors";
import { innerSchema } from "../utils";
import ImportTable from "./importTable";
import _ from "lodash/index";
import insertOsccPersonImport from "../../../actions/osccperson/importOsccperson";
import moment from "moment";

const useStyles = makeStyles(() =>
  createStyles({
    root: { flexGrowl: 1 },
    input: {
      display: "none",
    },
  })
);

const OsccImportExcel = () => {
  const [fileName, setFileName] = React.useState();
  const [err, serError] = React.useState(true);
  const importData = useSelector(
    (state: RootState) => state.osccperson.importOsccperson
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (importData.importData.length > 0) {
      dispatch({ type: types.OSCCPERSON.CLEAR });
    }
  }, [err]); 

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setFileName(file?.name as any);
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const workbook = XLSX.read(event.target?.result as string, {
          type: "binary",
        });

        const ajv = new Ajv({
          coerceTypes: "array",
          validateFormats: true,
          allErrors: true,
          verbose: true,
          allowDate: true,
          allowUnionTypes: true,
        });

        ajv.addFormat("cidcheck", {
          type: "string",
          validate: ScriptCheckID,
        });
        ajvErrors(ajv);

        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          blankrows: false,
          range: 1,
          defval: "",
          header: [
            "cid",
            "hcode",
            "fname",
            "lname",
            "hn",
            "sex",
            "age",
            "province_name",
            "amphur_id",
            "amphur_name",
            "tambol_id",
            "tambol_name",
            "villagecode",
            "physical",
            "mental",
            "sexual",
            "riskLevel",
          ],
        }) as any;

        const result = jsonData
          .filter((x: any) => x.cid)
          .map((x: any) => {
            const transformer = {
              ...x,
              cid: x.cid.toString().replace(/\D/g, ""),
            };
            const validate = ajv.compile(innerSchema);
            const valid = validate(transformer);
            const error_arr: any = [];

            validate.errors?.map((x: any) => {
              return error_arr.push({
                message: x.message,
                path: x.instancePath,
              });
            });

            return {
              ...transformer,
              isValid: valid,
              errors: error_arr || [],
            };
          });

        const error_recode = _(result).find((err: any) => {
          return err.errors.length > 0;
        });

        error_recode === undefined && serError(false);

        dispatch({
          type: types.OSCCPERSON.IMPORT,
          payload: result,
        });
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleImport = () => {
    const data = importData.importData;
    const result = data.map((x: any) => {
      const severity = [];

      x.physical === "Y" && severity.push("ทางร่่างกาย");
      x.sexual === "Y" && severity.push("ทางเพศ");
      x.mental === "Y" && severity.push("ทางจิตใจ");

      return {
        severityType: severity,
        age: x.age,
        amphur_id: x.amphur_id,
        amphur_name: x.amphur_name,
        cid: x.cid,
        fname: x.fname,
        hcode: x.hcode,
        hn: x.hn,
        lname: x.lname,
        mental: x.mental,
        physical: x.physical,
        province_name: x.province_name,
        riskLevel: x.riskLevel,
        sex: x.sex,
        sexual: x.sexual,
        tambol_id: x.tambol_id,
        tambol_name: x.tambol_name,
        villagecode: x.villagecode,
        insertType: "Import",
        caseStatus: "new",
        import_no: moment().format("YYYYMMDD"),
      };
    });

    if (result.length === 0) {
      return;
    }

    return dispatch(insertOsccPersonImport.request(result));
  };

  const classes = useStyles();

  return (
    <div>
      {JSON.stringify(err)}
      <Grid container spacing={2} className={classes.root}>
        <Grid item lg={12}>
          <Grid container justify="flex-end" spacing={2}>
            <Grid item>
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span">
                  Upload
                </Button>
                <input
                  type="file"
                  onChange={handleFileUpload}
                  className={classes.input}
                  id="contained-button-file"
                />{" "}
                {fileName}
              </label>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={err}
                onClick={handleImport}
              >
                Import
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br></br>
      {importData.importData.length > 0 && <ImportTable />}
    </div>
  );
};

export default OsccImportExcel;
