/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// import React, { useState, useEffect } from "react";
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import useGlobal from "../../store";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import * as UAPI from "../../services/UniversalAPI";
import UCard from "../../services/universal/UniversalCard";

import {
  Grid,
  Button,
  ButtonGroup,
  CircularProgress,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextareaAutosize,
} from "@material-ui/core";

import { MdRemoveRedEye as MdRemoveRedEyeIcon } from 'react-icons/md';
import CaseInfoBar from "./CaseInfoBar";

const useStyles = makeStyles((theme) => ({
  // const useStyles = makeStyles({
  TextField: {
    minHeight: 30,
    padding: 0,
    paddingLeft: 5,
  },
  TextFieldScore: {
    minHeight: 30,
    padding: 0,
    paddingLeft: 0,
    textAlign: 'center'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  tdQDetail: {
    verticalAlign: 'top',
  },
  thead: {
    backgroundColor: '#cdedff'
  },
  trA: {
    backgroundColor: 'transparent'
  },
  trB: {
    backgroundColor: '#efefef'
  }
}));

export default function Cases(props) {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [personData, setPersonData] = useState({});
  const [evaluationData, setEvaluationData] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [globalState, globalActions] = useGlobal();
  const [thisFocusedCaseStatus, setThisFocusedCaseStatus] = useState(globalState.focusedCaseStatus);
  const [hcode, setHcode] = useState(globalState.currentUser.user.department.hcode);
  const [hname, setHname] = useState(globalState.currentUser.user.department.hos_name);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [dialogSet, setDialogSet] = useState('');
  const [radioRiskLevel, setRadioRiskLevel] = useState(null);
  const [radioImproveLevel, setRadioImproveLevel] = useState(null);
  const [summaryNote, setSummaryNote] = useState('');


  const getPlanServiceData = async (personId) => {
    // let xParams = {
    //   filter: {
    //     // where: { personId: personId },
    //     where: { osccPersonId: personId },
    //   }
    // };
    let xParams = {
      filter: {
        where: { osccPersonId: personId },
        order: "planDate DESC",
        limit: 1
      }
    };
    let response = await UAPI.getAll(xParams, 'planservices', null);
    if (response.status === 200) {
      if (response.data) {
        // console.log(response.data);
        let evaluation = [];
        // let x = response.data;
        // x.forEach(i => {
        //   i.serviceslotList.forEach(ev => {
        //     let ed = ev;
        //     ed['planServiceId'] = i.id;
        //     evaluation.push(ed);
        //   });
        // });
        let x = response.data[0];
        x.serviceslotList.forEach(ev => {
          let ed = ev;
          ed['planServiceId'] = x.id;
          evaluation.push(ed);
        });
        setEvaluationData(evaluation);

        if (typeof x.summaryRiskLevel !=='undefined') {
          setRadioRiskLevel(x.summaryRiskLevel);
        }
        if (typeof x.improveLevel !=='undefined') {
          setRadioImproveLevel(x.improveLevel);
        }
        if (typeof x.summaryNote !=='undefined') {
          setSummaryNote(x.summaryNote);
        }

      }
    }
    return null;
  }

  const getPersonData = async (cid) => {
    let xParams = {
      filter: {
        limit: 1,
        where: { cid: cid },
      }
    };
    let response = await UAPI.getAll(xParams, 'people', null);
    if (response.status === 200) {
      if (response.data) {
        let d = response.data[0];
        let tambolInfo="";
        let paramTambol = { filter: { 
          limit: 1,
          where: { addressid: d.chwpart+d.amppart+d.tmbpart } 
        }};
        let responseTambol = await UAPI.getAll(paramTambol,"tambols");
        if (responseTambol.status === 200) {
          if (responseTambol.data.length>0) {
            // console.log(responseTambol.data);
            tambolInfo=responseTambol.data[0].full_name;
          }
        }
        d['addressInfo']=d.addrpart+' ม.'+parseInt(d.moopart)+' '+tambolInfo;
        d['riskLevel']=props.location.state['riskLevel'];
        d['severityType']=props.location.state['severityType'];
        // console.log(d);
        setPersonData(d);
      }
    }
  }

  const currentDate = () => {
    let d = new Date();
    let dY = d.getFullYear().toString();
    let dM = addZero(d.getMonth() + 1, 2);
    let dD = addZero(d.getDate(), 2);
    return dY + '-' + dM + '-' + dD;
  }

  const currentTime = () => {
    let d = new Date();
    let dHH = addZero(d.getHours(), 2);
    let dMM = addZero(d.getMinutes(), 2);
    let dSS = addZero(d.getSeconds(), 2);
    return dHH + ':' + dMM + ':' + dSS;
  }

  const calVN = () => {
    let d = new Date();
    let dY = (d.getFullYear() + 543).toString().substr(-2);
    let dM = addZero(d.getMonth() + 1, 2);
    let dD = addZero(d.getDate(), 2);
    let dHH = addZero(d.getHours(), 2);
    let dMM = addZero(d.getMinutes(), 2);
    let dSS = addZero(d.getSeconds(), 2);
    return dY + dM + dD + dHH + dMM + dSS;
  }

  const addZero = (n, z) => {
    let r = n.toString();
    for (var i = (parseInt(z) - n.toString().length); i > 0; i--) {
      r = '0' + r;
    }
    return r;
  }

  const closeBackdrop = () => {
    setTimeout(() => {
      setOpenBackdrop(false);
    }, 100);
    // setOpenBackdrop(false);
  }

  const mkRows = () => {
    let r = [];
    if (evaluationData.length > 0) {
      let n = 0;
      let rowColor = "#FFFFFF";
      let score = null;
      let ss = evaluationData;
      ss.sort(function (a, b) {
        // b-a เรียงมากไปน้อย
        // a-b เรียงน้อยไปมาก
        let dateA = a.startDate;
        let dateB = b.startDate;
        dateA = (typeof dateA === 'undefined'?'0000-00-00':dateA);
        dateB = (typeof dateB === 'undefined'?'0000-00-00':dateB);
        dateA = (dateA === null|dateA===''?'0000-00-00':dateA);
        dateB = (dateB === null|dateB===''?'0000-00-00':dateB);
        let ax = parseInt((new Date(dateA).getTime()).toString());
        let bx = parseInt((new Date(dateB).getTime()).toString());
        ax = (isNaN(ax) ? 0 : ax);
        bx = (isNaN(bx) ? 0 : bx);
        return ax - bx;
        // return bx - ax;
      });
      // console.log(ss);
      ss.forEach(i => {
        let status = 'onprocess';
        // console.log(i);
        ++n;
        if (n % 2 === 0) {
          rowColor = "#EEEEEE";
        }
        else {
          rowColor = "#FFFFFF";
        }

        // if (typeof i.status != 'undefined') {
        //   if (i.status === 'completed') {
        //     status = i.status
        //   }
        // }

        if (typeof i.interventionId !== 'undefined') {
          if (i.interventionId!==''&&i.interventionId!==null) {
            status='completed';
          }
        }

        if (typeof i.score !== 'undefined') {
          if (i.score !== null && i.score.toString().trim() !== '') {
            score = parseInt(i.score);
          }
        }

        r.push(
          <Grid key={n} item xs={12} sm={12} style={{ border: 'solid 1px #E2E2E2', borderRadius: 7, marginTop: 10, padding: 2, backgroundColor: rowColor }}>
            <Grid container direction={isDesktop ? "row" : "column-reverse"}>
              <Grid item xs={12} sm={11}>
                <Grid container justify="flex-start" style={{ padding: 5, marginTop: isDesktop?0:-30 }}>
                  <Grid item xs={12} sm={1}>
                    {isDesktop ? <b>&nbsp;&nbsp;</b> : <b>ลำดับ: </b>}
                    {n}
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    {isDesktop ? '' : <b>แบบสอบถาม/กิจกรรม: </b>}
                    {i.serviceslotName}
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    {isDesktop ? '' : <b>เริ่ม: </b>}
                    {UAPI.dateTHs(i.startDate)}
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    {isDesktop ? '' : <b>สิ้นสุด: </b>}
                    {UAPI.dateTHs(i.endDate)}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {isDesktop ? '' : <b>สถานะ: </b>}
                    {status === 'completed' ? (
                      <div style={{ backgroundColor: '#47C10D', color: '#FFFFFF', borderRadius: 15, display: 'inline-block', paddingLeft: 10, paddingRight: 10, height: 29 }}>ดำเนินการแล้ว</div>
                    ) : (
                      <div style={{ backgroundColor: '#FF7A00', color: '#FFFFFF', borderRadius: 15, display: 'inline-block', paddingLeft: 10, paddingRight: 10, height: 29 }}>กำลังดำเนินการ</div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    {isDesktop ? '' : <b>คะแนน: </b>}
                    {score && score >= 0 ? (
                      <font>{score}</font>
                    ) : (
                      <font>-</font>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Grid container justify="flex-end">
                  <Button
                    onClick={() => {
                      let questionData = {
                        returnAnswerTo: '/caseconclude',
                        veiwType: props.location.state.caseStatus === "assignedOfficer" || props.location.state.caseStatus === "sentCM" || props.location.state.caseStatus === "completed" ? 'readonly' : 'recordable',
                        id: i.questionnaireId,
                        planServiceId: i.planServiceId,
                        interventionId: (typeof i.interventionId === 'undefined' ? null : (i.interventionId === '' ? null : i.interventionId))
                      };
                      // let questionData = { 
                      //   returnAnswerTo: '/caseplanlist', 
                      //   veiwType: props.location.state.caseStatus==="assignedOfficer"||props.location.state.caseStatus==="sentCM"?'readonly':'recordable', 
                      //   id: i.questionnaireId, 
                      //   questionnaire: i.questionnaire,
                      //   planServiceId: i.planServiceId,
                      //   interventionId: (typeof i.interventionId === 'undefined'?null:(i.interventionId === ''?null:i.interventionId))
                      // };
                      // console.log(questionData);
                      history.push({
                        pathname: '/questionnaireview',
                        state: {
                          questionData: questionData,
                          caseData: props.location.state,
                          personData: personData
                        }
                      });
                    }}
                    variant="contained"
                    color="primary"
                    // className={classes.button}
                    startIcon={<MdRemoveRedEyeIcon style={{ marginLeft: 11, marginTop: -2 }} />}
                    style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white' }}
                  />
                  &nbsp;&nbsp;
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      });
    }
    return r;
  }
  

  const confirmCancelSentCM = async () => {
    // console.log('confirmCancelCase');
    setOpenDialog(false);
    setOpenBackdrop(true);
    let x = JSON.parse(JSON.stringify(props.location.state));
    let a = {};
    for (const [k, v] of Object.entries(x)) {
      if (k !== 'focusedCaseStatus') {
        a[k] = v;
      }
    }
    a['caseStatus'] = 'recievedCase';
    // console.log(a);
    let responseUpdate = await UAPI.update(a.id, a, 'osccpeople', '3009');
    if (responseUpdate.status === 200) {
      if (responseUpdate.data) {
        setTimeout(() => {
          setOpenBackdrop(false);
          history.push({ pathname: '/cases', state: { status: thisFocusedCaseStatus } });
        }, 100);
      }
    }
  }

  const confirmCancelCase = async () => {
    // console.log('confirmCancelCase');
    setOpenDialog(false);
    setOpenBackdrop(true);
    let x = JSON.parse(JSON.stringify(props.location.state));
    let a = {};
    for (const [k, v] of Object.entries(x)) {
      if (k !== 'focusedCaseStatus') {
        a[k] = v;
      }
    }
    a['caseStatus'] = 'sentCM';
    // console.log(a);
    let responseUpdate = await UAPI.update(a.id, a, 'osccpeople', '3009');
    if (responseUpdate.status === 200) {
      if (responseUpdate.data) {
        let xParams = {
          filter: {
            // where: { personId: personId },
            where: { osccPersonId: a.id },
            order: "planDate DESC",
            limit: 1
          }
        };
        let response = await UAPI.getAll(xParams, 'planservices', '3009');
        if (response.status === 200) {
          if (response.data) {
            // console.log(response.data);
            let x = response.data[0];
            let a = {};
            for (const [k, v] of Object.entries(x)) {
              if (k !== 'completeStatus' && k !== 'completeDateTime') {
                a[k] = v;
              }
            }
            // console.log(a);
            let responseUpdate = await UAPI.update(a.id, a, 'planservices', '3009');
            if (responseUpdate.status === 200) {
              // console.log(responseUpdate.data);
              setTimeout(() => {
                setOpenBackdrop(false);
                history.push({ pathname: '/cases', state: { status: thisFocusedCaseStatus } });
              }, 100);
            }
          }
        }
      }
    }
  }

  const confirmRenewCase = async () => {
    // console.log('confirmRenewCase ---');
    setOpenDialog(false);
    setOpenBackdrop(true);
    let x = JSON.parse(JSON.stringify(props.location.state));
    let a = {};
    for (const [k, v] of Object.entries(x)) {
      if (k !== 'focusedCaseStatus') {
        a[k] = v;
      }
    }
    a['caseStatus'] = 'renew';
    // console.log(a);
    let responseUpdate = await UAPI.update(a.id, a, 'osccpeople', '3009');
    if (responseUpdate.status === 200) {
      if (responseUpdate.data) {
        let xParams = {
          filter: {
            // where: { personId: personId },
            where: { osccPersonId: a.id },
            order: "planDate DESC",
            limit: 1
          }
        };
        let response = await UAPI.getAll(xParams, 'planservices', '3009');
        if (response.status === 200) {
          if (response.data) {
            // console.log(response.data);
            let x = response.data[0];
            x['completeStatus'] = 'renew';
            x['completeDateTime'] = new Date();
            // console.log(x);
            let responseUpdate = await UAPI.update(x.id, x, 'planservices', '3009');
            if (responseUpdate.status === 200) {
              // console.log(responseUpdate.data);
              setTimeout(() => {
                setOpenBackdrop(false);
                history.push({ pathname: '/cases', state: { status: thisFocusedCaseStatus } });
              }, 100);
            }
          }
        }
      }
    }
  }

  const confirmCloseCase = async () => {
    // console.log('confirmCloseCase ---');
    setOpenDialog(false);
    setOpenBackdrop(true);
    let x = JSON.parse(JSON.stringify(props.location.state));
    let a = {};
    for (const [k, v] of Object.entries(x)) {
      if (k !== 'focusedCaseStatus') {
        a[k] = v;
      }
    }
    a['caseStatus'] = 'completed';
    // console.log(a);
    let responseUpdate = await UAPI.update(a.id, a, 'osccpeople', '3009');
    if (responseUpdate.status === 200) {
      if (responseUpdate.data) {
        let xParams = {
          filter: {
            // where: { personId: personId },
            where: { osccPersonId: a.id },
            order: "planDate DESC",
            limit: 1
          }
        };
        let response = await UAPI.getAll(xParams, 'planservices', '3009');
        if (response.status === 200) {
          if (response.data) {
            // console.log(response.data);
            let x = response.data[0];
            x['completeStatus'] = 'completed';
            x['completeDateTime'] = new Date();
            // console.log(x);
            let responseUpdate = await UAPI.update(x.id, x, 'planservices', '3009');
            if (responseUpdate.status === 200) {
              // console.log(responseUpdate.data);
              setTimeout(() => {
                setOpenBackdrop(false);
                history.push({ pathname: '/cases', state: { status: thisFocusedCaseStatus } });
              }, 100);
            }
          }
        }
      }
    }
  }

  const clickSaveSummary = async () => {
    setOpenBackdrop(true);
    let x = JSON.parse(JSON.stringify(props.location.state));
    let a = {};
    for (const [k, v] of Object.entries(x)) {
      if (k !== 'focusedCaseStatus') {
        a[k] = v;
      }
    }
    a['summaryRiskLevel'] = radioRiskLevel;
    a['improveLevel'] = radioImproveLevel;
    // console.log(a);
    let responseUpdate = await UAPI.update(a.id, a, 'osccpeople', '3009');
    if (responseUpdate.status === 200) {
      if (responseUpdate.data) {
        let xParams = {
          filter: {
            // where: { personId: personId },
            where: { osccPersonId: a.id },
            order: "planDate DESC",
            limit: 1
          }
        };
        let response = await UAPI.getAll(xParams, 'planservices', '3009');
        if (response.status === 200) {
          if (response.data) {
            // console.log(response.data);
            let x = response.data[0];
            x['summaryRiskLevel'] = radioRiskLevel;
            x['improveLevel'] = radioImproveLevel;
            x['summaryNote'] = summaryNote;
            x['summaryDateTime'] = new Date();
            // console.log(x);
            let responseUpdate = await UAPI.update(x.id, x, 'planservices', '3009');
            if (responseUpdate.status === 200) {
              // console.log(responseUpdate.data);
              setTimeout(() => {
                setOpenBackdrop(false);
              }, 100);
            }
          }
        }
      }
    }
  }

  const changeRadioRiskLevel = (e) => {
    setRadioRiskLevel(e.target.value);
  }

  const changeRadioImproveLevel = (e) => {
    setRadioImproveLevel(e.target.value);
  }

  const changeSummaryNote = (e) => {
    setSummaryNote(e.target.value);
  }

  useEffect(() => {
    if (typeof personData != 'undefined') {
      if (Object.keys(personData).length > 0) {
        if (typeof personData.id != 'undefined') {
          // getPlanServiceData(personData.id);
          // ติดปัญหาใน person มี cid ซ้ำ ดังนั้นให้ getPlanServiceData ด้วย osccPersonId แทน
        }
      }
    }
  }, [personData]); 

  useEffect(() => {
    if (typeof props.location.state != 'undefined') {
      getPlanServiceData(props.location.state.id);
      if (typeof props.location.state.cid != 'undefined') {
        getPersonData(props.location.state.cid);
      }
      let x = props.location.state;
      if (globalState.focusedCaseStatus) {
        x['focusedCaseStatus'] = globalState.focusedCaseStatus;
      }
      history.replace('/caseconclude', x);
      setThisFocusedCaseStatus(props.location.state.focusedCaseStatus);
    }
    // console.log(props.location.state);
  }, []); 

  return (
    <div style={{ marginBottom: 100 }}>
      {/* <div style={{ color: 'red' }}>ถ้าไม่ติ๊กอะไรเลยในแบบสอบ จะต้องกดบันทึกไม่ได้</div> */}
      <Backdrop
        className={classes.backdrop}
        open={openBackdrop}
      // onClick={closeBackdrop}
      >
        {/* <Backdrop className={classes.backdrop} open={true}> */}
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container style={{ marginTop: -10, marginBottom: 10 }} justify="center" direction={isDesktop ? "row" : "column-reverse"}>
        <Grid item xs={12} sm={6} style={{ marginTop: 10 }}>
          <h4>สรุปผลการประเมิน</h4>
        </Grid>
        <Grid item xs={12} sm={6} >
          <Grid container justify="flex-end">
            <ButtonGroup size={isDesktop?'large':'medium'} fullWidth={false} style={{ marginTop: 0, marginBottom: 0, borderRadius: 8, border: 'solid 4px #CDCDCD', padding: 0 }}>
              <Button color="primary" style={{ margin: 0, paddingBottom: 0, paddingTop: 0 }}
                onClick={() => {
                  history.push({ pathname: '/cases', state: { status: thisFocusedCaseStatus } });
                }}
              >
                กลับ
              </Button>
              <Button color="primary" style={{ margin: 0, paddingBottom: 0, paddingTop: 0 }}
                onClick={() => { history.push({ pathname: '/caseemr', state: props.location.state }); }}
              >
                EMR
              </Button>
              {(globalState.currentUser.user.role==='CM' && props.location.state.caseStatus==="completed")&&(
                <Button color="secondary" style={{ margin: 0, paddingBottom: 0, paddingTop: 0 }}
                  onClick={() => { setDialogSet('cancelCase'); setDialogText('ยืนยันยกเลิกการปิดเคสรายนี้ ใช่หรือไม่!?'); setOpenDialog(true); }}
                >
                  ยกเลิก
                </Button>
              )}
              {(globalState.currentUser.user.role==='CM' && props.location.state.caseStatus==="sentCM")&&(
                <Button color="secondary" style={{ margin: 0, paddingBottom: 0, paddingTop: 0 }}
                  onClick={() => { setDialogSet('cancelSentCM'); setDialogText('ยืนยันยกเลิกและส่งคืนเคสรายนี้ ใช่หรือไม่!?'); setOpenDialog(true); }}
                >
                  ยกเลิก
                </Button>
              )}
            </ButtonGroup>
          </Grid>
        </Grid>
      </Grid>

      {/* <CaseInfoBar data={props.location.state} /> */}
      {Object.keys(personData).length>0&&(
        <CaseInfoBar data={personData} />
      )}

      <Grid container justify="flex-start">

        <Grid item xs={12} sm={3} style={{ padding: 5 }}>
          <Grid container justify="flex-start" style={{ padding: 10, border: 'solid 1px #555555', borderRadius: 7 }}>
            <Grid item xs={12} sm={12}>
              สรุปสถานะความเสี่ยง
            </Grid>
            <Grid item xs={12} sm={12} style={{ height: 130 }}>

              <RadioGroup name="radioRiskLevel" value={radioRiskLevel} onChange={changeRadioRiskLevel}>
                <FormControlLabel control={<Radio />} value={'low'} label={'Low Risk'} style={{ height: 25, margin: 0, padding: 0 }} disabled={props.location.state.caseStatus === "completed"?true:false} />
                <FormControlLabel control={<Radio />} value={'medium'} label={'Medium Risk'} style={{ height: 25, margin: 0, padding: 0 }} disabled={props.location.state.caseStatus === "completed"?true:false} />
                <FormControlLabel control={<Radio />} value={'high'} label={'High Risk'} style={{ height: 25, margin: 0, padding: 0 }} disabled={props.location.state.caseStatus === "completed"?true:false} />
                <FormControlLabel control={<Radio />} value={'victim'} label={'Victim'} style={{ height: 25, margin: 0, padding: 0 }} disabled={props.location.state.caseStatus === "completed"?true:false} />
                <FormControlLabel control={<Radio />} value={'other'} label={'Other'} style={{ height: 25, margin: 0, padding: 0 }} disabled={props.location.state.caseStatus === "completed"?true:false} />
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={3} style={{ padding: 5 }}>
          <Grid container justify="flex-start" style={{ padding: 10, border: 'solid 1px #555555', borderRadius: 7 }}>
            <Grid item xs={12} sm={12}>
              การแปลผลการดูแล
            </Grid>
            <Grid item xs={12} sm={12} style={{ height: 130 }}>
              <RadioGroup name="radioImproveLevel" value={radioImproveLevel} onChange={changeRadioImproveLevel}>
                <FormControlLabel control={<Radio />} value={'improved'} label={'ดีขึ้น'} style={{ height: 25, margin: 0, padding: 0 }} disabled={props.location.state.caseStatus === "completed"?true:false} />
                <FormControlLabel control={<Radio />} value={'unchanged'} label={'ไม่เปลี่ยนแปลง'} style={{ height: 25, margin: 0, padding: 0 }} disabled={props.location.state.caseStatus === "completed"?true:false} />
                <FormControlLabel control={<Radio />} value={'worse'} label={'แย่ลง'} style={{ height: 25, margin: 0, padding: 0 }} disabled={props.location.state.caseStatus === "completed"?true:false} />
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={3} style={{ padding: 5 }}>
          <Grid container justify="flex-start" style={{ padding: 10, border: 'solid 1px #555555', borderRadius: 7 }}>
            <Grid item xs={12} sm={12}>
              สรุปผลการประเมิน
            </Grid>
            <Grid item xs={12} sm={12} style={{ height: 130 }}>
              <TextareaAutosize rowsMin={5} style={{ width: '100%' }} value={summaryNote} onChange={changeSummaryNote} disabled={props.location.state.caseStatus === "completed"?true:false} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={3} style={{ padding: 5 }}>
          <Grid container justify="flex-start">
            <Grid item xs={12} sm={12}>
              <Grid container justify="flex-start">
                <Grid item xs={12} sm={12}>
                  <Button variant="contained" color="primary" fullWidth style={{ marginBottom: 5 }} onClick={clickSaveSummary} disabled={props.location.state.caseStatus === "completed"?true:false}>
                    บันทึก
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingRight: 5 }}>
                  {/* <Button variant="contained" color="primary" fullWidth style={{ marginBottom: 5 }} onClick={clickCloseCase}>
                    ปิดเคส
                  </Button> */}
                  <Button variant="contained" color="primary" fullWidth style={{ marginBottom: 5 }} onClick={() => { setDialogSet('closeCase'); setDialogText('ยืนยัน ปิดเคสนี้ ใช่หรือไม่!?'); setOpenDialog(true); }} disabled={props.location.state.caseStatus === "completed"?true:false}>
                    ปิดเคส
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* <Button variant="contained" color="primary" fullWidth style={{ marginBottom: 5 }} onClick={clickRenewCase} disabled={props.location.state.caseStatus === "completed"?true:false}>
                    เริ่มแผนใหม่
                  </Button> */}
                  <Button variant="contained" color="primary" fullWidth style={{ marginBottom: 5 }} onClick={() => { setDialogSet('renewCase'); setDialogText('ยืนยัน เริ่มวางแผนใหม่สำหรับเคสนี้ ใช่หรือไม่!?'); setOpenDialog(true); }} disabled={props.location.state.caseStatus === "completed"?true:false}>
                    เริ่มแผนใหม่
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>


      <Grid container style={{ marginBottom: 10 }}>
        {/* TABLE HEAD */}
        {isDesktop ? (
          <Grid item xs={12} sm={12} style={{ border: 'solid 0px #666666', borderRadius: 7, backgroundColor: '#00a8ff', color: '#FFFFFF' }}>
            <Grid container justify="flex-start">
              <Grid item xs={12} sm={11}>
                <Grid container justify="flex-start">
                  <Grid item xs={12} sm={1}>
                    &nbsp;&nbsp;ลำดับ
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    รายการแบบสอบถาม/กิจกรรม
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    เริ่ม
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    สิ้นสุด
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    สถานะ
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    คะแนน
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={1}>
                &nbsp;&nbsp;
              </Grid>
            </Grid>
          </Grid>
        ):(
          <Grid item xs={12} sm={12} style={{ marginTop: 20 ,border: 'solid 0px #666666', borderRadius: 5, backgroundColor: '#00a8ff', color: '#FFFFFF' }}>
              &nbsp;&nbsp;รายการแบบสอบถาม/กิจกรรม
          </Grid>
        )}

        {/* TABLE ROWS */}
        {mkRows()}

      </Grid>

      <Dialog open={openDialog}>
        {/* <DialogTitle id="alert-dialog-title">เพิ่ม : </DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component={'div'}>
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialogSet === 'cancelSentCM' && (
            <Button variant="contained" color="primary" onClick={confirmCancelSentCM}>
              ยืนยัน
            </Button>
          )}
          {dialogSet === 'cancelCase' && (
            <Button variant="contained" color="primary" onClick={confirmCancelCase}>
              ยืนยัน
            </Button>
          )}
          {dialogSet === 'closeCase' && (
            <Button variant="contained" color="primary" onClick={confirmCloseCase}>
              ยืนยัน
            </Button>
          )}
          {dialogSet === 'renewCase' && (
            <Button variant="contained" color="primary" onClick={confirmRenewCase}>
              ยืนยัน
            </Button>
          )}
          <Button variant="contained" autoFocus
            onClick={() => setOpenDialog(false)}
          >
            ปิด
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}