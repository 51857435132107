/* eslint-disable @typescript-eslint/no-unused-vars */
import { INSERT_TEAMS } from '../../actions';
import Actions from '../../actions/teams';
import { snackbarFailure,snackbarSuccess } from '../../actions/notification';

import * as api from '../../services/api';
import { fetchEntity } from '../saga';
import { all, AllEffect, call, fork, put, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

const insertTeams = fetchEntity.bind(null, Actions.insertTeams, api.insertTeams);
const updateTeams = fetchEntity.bind(null, Actions.updateTeams, api.updateTeams);
const updateTeamsUser = fetchEntity.bind(null, Actions.updateTeamusers, api.updateTeamusers);
const getTeams = fetchEntity.bind(null, Actions.getTeams, api.getTeams);

function* callFirstAction(action: any) {
  // console.log("SAGA insertTeams",action.insertTeams.params);
  yield call(
    insertTeams,{data: action.insertTeams.params},
    {
      onSuccess: (success: any) => {
        action["teamCodeNew"]=success.id;
        return call(actionUpdateTeams, action);
      },
      onFailure: (error: any) => {
        return put(snackbarFailure('insertTeams FAILURE',4000));
      },
    }
  );
}

function* actionUpdateTeams(action: any) {
  const membersData:any = [...action.insertTeams.members];
  const newTeamuserData = membersData.map((teamusers:any) => { 
    const osccteamsNew = teamusers.osccteams.map((teams:any)=>{
      if(!teams.hasOwnProperty("teamCode")){
        teams["teamCode"]=action.teamCodeNew;
      }
      return teams;
    });
    teamusers.osccteams=osccteamsNew;
    return teamusers;
  });
  const updateTeamsParams = {
    id: action.teamCodeNew,
    data: {members : newTeamuserData}
  }
  yield call(
    updateTeams, updateTeamsParams,
    {
      onSuccess: (success: any) => {
        return call(actionUpdateTeamusers, action);
      },
      onFailure: (error: any) => {
        return put(snackbarFailure('insertTeams FAILURE',4000));
      },
    }
  );
}

function* actionUpdateTeamusers(action: any) {
  // เอา teamCode ที่ได้จากการ insertTeams ใส่ใน osccteams ของ Teamusers
  const teamusersData:any = [...action.updateTeamsUser.params.data];
  const newTeamuserData = teamusersData.map((teamusers:any) => { 
    const osccteamsNew = teamusers.data.osccteams.map((teams:any)=>{
      if(!teams.hasOwnProperty("teamCode")){
        teams["teamCode"]=action.teamCodeNew;
      }
      return teams;
    });
    teamusers.data.osccteams=osccteamsNew;
    return teamusers;
  });
  // ทำงาน action หลายตัวพร้อมกัน promiseAll
  // console.log(newTeamuserData);
  const results:Array<AllEffect<any>> = yield all( newTeamuserData.map((data:any)=>call( updateTeamsUser, data)));
  const success = results.every((res:any) => res.response.status===200);
  const failure = results.every((res:any) => res.response.status !== 200);
  if(failure){
    put(snackbarFailure('updateTeamsUser FAILURE',1500));
  }
  if(success){
    yield call(actionGetTeams, action);
  }
}

function* actionGetTeams(action: any) {
  yield call(
    getTeams, 
    {data:action.getTeams.params},
    {
      onSuccess: (success: any) => put(snackbarSuccess(action.insertTeams.successMessage,2000)),
      onFailure: () => put(snackbarFailure('getTeams failed',1500))
    }
  );
}

function* watchFirstRequest() {
  yield takeLatest(INSERT_TEAMS.REQUEST, callFirstAction);
}

export default function* root() {
  yield fork(watchFirstRequest);
}
