/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */

/* System */
import React,{ FC, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { DataGrid, GridColDef, GridCellParams, GridRowData } from "@material-ui/data-grid";
import { Box,  Button, Tooltip,  IconButton } from '@material-ui/core';
import moment from 'moment';
import "moment/dist/locale/th";
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

/* Custom Function and Component */
import CustomDialog from '../../components/custom_components/Dialog';
import ButtonLoading from '../../components/custom_components/ButtonLoading';
import Actions from "../../actions/teams";
import DialogEdit from './edit';
import getTeamusers from "./helper/getTeamusers";
import { SearchContext } from './helper/SetPageContext';
import { useGetTeams } from './hook';
import { DialogProvider } from './helper/SetPageContext';

/* Typescript */
interface IProps {
  loading?:boolean;
  caseStatus?: Record<string, any>;
  filltered?: Record<string, any>;
  currentPage?: number;
  pageSize?: number;
  handlePageChange?: (page: number, details?: any) => void;
}

const List:FC<IProps> = (props) => {
  moment.locale(["th", "th"]);
  // const dispatch = useDispatch();
  // const history = useHistory();
  const globalState = useSelector((state: RootState) => state);
  // const token = globalState.auth.user.response?.id;
  // const role = globalState.auth.user.response?.user.role;
  // const teamsList = globalState.teams.teamsList;
  // const userAmphur = globalState.auth.user.response?.user.amphur;

  const [showDialog, setShowDialog] = useState(false);
  const [showModalDeleteTeams, setShowModalDeleteTeams] = useState(false);
  const [showModalLogout, setShowModalLogout] = useState(false);

  const [teamEdit, setTeamEdit] = useState<GridRowData>({});
  const [userlist, setUserlist] = useState<Array<Record<string,any>>>([]);
  // const [loading,setLoading]=useState(false);

  const {searchText,setLoading} = useContext(SearchContext);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const { 
    isFetching,
    data:{ data, totalPage }={data:[],totalPage:0}
  } = useGetTeams(pageSize, pageNumber,searchText);

  const columns: GridColDef[] = [
    { 
      field: 'name', 
      headerName: 'ชื่อทีม', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'left',
      flex: 0.6
    },
    { 
      field: 'dupdate',
      headerName: 'วันที่แก้ไข', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.2
    },
    { 
      field: 'manage', 
      headerName: 'จัดการ', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center', 
      flex: 0.2,
      renderCell:(params: GridCellParams)=> {
        return (
          <>
            <Tooltip title="แก้ไขทีม" placement="top" arrow>
              <IconButton key="editTeam" onClick={()=>{
                setTeamEdit(params.row);
                setShowDialog(true);
              }}>
                <CreateIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="ลบทีม" placement="top" arrow>
              <IconButton key="deleteTeam" onClick={()=>{
                // dispatch(Actions.getTeamsUsers.request({where:{id: { inq : focusID }}}));
                // prepairDelete(params.row);
                // setFocusDataRow(params.row);
                // setShowModalDeleteTeams(true);
              }}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        )
      }
    }
  ];

  return (
    <Box style={{marginTop:3,width: '100%'}}>
      <DataGrid
        rows={data}
        columns={columns}
        loading={isFetching}
        autoHeight
        pagination
        paginationMode="server"
        page={pageNumber}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        rowCount={ totalPage|0 }
        onPageChange={(page:number)=>setPageNumber(page)}
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        disableDensitySelector
      />
      {(teamEdit && Object.keys(teamEdit).length>0) && 
        <DialogProvider data={teamEdit} dialogState={{showDialog,setShowDialog}}>
          <DialogEdit />
        </DialogProvider>
      }
    </Box>
  );
}
export default List;