/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getCurrentUser } from "../../services/auth.service";
import { useHistory } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import "moment/locale/th";
import * as AST from "../../services/AutoSetting";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiTextField-root': {
      width: '100%',
    },
    '& .MuiInputLabel-outlined': {
      zIndex: 1,
      transform: 'translate(15px, 12px) scale(1)',
      pointerEvents: 'none'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(15px, -17px) scale(0.75)',
    },
    '& .MuiInputBase-input': { textAlign: 'center' },
  }
}));

export default function Setting(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const userinfo = getCurrentUser();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [dialogSet, setDialogSet] = useState('');

  const [openDialogMS, setOpenDialogMS] = useState(false);
  const [dialogTextMS, setDialogTextMS] = useState('');

  const [disabledButtonUpdateSS, setDisabledButtonUpdateSS] = useState(true);

  const confirmedUpdateSS = async () => {
    setOpenBackdrop(true);
    let runFromSystemSetting = true;
    let x = await AST.AutoSetting(getCurrentUser(), null, runFromSystemSetting); 
    // console.log(x); 
    if (x === true) {
      setOpenDialog(false);
      setDialogTextMS('อัพเดตเสร็จแล้ว');
      setOpenDialogMS(true);
    }
    setTimeout(() => {
      setOpenDialog(false);
      if (x===false) {
        setDialogTextMS('อัพเดตเสร็จแล้ว');
        setOpenDialogMS(true);
      }
    }, 30000);
  }

  useEffect(() => {
    if (typeof userinfo.user.role !== 'undefined') {
      if (userinfo.user.role) {
        if (["CM","AdminTeams","Teams"].indexOf(userinfo.user.role)>-1) {
          setDisabledButtonUpdateSS(false);
        }
      }
    }
    // eslint-disable-next-line 
  }, []);

  return (
    <div style={{ marginBottom: 100 }}>

      <Dialog onClose={(_event, reason)=>{if (reason === "backdropClick") {return;}}} className={classes.backdrop} open={openBackdrop}>
        <DialogContent>
          <CircularProgress color="inherit" />
        </DialogContent>
      </Dialog>

      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"th"}>

        <Grid container style={{ marginTop: -10, marginBottom: 0 }} justify="center" direction={isDesktop ? "row" : "column-reverse"}>
          <Grid item xs={12} sm={12}>
            <h4>ตั้งค่า</h4>
          </Grid>
          <Grid item xs={12} sm={12} style={{ border: 'solid 1px grey', borderRadius: 5, padding: 4 }}>

            <Grid container>
              <Grid item xs={12} sm={10} style={{ paddingLeft: 10 }}>
                อัพเดต ServiceSlot/แบบสอบถาม (สำหรับ รพศ./รพท./รพช./รพ.สต.)
                <div style={{ fontSize: 14, color: 'grey' }}>
                  โดยปกติระบบจะตรวจสอบและอัพเดตข้อมูล ServiceSlot และแบบสอบถาม เมื่อหน่วยบริการ(รพศ./รพท./รพช./รพ.สต.) ล็อคอินเข้าสู่ระบบครั้งแรกของแต่ละวัน แต่อาจเกิดข้อขัดข้องที่ทำให้ไม่เกิดกระบวนนี้ได้ เช่น อินเตอร์เน็ตไม่เสถียร คอมพิวเตอร์ไม่เสถียร เป็นต้น ผู้ใช้งานสามารถอัพเดต ServiceSlot/แบบสอบถาม ได้ด้วยตนเองโดยกดปุ่ม "อัพเดต" ของเมนูนี้
                </div>
              </Grid>
              <Grid item xs={12} sm={2} style={{ paddingRight: 5 }}>
                <Button variant="contained" fullWidth color='primary'
                  disabled={disabledButtonUpdateSS}
                  onClick={() => {
                    setDialogSet('updateSS'); 
                    setDialogText('ต้องการอัพเดต ServiceSlot/แบบสอบถาม ของ '+userinfo.user.department.hos_name+' ใช่หรือไม่!?'); 
                    setOpenDialog(true);
                  }}
                >
                  อัพเดต
                </Button>
              </Grid>
            </Grid>

          </Grid>
        </Grid>

      </MuiPickersUtilsProvider>

      <Dialog onClose={(_event, reason)=>{if (reason === "backdropClick") {return;}}} open={openDialog}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component={'div'}>
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          {dialogSet === 'updateSS' && (
            <Button variant="contained" color="primary" onClick={confirmedUpdateSS}>
              ใช่
            </Button>
          )}


          <Button variant="contained" autoFocus
            onClick={() => setOpenDialog(false)}
          >
            ปิด
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog onClose={(_event, reason)=>{if (reason === "backdropClick") {return;}}} open={openDialogMS}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component={'div'}>
            {dialogTextMS}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus
            onClick={() => {
              setOpenDialogMS(false);
              setOpenBackdrop(false);
            }}
          >
            ปิด
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}