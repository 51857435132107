/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState, useMemo, useContext } from "react";
import Button from "@material-ui/core/Button";
import ButtonLoading from "../../components/custom_components/ButtonLoading";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TeamsDialogList from "./teamsDialogList";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import Actions from "../../actions/teams";
import moment from "moment";
import "moment/dist/locale/th";
import FilterListIcon from '@material-ui/icons/FilterList';
import Filter from './helper/Filter';
import { getHospitalInfo } from "../../services/api";
import _ from "lodash";
import * as Snackbar from "../../actions/notification";
import { GridRowId } from "@material-ui/data-grid";
import getTeamusers from "./helper/getTeamusers";
import { DialogContext } from "./helper/SetPageContext";
import { useForm, SubmitHandler, UseFormRegister, UseFormSetValue, Control, UseFormHandleSubmit, FormProvider, useFieldArray } from "react-hook-form";
import * as DynamicInput from "./dynamicInput";
import { useGetUsers } from "./hook";

interface IProps {
  open: true;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  editData: Record<any,any>;
}

interface IGenInput {
  key: number;
  name: any;
  answersList?: Array<Record<string,any>>;
  disable?: boolean;
  uForm: {
    register: UseFormRegister<any>;
    handleSubmit: UseFormHandleSubmit<any>;
    setValue: UseFormSetValue<any>;
    control: Control<any, any>;
  };
}

export default function FormDialog() {
  const globalState = useSelector((state: RootState) => state);
  const amphurID = globalState.auth.user.response.user.amphur.amphur_id;
  const {data:contextData,open,close} = useContext(DialogContext);
  const { register, handleSubmit, setValue, getValues, control} = useForm<any>();

  const { 
    isFetching,
    data:{ data:usersData, totalPage }={userData:[],totalPage:0}
  } = useGetUsers({ where:{ amphurID: amphurID }, pagination:{skip: 0,limit: 50}});

  useEffect(() => {
    console.log(amphurID);
  }, [globalState]);

  const useFormEdit = useForm<any>({ 
    mode: 'onSubmit', 
    defaultValues: [contextData].map((item:any)=>{
      item["teamName"]=item.name;
      delete item.name;
      return { 
        teamName: item.teamName
      };
    })[0]
  });
  
  const dispatch = useDispatch();
  
  const token = globalState.auth.user.response?.id;
  const userchangwat = globalState.auth.user.response?.user.changwat;
  
  const userTambon = globalState.auth.user.response?.user.tambon;
  const usersList = globalState.teams.usersList;

  const [users,setUsers]=useState([{id:"",label:"",value: {}}]);
  const [usersListTable,setUsersListTable]=useState<any>([]);
  const [searchUsers,setSearchUsers]=useState<Record<string,any>>({id:"",label:"",value: {}});
  const [teamName, setTeamName] = useState<string>("");
  const [showFilter, setShowFilter] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [teamsTypeValue, setTeamsTypeValue] = useState<Record<string,string>|null >(null);
  const [loading, setLoading] = useState(false);
  const teamsType=[
    {id:"1", label:"ผู้จัดการเคส (CM)", value:"CM"},
    {id:"2", label:"หัวหน้าทีม (AdminTeams)", value:"AdminTeams"},
    {id:"4", label:"ผู้ประเมินวิทยากร (Coach)", value:"Coach"},
    {id:"3", label:"ผู้ประเมินเคส (Assessor)", value:"Assessor"}
  ]

  const genInput= useCallback((key:number,item:any) => {
    const params: IGenInput = {
      key: key,
      name: item.inputName,
      uForm: { register, handleSubmit, setValue, control }
    };
    switch(item.inputType){
      case 'text'     : return <DynamicInput.GenTextfield data={params}/>;
      case 'textarea' : return <DynamicInput.GenTextarea data={params}/>;
      case 'radio'    :  return <DynamicInput.GenRadioGroup data={params}/>;
      case 'date'     :  return <DynamicInput.GenDatePicker data={params}/>;
      case 'time'     :  return <DynamicInput.GenTimePicker data={params}/>;
      default : return <></>;
    }
  },[control, handleSubmit, register, setValue]);


  const handleClose = () => close();
  const onSubmit: SubmitHandler<any> = (data) => {
    console.log(data);
  }
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={(_event, reason)=>{if (reason === "backdropClick") {return;}handleClose();}}
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        id="form-dialog-title"
        disableTypography
        style={{ paddingTop: "24px" }}
      >
        <Typography variant="h4" style={{ marginBottom: 0 }}>
          แก้ไขข้อมูลทีม
        </Typography>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...useFormEdit}>
          <form onSubmit={useFormEdit.handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <DynamicInput.GenTextfield
                  props = {{
                    autoFocus: true,
                    fullWidth: true,
                    id:"teamName",
                    name:"teamName",
                    label:"ชื่อทีม",
                    type:"text",
                    variant:"outlined",
                    size:"small",
                    style: { height: "100%" },
                  }}
                />
              </Grid>
              <Grid item xs={2} >
                <Button 
                  fullWidth 
                  variant="contained" 
                  color="primary"
                  startIcon={<FilterListIcon />}
                  style={{ height: "100%" }}
                  onClick={()=>setShowFilter(true)}
                >
                    ค้นหา
                </Button>
              </Grid>
              <Grid item xs={7}>
                <DynamicInput.GenAutocomplete
                  label= "ค้นหาผู้ใช้งาน"
                  props={{
                    id: "searchUsers",
                    name: "searchUsers",
                    fullWidth: true,
                    freeSolo: true,
                    options: ((usersData)?usersData:[]).map((item:any)=>{
                      const hosname = (item.department)?" | "+item.department.hos_name:"";
                      return { id:item.id, label: `${item.fullname}${hosname}` ,value:item }
                    }),
                    size: "small",
                    style: { height: "100%" }
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions style={{ padding: "12px 24px 24px" }}>
        <Button onClick={handleClose} variant="outlined" color="primary">
          ยกเลิก
        </Button>
        <ButtonLoading 
          variant="contained"
          color="primary"
          // onClick={saveTeams}
          // loading={props.loading}
          >
            บันทึก
        </ButtonLoading>
      </DialogActions>
    </Dialog>
  );
}
