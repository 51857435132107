import { useQuery } from '@tanstack/react-query';
import { GroupsData, Teams } from './types';
import axiosInstance, { genParams } from './axios';

const useGetTeams = () => {
	return useQuery<Teams[]>({
    queryKey: ['ReportTeams'],
		queryFn: async () => {
      const PARAMS =  await genParams(`/osccteams`,{"where" : {"version": "PLH2", "id": { "neq" : "659a496e9a6ae7543599a131"}}, "order": "id,name asc"});
      return axiosInstance.get(PARAMS);
    },
		refetchOnWindowFocus: false,
	});
};

const useGetGroups = (teamsId: string[]) => {
  return useQuery<GroupsData[]>({
    queryKey: ['ReportGroups',teamsId],
    queryFn: async () => {
      const PARAMS = await genParams(`/osccperson_groups`,{
        fields: {
          id: true,
          groupStatus: true,
          groupName: true,
          teams: true,
          member: true,
        },
        where: {"teams.teamCode": { in: teamsId }},
        include: {
          relation: "questionnaire_answer",
          scope: {
            include: [
              {
                relation: "osccperson_assign"
              },
              {
                relation: "questionnaire",
                scope: {
                  fields: { id: true, questionnaire_code: true, questionnaire_group: true,  questionsList: true, questionnaire_layout:true },
                },
              }
            ],
          },
        },
      });
      return axiosInstance.get(PARAMS);
    },
    refetchOnWindowFocus: false,
    enabled: !!(teamsId.length>0)
  });
}

export {
  useGetTeams,
  useGetGroups
}