/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from '../const';
import { INSERT_CONCLUSION } from '../index';

const insertConclusion = {
  request: (payload: any) => action(INSERT_CONCLUSION.REQUEST, { data: payload }),
  success: (response: any) => action(INSERT_CONCLUSION.SUCCESS, { data: response }),
  failure: (error: any) => action(INSERT_CONCLUSION.FAILURE, { error }),
};

export default insertConclusion;
