import { fork, call, takeEvery } from 'redux-saga/effects';
import actions from '../../actions/dashboard';
import { COUNT_INPROGRESS } from '../../actions';
import * as api from '../../services/api';
import { fetchEntity } from '../saga';

const getEntity = fetchEntity.bind(null, actions.countInprogress, api.getCount);

function* callAction(action: any) {
  yield call(getEntity, { ...action });
}

function* watchAction() {
  yield takeEvery(COUNT_INPROGRESS.REQUEST, callAction);
}

export default function* root() {
  yield fork(watchAction);
}
