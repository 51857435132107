import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { reducer as formReducer } from 'redux-form';
import { combineReducers } from 'redux';
import { default as auth } from './auth';
import activity from './activity/';
import notify from './notify';
import filter from './filter';
import inform from './inform';
import osccperson from './osccperson';
import dashboard from './dashboard';
import teams from './teams';

const mainReducer = {
  form: formReducer,
  auth,
  notify,
  activity,
  filter,
  inform,
  osccperson,
  dashboard,
  teams
};

// const rootType = combineReducers(mainReducer);
// const rootReducer = (history: History) => combineReducers({ router: connectRouter(history), ...mainReducer });
// export type RootState = ReturnType<typeof rootType>;
// export default rootReducer;

const appReducer = combineReducers(mainReducer);
const rootReducer = (history: History) => (state: any, action: any) => {
  if (action.type === '@@router/LOCATION_CHANGE' && action.payload.location.pathname==='/logout') {
    state = undefined;
  }

  return combineReducers({
    router: connectRouter(history),
    ...mainReducer,
  })(state, action);
};

export type RootState = ReturnType<typeof appReducer>;
export default rootReducer;
