/* eslint-disable @typescript-eslint/no-unused-vars */
import { DELETE_TEAMS } from '../../actions';
import Actions from '../../actions/teams';
import { snackbarFailure,snackbarSuccess } from '../../actions/notification';

import * as api from '../../services/api';
import { fetchEntity } from '../saga';
import { all, AllEffect, call, fork, put, take, takeEvery, takeLatest } from 'redux-saga/effects';

const deleteTeams = fetchEntity.bind(null, Actions.deleteTeams, api.deleteTeams);
const updateTeamsUser = fetchEntity.bind(null, Actions.updateTeamusers, api.updateTeamusers);
const getTeams = fetchEntity.bind(null, Actions.getTeams, api.getTeams);

function* callFirstAction(action: any) {
  yield call(
    deleteTeams,action.deleteTeams.params,
    {
      onSuccess: (success: any) => {
        return call(actionUpdateTeamusers, action);
      },
      onFailure: (error: any) => {
        return put(snackbarFailure('deleteTeams FAILURE',4000));
      },
    }
  );
}

function* actionUpdateTeamusers(action: any) {
  const updateParams=action.updateTeamsUser.params;
  const results:Array<AllEffect<any>> = yield all( updateParams.map((data:any)=>call( updateTeamsUser, data)));
  const success = results.every((res:any) => res.response.status===200);
  const failure = results.every((res:any) => res.response.status !== 200);
  if(failure){
    put(snackbarFailure('updateTeamsUser FAILURE',1500));
  }
  if(success){
    yield call(actionGetTeams, action);
  }
}

function* actionGetTeams(action: any) {
  yield call(
    getTeams, 
    {data:action.getTeams.params},
    {
      onSuccess: (success: any) => put(snackbarSuccess(action.deleteTeams.successMessage,2000)),
      onFailure: () => put(snackbarFailure('getTeams failed',1500))
    }
  );
}

function* watchFirstRequest() {
  yield takeLatest(DELETE_TEAMS.REQUEST, callFirstAction);
}

export default function* root() {
  yield fork(watchFirstRequest);
}
