/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { getAll } from "../../services/UniversalAPI";
import { getCurrentUser } from '../../services/auth.service';
import Permission from "../../services/Permission";
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import useGlobal from "../../store";
import useScreenType from "react-screentype-hook";

import Actions from '../../actions/dashboard';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles({
  linkDateServ: {
    cursor: 'pointer',
    '&:hover': {
      background: "#cdf1ff",
    },
  },
  hotLink: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: 10,
    width: '100%',
    height: 150,
    textAlign: 'center',
    verticalAlign: 'middle',
    cursor: 'pointer'
  },
  contentHotLink: {
    fontWeight: 'bold',
    fontSize: '1.6rem',
    color: '#474747',
  },
  firstHotLink: {
    border: 'solid 5px #c200c6',
    backgroundImage: 'linear-gradient(to bottom, #e990ff, #fefaff)',
    '&:hover': {
      border: 'solid 5px #e6e6e6',
    }
  },
  secondHotLink: {
    border: 'solid 5px #00cd21',
    backgroundImage: 'linear-gradient(to bottom, #b6fc9c, #fcfffb)',
    '&:hover': {
      border: 'solid 5px #e6e6e6',
    }
  },
  thirdHotLink: {
    border: 'solid 5px #ff6293',
    backgroundImage: 'linear-gradient(to bottom, #ffc4d6, #fffefe)',
    '&:hover': {
      border: 'solid 5px #e6e6e6',
    }
  },
  fourthHotLink: {
    border: 'solid 5px #ff6293',
    backgroundImage: 'linear-gradient(to bottom, #ffc4d6, #fffefe)',
    '&:hover': {
      border: 'solid 5px #e6e6e6',
    }
  },
  lastHotLink: {
    border: 'solid 5px #0abaf0',
    backgroundImage: 'linear-gradient(to bottom, #8fe4ff, #fafeff)',
    '&:hover': {
      border: 'solid 5px #e6e6e6',
    }
  }
});

export default function DashBoard(props) {
  const { t } = props;
  const dispatch = useDispatch();
  const globalStateNew = useSelector((state) => state);
  const caseStatusNew = globalStateNew.dashboard.caseStatus;
  const userinfo = globalStateNew.auth.user.response?.user;
  const teamCodes = userinfo?.osccteams?.map((item)=>item.teamCode);
  const screenType = useScreenType();
  const classes = useStyles();
  const history = useHistory();
  const [globalState,globalActions] = useGlobal();
  const currentUser = getCurrentUser();
  const { role, changwat, amphur, amphur_id, hospcode, casestatusRole } = Permission("dashboard");
  const [casestatus, setCasestatus] = useState({});
  let configCaseStatus = casestatusRole[role];
  let officerId = "";
  let userTeams = [];
  if(currentUser!=null){
    officerId = currentUser.userId;
    userTeams = (currentUser.user.teams!==undefined)?currentUser.user.teams.map((teams)=>{return teams.id;}):[];
  }
  
  const clickHotlink = (x, z) => {
    if (typeof x !== 'undefined') {
      if (x !== null) {
        history.push({ pathname: '/activity/case', state: { status: x, caseStatus: z } });
      }
    }
  };

  // useEffect(() => {
  //   let mounted = true;
  //   const getData = async () => {
  //     const params = {
  //       filter: {
  //         fields: { name: true, osccpersonCount: true },
  //         counts: "osccperson"
  //       }
  //     };
  //     try {
  //       if (role === "R8") { // นับทั้งหมด

  //       } else if (role === "CH") { // นับเฉพาะจังหวัดตนเอง
  //         params.filter.countsWhere = [{ province_name: changwat }];
  //       } else if (role === "HOS") { // นับเฉพาะหน่วยบริการตนเอง
  //         params.filter.countsWhere = [{ hcode: hospcode }];
  //       } else if (role === "OG") { // Organization
  //         params.filter.countsWhere = {and:[]};
  //         if(globalState.dashboardFilter.changwat.changwatname !== ""){
  //           params.filter.countsWhere.and.push({ province_name: globalState.dashboardFilter.changwat.changwatname });
  //         }else if(globalState.dashboardFilter.amphur.amphur_name !== ""){
  //           params.filter.countsWhere.and.push({ amphur_name: globalState.dashboardFilter.amphur.amphur_name });
  //         }else if(globalState.dashboardFilter.hospital.hos_id !== ""){
  //           params.filter.countsWhere.and.push({ hcode: globalState.dashboardFilter.hospital.hos_id });
  //         }else{
  //           params.filter.countsWhere = {};
  //         }
  //       } else if (role === "CM") { // นับเฉพาะในอำเภอตนเอง
  //         params.filter.countsWhere = [{ amphur_id: amphur_id }];
  //       } else if (role === "ATM") { // Admin Teams
  //         params.filter.countsWhere = [{ osccTeamId : { in: userTeams } }];
  //       } else { // Teams
  //         params.filter.countsWhere = [{ officerId : { in : [officerId] } }];
  //       }
  //       console.log(JSON.stringify(params));
  //       if(mounted){
  //         const response = await getAll(params, "casestatuses");
  //         if (response.status === 200) {
  //           if (typeof configCaseStatus !== 'undefined') {
  //             response.data.map(function (currentValue, index) {
  //               if (currentValue.name in configCaseStatus) {
  //                 let counts = (typeof currentValue.osccpersonCount !=='undefined' && currentValue.osccpersonCount !==null)?currentValue.osccpersonCount:0;
  //                 if (currentValue.name === "assigned") { // ถ้าวนเจอข้อมูลตัวมันเองให้จับบวกกัน
  //                   configCaseStatus["assigned"].count = configCaseStatus["assigned"].count + counts;
  //                 } else {
  //                   configCaseStatus[currentValue.name].count = counts;
  //                 }
  //                 if (role === "CM") {
  //                   if (["assigned"].includes(currentValue.name)) { // ถ้าเจอข้อมูลสองตัวนี้ให้จับบวกเข้า recievedCase
  //                     configCaseStatus["assigned"].count = configCaseStatus["assigned"].count + currentValue.osccpersonCount;
  //                   }
  //                   if (["renew"].includes(currentValue.name)) { // ถ้าเจอข้อมูลสองตัวนี้ให้จับบวกเข้า recievedCase
  //                     configCaseStatus["new"].count = configCaseStatus["new"].count + currentValue.osccpersonCount;
  //                   }
  //                 }
  //               }
  //               return null;
  //             }); 
  //             console.log("configCaseStatus",configCaseStatus);
  //             setCasestatus(configCaseStatus);
  //           }
  //         }
  //       }
  //     } catch (error) { console.log('error', error) }
  //   };
  //   if(mounted){getData();}
  //   return () => { mounted = false; }
  // }, [globalState.dashboardFilter]);

  useEffect(() => {
    let mounted = true;
    if(mounted){
      if(configCaseStatus && configCaseStatus!==null){
        Object.keys(configCaseStatus).forEach((key)=>{
          let params = {};
          if (role === "R8") { // นับเฉพาะในอำเภอตนเอง
            params.where = { caseStatus: key };
          } else if (role === "CM" || role === "Coach" || role === "Assessor") { // นับเฉพาะในอำเภอตนเอง
            params.where = { caseStatus: key, amphur_id: userinfo?.amphur?.amphur_id };
          } else if (role === "ATM") { // Admin Teams
            params.where = { caseStatus: key, osccTeamId : { in: teamCodes } };
          } else if (role === "TM") { // Teams
            params.where = { officerId : { in : [officerId] } };
          }
          switch(key){
            case 'new' : 
              params.where = { caseStatus: key, amphur_id: userinfo?.amphur?.amphur_id };
              dispatch(Actions.countNew.request(params)); break;
            case 'assigned' : dispatch(Actions.countAssigned.request(params)); break;
            case 'inProgress' : dispatch(Actions.countInprogress.request(params)); break;
            case 'waitConclusion' : dispatch(Actions.countWaitconclusion.request(params)); break;
            case 'finish' : dispatch(Actions.countFinish.request(params)); break;
            case 'renew' : dispatch(Actions.countRenew.request(params)); break;
            default : break;
          }
        });
      }
    }
    return () => mounted = false;
  },[]);

  useEffect(() => {
    let mounted = true;
    if(mounted){
      if(configCaseStatus && configCaseStatus!==null){
        Object.keys(caseStatusNew).forEach((key)=>{
          if (key in configCaseStatus || key==="newCase") {
            configCaseStatus[(key==="newCase")?"new":key].count = caseStatusNew[key].count;
          }
        });
        // console.log(configCaseStatus);
        // delete configCaseStatus.renew;
        setCasestatus(configCaseStatus);
      }
    }
    return () => mounted = false;
  },[caseStatusNew]);
  
  return (
    <>
      {Object.keys(casestatus).map((item, index) => {
        if (casestatus[item].show) {
          let title = casestatus[item].title;
          let hotLink = casestatus[item].hotLink;
          let count = casestatus[item].count;
          let allData = Object.keys(casestatus).length;
          let boxClass = '';
          let mediaQuery = 3;
          switch (item) {
            case "new": boxClass = 'firstHotLink'; break;
            case "assigned": boxClass = 'secondHotLink'; break;
            case "inProgress": boxClass = 'thirdHotLink'; break;
            case "waitConclusion": boxClass = 'fourthHotLink'; break;
            case "finish": boxClass = 'lastHotLink'; break;
            default: ;
          }
          if ((index + 1) === allData) { boxClass = 'lastHotLink'; }
          if(allData<5){
            return (
              <Grid key={index} item xs={6} sm={mediaQuery}>
                <div className={`${classes.hotLink} ${classes[boxClass]}`} onClick={() => clickHotlink(hotLink, item)}>
                  <div className={classes.contentHotLink}>{t(title)}</div>
                  <div className={classes.contentHotLink}>{count}</div>
                </div>
              </Grid>
            );
          }else{
            let style5Box = (!screenType.isMobile) ? {flexGrow:0, maxWidth:'20%', flexBasis: '20%'} : {maxWidth:'50%', flexBasis: '50%'};
            return (role!=='OG')&&(
              <Grid key={index} item xs={12} style={style5Box}>
                <div className={`${classes.hotLink} ${classes[boxClass]}`} onClick={() => clickHotlink(hotLink, item)}>
                  <div className={classes.contentHotLink}>{t(title)}</div>
                  <div className={classes.contentHotLink}>{count}</div>
                </div>
              </Grid>
            );
          }
        } else {
          return '';
        }
      })}
    </>
  )
}