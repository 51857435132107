import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: '#494949',
      color: 'white',
    },
    '& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      fontSize: '0.9em'
    },
    '& .MuiDataGrid-root .MuiDataGrid-row': {
      fontSize: '0.9em'
    }
  },
  loadingOverlay: {
    position:'relative',
    width: '100%',
    height: '100dvh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background
    backdropFilter: 'blur(3px)', // Blur effect
    zIndex: 999,
  },
});