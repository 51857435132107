import React, { ReactElement, ReactNode } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
interface IProps {
  state: { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>> };
  title?: string;
  disableBackdropClick?: boolean;
  content?: ReactNode | ReactElement<any, any>;
  btn: {
    okBtn: JSX.Element;
    cancelBtn?: JSX.Element;
  };
}

export default function AlertDialog(props: IProps) {
  return (
    <div>
      <Dialog
        open={props.state.open}
        onClose={(_event, reason) => {
          if (props.disableBackdropClick && reason === "backdropClick") return;
          props.state.setOpen(false)
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{props.title}</DialogTitle>
        <DialogContent>{props.content}</DialogContent>
        <DialogActions>
          {(props.btn.cancelBtn)&&props.btn.cancelBtn}
          {props.btn.okBtn}
        </DialogActions>
      </Dialog>
    </div>
  );
}
