import { GridRowData } from "@material-ui/data-grid";
import { callGet, callPost, callDelete, callPatch } from "../request";
import { getToken } from "./getToken";
const token = getToken();

export const getServiceSlot = async (payload: any = {}) => {
	let params: any = {
		access_token: token,
		filter: {
			order: "updateDateTime desc, createDateTime desc",
		},
	};
	// console.log("payload",payload);
	if(payload){
		params.filter["where"]=payload;
	}
	console.log("API getServiceSlot",params);
	const response = await callGet(`serviceslots`, token, params);
	
	return { 
		data : response.response.data as readonly GridRowData[], 
		totalPage: response.response.headers["x-total-count"] as number
	};
};

export const insertServiceSlot = (payload: any = {}) => {
	return callPost(`serviceslots`, token, payload);
};

export const updateServiceSlot = (payload: any = {}) => {
	return callPatch(
		`serviceslots/${payload.id}?access_token=${token}`,
		token,
		payload.data
	);
};

export const deleteServiceSlot = (payload: any = {}) => {
	return callDelete(`serviceslots/${payload.id}?access_token=${token}`);
};