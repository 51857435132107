/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { Grid } from "@material-ui/core";
import OsccImportExcel from "./modals";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 1280,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function Osccperson() {
  const classes = useStyles();

  const [openImport, setOpenImport] = React.useState(false);

  useEffect(() => {}, []);

  const body = (
    <div className={classes.paper}>
      <h2 id="simple-modal-title">เพิ่มข้อมูลxxxx</h2>
      <p id="simple-modal-description">TEST xxxxxx</p>
      {/* <OsccPersonModal  /> */}
    </div>
  );

  const bodyImport = (
    <div className={classes.paper}>
      <h2 id="simple-modal-title">นำเข้าไฟล์ Excel</h2>
      <p id="simple-modal-description">ดาวน์โหลด xlsx</p>
      <OsccImportExcel />
    </div>
  );

  const handleOpenImport = () => {
    setOpenImport(true);
  };

  const handleCloseImport = () => {
    setOpenImport(false);
  };

  return (
    <div>
      <Grid
        container
        spacing={1}
        direction="column"
        // justifyContent="center"
        alignItems="flex-end"
      >
        <Grid item>
          <Button variant="outlined" color="primary" onClick={handleOpenImport}>
            Import
          </Button>{" "}
        </Grid>
      </Grid>
      <br></br>
      <Grid container spacing={1}>
        {/* <OsccPersonDataGrid /> */}
      </Grid>

      <Modal
        open={openImport}
        onClose={handleCloseImport}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyImport}
      </Modal>
    </div>
  );
}
