import { UPDATE_GROUP } from "../../actions";
import { AnyAction } from "redux";
import { UpdateGroup } from "../../types/activity";

const initialState:UpdateGroup = {
  isFetching: false,
  isSuccess: false,
  data: {
    createDatetime: "",
    groupName: "",
    groupStatus:"",
    member: [],
    teams: {},
    inform: []
  }
};

export default function updateGroup(
  state = initialState,
  action: AnyAction
): UpdateGroup {
  switch (action.type) {
    case UPDATE_GROUP.REQUEST:{
      return {
        ...state,
        isFetching: true,
      };
    }
    case UPDATE_GROUP.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isSuccess:true,
        data: action.data
      };
    }
    case UPDATE_GROUP.FAILURE:
      return {
        ...state,
        isFetching: false,
        isSuccess:false,
        data: action.data
      };
    default:
      return state;
  }
}
