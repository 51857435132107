/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { DELETE_TOKEN } from "../index";

const deleteToken = {
  request: (payload: any) => action(DELETE_TOKEN.REQUEST, payload),
  success: (response: any) => action(DELETE_TOKEN.SUCCESS, { data: response }),
  failure: (error: any) => action(DELETE_TOKEN.FAILURE, { error }),
};

export default deleteToken;