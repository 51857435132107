import React from 'react';
import { createStyles, FormControlLabel, makeStyles, Radio, RadioGroup, Theme } from "@material-ui/core";
import { Control, Controller, UseFormHandleSubmit, UseFormRegister, UseFormSetValue } from "react-hook-form";

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    radio:{
      marginBottom: 0
    }
  })
);

interface IProps {
  data: {
    key: number;
    name: any;
    answersList?: Array<Record<string,any>>;
    disable?: boolean;
    uForm: {
      register: UseFormRegister<any>;
      setValue: UseFormSetValue<any>;
      control: Control<any, any>;
    };
  }
}

export default function GenRadioGroup(props: IProps){
  const classes = useStyles();
  const { key, name, answersList, disable, uForm } = props.data;
  return (
    <Controller
      key={name+key}
      name={name}
      control={uForm.control}
      render={({ field : { onChange, value }}:any) => {
        return (
        <RadioGroup 
          row
          key={name+key}  
          value={(typeof value !== "undefined")?value:""}
          onChange={(_, v) => onChange(v)}
          >
          {
            (answersList?answersList:[]).map((item:any,k:number)=>{
              return <FormControlLabel 
                key={name+k}
                className={classes.radio} 
                value={item.score}
                label={item.answer} 
                control={<Radio color="primary"/>}
                disabled={disable}
              />;
            })
          }
        </RadioGroup>
      )}}
    />
  )
}