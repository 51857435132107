export const jwt =
  (store: any)=>
  (next: any) =>
  (action: any): any => {
    if (action.type === 'INVALID_TOKEN') {
      store.dispatch({ type: 'LOGOUT.REQUEST' });
    } else {
      return next(action);
    }
  };
