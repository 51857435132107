import React from 'react';
import { TextField } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import { Autocomplete } from '@material-ui/lab';

export default function GenAutocomplete(data:any){
  const useForm = useFormContext();
  const {props,label} = data;

  return (
    <Controller
			name={props.name || uuidv4()}
			control={useForm.control}
			rules={props.validation}
			render={({ field: { onChange, value }, fieldState: { error } }: any) => {
				return (
          <Autocomplete 
            {...props}
            value={value || ''}
            onChange={onChange}
            getOptionLabel= {(option: any) => option.label || ""}
            renderInput={
              (params) => <TextField {...params} label={label} variant="outlined" fullWidth/>
            }
            onInputChange={(e:any)=>{
              console.log(e.target);
            }}
          />
        )
      }}
    />
  )
}