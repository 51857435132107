/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { COUNT_RENEW } from "../index";

const countCaseStatus = {
  request: (payload:Record<string,any>) => action(COUNT_RENEW.REQUEST, payload),
  success: (response: any) => action(COUNT_RENEW.SUCCESS, { data: response }),
  failure: (error: any) => action(COUNT_RENEW.FAILURE, { error }),
};

export default countCaseStatus;