import { Button } from '@material-ui/core';
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { thaiYear } from '../../reducers/selectors/thaiYear';

interface IProps {
  title:string;
  type: string;
  column: string[];
  data: Record<string,string>[];
  fullWidth?: boolean;
  fileName?: string;
}

const ExcelExport=(props:IProps)=>{
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const exportToExcel= ()=>{
    if(props.data.length>0){
      const newData: Record<string,string>[] = [];
      if(props.type==="button"){
          props.data.forEach((d)=>{
            if(d.member3){delete d.member3}
            const data = {
              id: d.id, member1: d.member1, member2 :d.member2, area: d.teamName, group: d.groupName , family: d.familyCount,
              PSu1: d.PSu1, PCP: d.PCP, PARe: d.PARe, CRF: d.CRF,
              FF1: d.FF1, FF2: d.FF2, FF3: d.FF3, FF4: d.FF4, FF5: d.FF5, FF6: d.FF6, FF7: d.FF7, FF8: d.FF8,
              FSR: d.FSR,"L&M": d.L_M, PCRe: d.PCRe, PSu2: d.PSu2, total: d.total, remark: "", summaryDate: thaiYear(null,"ll")
            };
            newData.push(data);
        });
      }
      if(newData.length > 0){
        const ws = XLSX.utils.json_to_sheet(newData);
        XLSX.utils.sheet_add_aoa(ws, [props.column], { origin: "A1" });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const blobData = new Blob([excelBuffer], { type: fileType });
        saveAs(blobData, props.fileName);
      }
    }
  }

  return (props.type==="button") ?
    <Button 
      {...(props.fullWidth&&{fullWidth:props.fullWidth})} 
      size="medium"
      variant="contained" 
      color="primary"
      style={{ height: "40px", margin: 0 }}
      onClick={exportToExcel}
      >
        {props.title}
    </Button> :
    <Button 
      size="small" 
      color="primary"
      onClick={exportToExcel}
      >
        {props.title}
    </Button>
}

export default ExcelExport;