import React from 'react';
import { RootState } from "../../../reducers";
import { useSelector } from "react-redux";
import { Grid, Tooltip, createStyles, makeStyles } from "@material-ui/core";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from "@material-ui/data-grid";
import _ from "lodash/index";
import clsx from "clsx";

const useStyles = makeStyles(() =>
  createStyles({
    columns: {
      "& .super-app-theme--cell": {
        backgroundColor: "rgba(224, 183, 60, 0.55)",
        color: "#000000",
        fontWeight: "600",
      },
      "& .super-app.positive": {
        backgroundColor: "#DC143C",
        color: "#000000",
        border: "1px solid",
      },
    },
  })
);

const columns: GridColDef[] = [
  {
    field: "cid",
    headerName: "CID",
    align: "center",
    headerAlign: "center",
    sortable: false,
    width: 150,
    cellClassName: (params: GridCellParams) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/cid";
      });

      if (x === undefined) {
        return clsx();
      } else {
        return clsx("super-app", { positive: true });
      }
    },
    renderCell: (params: GridValueGetterParams|any) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/cid";
      });

      return x !== undefined ? (
        <Tooltip title={x.message} placement="top">
          <div>{params.value}</div>
        </Tooltip>
      ) : (
        <div>{params.value}</div>
      );
    },
  },
  {
    field: "hcode",
    headerName: "hcode",
    width: 130,
    align: "center",
    headerAlign: "center",
    sortable: false,

    cellClassName: (params: GridCellParams) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/hcode";
      });

      if (x === undefined) {
        return clsx();
      } else {
        return clsx("super-app", { positive: true });
      }
    },
    renderCell: (params: GridValueGetterParams|any|any) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/hcode";
      });

      return x !== undefined ? (
        <Tooltip title={x.message} placement="top">
          <div>{params.value}</div>
        </Tooltip>
      ) : (
        <div>{params.value}</div>
      );
    },
  },
  {
    field: "fname",
    headerName: "ชื่อ",
    width: 150,
    sortable: false,

    cellClassName: (params: GridCellParams) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/fname";
      });

      if (x === undefined) {
        return clsx();
      } else {
        return clsx("super-app", { positive: true });
      }
    },
    renderCell: (params: GridValueGetterParams|any) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/fname";
      });

      return x !== undefined ? (
        <Tooltip title={x.message} placement="top">
          <div>{params.value}</div>
        </Tooltip>
      ) : (
        <div>{params.value}</div>
      );
    },
  },
  {
    field: "lname",
    headerName: "นามสกุล",
    width: 150,
    sortable: false,

    cellClassName: (params: GridCellParams) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/lname";
      });

      if (x === undefined) {
        return clsx();
      } else {
        return clsx("super-app", { positive: true });
      }
    },
    renderCell: (params: GridValueGetterParams|any) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/fname";
      });

      return x !== undefined ? (
        <Tooltip title={x.message} placement="top">
          <div>{params.value}</div>
        </Tooltip>
      ) : (
        <div>{params.value}</div>
      );
    },
  },
  {
    field: "hn",
    headerName: "HN",
    align: "center",
    headerAlign: "center",
    sortable: false,

    cellClassName: (params: GridCellParams) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/hn";
      });

      if (x === undefined) {
        return clsx();
      } else {
        return clsx("super-app", { positive: true });
      }
    },
    renderCell: (params: GridValueGetterParams|any) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/hn";
      });

      return x !== undefined ? (
        <Tooltip title={x.message} placement="top">
          <div>{params.value}</div>
        </Tooltip>
      ) : (
        <div>{params.value}</div>
      );
    },
  },
  {
    field: "sex",
    headerName: "เพศ",
    align: "center",
    headerAlign: "center",
    sortable: false,

    cellClassName: (params: GridCellParams) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/sex";
      });

      if (x === undefined) {
        return clsx();
      } else {
        return clsx("super-app", { positive: true });
      }
    },
    renderCell: (params: GridValueGetterParams|any) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/sex";
      });

      return x !== undefined ? (
        <Tooltip title={x.message} placement="top">
          <div>{params.value}</div>
        </Tooltip>
      ) : (
        <div>{params.value}</div>
      );
    },
  },
  {
    field: "age",
    headerName: "อายุ",
    align: "center",
    headerAlign: "center",
    sortable: false,

    cellClassName: (params: GridCellParams) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/age";
      });

      if (x === undefined) {
        return clsx();
      } else {
        return clsx("super-app", { positive: true });
      }
    },
    renderCell: (params: GridValueGetterParams|any) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/age";
      });

      return x !== undefined ? (
        <Tooltip title={x.message} placement="top">
          <div>{params.value}</div>
        </Tooltip>
      ) : (
        <div>{params.value}</div>
      );
    },
  },
  {
    field: "province_name",
    headerName: "จังหวัด",
    width: 150,
    align: "center",
    headerAlign: "center",
    sortable: false,

    cellClassName: (params: GridCellParams) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/province_name";
      });

      if (x === undefined) {
        return clsx();
      } else {
        return clsx("super-app", { positive: true });
      }
    },
    renderCell: (params: GridValueGetterParams|any) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/province_name";
      });

      return x !== undefined ? (
        <Tooltip title={x.message} placement="top">
          <div>{params.value}</div>
        </Tooltip>
      ) : (
        <div>{params.value}</div>
      );
    },
  },
  {
    field: "amphur_id",
    headerName: "รหัสอำเภอ",
    width: 150,
    align: "center",
    headerAlign: "center",
    sortable: false,

    cellClassName: (params: GridCellParams) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/amphur_id";
      });

      if (x === undefined) {
        return clsx();
      } else {
        return clsx("super-app", { positive: true });
      }
    },
    renderCell: (params: GridValueGetterParams|any) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/amphur_id";
      });

      return x !== undefined ? (
        <Tooltip title={x.message} placement="top">
          <div>{params.value}</div>
        </Tooltip>
      ) : (
        <div>{params.value}</div>
      );
    },
  },
  {
    field: "amphur_name",
    headerName: "อำเภอ",
    width: 150,
    align: "center",
    headerAlign: "center",
    sortable: false,

    cellClassName: (params: GridCellParams) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/amphur_name";
      });

      if (x === undefined) {
        return clsx();
      } else {
        return clsx("super-app", { positive: true });
      }
    },
    renderCell: (params: GridValueGetterParams|any) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/amphur_name";
      });

      return x !== undefined ? (
        <Tooltip title={x.message} placement="top">
          <div>{params.value}</div>
        </Tooltip>
      ) : (
        <div>{params.value}</div>
      );
    },
  },
  {
    field: "tambol_id",
    headerName: "รหัสตำบล",
    width: 150,
    align: "center",
    headerAlign: "center",
    sortable: false,

    cellClassName: (params: GridCellParams) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/tambol_id";
      });

      if (x === undefined) {
        return clsx();
      } else {
        return clsx("super-app", { positive: true });
      }
    },
    renderCell: (params: GridValueGetterParams|any) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/tambol_id";
      });

      return x !== undefined ? (
        <Tooltip title={x.message} placement="top">
          <div>{params.value}</div>
        </Tooltip>
      ) : (
        <div>{params.value}</div>
      );
    },
  },
  {
    field: "tambol_name",
    headerName: "ตำบล",
    width: 150,
    align: "center",
    headerAlign: "center",
    sortable: false,

    cellClassName: (params: GridCellParams) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/tambol_name";
      });

      if (x === undefined) {
        return clsx();
      } else {
        return clsx("super-app", { positive: true });
      }
    },
    renderCell: (params: GridValueGetterParams|any) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/tambol_name";
      });

      return x !== undefined ? (
        <Tooltip title={x.message} placement="top">
          <div>{params.value}</div>
        </Tooltip>
      ) : (
        <div>{params.value}</div>
      );
    },
  },
  {
    field: "villagecode",
    headerName: "villagecode",
    width: 150,
    align: "center",
    headerAlign: "center",
    sortable: false,

    cellClassName: (params: GridCellParams) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/villagecode";
      });

      if (x === undefined) {
        return clsx();
      } else {
        return clsx("super-app", { positive: true });
      }
    },
    renderCell: (params: GridValueGetterParams|any) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/villagecode";
      });

      return x !== undefined ? (
        <Tooltip title={x.message} placement="top">
          <div>{params.value}</div>
        </Tooltip>
      ) : (
        <div>{params.value}</div>
      );
    },
  },
  {
    field: "physical",
    headerName: "physical",
    width: 150,
    align: "center",
    headerAlign: "center",
    sortable: false,

    cellClassName: (params: GridCellParams) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/physical";
      });

      if (x === undefined) {
        return clsx();
      } else {
        return clsx("super-app", { positive: true });
      }
    },
    renderCell: (params: GridValueGetterParams|any) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/physical";
      });

      return x !== undefined ? (
        <Tooltip title={x.message} placement="top">
          <div>{params.value}</div>
        </Tooltip>
      ) : (
        <div>{params.value}</div>
      );
    },
  },
  {
    field: "mental",
    headerName: "mental",
    width: 150,
    align: "center",
    headerAlign: "center",
    sortable: false,

    cellClassName: (params: GridCellParams) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/mental";
      });

      if (x === undefined) {
        return clsx();
      } else {
        return clsx("super-app", { positive: true });
      }
    },
    renderCell: (params: GridValueGetterParams|any) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/mental";
      });

      return x !== undefined ? (
        <Tooltip title={x.message} placement="top">
          <div>{params.value}</div>
        </Tooltip>
      ) : (
        <div>{params.value}</div>
      );
    },
  },
  {
    field: "sexual",
    headerName: "sexual",
    width: 150,
    align: "center",
    headerAlign: "center",
    sortable: false,

    cellClassName: (params: GridCellParams) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/sexual";
      });

      if (x === undefined) {
        return clsx();
      } else {
        return clsx("super-app", { positive: true });
      }
    },
    renderCell: (params: GridValueGetterParams|any) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/sexual";
      });

      return x !== undefined ? (
        <Tooltip title={x.message} placement="top">
          <div>{params.value}</div>
        </Tooltip>
      ) : (
        <div>{params.value}</div>
      );
    },
  },
  {
    field: "riskLevel",
    headerName: "ระดับความรุนแรง",
    width: 150,
    align: "center",
    headerAlign: "center",
    sortable: false,

    cellClassName: (params: GridCellParams) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/riskLevel";
      });

      if (x === undefined) {
        return clsx();
      } else {
        return clsx("super-app", { positive: true });
      }
    },
    renderCell: (params: GridValueGetterParams|any) => {
      const x = _.find(params.row.errors, function (o) {
        return o.path === "/riskLevel";
      });

      return x !== undefined ? (
        <Tooltip title={x.message} placement="top">
          <div>{params.value}</div>
        </Tooltip>
      ) : (
        <div>{params.value}</div>
      );
    },
  },
];

const ImportTable = () => {
  const importData = useSelector(
    (state: RootState) => state.osccperson.importOsccperson
  );
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={1} direction="column">
        <Grid item>
          <Grid item>
            <div
              style={{ height: 800, width: "100%" }}
              className={classes.columns}
            >
              <DataGrid
                rows={importData.importData as any}
                columns={columns}
                rowHeight={38}
                disableColumnMenu
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ImportTable;
