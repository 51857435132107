import { SelectBox } from '../../../types/activity';

const caseStatusAll = [
  {id:"new",label:"รายใหม่"},
  {id:"assigned",label:"มอบหมายแล้ว"},
  {id:"inProgress",label:"ระหว่างดูแล"},
  {id:"waitConclusion",label:"รอสรุป"},
  {id:"finish",label:"เสร็จสิ้น", variant:"default", bgcolor:"#07a607", fontColor:"white"},
  // {id:"completed",label:"เสร็จสิ้น", variant:"default", bgcolor:"#07a607", fontColor:"white"},
  {id:"renew",label:"เริ่มกระบวนการใหม่"}
];
const caseStatusOrganization = [
  {id:"new",label:"รายใหม่", bgcolor:"#b2e0ff", fontColor:"black"},
  {id:"assigned", label:"มอบหมายแล้ว", variant:"outlined", fontColor:"#07a607"},
  {id:"inProgress",label:"ระหว่างดูแล", variant:"default", bgcolor:"#07a607", fontColor:"white"},
  {id:"waitConclusion",label:"รอสรุป", variant:"default", bgcolor:"#e79909", fontColor: "white"},
  {id:"finish",label:"เสร็จสิ้น", variant:"default", bgcolor:"#07a607", fontColor:"white"},
  {id:"renew",label:"เริ่มกระบวนการใหม่"}
];
const caseStatusAssessor = [
  {id:"new",label:"รายใหม่", bgcolor:"#b2e0ff", fontColor:"black"},
  {id:"assigned", label:"มอบหมายแล้ว", variant:"outlined", fontColor:"#07a607"},
  {id:"inProgress",label:"ระหว่างดูแล", variant:"default", bgcolor:"#07a607", fontColor:"white"},
  {id:"waitConclusion",label:"รอสรุป", variant:"default", bgcolor:"#e79909", fontColor: "white"},
  {id:"finish",label:"เสร็จสิ้น", variant:"default", bgcolor:"#07a607", fontColor:"white"},
  {id:"renew",label:"เริ่มกระบวนการใหม่"}
];
const caseStatusCoach = [
  {id:"new",label:"รายใหม่", bgcolor:"#b2e0ff", fontColor:"black"},
  {id:"assigned", label:"มอบหมายแล้ว", variant:"outlined", fontColor:"#07a607"},
  {id:"inProgress",label:"ระหว่างดูแล", variant:"default", bgcolor:"#07a607", fontColor:"white"},
  {id:"waitConclusion",label:"รอสรุป", variant:"default", bgcolor:"#e79909", fontColor: "white"},
  {id:"finish",label:"เสร็จสิ้น", variant:"default", bgcolor:"#07a607", fontColor:"white"},
  {id:"renew",label:"เริ่มกระบวนการใหม่"}
];
const caseStatusCM = [
  {id:"new",label:"รายใหม่", bgcolor:"#b2e0ff", fontColor:"black"},
  {id:"assigned", label:"มอบหมายแล้ว", variant:"outlined", fontColor:"#07a607"},
  {id:"inProgress",label:"ระหว่างดูแล", variant:"default", bgcolor:"#07a607", fontColor:"white"},
  {id:"waitConclusion",label:"รอสรุป", variant:"default", bgcolor:"#e79909", fontColor: "white"},
  {id:"finish",label:"เสร็จสิ้น", variant:"default", bgcolor:"#07a607", fontColor:"white"},
  {id:"renew",label:"เริ่มกระบวนการใหม่"}
];
const caseStatusTeams = [
  {id:"new",label:"รายใหม่", bgcolor:"#b2e0ff", fontColor:"black"},
  {id:"assigned", label:"มอบหมายแล้ว", variant:"outlined", fontColor:"#07a607"},
  {id:"inProgress",label:"ระหว่างดูแล", variant:"default", bgcolor:"#07a607", fontColor:"white"},
  {id:"waitConclusion",label:"รอสรุป", variant:"default", bgcolor:"#e79909", fontColor: "white"},
  {id:"finish",label:"เสร็จสิ้น", variant:"default", bgcolor:"#07a607", fontColor:"white"},
  {id:"renew",label:"เริ่มกระบวนการใหม่"}
];
// const caseStatusTeams = [
//   {id:"assigned",label:"ได้รับมอบหมาย", bgcolor:"#b2e0ff", fontColor:"black"},
//   {id:"inProgress",label:"ระหว่างดูแล"},
//   {id:"waitConclusion",label:"รอสรุป"},
//   {id:"finish",label:"เสร็จสิ้น", variant:"default", bgcolor:"#07a607", fontColor:"white"},
//   // {id:"completed",label:"เสร็จสิ้น"}
// ];

const CaseStatusByRole = (role:string) => {
  let statusByRole: Array<SelectBox> = [];
  switch(role){
    case "AdminR8": statusByRole=caseStatusOrganization; break;
    case "Organization": statusByRole=caseStatusOrganization; break;
    case "Assessor": statusByRole=caseStatusAssessor; break;
    case "Coach": statusByRole=caseStatusCoach; break;
    case "CM": statusByRole=caseStatusCM; break;
    case "AdminTeams": statusByRole=caseStatusTeams; break;
    case "Teams": statusByRole=caseStatusTeams; break;
    default: statusByRole=caseStatusAll; break;
  }
  return statusByRole;
}
export default CaseStatusByRole;