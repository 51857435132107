/* eslint-disable @typescript-eslint/no-unused-vars */
import { INSERT_ASSIGN } from '../../actions';
import Actions from '../../actions/activity';
import { snackbarFailure,snackbarSuccess } from '../../actions/notification';

import * as Api from '../../services/api';
import { fetchEntity } from '../saga';
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _ from 'lodash';

const insertAssign = fetchEntity.bind(null, Actions.InsertAssign, Api.insertAssign);
const updateGroup = fetchEntity.bind(null, Actions.UpdateGroup, Api.updateGroup);
const updateCases = fetchEntity.bind(null, Actions.UpdateCases, Api.updateCases);
const getGroups = fetchEntity.bind(null, Actions.GetGroupList, Api.getGroupList);
const getTeams = fetchEntity.bind(null, Actions.getGroupListTeams, Api.getTeams);

function* firstAction(action: any) {
  yield call(
    insertAssign, action.insertAssign.params,
    {
      onSuccess: (success: any) => {
        return call(actionUpdateGroup, action);
      },
      onFailure: () => put(snackbarFailure('insertAssign failed',1500))
    }
  );
}

function* actionUpdateGroup(action: any) {
  yield call(
    updateGroup, action.updateGroup.params,
    {
      onSuccess: (success: any) => {        
        return call(actionUpdateCases, action)
      },
      onFailure: () => put(snackbarFailure('updateGroup failed',1500))
    }
  );
}

function* actionUpdateCases(action: any) {
  yield call(
    updateCases, action.updateCases.params,
    {
      onSuccess: (success: any) => {        
        return call(actionGetGroups, action)
      },
      onFailure: () => put(snackbarFailure('updateCases failed',1500))
    }
  );
}

function* actionGetGroups(action: any) {
  const params = { data: action.getGroups.params };
  const message = (action.successMessage) ? action.successMessage : "Success";
  yield call( getGroups, params,
    {
      onSuccess: (groups: any) => call(actionGetTeams, { data: groups, message: message}),
      onFailure: () => put(snackbarFailure('getGroups failed',1500))
    }
  );
}

function* actionGetTeams(action: any) {
  if(action.data && action.data.length>0){
    const teamCode = _.uniq(action.data.map((data:any)=>data.teams.teamCode));
    const params = { data: { type: "merge", where: { id: { inq: teamCode } }, groupData: action.data }}
    yield call(getTeams, params,
      {
        onSuccess: () => put(snackbarSuccess(action.message,2000)),
        onFailure: () => put(snackbarFailure('getTeams failed',1500))
      }
    );
  }else{
    yield put(snackbarFailure('getTeams failed',1500));
  }
}

function* watchFirstRequest() {
  yield takeLatest(INSERT_ASSIGN.REQUEST, firstAction);
}

export default function* root() {
  yield fork(watchFirstRequest);
}
