import { DELETE_ANSWER } from "../../actions";
import { AnyAction } from "redux";
import { DeleteAnswer } from "../../types/activity";

const initialState:DeleteAnswer = {
  isFetching: false,
  data: {}
};

export default function deleteAnswer(
  state = initialState,
  action: AnyAction
): DeleteAnswer {
  switch (action.type) {
    case DELETE_ANSWER.REQUEST:{
      return {
        ...state,
        isFetching: true,
      };
    }
    case DELETE_ANSWER.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    }
    case DELETE_ANSWER.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    default:
      return state;
  }
}
