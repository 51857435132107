export const TRANSLATIONS_EN = {
	"menu_dashboard":"Dashboard",
	"menu_cases":"Cases",
	"menu_cases_new":"Cases",
	"menu_questionnaire_list":"Questionnaires",
	"menu_questionnaires":"Questionnaires",
	"menu_teamsnew":"Teams",
	"menu_evaluate":"Evaluate",
	"menu_users":"Users",
	"menu_casesOut":"Removed Cases",
	"menu_settings":"Settings",
	"menu_report":"Report",
	"menu_serviceslot":"Service-Slot",

	"pages": {
		"login":{
			"email":"Email",
			"password":"Password",
			"btn_login":"Login",
			"btn_login_thaid":"ThaiD Login",
			"btn_linelogin":"Line Login",
			"btn_register":"Register",
			"forgot_password":"Forgot Password",
			"dialog_error":{
				"line1":"Username or password wrong",
				"line2":"Or may not be approved for use",
				"line3":"Please contact the system administrator.",
				"close_btn":"Close"
			}
		},
		"register":{
			"header":"Register",
			"type":"Type",
			"position":"Position",
			"name":"Name",
			"cid":"Citizen ID",
			"phone":"Phone",
			"email":"Email",
			"password":"Password",
			"changwat":"District",
			"ampur":"Sub District",
			"agency":"Agency",
			"btn_register":"Register",
		}
	},

	"topmenu_userdata":"My Infomation",
	"Age Assessment at 8th health region support by UNICEF": "Age Assessment at 8th health region support by UNICEF",
	"Age Assessment": "Age Assessment",
	"female": "female",
	"Sign in": "Sign in",
	"Email": "Email",
	"Password": "Password",
	"Forgot password?": "Forgot password?",
	"SIGN IN": "SIGN IN",
	"Sign Up": "Sign Up",
	// "Home": "Home",
	"Login": "Login",
	"Signup": "Signup",
	"Dashboard": "Dashboard",
	"Add New Member": "Add New Member",
	"Show Child and Family": "Show Child and Family",
	"Show Clinic members": "Show Clinic members",
	"Show collection OPDscreen": "Show collection OPDscreen",
	"Profile": "Profile",
	"LogOut": "Log Out",
	"Avatar": "Avatar",
	"Notificaion":{
		"actExp":"Activities nearing the end of time"
	},
	"CID": "CID",
	"Pre Name": "Pre Name",
	"First Name": "First Name",
	"Last Name": "Last Name",
	"Sex": "Sex",
	"Birthday Age Years": "Birthday Age Years ",
	"Height": "Height",
	"Insurance": "Insurance",
	"Items per Page": "Items per Page",
	"Blood Group": "Blood Group",
	"Father": "Father",
	"Mother": "Mother",
	"Service": "Service",
	"Hospital": "Hospital",
	"Building": "Building",
	"Service Type": "Service Type",
	"Duration": "Duration",
	"Doctor": "Doctor",
	"Request": "Request",
	"Emergency": "Emergency",
	"Queue": "Queue",
	"Date": "Date",
	"Time": "Time",
	"My Health": "My Health",
	"My Plan": "My Plan",
	"Select Service": "Select Service",
	"Choose time slot": "Choose time slot",
	"Consent and Finish": "Consent and Finish",
	"Choose Service": "Choose Service",
	"Back": "Back",
	"Next": "Next",
	"Show details": "Show details",
	"Hide details": "Hide details",
	"Accept": "Accept",
	"Not accept": "Not accept",
	"Inform and consent": "Inform and consent",

	"Save":"Save",

	"User data": "User data",
	"Change password": "Change password",
	"Register inform and consent": "Register inform and consent",
	"Log in": "Log in",
	"Log out": "Log out",
	"Register": "Register",


	"DASHBOARD": "DASHBOARD",
	"New Cases": "New",
	"Assigned": "Assigned",
	"Reception Wait": "Waiting for Reception",
	"On Process": "On Process",
	"Conclusion Wait": "Waiting for Conclusion",
	"Finished": "Finished",
	
	"Teams": "Teams",
	"Questionnaires": "Questionnaires",
	"Users": "Users",
	"Home": "Home",
	"Service slots": "Service slots",
	"Removed cases": "Removed cases",

	"inform":{
		"PLHA1": " I acknowledge and accept that I have been selected to participate in the PLH programme.",
		"PLHA2": " I acknowledge and accept that participation in the programme is not compulsory.",
		"PLHA3": " BUT: I acknowledge and accept that failure to fully participate in the programme, or partial completion of the programme meant that not only will I not be able to enjoy the full benefit of the programme, and that failure will be noted in my risk assessment.",
		"PLHA4": " I acknowledge and accept that my participation will lead to the collection of my data that will be used for the purpose of the programme.",
		"PLHA5": " I acknowledge and accept that a video record of the programme will be made.",
		"PLHA6": " I acknowledge and accept that the data collected will also be used for public health purposes.",
		"PLHA7": "I acknowledge and accept that in addition to the purposes specified above, the programme will be using the information it have collected from me for academic purposes, including but not limited to making academic publications, further researches and academic projects.",
		"PLHA8": " I acknowledge and accept that the data collected will also be used for risk assessment purposes. Which will utilize automated processes.",
		"PLHA9": "I acknowledge and accept that any of my personal data that is collected by the programme belongs to me and that I can request that these information be given to me in a form that I can understand and utilize, at any times.",
		"PLHA10": " I acknowledge and accept that should there be any error in any information pertaining to me, the Child-shield project will endeavour to investigate and where necessary correct that information where necessary, as soon as possible.",
		"PLHA11": " I acknowledge and accept that due to the need and the purpose of the Child-shield system, which will draw information from the programme (which is a part of the system), my information will be required to be retained by the system indefinitely.",
		"PLHA12": " I acknowledge and accept that the Child-shield project, on behalf of the programme, will be responsible for the security and integrity of the data that it collected on me.",
		"PLHA13": " I acknowledge and accept that If my family or I require any other specialized support during or after the programme, I shall inform the team as soon as I am able.",
		"PLHA14": " I acknowledge and accept that any act done, any decision made by the programme will be done with the best interest of the child as its primary consideration.",
		"PLHA15": " I acknowledge and accept that I have the right to lodge complaints with the facilitators and supervising authorities of the PLH Programme, the supervising authorities of the Child-Shield project as well as the Child-shield project’s Data Protection Officer. The relevant contact details will be provided:",
		"PLHAD1": "<p>The PLH Programme is a training programme designed to assist parents with the development of positive parenting skills. You are invited to attend a parenting programme aimed at helping parents learn ways to manage their children’s behavior.</p><p>The aim of the programme is to help parents find alternatives to physical punishment or to psychological violence in disciplining and motivating their child.</p><p>The program will be delivered to groups of 15 families like yours over 8 weekly sessions. Each session will take place on a Sunday and will take between 2-3 hours. The group sessions will be held online, and you will need to have a smartphone to participate.</p><p>The group sessions will include interesting discussions about parenting and fun activities to do together. Although you will be encouraged to participate in the activities, you do not need to do anything you do not wish to do. Your child will not participate in any sessions.</p><p>A trained facilitator will be presented at all times during the training to help the participants.</p><p>In order to participate, you must be:</p><li>A parent or primary caregiver (for example, grandparent, foster parent, aunts, or uncles) of a child aged 2-9 years;</li><li>The parent or primary caregiver who spends the most time with this child;</li><li>Living in the same household as the child; and</li><li>Willing to sign the service agreement.</li><p>Please understand that the programme would have used your personal data that were obtained from other state databases to determine your suitability for the programme.</p>",
		"PLHAD2": "<p>Though, for your family and your own benefit, you are highly encouraged to participate fully until the completion of the training programme.</p><p>During the Parenting for Lifelong Health for Young Children Programme, you will collaborate closely with two parenting group facilitators. They or another public health worker will:</p><li>Meet with you before the first group session to discuss the programme in more detail;</li><li>Help you to complete a 20-minute survey about you and your child both before and after the programme;</li><li>Help your child complete a 10-minute survey before and after the programme about activities they do with their family and different ways you manage their behaviour; and</li><li>Send you text message reminders during the week.</li><p>If you are unable to attend a group session, a facilitator will be able to talk to you separately by phone to fill you in on what was covered, so that you can keep up with the group.</p>",
		"PLHAD3": "<p>The PLH Programme is a training programme designed to assist parents with the development of positive parenting skills.</p><p>Completing the training means that you have developed positive parenting skills as alternatives to physical punishment in disciplining and motivating their child or to psychological violence as well as build up a support network of families that can help each other in time of need. Failure to attend and complete all the training courses will lead to the assumption that you have not fully develop the skills or the social networks that were required of you. This will be reflected in your assessment accordingly.</p>",
		"PLHAD4": "<p>The data that were collected will be used to assess your parenting skills at each stages of the course and to expand your support network with other participants, coaches and families in your area.</p>",
		"PLHAD5": "<p>During the programme, we will video record the sessions to see how the facilitators are leading the groups. These videos will be used to ensure the quality of the programme, and to help facilitators go through a certification process so that they can deliver the programme with high quality in the future. After the facilitators are certified, the videos will be destroyed.</p>",
		"PLHAD6": "<p> BUT: The programme is obligated to keep your information confidential throughout the publications. Your information that are disclosed for public health purposes will be anonymized so that you or others who are related to you cannot be identified. If the programme intend to publicize my information for public health purposes without the information being anonymized, then it can only do so pursuant to your consent to the publication.</p><p>In such event, your consent will be sought and documented.</p>",
		"PLHAD7": "<p>BUT: The programme is obligated to keep your information confidential throughout the publications. Your information that are used for academic purposes will be anonymized so that you or others who are related to you cannot be identified. If the programme intend to publicize my information for academic purposes without the information being anonymized, then it can only do so pursuant to your consent to the publication.</p><p>In such event, your consent will be sought and documented.</p><p>Your information will be shared with an Oxford Research Team for the purpose of improving the PLH programme.</p>",
		"PLHAD8": "<p>As PLH is linked to the Child-shield project, the programme will make a record of your behaviour, which will then feed into the Child-shield risk assessment system. Positive behaviour and results such as punctuality, contribution to the group, completion of all training programmes and willingness to learn will be reflected in your risk assessment, and so will your negative behaviour and results.</p><p>Though automated processes will be used, a human being, in this case, a case manager or social worker will be the ultimate decision-maker at the end of the process. So please rest assure that any actions taken by the project that directly effects you will only be carried out after considerations by human decision-makers.</p>",
		"PLHAD9": "<p>BUT the programme reserve the right to refuse access to information that could potentially exposed you or your family to harm. The programme will provide a reasonable explanation to you, in written form, every time access is refused.</p>",
		"PLHAD10": "<p>Where the programme refuses to make changes to my information, it must provide a reasonable explanation on why it had made such a decision.</p>",
		"PLHAD11": "<p> BUT: The programme will kept your identity anonymized and pseudonymized at all times, so that only selected users who need to know of your identity to carry out the purposes of the programme will be allowed access to your identity. Your information will be kept confidential at all time.</p>",
		"PLHAD12": "<p> I acknowledge and accept that by sharing my personal data with the programme and by extension, the Child-shield project, there is an inherent risk that any intentional act or negligence on the team’s part may result in the breach of my privacy which may result in physical and mental harm to me and those close to me.</p><p>BUT: the project is obligated to protect my information and is accountable to me in the event that any such leak occurred.</p>",
		"PLHAD13": "<p>If you or your family require any other specialized support during or after the programme, we may ask you if we can share your information with other service providers. You have the right to choose whether or not we share your information with them. This special consent will be requested from you, separate from the consent request below.</p><p>The only exception is if you share any information regarding a child who has been significantly harmed or is at risk of significant harm. If this happens, the team may share this information with child protection professionals according to the 2003 Child Protection Act (B.E. 2546), in order to help protect the child, regardless of your consent.</p>",
		"PLHAD14": "<p>What this meant is that the team will take a holistic approach and take action that will be in the best interest of the child. But would like you to understand that the best interest of the child will be given primacy above all other considerations. For example: your participation in the programme is deemed to be in the best interest of the child and keeping your information confidential is deemed to be in the best interest of you and your child, but if such acts were to cause harm to the child, the team will prioritize the child’s safety over your participation or privacy interest.</p>",
		"PLHAD15": "<p>contact details</p>"
	},

	"CP1": "👐 I consent to the physical assessment of my age",
	"CP2": "👐 I consent to sharing the information obtained in my physical assessment with relevant personnels for research purposes.",
	"CP3": "👐 I consent to sharing the information obtained in my physical assessment with the police.",
	"CP4": "👐 I consent to sharing the information obtained in my physical assessment with the judiciary.",
	"CP5": "👐 I consent to sharing the information obtained in my physical assessment with the social workers.",

	"CPD1": "<p>By consenting to the physical assessment of your age, you will allow the representative of Thailand to assess your age through the assessment of the development of your body, subjected to the conditions that you have set which have been accepted.</p>",
	"CD1": "<p>As stated before, age assessment is not an exact science. Any additional information that can be added into the database will allow the discipline to be improved and the results of future assessments can be made more accurate. For the benefit of those who will come after you, we request that you consent to us using your information for research purposes.</p><p>Information that will be shared can potentially include numerical statistics of your measurements, assessment results and relevant images. We will take all necessary precaution to maintain your anonymity. The information provided will not allow you to be identified, unless absolutely necessary. In such situation you will be informed and additional consent will be sought before we disclose the information </p>",
	"CD2": "<p>Under usual circumstances, your personal issues means that you would have been referred to us by the police. Therefore we recommend that you consent to sharing your data and the result of your assessment with them to resolve any ongoing issue. The information that will be shared will be limited to those necessary to resolve your issue. Your identity will need to be shared so that the information may be linked to your identity.</p><p>If this is not the case, do not feel that you need to share your information. All information pertaining to you that we have collected is yours and under our system, you are allow to control who can access and be allowed to use your personal information.</p><p>Please be aware that we cannot control how the police will use your information.</p><p>Please be aware that the protection that the system afford to your information under our system do not extend to those that have been shared, record and kept by the police. </p>",
	"CD3</p><p>If this is not the case, do not feel that you need to share your information. All information pertaining to you that we have collected is yours and under our system, you are allow to control who can access and be allowed to use your personal information.</p><p>Please be aware that we cannot control how the judiciary will use your information.</p><p>Please be aware that the protection that the system afford to your information under our system do not extend to those that have been shared, record and kept by the police. </p>": "<p>In some circumstances, your personal issues means that you would have been referred to us by the judiciary, Therefore we recommend that you consent to sharing your data and the result of your assessment with them to resolve any ongoing issue. The information that will be shared will be limited to those necessary to resolve your issue. Your identity will need to be shared so that the information may be linked to your identity.</p><p>If this is not the case, do not feel that you need to share your information. All information pertaining to you that we have collected is yours and under our system, you are allow to control who can access and be allowed to use your personal information.</p><p>Please be aware that we cannot control how the judiciary will use your information.</p><p>Please be aware that the protection that the system afford to your information under our system do not extend to those that have been shared, record and kept by the police. </p>",
	"CD4": "<p>The information obtained during the assessment may allow people in the social work profession to identify risk factors that could help to prevent or rescue you from harm. Therefore we ask that you consent to sharing your data and the result of your assessment with them so that they may take measures for your protection. Your identity will need to be shared so that the information may be linked to your identity.</p><p>By consenting to sharing your information to the social workers, you understand that your information will be processed by artificial intelligence and automated system that will determine your risk level.</p><p>We will take all necessary precaution to maintain your anonymity.</p><p>Do not feel that you need to share your information. All information pertaining to you that we have collected is yours and under our system, you are allow to control who can access and be allowed to use your personal information.</p><p>Please be aware that the protection that the system afford to your information under our system do not extend to those that have been shared, record and kept by the social workers. </p>",

	"AP1": "👨‍🏫 I understand that my height, my weight and my proportions will be measured as part of the assessment.",
	"AP2": "👨‍🏫 I understand that as part of the assessment, paediatrician will be required to make close examination of my genitals, pubic hair and secondary sexual characteristics.",
	"AP3": "👨‍🏫 I understand that the physical assessment of my age will only produce my age range rather than an exact result",
	"A1": "👨‍🏫 I understand that if I object to the assessment and refuse to consent to this procedure, I will not be examine under this procedure.",
	"A2": "👨‍🏫 I understand that I may consent to the procedure subjected to conditions of my choosing.",
	"A3": "👨‍🏫 I may choose to withdraw my consent at any times during the examinations.",

	"APD1": "<p>A paediatrician will be taking your measurements.</p><p>Your measurements will then be compared to the development of other children and your age will be determined by the similarities of your development with corresponding age groups.</p><p>Please be aware that in some cases, photos will be taken.</p><p>If you object to such procedures, please inform the officer and you will not be required to undertake this assessment.</p>",
	"APD2": "<p>The measurements of your genitals, pubic hair and secondary sexual characteristics is a vital part of the age assessment process by examination of your physical developments. The developments of your genitals, pubic hair and secondary sexual characteristics can provide a clear indication of your development stage.</p><p>Please be assured that we will take all reasonable steps available to protect your privacy during the assessment process.</p><p>Please be aware that in some cases, photos will be taken.</p><p>If you object to such procedures, please inform the officer and you will not be required to undertake this assessment.</p>",
	"AD1": "<p>Age assessment procedures are not an exact science. The assessment can only give an estimation of the range of age you fall under by comparing your development with other children from different age groups.</p><p>This is the reason why 3 different types of assessment is required. It is so that we can compare the result and narrow down your age range as much as possible.</p>",
	"AD2": "<p>Please understand that there is no procedure that can accurately calculate your precise age. All the procedures conducted can only give us a ‘range’ for your age. That you are likely to be inbetween a certain age to another certain age. The purpose of conducting multiple different types of assessment is to narrow down the range as much as possible to get as close to your correct age as we can.<p/><p>So, by refusing this assessment, you understand and accept that there is a risk that we might not be able to get an accurate assessment of your age.<p/>",
	"AD3": "<p>We will endeavour to meet your conditions where possible and will inform you before proceeding where it is not possible.</p>",
	"AD4": "<p>You may choose to withdraw your consent at any times during any examinations. We will immediately stop the assessment and you will be free to go.</p><p>Any information we obtained will not be used. It will be as if you have not taken the assessment.</p>",

	"CPS1": "🧠 I consent to the social/psychological assessment of my age",
	"CPS2": "🧠 I consent to sharing the information obtained in my social/psychological assessment with relevant personnels for research purposes.",
	"CPS3": "🧠 I consent to sharing the information obtained in my social/psychological assessment with the police.",
	"CPS4": "🧠 I consent to sharing the information obtained in my social/psychological assessment with the judiciary.",
	"CPS5": "🧠 I consent to sharing the information obtained in my social/psychological assessment with the social workers.",

	"CPSD1": "<p>By consenting to the social/psychological assessment of your age, you will allow the representative of Thailand to assess your age through the assessment of the development of your body, subjected to the conditions that you have set which have been accepted.</p>",

	"APS1": "👨‍🏫 I understand that the psychological assessment of my age will involve the assessment of my maturity through a test, where my age will then be determined",

	"APSD1": "<p>INCOMPLETE</p>",

	"CDT1": "🦷 I consent to the dental assessment of my age",
	"CDT2": "🦷 I consent to sharing the information obtained in my dental assessment with relevant personnels for research purposes.",
	"CDT3": "🦷 I consent to sharing the information obtained in my dental assessment with the police.",
	"CDT4": "🦷 I consent to sharing the information obtained in my dental assessment with the judiciary.",
	"CDT5": "🦷 I consent to sharing the information obtained in my dental assessment with the social workers.",

	"CDTD1": "<p>By consenting to the dental assessment of your age, you will allow the representative of Thailand to assess your age through the assessment of the development of your teeth, subjected to the conditions that you have set which have been accepted.</p>",

	"ADT1": "👨‍🏫 I understand that my teeth will be extensively examined",
	"ADT2": "👨‍🏫 I understand that the dental assessment of my age will only produce my 'age range' rather than an exact result",

	"ADTD1": "<p>Which means that you will be required to lie down on an operating table as a dentist examine your cavity. Tools may be used to allow the dentist clearer view of your teeth.</p><p>A radiographic image of your cavities will be taken and in some cases, photos will be taken. </p><p>Your measurements will then be compared to the development of other children and your age determined by the similarities of your development with corresponding age groups.</p><p>If you object to such procedures, please inform the officer and you will not be required to undertake this assessment.</p>",

	"CR1": "🦴 I consent to the radiological assessment of my age",
	"CR2": "🦴 I consent to sharing the information obtained in my radiological assessment with relevant personnels for research purposes.",
	"CR3": "🦴 I consent to sharing the information obtained in my radiological assessment with the police.",
	"CR4": "🦴 I consent to sharing the information obtained in my radiological assessment with the judiciary.",
	"CR5": "🦴 I consent to sharing the information obtained in my radiological assessment with the social workers.",

	"CRD1": "<p>By consenting to the radiological assessment of your age, you will allow the representative of Thailand to assess your age through the assessment of the development of your clavicle, subjected to the conditions that you have set which have been accepted.</p>",

	"AR1": "👨‍🏫 I understand that an x-ray of my wrist, specifically, my clavicle will be taken",
	"AR2": "👨‍🏫 I understand that the radiological assessment of my age will only produce my 'age range' rather than an exact result",

	"ARD1": "<p>You will be required to have a radiographic image of your wrist taken.</p><p>The image of your clavicle will then be compared to the development of other children and your age determined by the similarities of your development with corresponding age groups.</p><p>If you object to such procedures, please inform the officer and you will not be required to undertake this assessment.</p>",

	"PLHA1": " I acknowledge and accept that I have been selected to participate in the PLH programme.",
	"PLHA2": " I acknowledge and accept that participation in the programme is not compulsory.",
	"PLHA3": " BUT: I acknowledge and accept that failure to fully participate in the programme, or partial completion of the programme meant that not only will I not be able to enjoy the full benefit of the programme, and that failure will be noted in my risk assessment.",
	"PLHA4": " I acknowledge and accept that my participation will lead to the collection of my data that will be used for the purpose of the programme.",
	"PLHA5": " I acknowledge and accept that a video record of the programme will be made.",
	"PLHA6": " I acknowledge and accept that the data collected will also be used for public health purposes.",
	"PLHA7": "I acknowledge and accept that in addition to the purposes specified above, the programme will be using the information it have collected from me for academic purposes, including but not limited to making academic publications, further researches and academic projects.",
	"PLHA8": " I acknowledge and accept that the data collected will also be used for risk assessment purposes. Which will utilize automated processes.",
	"PLHA9": "I acknowledge and accept that any of my personal data that is collected by the programme belongs to me and that I can request that these information be given to me in a form that I can understand and utilize, at any times.",
	"PLHA10": " I acknowledge and accept that should there be any error in any information pertaining to me, the Child-shield project will endeavour to investigate and where necessary correct that information where necessary, as soon as possible.",
	"PLHA11": " I acknowledge and accept that due to the need and the purpose of the Child-shield system, which will draw information from the programme (which is a part of the system), my information will be required to be retained by the system indefinitely.",
	"PLHA12": " I acknowledge and accept that the Child-shield project, on behalf of the programme, will be responsible for the security and integrity of the data that it collected on me.",
	"PLHA13": " I acknowledge and accept that If my family or I require any other specialized support during or after the programme, I shall inform the team as soon as I am able.",
	"PLHA14": " I acknowledge and accept that any act done, any decision made by the programme will be done with the best interest of the child as its primary consideration.",
	"PLHA15": " I acknowledge and accept that I have the right to lodge complaints with the facilitators and supervising authorities of the PLH Programme, the supervising authorities of the Child-Shield project as well as the Child-shield project’s Data Protection Officer. The relevant contact details will be provided:",

	"PLHC1": "I consent to the sharing of my information with my family members, where necessary.",
	"PLHC2": "I consent to the sharing of my information with the police, where necessary.",
	"PLHC3": "I consent to the sharing of my information with the judiciary, where necessary.",
	"PLHC4": "I consent to the sharing of my information with my legal representative, where necessary.",

	"PLHAD1": "<p>The PLH Programme is a training programme designed to assist parents with the development of positive parenting skills. You are invited to attend a parenting programme aimed at helping parents learn ways to manage their children’s behavior.</p><p>The aim of the programme is to help parents find alternatives to physical punishment or to psychological violence in disciplining and motivating their child.</p><p>The program will be delivered to groups of 15 families like yours over 8 weekly sessions. Each session will take place on a Sunday and will take between 2-3 hours. The group sessions will be held online, and you will need to have a smartphone to participate.</p><p>The group sessions will include interesting discussions about parenting and fun activities to do together. Although you will be encouraged to participate in the activities, you do not need to do anything you do not wish to do. Your child will not participate in any sessions.</p><p>A trained facilitator will be presented at all times during the training to help the participants.</p><p>In order to participate, you must be:</p><li>A parent or primary caregiver (for example, grandparent, foster parent, aunts, or uncles) of a child aged 2-9 years;</li><li>The parent or primary caregiver who spends the most time with this child;</li><li>Living in the same household as the child; and</li><li>Willing to sign the service agreement.</li><p>Please understand that the programme would have used your personal data that were obtained from other state databases to determine your suitability for the programme.</p>",
	"PLHAD2": "<p>Though, for your family and your own benefit, you are highly encouraged to participate fully until the completion of the training programme.</p><p>During the Parenting for Lifelong Health for Young Children Programme, you will collaborate closely with two parenting group facilitators. They or another public health worker will:</p><li>Meet with you before the first group session to discuss the programme in more detail;</li><li>Help you to complete a 20-minute survey about you and your child both before and after the programme;</li><li>Help your child complete a 10-minute survey before and after the programme about activities they do with their family and different ways you manage their behaviour; and</li><li>Send you text message reminders during the week.</li><p>If you are unable to attend a group session, a facilitator will be able to talk to you separately by phone to fill you in on what was covered, so that you can keep up with the group.</p>",
	"PLHAD3": "<p>The PLH Programme is a training programme designed to assist parents with the development of positive parenting skills.</p><p>Completing the training means that you have developed positive parenting skills as alternatives to physical punishment in disciplining and motivating their child or to psychological violence as well as build up a support network of families that can help each other in time of need. Failure to attend and complete all the training courses will lead to the assumption that you have not fully develop the skills or the social networks that were required of you. This will be reflected in your assessment accordingly.</p>",
	"PLHAD4": "<p>The data that were collected will be used to assess your parenting skills at each stages of the course and to expand your support network with other participants, coaches and families in your area.</p>",
	"PLHAD5": "<p>During the programme, we will video record the sessions to see how the facilitators are leading the groups. These videos will be used to ensure the quality of the programme, and to help facilitators go through a certification process so that they can deliver the programme with high quality in the future. After the facilitators are certified, the videos will be destroyed.</p>",
	"PLHAD6": "<p> BUT: The programme is obligated to keep your information confidential throughout the publications. Your information that are disclosed for public health purposes will be anonymized so that you or others who are related to you cannot be identified. If the programme intend to publicize my information for public health purposes without the information being anonymized, then it can only do so pursuant to your consent to the publication.</p><p>In such event, your consent will be sought and documented.</p>",
	"PLHAD7": "<p>BUT: The programme is obligated to keep your information confidential throughout the publications. Your information that are used for academic purposes will be anonymized so that you or others who are related to you cannot be identified. If the programme intend to publicize my information for academic purposes without the information being anonymized, then it can only do so pursuant to your consent to the publication.</p><p>In such event, your consent will be sought and documented.</p><p>Your information will be shared with an Oxford Research Team for the purpose of improving the PLH programme.</p>",
	"PLHAD8": "<p>As PLH is linked to the Child-shield project, the programme will make a record of your behaviour, which will then feed into the Child-shield risk assessment system. Positive behaviour and results such as punctuality, contribution to the group, completion of all training programmes and willingness to learn will be reflected in your risk assessment, and so will your negative behaviour and results.</p><p>Though automated processes will be used, a human being, in this case, a case manager or social worker will be the ultimate decision-maker at the end of the process. So please rest assure that any actions taken by the project that directly effects you will only be carried out after considerations by human decision-makers.</p>",
	"PLHAD9": "<p>BUT the programme reserve the right to refuse access to information that could potentially exposed you or your family to harm. The programme will provide a reasonable explanation to you, in written form, every time access is refused.</p>",
	"PLHAD10": "<p>Where the programme refuses to make changes to my information, it must provide a reasonable explanation on why it had made such a decision.</p>",
	"PLHAD11": "<p> BUT: The programme will kept your identity anonymized and pseudonymized at all times, so that only selected users who need to know of your identity to carry out the purposes of the programme will be allowed access to your identity. Your information will be kept confidential at all time.</p>",
	"PLHAD12": "<p> I acknowledge and accept that by sharing my personal data with the programme and by extension, the Child-shield project, there is an inherent risk that any intentional act or negligence on the team’s part may result in the breach of my privacy which may result in physical and mental harm to me and those close to me.</p><p>BUT: the project is obligated to protect my information and is accountable to me in the event that any such leak occurred.</p>",
	"PLHAD13": "<p>If you or your family require any other specialized support during or after the programme, we may ask you if we can share your information with other service providers. You have the right to choose whether or not we share your information with them. This special consent will be requested from you, separate from the consent request below.</p><p>The only exception is if you share any information regarding a child who has been significantly harmed or is at risk of significant harm. If this happens, the team may share this information with child protection professionals according to the 2003 Child Protection Act (B.E. 2546), in order to help protect the child, regardless of your consent.</p>",
	"PLHAD14": "<p>What this meant is that the team will take a holistic approach and take action that will be in the best interest of the child. But would like you to understand that the best interest of the child will be given primacy above all other considerations. For example: your participation in the programme is deemed to be in the best interest of the child and keeping your information confidential is deemed to be in the best interest of you and your child, but if such acts were to cause harm to the child, the team will prioritize the child’s safety over your participation or privacy interest.</p>",
	"PLHAD15": "<p>contact details</p>",

	"PLHCD1": "<p>You may want to share details of the skills you have developed and the network you have made from the programme with your family members, so that they can keep track of your progress, help encourage your participations, reinforce your skills and relies on your network in the event where you are unable to take advantage of them.</p><p>As stated, the programme will only share what information it deems necessary.</p><p>The only exception is if you share any information regarding a child who has been significantly harmed or is at risk of significant harm. If this happens, the team may share this information with child protection professionals according to the 2003 Child Protection Act (B.E. 2546), in order to help protect the child, regardless of your consent.</p>",
	"PLHCD2": "<p>In the unfortunate event of you becoming suspected or even worse, your arrest, your record in the programme may be used as mitigating factors or evidence in your favour.</p><p>As stated, the programme will only share what information it deems necessary.</p><p>The only exception is if you share any information regarding a child who has been significantly harmed or is at risk of significant harm. If this happens, the team may share this information with child protection professionals according to the 2003 Child Protection Act (B.E. 2546), in order to help protect the child, regardless of your consent.</p>",
	"PLHCD3": "<p>In the unfortunate event where you become involved in a legal proceeding, your record in the programme may be useful.</p><p>As stated, the programme will only share what information it deems necessary.</p><p>The only exception is if you share any information regarding a child who has been significantly harmed or is at risk of significant harm. If this happens, the team may share this information with child protection professionals according to the 2003 Child Protection Act (B.E. 2546), in order to help protect the child, regardless of your consent.</p>",
	"PLHCD4": "<p>In the unfortunate event of you becoming involved in a legal proceeding, your record in the programme may be useful.</p><p>As stated, the programme will only share what information it deems necessary.</p><p>The only exception is if you share any information regarding a child who has been significantly harmed or is at risk of significant harm. If this happens, the team may share this information with child protection professionals according to the 2003 Child Protection Act (B.E. 2546), in order to help protect the child, regardless of your consent.</p>",

	// CONSENT REGISTER Child&Parent HEADING
	"CRCH1": "<p>I acknowledge that the Child-shield project will be processing my data for the purpose of monitoring my safety so that the state may intervene to rescue me from harm, to prevent me from coming to harm and to ensure that I am safe during my rehabilitation.</p>",
	"CRCH2": "<p>I acknowledge that the Child-shield project will be processing my data through an automated machine learning system to make predictions and trends.</p>",
	"CRCH3": "<p>I acknowledge that the information on my HIS, the OSCC database and other relevant information that have been collected by the state will be use for the purpose of the Child-shield project.</p>",
	"CRCH4": "<p>I acknowledge that the ownership any information collected by the Child-shield project pertaining to myself belongs to me and that I can request that these information be given to me in a form that I can understand and utilize, at any times.</p>",
	"CRCH5": "<p>I acknowledge that in addition to the purposes specified above, the Child-shield project will be using the information it have collected from me for academic purposes, including but not limited to making academic publications, further researches and academic projects.</p>",
	"CRCH6": "<p>I acknowledge that in addition to the purposes specified above, the Child-shield project will publicized the information it have collected from me for public health purposes.</p>",
	"CRCH7": "<p>I acknowledge that should there be any error in any information pertaining to me, the Child-shield project will endeavour to investigate and where necessary correct that information where necessary, as soon as possible.</p>",
	"CRCH8": "<p>I acknowledge that due to the need and the purpose of the Child-shield system, my information will be required to be retained by the system indefinitely.</p>",
	"CRCH9": "<p>I acknowledge that the Child-shield project will be responsible for the security and integrity of the data that it collected on me.</p>",
	"CRCH10": "<p>I acknowledge that I have the right to lodge complaints with the supervising authorities of the Child-Shield project as well as the Child-shield project’s Data Protection Officer. The relevant contact details will be provided.</p>",

	// CONSENT REGISTER Child&Parent DETAIL
	"CRCD1":"<p>I acknowledge that the Data Subject have the right to be notified</p><p>Any consent sought will be done through the system.</p><p>Any consent sought, given and refused will be documented.</p>",
	"CRCD2": "<p>The Child-shield project will aggregate the data of all the Data Subject in its database to make prediction on the extent and type of risks for each Data Subject in the system. This is done by treating like cases alike. The system will identify factors whose presence in a Data Subject’s environment increases the risk of the Data Subject becoming a victim themselves, compared to other Data Subject who do not have such factor in their environment then use these factors to identify Data Subject who are at risk.</p><p>The risks prediction will effect the whether service and interventions will be provided to the Data Subject as well as the type and extent that the team will be provide.</p><p>Though automated processes will be used, a human being, in this case, a case manager or social worker will be the ultimate decision-maker at the end of the process. So please rest assure that any actions taken by the project that directly effects you will only be carried out after considerations by human decision-makers.",
	"CRCD3": "<p>The team will collect preliminary data from these databases to build the foundation of its database. It will then collect additional information to make its calculation more accurate.</p>",
	"CRCD4": "<p>BUT: I also acknowledge that the Child-shield project reserve the right to refuse my access to information that could potentially exposed me to harm. I acknowledge that the Child-shield system must provide a reasonable explanation to me, in written form, every time my access is refused.</p>",
	"CRCD5": "<p>BUT: I also acknowledge that the Child-shield project is obligated to keep my information confidential throughout the publications. That my information that are used for academic purposes will be anonymized so that I cannot be identified. If the Child-shield project intend to publicize my information for academic purposes without the information being anonymized, then it can only do so pursuant to my consent to the publication.</p>",
	"CRCD6": "<p>BUT: I also acknowledge that the Child-shield project is obligated to keep my information confidential throughout the publications. That my information that are used for public health purposes will be anonymized so that I cannot be identified. If the Child-shield project intend to publicize my information for public health purposes without the information being anonymized, then it can only do so pursuant to my consent to the publication.</p>",
	"CRCD7": "<p>I acknowledge that where the Child-shield project refuses to make changes to my information, it must provide a reasonable explanation to me on why it had made this decision.</p>",
	"CRCD8": "<p>BUT: I acknowledge that my identity will be anonymized and pseudonymized at all times, so that only users who need to know of my identity to carry out the purposes of the project will be allowed access to my identity. My information will be kept confidential at all time.</p><p>ALSO: I acknowledge that upon my reaching the age of majority (18 years old), the Child-shield project will cease collecting information on me and my information that was collected by the Child-shield project will immediately be anonymized and pseudonymized so that I can no longer be identified (unless I requested otherwise).</p>",
	"CRCD9": "<p>I acknowledge that by sharing my personal data with the Child-shield project, there is an inherent risk that any intentional act or negligence on the team’s part may result in the breach of my privacy which may result in physical and mental harm to me and those close to me.</p><p>BUT: I acknowledge that the Child-shield project will be accountable to me in the event of such breach.</p><p>I acknowledge that the Child-shield project will keep my information anonymized and pseudonymized.</p><p>I acknowledge that the Child-shield project will notify me in any event where there is a breach or misuse of my information; in the notification, the Child-shield project will inform me of the extent of the breach, what damage this could cause me, what measure it has taken to limit this damage, what remedies are available and how it will make sure that such breach or misuse will never happen again.",
	"CRCD10": "",

	// CONSENT REGISTER Officer HEADING
	"CROH1": "<p>I acknowledge that the Application and the Project will be processing the Data Subject’s data for the purpose of the PLH programme. If I wish the process the Data Subject’s data for any purposes other than the purpose stated by the PLH programme, I am required to formally notify the Data Subject and obtain their consent.</p>",
	"CROH2": "<p>I acknowledge that the ownership any information collected by the Application and the Project pertaining to the Data Subject belongs to the Data Subject and that they can request that these information be given to them in a form that they can understand and utilize, at any times. Which means that I will be obligated to process their data for them should they request it.</p>",
	"CROH3": "<p>I acknowledge that the Child-shield Team will be responsible for the security and integrity of the data that it collected and process.</p>",
	"CROH4": "<p>I acknowledge that I have an obligation to inform the team of any breach or potential breach committed by other officers or users.</p>",
	"CROH5": "<p>I acknowledge that I have an obligation to inform the team and the data protection officer of any flaw or vulnerability to the Child-shield system’s security or practices.</p>",
	"CROH6": "<p>I acknowledge that I have an obligation to correct any errors in any information pertaining to the Data Subject.</p>",

	// CONSENT REGISTER Officer DETAIL
	"CROD1": "<p>I acknowledge that the Data Subject have the right to be notified.</p><p>The PLH Programme is a training programme designed to assist parents with the development of positive parenting skills. Parents are invited to attend a parenting programme aimed at helping parents learn ways to manage their children’s behavior.</p><p>The aim of the programme is to help parents find alternatives to physical punishment or to psychological violence in disciplining and motivating their child.</p><p>Suitable participants are selected using the Child-shield risk assessment system.</p>",
	"CROD2": "<p>BUT: I also acknowledge that the Team reserve the right to refuse the Data Subject’s access to information that could potentially exposed them to harm. Though I will be required as part of the PLH Team to provide a reasonable explanation, in written form, every time I refuse such access.</p><p>I acknowledge that I am obligated to keep the Data Subject’s in confidence. I understand that failure to do so, by action, inactions and negligence will result in internal discipline within the team, discipline within the workforce or where appropriate penalty imposed by the law.</p>",
	"CROD3": "<p>I acknowledge that the Team will not be responsible for any information that is beyond its control.</p><p>I acknowledge that I am obligated to notify the Team of any breach or potential breach.</p><p>I acknowledge that I am obligated to notify the Data Subject of any breach or potential breach, unless instructed otherwise.</p><p>I acknowledge that I am obligated to notify the Data Protection Officer of any breach or potential breach.</p>",
	"CROD4": "<p>The report can be submitted to team leaders, IT personnels or the Data Protection Officer.</p><p>The report will be submitted via a formal process. The report will be documented. My identity will be kept anonymous to non-relevant personnel.</p>",
	"CROD5": "<p>These also include any improvements to current practices to futureproof and innovate the system.</p>",
	"CROD6": "<p>BUT I must have investigated and confirm that error before I correct it.</p><p>Where I decided not to correct such error, I must provide a reasonable explanation to the Data Subject as to why I decided not to correct the error.</p>",

}