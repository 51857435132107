import React from "react";
import globalHook from "use-global-hook";
import * as actions from "./actions";

const initialState = {
  language:'th',
  currentUser: {},
  userRole: 'noRole',
  loginStatus: false,
  isLineLogin: false,
  anchorEl: null,
  anchorNotification: null,
  notificationBadge:null,
  drawerOpen: false,
  mobileView: false,
  focusedCaseStatus:'',
  dashboardFilter:{
    changwat:{
      changwatcode:"",
      changwatname:""
    },
    amphur:{
      amphur_id:"",
      amphur_name:""
    },
    hospital:{
      hos_id:"",
      hos_fullname:"",
      hos_name:""
    }
  }
};

const useGlobal = globalHook(React, initialState, actions);

export default useGlobal;
