/* eslint-disable no-unused-vars */
import axios from "axios";
import * as authHeader from "../services/auth-header";
import { getYear, getMonth, getDate } from "date-fns";

const API_URL = authHeader.API_URL;
const DEFAULT_PORT = authHeader.PORT;

const makeUrl = (port) => {
  let urlPort = DEFAULT_PORT;
  if (typeof port !== 'undefined') {
    if (port !== null && port !== '') {
      urlPort = port;
    }
  }
  let url = API_URL + ":" + urlPort + '/api/';
  return url;
}

const getAll = async (params, thisCollection, port) => {
  const headers = await authHeader.getToken();
  let url = `${makeUrl(port)}${thisCollection}`;
  return await axios.get(url, { headers: headers, params: params });
};

const getLimit = async (params, thisCollection, port) => {
  const headers = await authHeader.getToken();
  let url = `${makeUrl(port)}${thisCollection}`;
  return axios.get(url, { headers: headers, params: params });
};

const getCount = async (params, thisCollection, port) => {
  const headers = await authHeader.getToken();
  let url = `${makeUrl(port)}${thisCollection}`;
  return axios.get(url + "/count", { headers: headers, params: params });
};

const get = async(id, thisCollection, port) => {
  const headers = await authHeader.getToken();
  let url = `${makeUrl(port)}${thisCollection}`;
  return axios.get(url + "/" + id, { headers: headers });
};

const create = async(data, thisCollection, port) => {
  const headers = await authHeader.getToken();
  let url = `${makeUrl(port)}${thisCollection}`;
  return axios.post(url, data, { headers: headers });
};

const update = async(id, data, thisCollection, port) => {
  const headers = await authHeader.getToken();
  let url = `${makeUrl(port)}${thisCollection}`;
  return axios.put(url + "/" + id, data, { headers: headers });
};

const remove = async(id, thisCollection, port) => {
  const headers = await authHeader.getToken();
  let url = `${makeUrl(port)}${thisCollection}`;
  return axios.delete(url + "/" + id, { headers: headers });
};
const patch = async(id, data, thisCollection, port) => {
  const headers = await authHeader.getToken();
  let url = `${makeUrl(port)}${thisCollection}`;
  return axios.patch(url + "/" + id, data, { headers: headers });
};
const removeAll = async(thisCollection, port) => {
  const headers = await authHeader.getToken();
  let url = `${makeUrl(port)}${thisCollection}`;
  return axios.delete(url, { headers: headers });
};

const findByTitle = async(title, thisCollection, port) => {
  const headers = await authHeader.getToken();
  let url = `${makeUrl(port)}${thisCollection}`;
  return axios.get(url + "?filter[where][title][like]=" + title, { headers: headers });
};

const dateTHs = (d) => {
  let r = d;
  if (d && typeof d != 'undefined') {
    // r = parseInt(getDate(d)).toString() + ' ' + thaiMonth(parseInt(getMonth(d)) + 1, 'short') + ' ' + (parseInt(getYear(d)) + 543).toString();
    r = parseInt(d.substr(8,2)).toString() + ' ' + thaiMonth(d.substr(5,2), 'short') +' '+ (parseInt(d.substr(0,4))+543).toString();
  }
  else {
    r = '-';
  }
  return r;
}

const thaiMonth = (m, z) => {
  let x = parseInt(m);
  let r = m;
  if (z === 'short') {
    switch (x) {
      case 1: r = 'ม.ค.'; break;
      case 2: r = 'ก.พ.'; break;
      case 3: r = 'มี.ค.'; break;
      case 4: r = 'เม.ย.'; break;
      case 5: r = 'พ.ค.'; break;
      case 6: r = 'มิ.ย.'; break;
      case 7: r = 'ส.ค.'; break;
      case 8: r = 'ก.ค.'; break;
      case 9: r = 'ก.ย.'; break;
      case 10: r = 'ต.ค.'; break;
      case 11: r = 'พ.ย.'; break;
      case 12: r = 'ธ.ค.'; break;
      default: r = ''; break;
    }
  }
  if (z === 'long') {
    switch (x) {
      case 1: r = 'มกราคม'; break;
      case 2: r = 'กุมภาพันธ์'; break;
      case 3: r = 'มีนาคม'; break;
      case 4: r = 'เมษายน'; break;
      case 5: r = 'พฤษภาคม'; break;
      case 6: r = 'มิถุนายน'; break;
      case 7: r = 'สิงหาคม'; break;
      case 8: r = 'กรกฎาคม'; break;
      case 9: r = 'กันยายน'; break;
      case 10: r = 'ตุลาคม'; break;
      case 11: r = 'พฤศจิกายน'; break;
      case 12: r = 'ธันวาคม'; break;
      default: r = ''; break;
    }
  }
  return r;
}

const generateTeamCode = () => {
  let length = 24;
  let chars = '0123456789abcdefghijklmnopqrstuvwxyz';
  var x = '';
  for (var i = length; i > 0; --i) {
    x += chars[Math.floor(Math.random() * chars.length)];
  }
  return x;
}

const genKey = () => {
  return Math.floor(Math.random() * 1000000000) + 1;
}

export {
  dateTHs,
  getAll,
  getLimit,
  getCount,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  patch,
  generateTeamCode,
  genKey
};
