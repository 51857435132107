import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Theme, createStyles, makeStyles, styled } from '@material-ui/core/styles';
// import clsx from 'clsx';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
// import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { Box, Grid } from '@material-ui/core';
// import AddIcon from '@material-ui/icons/Add';
import CaseStatusByRole from './caseStatusByRole';
import ButtonsByRole from './actionByRole';
import { SelectBox } from '../../../types/activity';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
// import { useHistory } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import { GridRowData } from '@material-ui/data-grid';
import { CurrentPageContext } from './SetPageContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import useDeepCompareEffect from 'use-deep-compare-effect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    details: {
      alignItems: 'center',
    },
    column: {
      flexBasis: '33.33%',
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    loading: {
      minHeight: "432px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    accordion: {
      "& .MuiAccordionSummary-root.Mui-expanded" : {
        backgroundColor: "#6a6a79",
        color: "white"
      },
      "& .MuiIconButton-root.Mui-expanded": {
        color: "white"
      },
      "& .MuiAccordionSummary-content .MuiGrid-item": {
        alignItems: "center",
        display: "flex"
      }
    },
    pagination: {
      marginTop: "1rem",
      justifyContent: "center",
      display: "flex",
      // "& .MuiPagination-root":{
      //   width: "100%"
      // },
      "& .MuiPagination-ul":{
        // justifyContent: "space-between",
        gridColumnGap: "5px"
      }
    },
    columnLabel:{
      display: "flex",
      justifyContent: "space-between"
    },
    columnChip: {
      flexDirection: "column",
      display: "flex",
      rowGap: "5px",
      "& .MuiChip-root" : {width: "max-content"}
    }
  }),
);

interface IProps {
  loading?:boolean;
  data: GridRowData;
  filltered?: Record<string, any>;
  setShowInformConsent?: Dispatch<SetStateAction<boolean>>;
  setShowConclusionDialog?: Dispatch<SetStateAction<boolean>>;
  removeGroupCase?: (id: string) => void;
  page?: string;
}
const StyledChip = styled(Chip)((props:any) => ({
  color: props.fontcolor,
  borderColor: props.bordercolor,
  backgroundColor: props.bgcolor,
}));

export default function DataGridMobile(props: IProps) {
  const pageContext = useContext(CurrentPageContext);
  const classes = useStyles();
  // const dispatch = useDispatch();
  // const history = useHistory();
  const globalState = useSelector((state: RootState) => state);
  const userinfo = globalState.auth.user.response.user;
  const role = userinfo.role;
  const caseStatus: Array<SelectBox> = CaseStatusByRole(role as string);
  const {loading, data, setShowInformConsent, setShowConclusionDialog, page} = props;
  const [_startEffect, _setStartEffect] = useState(false);
  const [expanded, setExpanded] = React.useState<string | boolean>(false);
  // const [locationState, setLocationState] = useState({});
  // const [caseData, setCaseData] = useState<GridRowData>({});
  // const [elementsData, setElementsData] = useState<GridRowData>([]);

  const handleChange = (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    // console.log("handleChange",isExpanded,panel);
    setExpanded(isExpanded ? panel : false);
  };
  const genStatus = useCallback((status:any) => {
    if(caseStatus && caseStatus.length>0){
      let returnChip;
      caseStatus.forEach((item:any,key:number)=>{
        if(status===item.id){
          returnChip = <StyledChip
            key={key}
            label={item.label}
            size="small"
            variant={(item.variant)?item.variant:"default"}
            fontcolor={(item.fontColor)?item.fontColor:"black"}
            bordercolor={(item.variant==="outlined")?item.fontColor:"black"}
            bgcolor={(item.bgcolor)?item.bgcolor:"primary"}
            />;
        }
      });
      return returnChip;
    }else{
      return status;
    }
  },[caseStatus]);
  const genAccordionDetails = useCallback((data: Record<string, any>)=>{
      // console.log(data);
      const detail = data.map((item:any, index:number)=>{
        const label = <Grid item xs={(item.style)?item.style.label:4}><Typography variant='body2' className={classes.columnLabel}>{item.label}</Typography></Grid>;
        let value = null;
        if(_.isArray(item.value)){
          value = <Grid item xs={7} className={classes.columnChip}>{
            item.value.map((v:any,i:number)=>{
              if(typeof v ==="string"){
                return <StyledChip
                  key={"chip"+i}
                  label={v}
                  size="small"
                  variant="default"
                  fontcolor="black"
                  bordercolor="black"
                  bgcolor="primary"
                  />
              }else if(typeof v ==="object"){
                return <Box style={{width:"100%"}} key={"object"+i}>
                  <Typography variant="body2" style={{width:"100%"}}>{v.label}</Typography>
                  {
                    (_.isArray(v.value))? 
                      v.value.map((vv:any,ii:number)=>{
                        return <StyledChip
                          key={"subchip"+ii}
                          label={vv}
                          size="small"
                          variant="default"
                          fontcolor="black"
                          bordercolor="black"
                          bgcolor="primary"
                          />
                      }):v.value
                  }
                </Box>
              }else{
                return v;
              }
            })
          }</Grid>
        }else{
          value = <Grid item xs={(item.style)?item.style.value:7} className={classes.columnChip}><Typography variant='body2'>{item.value}</Typography></Grid>
        }
        return (
          <Grid item xs={12} key={"row"+index}>
            <Grid container spacing={1} style={{width:"100%"}}>{label}<Grid item xs={1} style={{marginTop:"-3px"}}>:</Grid>{value}</Grid>
          </Grid>
        )
      });
    return <Grid container spacing={2}>{detail}</Grid>;
  },[classes.columnChip,classes.columnLabel]);
  const genAccordion = useMemo(()=>{   
    //data: Record<string, any>
    if(data.length>0){
      // console.log("genAccordion useCallback",data);
      // console.log("expanded",expanded);
      const elements = data.map( (item: any, index: number) => {
        // console.log('expanded={expanded === "panel'+(index+1)+'} | onChange={handleChange("panel'+(index+1)+')}');
        return (
          <Accordion className={classes.accordion} key={index} expanded={expanded === "panel"+(index+1)}  onChange={handleChange("panel"+(index+1))}>
            <AccordionSummary 
              expandIcon={<ExpandMoreIcon />}  
              id={"panel"+(index+1)+"-header"} 
              aria-controls={"panel"+(index+1)+"-content"}>
              <Grid container spacing={3}>
                <Grid item xs={(item.status&&item.status!=="")?8:12}>
                  <Typography className={classes.heading}>{item.name}</Typography>
                </Grid>
                {(item.status&&item.status!=="")&&<Grid item xs={4}>{genStatus(item.status)}</Grid>}
              </Grid>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className={classes.details}>
              { (item.data.length>0)&&genAccordionDetails(item.data)}
            </AccordionDetails>
            <Divider />
            <AccordionActions>
              <ButtonsByRole role={role} manage={item.manage} caseStatus={item.status} handleClick={item.allFunction} page={page}/>
            </AccordionActions>
          </Accordion>
        )
      });
      // console.log(elements);
      // setElementsData(elements);
      return elements;
    }else{
      return (
        <Accordion className={classes.accordion} expanded={false}>
          <AccordionSummary id="panel-header" aria-controls="panel-content">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography className={classes.heading}>ไม่มีข้อมูล</Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
        </Accordion>
      );
    }
  },[data,classes.accordion, classes.heading, classes.details, expanded, genStatus, genAccordionDetails, role, page]);
  
  // useDeepCompareEffect(() => { // ✅ ถ้าข้อมูลเคส มีการเปลี่ยนแปลงให้ปิด Dialog Filter
  //   if(["/groupDialogEdit"].includes(page as string))console.log(data,page);
  // },[data]);

  return (
    <Grid container className={classes.root} style={{margin:0}}>
      <Grid item xs={12} className={(loading)?classes.loading:""}>
        { (loading)? <CircularProgress disableShrink /> : genAccordion }
      </Grid>
      {
        (pageContext.count>0)&&
          <Grid item xs={12} className={classes.pagination}>
            <Pagination count={pageContext.count} page={pageContext.currentPage+1} shape="round" color="primary" size="small"
              onChange={(event: React.ChangeEvent<unknown>, page: number)=>{
                pageContext.setCurrentPage(page-1)
              }}/>
          </Grid>
      }
    </Grid>
  );
}
