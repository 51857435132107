/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getCurrentUser } from "../../services/auth.service";
import { useHistory } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  CircularProgress,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl
} from "@material-ui/core";
// import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import "moment/locale/th";
import { useTranslation } from "react-i18next";
import * as UAPI from "../../services/UniversalAPI";
import CaseFilter from "../../services/caseFilter";
import {
  Autocomplete,
  createFilterOptions,
} from '@material-ui/lab';

// DATATABLE + EXCEL ของ DevExtreme
// import DataGrid, { Column, Export } from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
// import { exportDataGrid } from 'devextreme/excel_exporter';
import './dx.light.css';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiTextField-root': {
      width: '100%',
    },
    '& .MuiInputLabel-outlined': {
      zIndex: 1,
      transform: 'translate(15px, 12px) scale(1)',
      pointerEvents: 'none'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(15px, -17px) scale(0.75)',
    },
    '& .MuiInputBase-input': { textAlign: 'center' },
  }
}));

export default function Report(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const userinfo = getCurrentUser();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [questionnaireData, setQuestionnaireData] = useState([]);

  const [filterData, setFilterData] = useState({});
  const [formData, setFormData] = useState({
    questionnaire: {}
  });

  const [disabledExecuteReportButton, setDisabledExecuteReportButton] = useState(true);
  const [tableData, setTableData] = useState([]);

  const OPTIONS_LIMIT = 100;
  const defaultFilterOptions = createFilterOptions();
  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  const [seletedVersion, setSeletedVersion] = useState('');
  const [XLSX, setXLSX] = useState({
    filename: '',
    sheetname: 'SHEET1',
    header1: '',
    header2: '',
    footer1: '',
    footer2: ''
  });

  const mkAnswer = (a) => {
    let thisAnswer = '';
    if (typeof a !== 'undefined') {
      if (typeof a.questionType !== 'undefined') {
        if (parseInt(a.questionType) === 1) {
          if (typeof a.answersList !== 'undefined') {
            if (a.answersList) {
              thisAnswer = a.answersList.toString();
            }
          }
        }
        else if (parseInt(a.questionType) === 2) {
          if (typeof a.answersList !== 'undefined') {
            if (a.answersList) {
              if (a.answersList.length > 0) {
                a.answersList.forEach(i => {
                  if (typeof i.checked !== 'undefined') {
                    if (i.checked === true) {
                      if (typeof i.answer !== 'undefined') {
                        if (i.answer) {
                          thisAnswer = i.answer.toString();
                        }
                      }
                    }
                  }
                });
              }
            }
          }
        }
        else if (parseInt(a.questionType) === 3) {
          let alist = [];
          if (typeof a.answersList !== 'undefined') {
            if (a.answersList) {
              if (a.answersList.length > 0) {
                a.answersList.forEach(i => {
                  if (typeof i.checked !== 'undefined') {
                    if (i.checked === true) {
                      if (typeof i.answer !== 'undefined') {
                        if (i.answer) {
                          alist.push(i.answer.toString());
                        }
                      }
                    }
                  }
                });
              }
            }
          }
          thisAnswer = alist.join(', ');
        }
      }
    }
    return thisAnswer;
  }

  const ATCChange = (e) => {
    if (typeof e.value !== 'undefined') {
      if (e.name === 'questionnaire') {
        setTableData([]);
        if (e.value) {
          // console.log(e.value);
          let date = new Date();
          var sd = date.getFullYear() + ('00' + (date.getMonth() + 1)).slice(-2) + ('00' + date.getDate()).slice(-2);
          setFormData({ ...formData, ...{ questionnaire: e.value } });
          setXLSX({ ...XLSX, ...{ header1: e.value.fullname, filename: sd + '_' + e.value.questionnaire_code, sheetname: e.value.questionnaire_code } });
        }
        else {
          setFormData({ ...formData, ...{ questionnaire: {} } });
          setXLSX({ ...XLSX, ...{ header1: '', filename: '', sheetname: 'SHEET1' } });
        }
      }
    }
  }

  const getQuestionnaire = async () => {
    // console.log('getQuestionnaire--- ');
    let PLHlist = [];
    let filter_ss = {
      filter : {
        where: {
          key: "bluePrintServiceSlotOSCCPLH8wks1st"
        }
      }
    };
    let responseSS = await UAPI.getAll(filter_ss, 'systemsettings', null);
    if (responseSS.status === 200) {
      if (responseSS.data) {
        if (responseSS.data.length > 0) {
          PLHlist = responseSS.data[0].value.serviceslotList;
        }
      }
    }

    let QNDATA = [];
    let xParams = {
      filter: {
        where: { questionnaire_type: "PLH1" },
        order: "questionnaire_code ASC",
        fields: ["id", "questionnaire_code", "questionnaire_name", "updateVersion", "analysisVersion"]
      }
    };
    let response = await UAPI.getAll(xParams, 'questionnaires', null);
    if (response.status === 200) {
      if (response.data) {
        if (response.data.length > 0) {
          QNDATA = response.data;
        }
      }
    }

    QNDATA.forEach(i => {
      i.fullname = i.questionnaire_code + ' ' + i.questionnaire_name;
    });

    PLHlist.forEach(i1 => {
      QNDATA.forEach(i2 => {
        if (i1.questionnaire_code===i2.questionnaire_code) {
          i1.questionnaire_name = i2.questionnaire_name;
          i1.fullname = 'สัปดาห์ที่ '+i1.week.toString()+' - '+i2.questionnaire_code + ' : ' + i2.questionnaire_name;
          i1.analysisVersion=i2.analysisVersion;
          i1.updateVersion=i2.updateVersion;
        }
      });
    });

    // console.log(PLHlist);
    // console.log(QNDATA);

    setQuestionnaireData(PLHlist);
  }

  const executeReport = async () => {
    setOpenBackdrop(true);
    const addressName = (x, d) => {
      // console.log(x, d);
      let r = { changwatname: '', ampurname: '', tambonname: '' };
      if (typeof x !== 'undefined') {
        if (typeof d !== 'undefined') {
          if (d) {
            if (d.length > 0) {
              d.forEach(i => {
                if (i.addressid === x) {
                  let z = i.full_name;
                  let zz = z.split(' ');
                  // console.log(zz);
                  r = { changwatname: zz[2].replace('จ.',''), ampurname: zz[1].replace('อ.',''), tambonname: zz[0].replace('ต.','') };
                }
              });
            }
          }
        }
      }
      return r;
    }

    let villagecode = '';
    let moopart = null;
    let tmbpart = null;
    let amppart = null;
    let chwpart = null;
    let tambolData = [];
    if (typeof filterData.changwat !== 'undefined') {
      if (filterData.changwat) {
        if (typeof filterData.changwat.changwatcode !== 'undefined') {
          if (filterData.changwat.changwatcode && filterData.changwat.changwatcode !== '') {
            villagecode = filterData.changwat.changwatcode;
            chwpart = villagecode.substr(0, 2);
            if (typeof filterData.ampur !== 'undefined') {
              if (filterData.ampur) {
                if (typeof filterData.ampur.amphur_id !== 'undefined') {
                  villagecode = filterData.ampur.amphur_id;
                  amppart = villagecode.substr(2, 2);
                  if (typeof filterData.tambon !== 'undefined') {
                    if (filterData.tambon) {
                      if (typeof filterData.tambon.addressid !== 'undefined') {
                        villagecode = filterData.tambon.addressid;
                        tmbpart = villagecode.substr(4, 2);
                        if (typeof filterData.moo !== 'undefined') {
                          if (filterData.moo) {
                            if (typeof filterData.moo.villagecode !== 'undefined') {
                              villagecode = filterData.moo.villagecode;
                              moopart = villagecode.substr(6, 2);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    let opData = [];
    if (chwpart) {
      let filter_osccperson = {
        filter: {
          where: {
            villagecode: { like: villagecode + '.*' }
          },
          include: {
            relation: 'planservice',
            scope: {
              include: {
                relation: 'person',
                scope: {
                  include: {
                    relation: 'intervention',
                    scope: {
                      where: {
                        "activities.homeservice.questionnaire.questionnaire_code": { gt: "" }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      };
      // console.log(JSON.stringify(filter_osccperson.filter));
      let responseOP = await UAPI.getAll(filter_osccperson, 'osccpeople', null);
      if (responseOP.status === 200) {
        if (responseOP.data) {
          if (responseOP.data.length > 0) {
            // console.log(responseOP.data);
            opData = responseOP.data;
          }
        }
      }
    }
    // console.log(opData);

    let sequenceQNData = [];
    if (typeof formData.questionnaire !== 'undefined') {
      if (typeof formData.questionnaire.sequenceId !== 'undefined') {
        opData.forEach(i2 => {
          if (typeof i2.planservice !== 'undefined') {
            if (i2.planservice) {
              if (i2.planservice.length>0) {
                i2.planservice.forEach(i3 => {
                  if (typeof i3.person !== 'undefined') {
                    if (i3.person) {
                      if (typeof i3.person.intervention !== 'undefined') {
                        if (i3.person.intervention) {
                          if (i3.person.intervention.length>0) {
                            let itvt = i3.person.intervention;
                            itvt.forEach(i4 => {
                              if (typeof i4.activities !== 'undefined') {
                                if (i4.activities) {
                                  if (typeof i4.activities.homeservice !== 'undefined') {
                                    if (i4.activities.homeservice) {
                                      if (i4.activities.homeservice.length>0) {
                                        let hs = i4.activities.homeservice;
                                        hs.forEach(i5 => {
                                          if (i5) {
                                            if (typeof i5.serviceType !== 'undefined') {
                                              if (i5.serviceType) {
                                                if (i5.serviceType.toLowerCase()==='oscc') {
                                                  if (typeof i5.questionnaire !== 'undefined') {
                                                    if (i5.questionnaire) {
                                                      if (i5.questionnaire.length>0) {
                                                        let qn = i5.questionnaire;
                                                        qn.forEach(i6 => {
                                                          if (typeof i6.consent !== 'undefined') {
                                                            if (typeof i6.consent.result !== 'undefined') {
                                                              if (i6.consent.result === 'consented') {
                                                                if (i6.sequenceId === formData.questionnaire.sequenceId) {
                                                                  let an = addressName(i2.villagecode.substr(0,6), tambolData);
                                                                  i6.fname = i2.fname;
                                                                  i6.lname = i2.lname;
                                                                  i6.changwatcode = i2.villagecode.substr(0,2);
                                                                  i6.changwatname = an.changwatname;
                                                                  i6.ampurcode = i2.villagecode.substr(2,2);
                                                                  i6.ampurname = an.ampurname;
                                                                  i6.tamboncode = i2.villagecode.substr(4,2);
                                                                  i6.tambonname = an.tambonname;
                                                                  i6.moocode = i2.villagecode.substr(6,2);
                                                                  if (typeof i6.analysisVersion === 'undefined') {
                                                                    i6.analysisVersion = '0000-00-00 00:00:00';
                                                                  }
                                                                  if (i6.analysisVersion === null) {
                                                                    i6.analysisVersion = '0000-00-00 00:00:00';
                                                                  }
                                                                  sequenceQNData.push(i6);
                                                                }
                                                              }
                                                            }
                                                          }
                                                        });
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        });
                                      }
                                    }
                                  }
                                }
                              }
                            });
                          }
                        }
                      }
                    }
                  }
                });
              }
            }
          }
        });
      }
    }
    // console.log(sequenceQNData);

    let chwpartList = [];
    sequenceQNData.forEach(i => {
      if (typeof i.changwatcode !== 'undefined') {
        if (i.changwatcode) {
          if (i.changwatcode !== '') {
            if (i.changwatcode.length === 2) {
              if (chwpartList.indexOf(i.changwatcode) < 0) {
                chwpartList.push(i.changwatcode);
              }
            }          
          }
        }
      }
    });
    // console.log(chwpartList);

    if (chwpartList.length > 0) {
      let filter_chwpart_or = [];
      chwpartList.forEach(cw => {
        filter_chwpart_or.push({ chwpart: cw });
      });
      let filter_chwpart = {
        filter: {
          where: {
            or: filter_chwpart_or
          }
        }
      };
      let responseT = await UAPI.getAll(filter_chwpart, 'tambols', null);
      if (responseT.status === 200) {
        if (responseT.data) {
          if (responseT.data.length > 0) {
            tambolData = responseT.data;
          }
        }
      }
    }
    // console.log(tambolData);

    sequenceQNData.forEach(i => {
      let addresscode = '';
      if (typeof i.changwatcode !== 'undefined') {
        if (i.changwatcode) {
          if (i.changwatcode.length===2) {
            addresscode=addresscode+i.changwatcode;
            if (typeof i.ampurcode !== 'undefined') {
              if (i.ampurcode) {
                if (i.ampurcode.length===2) {
                  addresscode=addresscode+i.ampurcode;
                  if (typeof i.tamboncode !== 'undefined') {
                    if (i.tamboncode) {
                      if (i.tamboncode.length===2) {
                        addresscode=addresscode+i.tamboncode;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      // console.log(addresscode);
      let an = addressName(addresscode, tambolData);
      i.changwatname = an.changwatname;
      i.ampurname = an.ampurname;
      i.tambonname = an.tambonname;
    });
    // console.log(sequenceQNData);

    let analysisVersionList = [];
    let x = [];
    if (sequenceQNData.length > 0) {
      sequenceQNData.forEach(i => {
        if (analysisVersionList.indexOf(i.analysisVersion) < 0) {
          analysisVersionList.push(i.analysisVersion);
        }
      });

      analysisVersionList.forEach(a => {
        let thisAnaVer = [];
        sequenceQNData.forEach(d => {
          if (d.analysisVersion === a) {
            thisAnaVer.push(d);
          }
        });
        x.push({ analysisVersion: a, data: thisAnaVer });
      });
      // console.log(data);
    }
    // console.log(x);

    x.sort(function (a, b) {
      // b-a เรียงมากไปน้อย
      // a-b เรียงน้อยไปมาก
      let ax = parseInt((new Date(a.analysisVersion).getTime()).toString());
      let bx = parseInt((new Date(b.analysisVersion).getTime()).toString());
      ax = (isNaN(ax) ? 0 : ax);
      bx = (isNaN(bx) ? 0 : bx);
      return bx - ax;
    });
    if (x.length > 0) {
      setSeletedVersion(x[0].analysisVersion);
    }
    // console.log(x);
    setTableData(x);
  }


  const onExporting = (e) => {
    console.log('onExporting--- ');
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(XLSX.sheetname);

    // exportDataGrid({
    //   component: e.component,
    //   worksheet,
    //   topLeftCell: { row: 4, column: 1 },
    // }).then((cellRange) => {
    //   // header
    //   const headerRow = worksheet.getRow(1);
    //   // headerRow.height = 30;
    //   // worksheet.mergeCells(2, 1, 2, 8);
    //   headerRow.getCell(1).value = XLSX.header1;
    //   // headerRow.getCell(1).font = { name: 'TH SarabunPSK', size: 16 };
    //   headerRow.getCell(1).font = { name: 'Tahoma', size: 14 };
    //   headerRow.getCell(1).alignment = { horizontal: 'left' };

    //   if (XLSX.header2 !== '') {
    //     const headerRow2 = worksheet.getRow(2);
    //     // headerRow2.height = 30;
    //     headerRow2.getCell(1).value = XLSX.header2;
    //     // headerRow2.getCell(1).font = { name: 'TH SarabunPSK', size: 16 };
    //     headerRow2.getCell(1).font = { name: 'Tahoma', size: 14 };
    //     headerRow2.getCell(1).alignment = { horizontal: 'left' };
    //   }

    //   // footer
    //   // const footerRowIndex = cellRange.to.row + 2;
    //   // const footerRow = worksheet.getRow(footerRowIndex);
    //   // worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);
    //   // footerRow.getCell(1).value = 'www.wikipedia.org';
    //   // footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true };
    //   // footerRow.getCell(1).alignment = { horizontal: 'right' };
    // }).then(() => {
    //   workbook.xlsx.writeBuffer().then((buffer) => {
    //     saveAs(new Blob([buffer], { type: 'application/octet-stream' }), XLSX.filename + '.xlsx');
    //   });
    // });
    e.cancel = true;
  }

  const mkQuestion = (x) => {
    let r=x;
    if (x.indexOf('[[')>=0 && x.indexOf(':')>=0 && x.indexOf(']]')>=0) {
      let z = x.split(':');
      r = z[0].replace('[[','');
    }
    return r;
  }

  const onContentReady = () => {
    setTimeout(() => {
      setOpenBackdrop(false);      
    }, 1000);
  }

  const ReportTable = useMemo(() => {
    let data = [];
    let columnList = [];
    let rowList = [];
    let hasSeletedVersion = false;
    if (typeof seletedVersion !== 'undefined') {
      if (seletedVersion) {
        if (seletedVersion !== '') {
          hasSeletedVersion = true;
        }
      }
    }

    if (hasSeletedVersion === true) {
      // console.log(seletedVersion);
      tableData.forEach(i => {
        if (i.analysisVersion === seletedVersion) {
          data = i.data;
        }
      });

      if (data.length > 0) {
        // สร้าง HEADER
        columnList.push(
          <Column key={UAPI.genKey()} dataField='order_no' caption='ลำดับ' minWidth={50} />
        );
        columnList.push(
          <Column key={UAPI.genKey()} caption='ข้อมูลกลุ่มเสี่ยง'>
            <Column dataField='case_Fname' caption='ชื่อ' minWidth={100} />
            <Column dataField='case_Lname' caption='สกุล' minWidth={100} />
            <Column dataField='case_changwat' caption='จังหวัด' minWidth={100} />
            <Column dataField='case_ampur' caption='อำเภอ' minWidth={100} />
            <Column dataField='case_tambon' caption='ตำบล' minWidth={100} />
            <Column dataField='case_moo' caption='หมู่' minWidth={50} />
          </Column>
        );
        columnList.push(
          <Column key={UAPI.genKey()} caption='การบันทึกข้อมูล'>
            <Column dataField='record_date' caption='วันที่' minWidth={100} />
            <Column dataField='record_userFullName' caption='ผู้บันทึก' minWidth={100} />
          </Column>
        );
        let colNum = 0;
        data[0].questionsList.forEach(i => {
          if (typeof i.question !== 'undefined') {
            ++colNum;
            columnList.push(
              <Column
                key={UAPI.genKey()}
                dataField={colNum.toString()}
                caption={mkQuestion(i.question)}
              />
            );
          }
          else {
            if (typeof i.groupQuestionsList !== 'undefined') {
              if (i.groupQuestionsList) {
                if (i.groupQuestionsList.length > 0) {
                  let childrenColumn = [];
                  i.groupQuestionsList.forEach(q => {
                    if (q) {
                      ++colNum;
                      childrenColumn.push(
                        <Column
                          key={UAPI.genKey()}
                          dataField={colNum.toString()}
                          caption={mkQuestion(q.question)}
                        />
                      );
                    }
                  });
                  columnList.push(
                    <Column key={UAPI.genKey()} caption={i.groupDescription}>
                      {childrenColumn}
                    </Column>
                  );
                }
              }
            }
          }
        });
        // สร้าง HEADER - จบ

        // สร้าง ROW
        let keyID = 0;
        data.forEach(i1 => {
          let thisRow = {};
          // console.log(i1);
          ++keyID;
          thisRow.ID = keyID;
          thisRow['order_no']=keyID.toString();
          thisRow['case_Fname']=i1.fname;
          thisRow['case_Lname']=i1.lname;
          thisRow['case_changwat']=i1.changwatname;
          thisRow['case_ampur']=i1.ampurname;
          thisRow['case_tambon']=i1.tambonname;
          thisRow['case_moo']=parseInt(i1.moocode).toString();
          thisRow['record_date']=i1.date;
          thisRow['record_userFullName']=i1.userFullName;
          let colNum = 0;
          if (typeof i1.questionsList !== 'undefined') {
            if (i1.questionsList) {
              if (i1.questionsList.length > 0) {
                i1.questionsList.forEach(i2 => {
                  if (typeof i2.question !== 'undefined') {
                    ++colNum;
                    thisRow[colNum.toString()] = mkAnswer(i2);
                  }
                  else {
                    if (typeof i2.groupQuestionsList !== 'undefined') {
                      if (i2.groupQuestionsList) {
                        if (i2.groupQuestionsList.length > 0) {
                          i2.groupQuestionsList.forEach(q => {
                            if (q) {
                              ++colNum;
                              thisRow[colNum.toString()] = mkAnswer(q);
                            }
                          });
                        }
                      }
                    }
                  }
                });
              }
            }
          }
          rowList.push(thisRow);
        });
        // สร้าง ROW - จบ
        // console.log(rowList);
      }

      return (<></>
        // <DataGrid
        //   dataSource={rowList}
        //   keyExpr="ID"
        //   showBorders={true}
        //   onExporting={onExporting}
        //   onContentReady={onContentReady}
        //   columnMinWidth={200}
        // >
        //   <Export enabled={true} />
        //   {columnList}
        // </DataGrid>
      );
    }
    else {
      setTimeout(() => {
        setOpenBackdrop(false);        
      }, 1000);
      return (
        <div style={{ textAlign: 'center', padding: 10 }}>
          ไม่พบข้อมูล
        </div>
      );
    }
    // eslint-disable-next-line 
  },[tableData, seletedVersion]);

  useEffect(() => {
    setTableData([]);
    setSeletedVersion('');
    let hasQNCODE = false;
    let hasAreaCODE = false;
    if (Object.keys(formData).length > 0) {
      if (typeof formData.questionnaire !== 'undefined') {
        if (typeof formData.questionnaire.questionnaire_code !== 'undefined') {
          hasQNCODE = true;
        }
      }
    }
    if (Object.keys(filterData).length > 0) {
      if (typeof filterData.changwat !== 'undefined') {
        if (filterData.changwat) {
          if (typeof filterData.changwat.changwatcode !== 'undefined') {
            hasAreaCODE = true;
          }
        }
      }
    }
    if (hasQNCODE && hasAreaCODE) {
      setDisabledExecuteReportButton(false);
    }
    else {
      setDisabledExecuteReportButton(true);
    }
    // eslint-disable-next-line 
  }, [formData, filterData]); 

  useEffect(() => {
    getQuestionnaire();
  }, []); // eslint-disable-next-line 

  return (
    <div style={{ marginBottom: 100 }}>

      <Dialog onClose={(_event, reason)=>{if (reason === "backdropClick") {return;}}} className={classes.backdrop} open={openBackdrop}>
        <DialogContent>
          <CircularProgress color="inherit" />
        </DialogContent>
      </Dialog>

      {/* <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"th"}> */}

      <Grid container style={{ marginTop: -10, marginBottom: 0 }} justify="center"
      // direction={isDesktop ? "row" : "column-reverse"}
      >
        <Grid item xs={12} sm={12}>
          <h4>รายงาน : คำตอบแยกรายแบบสอบถาม</h4>
          <Autocomplete
            filterOptions={filterOptions}
            id="serviceslot"
            size="small"
            fullWidth
            options={questionnaireData}
            // value={formData.serviceSlot}
            getOptionSelected={(option, value) => value.fullname === option.fullname}
            getOptionLabel={(option) => option.fullname || ''}
            onChange={(_, newValue) => {
              ATCChange({ name: 'questionnaire', value: newValue });
            }}
            ListboxProps={{ style: { maxHeight: '200px' } }}
            renderInput={(params) =>
              <TextField {...params}
                name="questionnaire"
                label="แบบสอบถาม"
                variant="outlined"
                onChange={(e) => ATCChange(e)}
                style={{ textAlign: 'left' }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={10} style={{ padding: 0, margin: 0, marginTop: 5 }}>
          <CaseFilter setFilterData={setFilterData} />
        </Grid>
        <Grid item xs={12} sm={2} style={{ padding: 0, margin: 0, marginTop: 5 }}>
          <Button color="primary" variant="contained" fullWidth
            style={{ marginTop: 5 }}
            onClick={() => executeReport()}
            disabled={disabledExecuteReportButton}
          >
            ประมวลผล
          </Button>
        </Grid>

        <Grid item xs={12} sm={12}>
          <div style={{ fontSize: 14, marginBottom: 20, color: 'grey' }}>
            ผลคำตอบของแบบสอบถามจะถูกแบ่งตามเวอร์ชั่นที่มีปรับปรุง
          </div>
        </Grid>

        <Grid item xs={12} sm={12}>
          {tableData.length > 0 && (
            <FormControl fullWidth size="small">
              <InputLabel style={{ marginTop: -9, marginLeft: 16 }} id="version-select-label">เวอร์ชั่นแบบสอบถาม</InputLabel>
              <Select
                labelId="version-select-label"
                variant='outlined'
                label="เวอร์ชั่นแบบสอบถาม"
                value={seletedVersion}
                onChange={(e) => {
                  setSeletedVersion(e.target.value);
                }}
              >
                {tableData.map((x) => (
                  <MenuItem key={UAPI.genKey()} value={x.analysisVersion}>{x.analysisVersion}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>

      </Grid>

      {/* </MuiPickersUtilsProvider> */}

      <div style={{ padding: 5, border: 'solid 1px #B5B5B5', borderRadius: 4, marginTop: 10, background: '#FFFFFF' }}>
        {ReportTable}
      </div>

    </div>
  );
}