/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from '../const';
import { INSERT_INTERVENTION } from '../index';

const insertIntervention = {
  request: (payload: any) => action(INSERT_INTERVENTION.REQUEST, { data: payload }),
  success: (response: any) => action(INSERT_INTERVENTION.SUCCESS, { data: response }),
  failure: (error: any) => action(INSERT_INTERVENTION.FAILURE, { error }),
};

export default insertIntervention;
