/* eslint-disable @typescript-eslint/no-unused-vars */
import { DELETE_GROUP } from '../../actions';
import Actions from '../../actions/activity';
import { snackbarFailure,snackbarSuccess } from '../../actions/notification';

import * as Api from '../../services/api';
import { fetchEntity } from '../saga';
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _ from 'lodash';

const deleteAssignGroup = fetchEntity.bind(null, Actions.DeleteAssignGroup, Api.deleteData);
const deleteGroups = fetchEntity.bind(null, Actions.DeleteGroup, Api.deleteData);
const updateCases = fetchEntity.bind(null, Actions.UpdateCases, Api.updateCases);
const getGroups = fetchEntity.bind(null, Actions.GetGroupList, Api.getGroupList);
const getTeams = fetchEntity.bind(null, Actions.getGroupListTeams, Api.getTeams);

function* firstAction(action: any) {
  yield call(
    deleteAssignGroup, action.deleteAssignGroup.params,
    {
      onSuccess: (success: any) => {
        return call(actionDeleteGroups, action);
      },
      onFailure: () => put(snackbarFailure('deleteAssignGroup failed',1500))
    }
  );
}

function* actionDeleteGroups(action: any) {
  yield call(
    deleteGroups, action.deleteGroup.params,
    {
      onSuccess: (success: any) => {        
        return call(actionUpdateCases, action);
      },
      onFailure: () => put(snackbarFailure('deleteGroups failed',1500))
    }
  );
}

function* actionUpdateCases(action: any) {
  yield call(
    updateCases, action.updateCases.params,
    {
      onSuccess: (success: any) => {        
        return call(actionGetGroups, action)
      },
      onFailure: () => put(snackbarFailure('updateCases failed',1500))
    }
  );
}

function* actionGetGroups(action: any) {
  yield call(
    getGroups, { data: action.getGroups.params },
    {
      onSuccess: (data: any) => {    
        return call(actionGetTeams, data, action.deleteAssignGroup.successMessage)
      },
      onFailure: () => put(snackbarFailure('getGroups failed',1500))
    }
  );
}

function* actionGetTeams(action: any,successMessage: string) {
  if(action && action.length>0){
    const teamCode = _.uniq(action.map((data:any)=>data.teams.teamCode));
    yield call(
      getTeams, { 
        data: { 
          type: "merge",
          where : { id : { inq : teamCode } },
          groupData : action
        } 
      },
      {
        onSuccess: (success: any) => {
          return put(snackbarSuccess(successMessage,2000))
        },
        onFailure: () => put(snackbarFailure('getTeams failed',1500))
      }
    );
  }else{
    yield put(snackbarFailure('getTeams failed',1500));
  }
}

function* watchFirstRequest() {
  yield takeLatest(DELETE_GROUP.REQUEST, firstAction);
}

export default function* root() {
  yield fork(watchFirstRequest);
}
