/* eslint-disable @typescript-eslint/no-unused-vars */
import { GET_GROUPLIST } from '../../actions';
import ActionsActitity from '../../actions/activity';
import ActionsTeam from '../../actions/teams';
import { snackbarFailure,snackbarSuccess } from '../../actions/notification';

import * as api from '../../services/api';
import { fetchEntity } from '../saga';
import { all, AllEffect, call, fork, put, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';

const getGroupList = fetchEntity.bind(null, ActionsActitity.GetGroupList, api.getGroupList);
const getTeams = fetchEntity.bind(null, ActionsActitity.getGroupListTeams, api.getTeams);

function* callFirstAction(action: any) {
  yield call(
    getGroupList, action,
    {
      onSuccess: (data: any) => {
        return call(actionGetTeams, data);
      },
      onFailure: (error: any) => {
        return put(snackbarFailure('updateTeams FAILURE',4000));
      },
    }
  );
}

function* actionGetTeams(action: any) {
  if(action && action.length>0){
    const teamCode = _.uniq(action.map((data:any)=>data.teams.teamCode));
    yield call(
      getTeams, { data: { 
        type: "merge",
        where : { id : { inq : teamCode } },
        groupData : action
      } },
      {
        onSuccess: (success: any) => {},
        onFailure: () => put(snackbarFailure('getTeams failed',1500))
      }
    );
  }
  // else{
  //   yield put(snackbarFailure('getTeams failed',1500));
  // }
}

function* watchFirstRequest() {
  yield takeLatest(GET_GROUPLIST.REQUEST, callFirstAction);
}

export default function* root() {
  yield fork(watchFirstRequest);
}
