interface TeamsDetail {
  teamCode: string;
  teamName: string;
  memberTypeId: string;
  memberTypeName: string;
}

export interface TeamsMember {
  id: string;
  cid: string;
  fullname: string;
  position?: string;
  email?: string;
  password?: string;
  mobile?: string;
  role?: string;
  type?: string;
  application?: string[];
  userType?: {userTypeId:number; userTypeName:string;};
  changwat?: {changwatcode: string; changwatname: string;};
  amphur?: {amphur_id: string; amphur_name: string;};
  tambon?: {tambon_id: string; tambon_name: string;};
  department?: {hcode: string; hos_fullname: string; hos_name: string;};
  osccteams?: TeamsDetail[];
}

export interface Teams {
  id: string;
  name: string;
  changwat: {changwatcode: string; changwatname: string;};
  amphur: {amphur_id: string; amphur_name: string;};
  tambon?: {tambon_id: string; tambon_name: string;};
  members: TeamsMember[];
  generation: string;
}

export interface TeamsData {
  id: string;
  generation: string;
  fullname: string;
  role: string;
  teamCode: string;
  teamName: string;
  memberTeamCode: string;
  memberTeamName: string;
}

export interface questionaireList {
  inputName: string;
  inputType: string;
  question: string;

  answers?: string|null;
  answersType?: string;
  answersList?: {
    score: string;
    answer: string;
    checked: boolean;
  }
  ref?: string;
  defaultValue?: {
    formArray: string;
    index: number;
    key: string;
  }
}

export interface questionaireGroup {
  groupDescription: string;
  groupQuestionsList: questionaireList[];
}

interface osccpersonAssign {
  id: string;
  type: string;
  assignDatetime: string;
  groupId: string;
  teams: TeamsDetail,
  questionaire: {
    id: string;
    label: string;
    questionnaire_manage?: string[];
    questionnaire_view?: string[];
  }
}

export interface questionaireAnswer {
  id: string;
  case_id?: string|null;
  group_id: string;

  assigned_id?: string;
  osccperson_assign?: osccpersonAssign;

  questionaire_id: string;
  questionnaire: {
    id: string;
    questionnaire_code: string;
    questionnaire_group: boolean;
    questionsList: questionaireList[]|questionaireGroup[];
    questionnaire_layout?: any;
  }

  answer_datetime: string;
  answer: Record<string,string|number>|Record<string,string|number>[];
  answer_respondent: {
    userid: string;
    teamCode: string;
    fullname: string;
  }
}

interface GroupsMember {
  id: string;
  cid: string;
  fname: string;
  lname: string;
  sex: string;
  age: number;
  riskLevel: string;
  inform: boolean;
}

export interface GroupsData {
  id: string;
  groupName: string;
  teams: {
    teamCode: string;
    teamName: string;
  };
  member: GroupsMember[];
  questionnaire_answer: questionaireAnswer[];
}

export interface GenerationData {
  teamCode: string;
  teamMembers: Array<
    Pick<TeamsData, 'teamCode' | 'teamName' | 'memberTeamCode' | 'memberTeamName'> & 
    Pick<TeamsMember, 'id' | 'fullname' | 'role'> & {
      generation: string;
    }
  >;
}

export interface GenerationValue {
  id: string;
  label: string;
  value: string;
}

export const QC = ["PSu1","PCP","PARe","CRF","FF1","FF2","FF3","FF4","FF5","FF6","FF7","FF8","FSR","L_M","PCRe","PSu2"];
export const excludeUser = ["64b55443031902d3a6aef362"];
export const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
export const singleQ = ["PSu1","PSu2","PCP"];
export const groupQ = ["PARe","FF1","FF2","FF3","FF4","FF5","FF6","FF7","FF8","L_M","PCRe"];
export const groupQM = ["CRF","FSR"];
export const riskLevel = [
  {id:1, label:'ต่ำ',value:'low'},
  {id:2, label:'ปานกลาง',value:'medium'},
  {id:3, label:'สูง',value:'high'},
  {id:4, label:'เหยื่อ',value:'victim'},
  {id:5, label:'ปกติ',value:'normal'},
  {id:6, label:'อื่นๆ',value:'other'}
];
export const excelColumn = [
  "ลำดับ","FA คนที่ 1","FA คนที่ 2","พื้นที่","กลุ่ม","ครอบครัว",
  "PSu1","PCP","PARe","CRF","FF1","FF2","FF3","FF4","FF5","FF6","FF7","FF8","FSR","L&M","PCRe","PSu2",
  "รวม","หมายเหตุ","วันที่สรุป"
];