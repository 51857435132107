/* eslint-disable no-unused-vars */
import { getCurrentUser } from "./auth.service";

const getPermission = (page) => {
  const userinfo = getCurrentUser();
  
  let changwatcode = '';
  let changwatname = '';
  let amphurcode = '';
  let amphurname = '';
  let hospcode = '';
  let hosname = '';
  let role = 'noRole';

  if(userinfo!==null){
    changwatcode = userinfo.user.changwat.changwatcode;
    changwatname = userinfo.user.changwat.changwatname;
    amphurcode = userinfo.user.amphur.amphur_id;
    amphurname = userinfo.user.amphur.amphur_name;
    hospcode = userinfo.user.department.hcode ;
    hosname = userinfo.user.department.hos_name;
    role = userinfo.user.role;
  }

  const permission = {
    "dashboard": {
      "role": "", // สามารถดูข้อมูลได้ในระดับ role นี้
      "changwat": changwatname, // สามารถดูข้อมูลได้ในระดับจังหวัดนี้
      "amphur_id": amphurcode, 
      "amphur": amphurname, // สามารถดูข้อมูลได้ในระดับอำเภอนี้
      "hospcode": hospcode, // สามารถดูข้อมูลได้ในระดับหน่วยงานนี้
      "hosname": hosname,
      "casestatusRole":{
        "R8" : {
          new: { show:true, title:"pages.dashboard.caseStatus.new", hotLink:"newCase", count:0}, // รายใหม่
          renew: { show:false, title:"pages.dashboard.caseStatus.renew", hotLink:"newCase", count:0 }, // รายใหม่
          assigned: { show:true, title:"pages.dashboard.caseStatus.assignedByCM", hotLink:"newCase", count:0}, // รายใหม่ // ใช้สำหรับ get ข้อมูลมาไว้นับรวม แต่ไม่แสดงผลกล่อง
          inProgress: { show:true, title:"pages.dashboard.caseStatus.inProgress", hotLink:"onWork", count:0}, // มอบหมายแล้ว // assignedTeam + assignedOfficer + recievedCase
          waitConclusion: { show:true, title:"pages.dashboard.caseStatus.waitConclusion", hotLink:"wait", count:0}, // รอสรุป
          finish: { show:true, title:"pages.dashboard.caseStatus.finish", hotLink:"completed", count:0}, // เสร็จสิ้น
        },
        "OG" : {
          new: { show:true, title:"pages.dashboard.caseStatus.new", hotLink:"newCase", count:0}, // รายใหม่
          renew: { show:false, title:"pages.dashboard.caseStatus.renew", hotLink:"newCase", count:0 }, // รายใหม่
          assigned: { show:true, title:"pages.dashboard.caseStatus.assignedByCM", hotLink:"newCase", count:0}, // รายใหม่ // ใช้สำหรับ get ข้อมูลมาไว้นับรวม แต่ไม่แสดงผลกล่อง
          inProgress: { show:true, title:"pages.dashboard.caseStatus.inProgress", hotLink:"onWork", count:0}, // มอบหมายแล้ว // assignedTeam + assignedOfficer + recievedCase
          waitConclusion: { show:true, title:"pages.dashboard.caseStatus.waitConclusion", hotLink:"wait", count:0}, // รอสรุป
          finish: { show:true, title:"pages.dashboard.caseStatus.finish", hotLink:"completed", count:0}, // เสร็จสิ้น
        },
        "Coach" : {
          new: { show:true, title:"pages.dashboard.caseStatus.new", hotLink:"newCase", count:0}, // รายใหม่
          renew: { show:false, title:"pages.dashboard.caseStatus.renew", hotLink:"newCase", count:0 }, // รายใหม่
          assigned: { show:true, title:"pages.dashboard.caseStatus.assignedByCM", hotLink:"newCase", count:0}, // รายใหม่ // ใช้สำหรับ get ข้อมูลมาไว้นับรวม แต่ไม่แสดงผลกล่อง
          inProgress: { show:true, title:"pages.dashboard.caseStatus.inProgress", hotLink:"onWork", count:0}, // มอบหมายแล้ว // assignedTeam + assignedOfficer + recievedCase
          waitConclusion: { show:true, title:"pages.dashboard.caseStatus.waitConclusion", hotLink:"wait", count:0}, // รอสรุป
          finish: { show:true, title:"pages.dashboard.caseStatus.finish", hotLink:"completed", count:0}, // เสร็จสิ้น
        },
        "Assessor" : {
          new: { show:true, title:"pages.dashboard.caseStatus.new", hotLink:"newCase", count:0}, // รายใหม่
          renew: { show:false, title:"pages.dashboard.caseStatus.renew", hotLink:"newCase", count:0 }, // รายใหม่
          assigned: { show:true, title:"pages.dashboard.caseStatus.assignedByCM", hotLink:"newCase", count:0}, // รายใหม่ // ใช้สำหรับ get ข้อมูลมาไว้นับรวม แต่ไม่แสดงผลกล่อง
          inProgress: { show:true, title:"pages.dashboard.caseStatus.inProgress", hotLink:"onWork", count:0}, // มอบหมายแล้ว // assignedTeam + assignedOfficer + recievedCase
          waitConclusion: { show:true, title:"pages.dashboard.caseStatus.waitConclusion", hotLink:"wait", count:0}, // รอสรุป
          finish: { show:true, title:"pages.dashboard.caseStatus.finish", hotLink:"completed", count:0}, // เสร็จสิ้น
        },
        "CM" : {
          new: { show:true, title:"pages.dashboard.caseStatus.new", hotLink:"newCase", count:0}, // รายใหม่
          renew: { show:false, title:"pages.dashboard.caseStatus.renew", hotLink:"newCase", count:0 }, // รายใหม่
          assigned: { show:true, title:"pages.dashboard.caseStatus.assignedByCM", hotLink:"newCase", count:0}, // รายใหม่ // ใช้สำหรับ get ข้อมูลมาไว้นับรวม แต่ไม่แสดงผลกล่อง
          inProgress: { show:true, title:"pages.dashboard.caseStatus.inProgress", hotLink:"onWork", count:0}, // มอบหมายแล้ว // assignedTeam + assignedOfficer + recievedCase
          waitConclusion: { show:true, title:"pages.dashboard.caseStatus.waitConclusion", hotLink:"wait", count:0}, // รอสรุป
          finish: { show:true, title:"pages.dashboard.caseStatus.finish", hotLink:"completed", count:0}, // เสร็จสิ้น
        },
        "ATM" : {
          new: { show:true, title:"pages.dashboard.caseStatus.new", hotLink:"newCase", count:0}, // รายใหม่
          assigned: { show:true, title:"pages.dashboard.caseStatus.assignedToTeams", hotLink:"newCase", count:0}, // รายใหม่
          inProgress: { show:true, title:"pages.dashboard.caseStatus.inProgress", hotLink:"onWork", count:0}, // ระหว่างดูแล
          waitConclusion: { show:true, title:"pages.dashboard.caseStatus.waitConclusion", hotLink:"wait", count:0}, // รอสรุป
          finish: { show:true, title:"pages.dashboard.caseStatus.finish", hotLink:"completed", count:0}, // เสร็จสิ้น
        },
        "TM" : {
          assigned: { show:true, title:"pages.dashboard.caseStatus.assignedToTeams", hotLink:"newCase", count:0}, // รายใหม่
          inProgress: { show:true, title:"pages.dashboard.caseStatus.inProgress", hotLink:"onWork", count:0}, // ระหว่างดูแล
          waitConclusion: { show:true, title:"pages.dashboard.caseStatus.waitConclusion", hotLink:"wait", count:0}, // รอสรุป
          finish: { show:true, title:"pages.dashboard.caseStatus.finish", hotLink:"completed", count:0}, // เสร็จสิ้น
        }
      } // role ที่สามารถดูข้อมูลกล่อง caseStatus ได้
    },
    "teams": {
      "role": "", // สามารถดูข้อมูลได้ในระดับ role นี้
      "changwat": changwatname, // สามารถดูข้อมูลได้ในระดับจังหวัดนี้
      "amphur_id": amphurcode, 
      "amphur": amphurname, // สามารถดูข้อมูลได้ในระดับอำเภอนี้
      "hcode": hospcode // สามารถดูข้อมูลได้ในระดับหน่วยงานนี้
    },
    "questionnaire": {
      "role": "", // สามารถดูข้อมูลได้ในระดับ role นี้
      "changwat": changwatname, // สามารถดูข้อมูลได้ในระดับจังหวัดนี้
      "amphur_id": amphurcode, 
      "amphur": amphurname, // สามารถดูข้อมูลได้ในระดับอำเภอนี้
      "hcode": hospcode, // สามารถดูข้อมูลได้ในระดับหน่วยงานนี้
      "action": { // ทดสอบสร้างสิทธิ์เฉพาะเรื่องในหน้านั้นๆ
        "add": false,
        "edit": false,
        "delete": false
      }
    },
    "userlist": {
      "role": "", // สามารถดูข้อมูลได้ในระดับ role นี้
      "changwat": changwatname, // สามารถดูข้อมูลได้ในระดับจังหวัดนี้
      "amphur_id": amphurcode, 
      "amphur": amphurname, // สามารถดูข้อมูลได้ในระดับอำเภอนี้
      "hcode": hospcode // สามารถดูข้อมูลได้ในระดับหน่วยงานนี้
    }
  }

  switch (role) {
    case "AdminR8":
      permission.dashboard.role = "R8";
      permission.userlist.role = "R8";
      break;
    case "AdminChangwat":
      permission.dashboard.role = "CW";
      permission.userlist.role = "CW";
      break;
    case "AdminHospital":
      permission.dashboard.role = "HOS";
      permission.userlist.role = "HOS";
      break;
    case "Organization":
      permission.dashboard.role = "OG";
      break;
    case "Coach":
      permission.dashboard.role = "Coach";
      permission.userlist.role = "Coach";
      break;
    case "Assessor":
      permission.dashboard.role = "Assessor";
      permission.userlist.role = "Assessor";
      break;
    case "CM":
      permission.dashboard.role = "CM";
      permission.userlist.role = "CM";
      break;
    case "AdminTeams":
      permission.dashboard.role = "ATM";
      permission.userlist.role = "ATM";
      break;
    case "Teams":
      permission.dashboard.role = "TM";
      permission.userlist.role = "TM";
      break;
    default:
  }

  return permission[page];
}
export default getPermission;