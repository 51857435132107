import React from 'react';
import { Bar } from 'react-chartjs-2';

const data = {
  labels: ['เลย', 'หนองบัวลำภู', 'อุดรธานี', 'หนองคาย', 'บึงกาฬ', 'สกลนคร', 'นครพนม'],
  datasets: [
    {
      label: "เสี่ยงต่ำ",
      data: [1, 3, 1, 3, 1, 3, 1],
      borderColor: "#0077b6",
      backgroundColor: '#0077b6'
    },
    {
      label: "เสี่ยงปานกลาง",
      data: [2,2,2,2,2,2,2],
      borderColor: "#fb8500",
      backgroundColor: '#fb8500'
    },
    {
      label: "เสี่ยงสูง",
      data: [3,1,3,1,3,1,3],
      borderColor: "#ccc",
      backgroundColor: '#ccc'
    }
  ],
};

const options = {
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false
      },
    },
    y: {
      beginAtZero: true,
      max:3.5,
      grid: {
        display: true,
        drawBorder: false
      },
      ticks: {
        padding:0,
        labelOffset: 0,
        // callback(value, index) {
        //    if (index % 2 === 0) return '';
        //    return value;
        // },
      },
    },
  },
  plugins:{
    title: {
      display: true,
      text: 'ระดับความเสี่ยง',
      font: { size: 20 },
      padding: { bottom: 20 }
    },
    datalabels: {
      display: false,
    },
    legend: {
      display: true,
      labels: {
        padding: 10
      }
    }
  },
  aspectRatio: function (context) {
    var width = context.chart.width;
    return (width < 400) ? 3 / 4 : 4 / 3;
  },
};

export default function GroupedBar(){
  return (<Bar data={data} options={options} />)
};
