/* eslint-disable no-unused-vars */
import * as UAPI from "./UniversalAPI";

const saveInterventions = async (personData, planservice_data,param_answerData,param_userId,param_userFullName, param_hcode, param_hname) => {
  // เก็บ planServiceId ไว้ใน intervention.acivities.homeservice[x].planServiceId จะได้รู้ว่า แบบสอบถามนี้มากจาก planservice ไหน
  let curDate=currentDate();
  let curTime=currentTime();
  let answerData = param_answerData;
  answerData["date"]=curDate;
  answerData["time"]=curTime;
  answerData["userId"]=param_userId;
  answerData["userFullName"]=param_userFullName;

  let thisInterventionId=null;
  let todayInterventionId=null;
  // console.log(planservice_data);
  // console.log(answerData);
  planservice_data.forEach(i => {
    // console.log(i.id, answerData.planServiceId);
    if (i.id === answerData.planServiceId) {
      // console.log('A');
      i.serviceslotList.forEach(ev => {
        if (typeof ev.interventionId !== 'undefined') {
          if (ev.interventionId !== null && ev.interventionId !== '') {
            thisInterventionId=ev.interventionId;
          }
        }
      });
    }else {
      // console.log('B');
      i.serviceslotList.forEach(ev => {
        // console.log('B ev.interventionId- ',ev.interventionId);
        if (typeof ev.interventionId !== 'undefined') {
          if (typeof ev.interventionDate !== 'undefined') {
            if (ev.interventionId !== null && ev.interventionId !== '' && ev.interventionDate !== null && ev.interventionDate !== '') {
              if (ev.interventionDate===currentDate()) {
                todayInterventionId=ev.interventionId;
              }
            }
          }
        }
      });
    }
  });

  // console.log(thisInterventionId, todayInterventionId);

  if (thisInterventionId !== null) {
    // -- ♪♫♪♫ 1. แก้ไข intervention เก่า
    // console.log('intervention เก่า');
    let responseGet = await UAPI.get(thisInterventionId, 'interventions', '3009');
    if (responseGet.status === 200) { if (responseGet.data) {
      let modifiedData = JSON.parse(JSON.stringify(responseGet.data));
      modifiedData.activities.homeservice.forEach((i, indx) => {
        if (i.serviceType==='oscc') {
          let QN=[];
          i.questionnaire.forEach((q,index) => {
            // if (typeof q.id !== 'undefined') {
            //   if (q.id) {
            //     if (q.id!==answerData.id) {
            //       QN.push(q);
            //     }
            //   }
            // }
            if (typeof q.sequenceId !== 'undefined') {
              if (q.sequenceId) {
                // เก็บคำตอบที่ไม่ถูกแก้ในครั้งนี้เข้าไปก่อน
                if (q.sequenceId!==answerData.sequenceId) {
                  QN.push(q);
                }
              }
            }
          });
          // แล้วเก็บคำตอบที่ถูกแก้ในครั้งนี้ตามเข้าไป
          QN.push(answerData);
          i['questionnaire']=QN;
        }
      });
      // console.log(modifiedData);
      // แทนที่ answer ด้วย answer ใหม่
      console.log("Update Intervention", thisInterventionId, modifiedData);
      let responseUpdate = await UAPI.update(thisInterventionId, modifiedData, 'interventions', '3009');
      console.log("Update interventions response",responseUpdate);
      if (responseUpdate.status === 200) { if (responseUpdate.data) {
        let rr = responseUpdate.data;
        let curPS=null;
        planservice_data.forEach(i => {
          if (answerData.planServiceId === i.id) {
            curPS=i;
          }
        });
        curPS['personId']=rr.personId;
        curPS.serviceslotList.forEach(i => {
          // console.log(i.interventionData);
          if (typeof i.interventionData !== 'undefined') {
            delete i['interventionData'];
          }
          if (typeof i.questionnaire !== 'undefined') {
            delete i['questionnaire'];
          }
          if (typeof i.sequenceId !=='undefined') {
            if (i.sequenceId===answerData.sequenceId) {
              i['interventionId']=rr.id;
              i['interventionDate']=curDate;
            }
          }
          // else {
          //   if (i.questionnaireId===answerData.id) {
          //     i['interventionId']=rr.id;
          //     i['interventionDate']=curDate;
          //   }
          // }
        });
        // console.log(curPS);
        console.log("Update planservices", curPS.id, curPS);
        let responsePS = await UAPI.update(curPS.id, curPS, 'planservices', '3009');
        console.log("Update planservices response",responseUpdate);
        if (responsePS.status === 200) { if (responsePS.data) {
          // console.log(responsePS.data);
          // getPersonData(props.location.state.cid);
          // window.location.reload();
        }}
      }}
    }}
  }else if (todayInterventionId !== null) {
    // -- ♪♫♪♫ 2. วันนี้มีแบบสอบถามอื่นที่ทำไปแล้ว ให้เพิ่มข้อมูลเข้า intervention เดียวกันนั้นแหละ
    // console.log('intervention วันนี้');
    // let responseGet = await UAPI.get(todayInterventionId, 'interventions', '3009');
    // if (responseGet.status === 200) { if (responseGet.data) {
    //   let modifiedData = JSON.parse(JSON.stringify(responseGet.data));
    //   modifiedData.activities.homeservice.forEach((i, indx) => {
    //     if (i.serviceType==='oscc') {
    //       if (typeof i.questionnaire !== 'undefined') {
    //         i.questionnaire.push(answerData);
    //       }
    //       else {
    //         let a = [];
    //         a.push(answerData);
    //         i['questionnaire']=a;
    //       }
    //     }
    //   });
    //   // เพิ่ม answer ใหม่
    //   let responseUpdate = await UAPI.update(todayInterventionId, modifiedData, 'interventions', '3009');
    //   if (responseUpdate.status === 200) { if (responseUpdate.data) {
    //     // console.log(responseUpdate.data);
    //     let rr = responseUpdate.data;
    //     let curPS=null;
    //     planservice_data.forEach(i => {
    //       if (answerData.planServiceId === i.id) {
    //         curPS=i;
    //       }
    //     });
    //     curPS.serviceslotList.forEach(i => {
    //       if (i.questionnaireId===answerData.id) {
    //         i['interventionId']=rr.id;
    //         i['interventionDate']=curDate;
    //       }
    //     });
    //     console.log(curPS);
    //     let responsePS = await UAPI.update(curPS.id, curPS, 'planservices', '3009');
    //     if (responsePS.status === 200) { if (responsePS.data) {
    //       // console.log(responsePS.data);
    //       getPersonData(props.location.state.cid);
    //     }}
    //   }}
    // }}
  }else {
    // -- ♪♫♪♫ 3. แบบสอบถามแรกของวันนี้ เพิ่ม intervention ใหม่เลย
    // console.log('intervention ใหม่');
    let visit_data = {
      "hcode": param_hcode,
      "personId": personData.id,
      "vn": calVN(),
      "ovstist": "oscc",
      "ovstist_name": "เยี่ยมบ้าน OSCC",
      "ovstost": null,
      "ovstost_name": null,
      "doctor": null,
      "doctor_name": null,
      "pttype": personData.pttype,
      "pttype_name": personData.pttype_name,
      "pttype_no": null,
      "hospmain": null,
      "hospmain_name": null,
      "hospsub": null,
      "hospsub_name": null,
      "spclty": null,
      "spclty_name": null,
      "vstdate": curDate,
      "vsttime": curTime,
      "cid": personData.cid,
      "hname": param_hname,
      "program_provider": "oscc",
      "version": null,
      "activities": {
        // นอกจาก OSCC แล้วจะมี homeservice ตัวอื่นอีกไหม // ใส่ serviceType ไว้ก่อนละกันนะ จะได้ง่ายกับ frontend อื่นๆ ที่จะดึง DB เดียวกันนี้
        "homeservice": [
          {
            "serviceType":"oscc",
            "questionnaire": [
              answerData
            ]
          }
        ],
      }
    };
    // console.log(props.location.state.cid);
    // console.log(visit_data);
    // console.log(JSON.stringify(visit_data));
    console.log("Create Intervention", visit_data);
    let responseInsert = await UAPI.create(visit_data , 'interventions', '3009');
    console.log("Create interventions response",responseInsert);
    if (responseInsert.status === 200) { 
      if (responseInsert.data) {
      // console.log(responseInsert.data);
      let rr = responseInsert.data;
      let curPS=null;
      // console.log(planservice_data);
      // console.log(answerData.planServiceId);
      planservice_data.forEach(i => {
        if (answerData.planServiceId === i.id) {
          curPS=i;
        }
      });
      curPS['personId']=rr.personId;
      curPS.serviceslotList.forEach(i => {
        // console.log(i.interventionData);
        if (typeof i.interventionData !== 'undefined') {
          delete i['interventionData'];
        }
        if (typeof i.questionnaire !== 'undefined') {
          delete i['questionnaire'];
        }
        if (typeof i.sequenceId !=='undefined') {
          if (i.sequenceId===answerData.sequenceId) {
            i['interventionId']=rr.id;
            i['interventionDate']=curDate;
          }
        }
        // แก้ไขแบบลูกทุ่ง โดย เอกภาพ 
        i['interventionId']=rr.id;
        i['interventionDate']=curDate;
        // else {
        //   if (i.questionnaireId===answerData.id) {
        //     i['interventionId']=rr.id;
        //     i['interventionDate']=curDate;
        //   }
        // }
        console.log("answerData",answerData);
        console.log("serviceslotList intervention",i);
      });
      // console.log(answerData);
      // console.log(curPS);
      console.log("Update planservices", curPS.id, curPS);
      let responsePS = await UAPI.update(curPS.id, curPS, 'planservices', '3009');
      console.log("Update planservices response",responsePS);
      if (responsePS.status === 200) { if (responsePS.data) {
        // console.log(responsePS.data);
        // window.location.reload();
      }}
    }}
  }
}


const currentDate = () => {
  let d = new Date();
  let dY = d.getFullYear().toString();
  let dM = addZero(d.getMonth()+1,2);
  let dD = addZero(d.getDate(),2);
  return dY+'-'+dM+'-'+dD;
}

const currentTime = () => {
  let d = new Date();
  let dHH = addZero(d.getHours(),2);
  let dMM = addZero(d.getMinutes(),2);
  let dSS = addZero(d.getSeconds(),2);
  return dHH+':'+dMM+':'+dSS;
}

const calVN = () => {
  let d = new Date();
  let dY = (d.getFullYear()+543).toString().substr(-2);
  let dM = addZero(d.getMonth()+1,2);
  let dD = addZero(d.getDate(),2);
  let dHH = addZero(d.getHours(),2);
  let dMM = addZero(d.getMinutes(),2);
  let dSS = addZero(d.getSeconds(),2);
  return dY+dM+dD+dHH+dMM+dSS;
}

const addZero = (n,z) => {
  let r=n.toString();
  for (var i=(parseInt(z)-n.toString().length);i>0;i--) {
    r='0'+r;
  }
  return r;
}

export {
  saveInterventions
};
