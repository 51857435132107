import { SET_FILLTER } from "../../actions";
import { AnyAction } from "redux";
import { Filtered } from "../../types/filter";

const initialState:Filtered = {
  data: {
    caseStatus: [],
    risklevel: [],
    severityType: [],
    sex: [],
    age: [1,20],
    changwat: null,
    ampur: null,
    tambon: null,
    village: null,
    agency: null
  }
};

export default function insertAssign(
  state = initialState,
  action: AnyAction
):Filtered {
  switch (action.type) {
    case SET_FILLTER.SET  :{
      return {...state,data:action.data};
    }
    default:
      return state;
  }
}
