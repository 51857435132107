/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React,{useEffect,useState,useCallback, useMemo} from 'react';
import { useSelector } from 'react-redux';
import { RootState } from "../../reducers";
import { getTeams, getCoachInfo, getCoachTeams, getGroupMembers, getQuestionaireAnswer } from '../../services/api';
import { Button, Grid, makeStyles,TextField,Typography } from "@material-ui/core";
import {  Link, useHistory } from 'react-router-dom';
import _ from "lodash";
import { Autocomplete } from '@material-ui/lab';
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
import ExcelExport from './excel';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { AxiosResponse } from 'axios';
import structuredClone from '@ungap/structured-clone';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import "moment/dist/locale/th";
import { thaiYear } from '../../reducers/selectors/thaiYear';

const useStyles = makeStyles({
  root: {
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: '#494949',
      color: 'white',
    },
    '& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      fontSize: '0.9em'
    },
    '& .MuiDataGrid-root .MuiDataGrid-row': {
      fontSize: '0.9em'
    }
  }
});
interface IRes {
  success: boolean;
  response: AxiosResponse<any>;
  error: null;
}
const Report1=()=>{
  const classes = useStyles();
  const history = useHistory();
  const globalState = useSelector((state: RootState) => state);
  const token: string = globalState.auth.user.response?.id||"";
  const [loading,setLoading] = useState(false);
  const QC = useMemo(()=>["PSu1","PCP","PARe","CRF","FF1","FF2","FF3","FF4","FF5","FF6","FF7","FF8","FSR","L_M","PCRe","PSu2"],[]);

  const [optionsValue, setOptionsValue] = useState<Record<string,any>>({
    id:"",
    label:"",
    generation: "",
    value:[]
  });
  const [optionsGeneration, setOptionsGeneration] = useState<Array<Record<string,any>>>([]);

  const getCoachData = useCallback(async()=>{
    try {
      const response_coachinfo = await getCoachInfo(token); // get ข้อมูลโคช
      // console.log(response_coachinfo);
      if(response_coachinfo.success){
        const generation: any = [];
        const coachinfo = response_coachinfo.response.data;
        coachinfo.forEach((c: any)=> {
          c.oscc_generation.forEach((g: any)=>{
            generation.push({userid: c.id, gen_id:g.generation}); // เอาข้อมูล userid และรุ่นที่ จากข้อมูลโคช
          });
        });
        const groupGeneration = _.groupBy(generation,"gen_id"); // groupby รุ่นที่
        const options = Object.keys(groupGeneration).map((o:any,i:number)=>{ // เอาข้อมูลรุ่นที่มาวนลูปทำเป็น options ตัวเลือกรุ่นที่
          return {
            id: i,
            label: "รุ่นที่ "+o,
            generation: o,
            value: groupGeneration[o].map((u:any)=>u.userid) // userid ของ coach ในรุ่นนั้นๆ
          }
        })
        setOptionsValue(options[0]);
        setOptionsGeneration(options);
      }
    } catch (error: any) {
      console.log(error);
    }
  },[token]);

  const [teamMembers, setTeamMembers] = useState<Record<string,any>>({});
  const getTeamsData = useCallback(async()=>{
    console.log("getTeamsData");
    try {
      const params = {data : {where : {version: "PLH2"}}}
      const response_teaminfo = await getTeams(token, params) as unknown as IRes;
      console.log("response_teaminfo",response_teaminfo);
      if(response_teaminfo.success){
        
        const teaminfo = response_teaminfo.response.data;
        // console.log(teaminfo);
        let newTeams: any = [];
        await teaminfo.forEach((c: any)=> {
          let members: any = [];
          c.members.forEach((m: any)=> {
            // console.log(m);
            if(m){
              if(["CM","AdminTeams"].includes(m.role||m.type) && m.id!=="64b55443031902d3a6aef362"){
                members.push({
                  id: m.id,
                  generation: c.generation,
                  role:m.role||"",
                  type:m.type||"",
                  // replace นางสาว ด้วย น.ส.
                  fullname: m.fullname.replace("นางสาว","น.ส."),
                  teamCode: m.osccteams.map((m:any)=>m.teamCode)[0],
                  teams: m.osccteams.map((m:any)=>m.teamName)[0]
                });
              }
            }
          });
          newTeams.push({
            id: c.id,
            name: c.name,
            member : members
          })
        });
        // console.log("newTeams",newTeams);

        const groupGeneration = _.groupBy(teaminfo,"generation");
        // console.log("groupGeneration",groupGeneration);
        const options = Object.keys(groupGeneration).map((o:string,i:number)=>{ // เอาข้อมูลรุ่นที่มาวนลูปทำเป็น options ตัวเลือกรุ่นที่
          return {
            id: i,
            label: "รุ่นที่ "+o,
            generation: o,
            value: groupGeneration[o].map((u:any)=>u.userid) // userid ของ coach ในรุ่นนั้นๆ
          }
        })
        setTeamMembers(newTeams);
        setOptionsValue(options[3]);
        console.log("options",options);
        setOptionsGeneration(options);
      }
    } catch (error: any) {
      console.log(error);
    }
  },[]);

  const [reportDaTa, setReportDaTa] = useState<Array<Record<string,any>>>([]);
  const [answerDaTa, setAnswerDaTa] = useState<Array<Record<string,any>>>([]);
  const generationHandleChange= useCallback(async (data:any)=>{
    setOptionsValue(data);
    // const coachGroup = data.value;
    // console.log(data);
    // if(teamMembers.length>0){
      // console.log(teamMembers);
    // }
    // const teamData = await getCoachTeams(token,coachGroup); // หาข้อมูลทีมของโคช
    // if(teamData.success){
      // หารายชื่อ FA ทั้งหมดของรุ่น
      // console.log(teamData.response?.data);
      // const teamMember: any = [];
      
      // console.log("teamMembers",teamMembers);
      if(teamMembers.length>0){

        const teamMember: any = [];
        teamMembers.forEach((t:any)=>{t.member.forEach((m:any)=>{teamMember.push(m)})});
        // console.log("teamMember",teamMember);
        const teamMemberGroupbyArea = _.groupBy(teamMember,"teams");
        // console.log("teamMemberGroupbyArea",teamMemberGroupbyArea);
        const dataTable: any = [];
        const newDataTable: any = [];
        let id = 1;
        Object.keys(teamMemberGroupbyArea).forEach((a:string,i:number)=>{
          // console.log(teamMemberGroupbyArea[a]);
          if(teamMemberGroupbyArea[a].length>0 && teamMemberGroupbyArea[a][0].generation===data.generation){
            const rowData:any = {
              id: id,
              gen: data.generation,
              teamCode: "",
              area: a.replace("ทีมพื้นที่ ",""),
              familyCount:0,
              total:0
            };
            teamMemberGroupbyArea[a].forEach((n:any,i:number)=>{
              if(rowData.teamCode===""){rowData.teamCode=n.teamCode}
              rowData["member"+(i+1)]=n.fullname;
            });
            QC.forEach((q:any)=>rowData[q]=0);
            // console.log("rowData",rowData);
            dataTable.push(rowData);
            id++;
          }
        });
        
        // หาจำนวนครอบครัว ของรุ่น รายทีม
        const teamCodes = dataTable.map((t:any)=>t.teamCode);
        const groupMembersResponse = await getGroupMembers(token,teamCodes);
        console.log("groupMembersResponse",groupMembersResponse);
        if(groupMembersResponse.success){
          const members = groupMembersResponse.response.data;
          console.log("members",members);
          console.log("dataTable",dataTable);
          dataTable.forEach((t:any,index:number)=>{
            // if(["660ad5321c78000081004c8c","6500b2225a260000130028bf"].includes(t.teamCode)){
              const cloneT = _.cloneDeep(t);
              const countGroup = members.filter((g:any)=>{
                if(g.teams.teamCode===cloneT.teamCode){
                  cloneT.familyCount = g.member.length;
                  cloneT.groupId = g.id;
                  cloneT.groupName = g.groupName;
                  newDataTable.push(cloneT);
                }
                return (g.teams.teamCode===cloneT.teamCode)
              }).length;
              dataTable.splice(index,1);
              // console.log(countGroup);
              members.forEach((m:any) => {
                if(countGroup>1){
                  const cloneTT = _.cloneDeep(cloneT);
                  // console.log(cloneTT);
                  if(cloneTT.teamCode===m.teams.teamCode){
                    cloneTT.familyCount = m.member.length;
                    cloneTT.groupId = m.id;
                    cloneTT.groupName = m.groupName;
                    newDataTable.push(cloneTT);
                  }
                }else{
                  if(cloneT.teamCode===m.teams.teamCode){
                    cloneT.familyCount = m.member.length;
                    cloneT.groupId = m.id;
                    cloneT.groupName = m.groupName;
                    newDataTable.push(cloneT);
                  }
                }
              });             
            // }
          });
          newDataTable.forEach((d:any,i:number)=>d.id=i+1);
        }
        console.log("newDataTable",newDataTable);
        // หาข้อมูลคำตอบทั้งหมด ของรุ่น รายทีม
        // console.log("teamCodes",teamCodes);
        const answerResponse = await getQuestionaireAnswer(token,teamCodes);
        let questionaireAnswer:any=[];
        if(answerResponse.success){
          questionaireAnswer = answerResponse.response.data;
          
          // console.log("questionaireAnswer",questionaireAnswer);
          newDataTable.forEach((row:any)=>{ // วนแถวข้อมูลทีม
            questionaireAnswer.forEach( (q:any) => { // วนข้อมูลคำตอบแบบสอบถาม
              // console.log("teamCode",q.teams.teamCode,row.teamCode);
              // console.log("groupId",q.id,row.groupId);
              if(q.teams.teamCode===row.teamCode && q.id===row.groupId){ // ถ้ารหัสทีมของคำตอบ ตรงกับ รหัสทีมของข้อมูลทีม
                if(q.questionnaire_answer.length>0){
                  q.questionnaire_answer.forEach((a:any)=>{
                    // ถ้าคำตอบเป็น Array แสดงว่าเป็นแบบสอบถาม CRF ให้นับจำนวน Array เป็นคะแนน นอกนั้นแบบสอบถามละ 1 คะแนน
                    row[a.questionnaire.questionnaire_code] += (_.isArray(a.answer)) ? a.answer.length : 1;
                  });
                }
              }
            });
          })
        }
        newDataTable.forEach((d:any)=>{
          let total=0;
          Object.keys(d).forEach((k:any)=>{
            if(QC.includes(k)) { total += d[k] }
          });
          d.total=total;
        });
        // console.log("dataTable",dataTable);
        setAnswerDaTa(questionaireAnswer);
        setReportDaTa(newDataTable);
        setLoading(false);
      }
      setLoading(false);
    // }
  },[QC, token, teamMembers]);

  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const exportToExcel = useCallback((title:string, data:any, columnTH:any) => {   
    const fileName = "ANSWER_"+title+"_"+(moment().format("YYYYMMDDHHmmss"));
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.sheet_add_aoa(ws, [columnTH], { origin: "A1" });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blobData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(blobData, fileName + ".xlsx");
  },[]);

  const genExcelColumn=(QN:any)=>{
    let excelCols: Array<string> = ["ชื่อ-สกุล","เลขบัตร","เพศ","อายุ","ความเสี่ยง","วิทยากร (FA)"];
    (QN?QN:[]).forEach((q:any)=>{
      if(typeof q.groupDescription === "undefined" && typeof q.groupQuestionsList === "undefined"){ // คำถามปกติไม่ใช่แบบ group
        excelCols.push(q.question.replace("<br>",""));
      }else{
        if(_.isArray(q.groupQuestionsList)){
          q.groupQuestionsList.forEach((g:any)=>{
            excelCols.push(g.question.replace("<br>",""));
          });
        }
      }
    });
    // console.log(excelCols);
    return excelCols;
  }
  const genExcelColumn2=(QN:any,prefixColumn=false)=>{
    let excelCols: Array<string> = (!prefixColumn)?[]: ["ชื่อ-สกุล","เลขบัตร","เพศ","อายุ","ความเสี่ยง","วิทยากร (FA)"];
    (QN?QN:[]).forEach((q:any)=>{
      if(typeof q.groupDescription === "undefined" && typeof q.groupQuestionsList === "undefined"){ // คำถามปกติไม่ใช่แบบ group
        excelCols.push(q.question.replace("<br>",""));
      }else{
        if(_.isArray(q.groupQuestionsList)){
          q.groupQuestionsList.forEach((g:any)=>{
            excelCols.push(g.question.replace("<br>",""));
          });
        }
      }
    });
    // console.log(excelCols);
    return excelCols;
  }
  const combindAnswer = (QN:any,ANSWER:any)=>{
    // console.log("QN",QN);
    // console.log("ANSWER",ANSWER);
    if(!_.isArray(ANSWER)){
      let newAnswer: Record<string,any> = {};
      (QN?QN:[]).forEach((q:any)=>{
        // console.log("QN,ANSWER",QN,ANSWER);
        if(typeof q.groupDescription === "undefined" && typeof q.groupQuestionsList === "undefined"){ // คำถามปกติไม่ใช่แบบ group
          // console.log("question",q.inputName,ANSWER[q.inputName]);
          if(ANSWER[q.inputName]!==null){
            newAnswer = Object.assign({...newAnswer,[q.inputName] : ANSWER[q.inputName]||""});
          }
        }else{
          if(_.isArray(q.groupQuestionsList)){
            q.groupQuestionsList.forEach((g:any)=>{
              if(typeof g.answersList !== "undefined" && _.isArray(g.answersList)){
                
                const answer = g.answersList.filter((l:any)=>l.score===ANSWER[g.inputName]).map((m:any)=>m.answer)[0];
                newAnswer = Object.assign({...newAnswer,[g.inputName] : answer||""});
                // console.log("question radiobox",g.answer);
              }else{
                // console.log("question",g.inputName,ANSWER[g.inputName]);
                newAnswer = Object.assign({...newAnswer,[g.inputName] : ANSWER[g.inputName]||""});
              }
            });
          }
        }
      });
      // console.log("newAnswer",newAnswer);
      return newAnswer;
    }else{
      // console.log("ANSWER",ANSWER);
      let newAnswerArray: Array<Record<string,any>> = [];
      ANSWER.forEach((a:any)=>{
        let newQN = structuredClone(QN);
        let newAnswer: Record<string,any> = {};
        newQN.forEach((q:any)=>{
          if(typeof q.groupDescription === "undefined" && typeof q.groupQuestionsList === "undefined"){ // คำถามปกติไม่ใช่แบบ group
            if(a[q.inputName]!==null){
              newAnswer = Object.assign({...newAnswer,[q.inputName] : a[q.inputName]||""});
            }
          }else{
            if(_.isArray(q.groupQuestionsList)){
              q.groupQuestionsList.forEach((g:any)=>{
                if(typeof g.answersList !== "undefined" && _.isArray(g.answersList)){
                  const answer = g.answersList.filter((l:any)=>l.score===a[g.inputName]).map((m:any)=>m.answer)[0];
                  newAnswer = Object.assign({...newAnswer,[g.inputName] : answer||""});
                }else{
                  newAnswer = Object.assign({...newAnswer,[g.inputName] : a[g.inputName]||""});
                }
              });
            }
          }
        });
        // console.log("newAnswer",newAnswer);
        newAnswerArray.push(newAnswer);
      });
      // console.log("newAnswerArray",newAnswerArray);
      return newAnswerArray;
    }
  };
  const prepareAnswerData = (row:any, questionnaire_code:string) =>{
    const singleQ = ["PSu1","PSu2","PCP"];
    const groupQ = ["PARe","FF1","FF2","FF3","FF4","FF5","FF6","FF7","FF8","L_M","PCRe"];
    const groupQM = ["CRF","FSR"];
    const teamCode=row.teamCode;
    const riskLevel = [
      {id:1, label:'ต่ำ',value:'low'},
      {id:2, label:'ปานกลาง',value:'medium'},
      {id:3, label:'สูง',value:'high'},
      {id:4, label:'เหยื่อ',value:'victim'},
      {id:5, label:'ปกติ',value:'normal'},
      {id:6, label:'อื่นๆ',value:'other'}
    ];
    let data:any = [];
    // console.log("answerDaTa",answerDaTa);
    const groupData = answerDaTa.filter((t:any)=>t.teams.teamCode===teamCode && !t.groupName.includes("ทดสอบ"))[0];
    // console.log("groupData",groupData);
    if(typeof groupData!=="undefined"){
      const allMember = groupData.member;
      const qAnswer = groupData.questionnaire_answer.filter((q:any)=>q.questionnaire.questionnaire_code===questionnaire_code);
      let excelCols:any = [];
      if(allMember.length>0){
        // console.log("allMember",allMember);
        if(singleQ.includes(questionnaire_code)){
          if(typeof qAnswer[0]!=="undefined"){
            qAnswer.forEach((a:any) => {
              const ql = a.questionnaire.questionsList;
              const answer = a.answer;
              const member = allMember.filter((m:any)=>m.id===a.case_id)[0];// ข้อมูลเคส
              // console.log(member);
              if(member){
                const newAnswer =  combindAnswer(ql,answer);
                let caseInfo: Record<string,string> = {caseName : "",cid: "",sex: "", age: "",risk: "",fa: ""};
                if(typeof a.osccperson_assign !=="undefined"){
                  caseInfo = {
                    caseName : member.fname +" "+ member.lname,
                    cid: member.cid,
                    sex: member.sex,
                    // birthdate: member.birthdate||"",
                    age: member.age.toString(),
                    risk: riskLevel.filter((r:any)=>r.value===member.riskLevel).map((l:any)=>l.label)[0]||"",
                    fa: row.member1+"|"+row.member2
                  }
                  data.push({...caseInfo,...newAnswer});
                }
              }
            });
            // console.log(excelCols);
            // console.log(data);
            exportToExcel(questionnaire_code,data,excelCols);
          }
        }else if(groupQ.includes(questionnaire_code)){
          const questionsList:any=[];
          const groupQuestionsList:any=[];
          const answerList:any=[];
          allMember.forEach((m:any,mIndex:number) => {
            let caseInfo = {
              caseName : m.fname +" "+ m.lname,
              cid: m.cid,
              sex: m.sex,
              age: m.age.toString(),
              risk: riskLevel.filter((r:any)=>r.value===m.riskLevel).map((l:any)=>l.label)[0]||"",
              fa: row.member1+"|"+row.member2
            };
            if(typeof qAnswer[0]!=="undefined"){
              const a = qAnswer[0];
              const ql = a.questionnaire.questionsList;
              const answer = a.answer;
              if(!ql){ // PARe ฟอร์มใหม่
                questionsList.length=0;
                groupQuestionsList.length=0;
                answerList.length=0;
                const questionnaire_layout = a.questionnaire.questionnaire_layout;
                if(questionnaire_layout){
                  for(const layouts in questionnaire_layout){
                    const layout = questionnaire_layout[layouts].rows;
                    const focusLayout = (+layouts===2) ? [{...layout[mIndex]}] : layout;
                    for(const rows of focusLayout){
                      // console.log(rows);
                      for(const col of rows.cols){
                        if(col.name){
                          questionsList.push({
                            question:(col.placeholder)?col.placeholder:col.label,
                            inputName: col.name,
                            answer: answer[col.name]
                          });
                          answerList.push((!col.name.includes("date")) ? (answer[col.name]).replace(/-/g,"") : answer[col.name]);
                        }
                      }
                      
                    }
                  }
                }     
                // console.log(questionsList);
                // console.log({...answerList});
                excelCols = genExcelColumn2(questionsList);           
                data.push({...answerList});
              }else{ // ฟอร์มเก่า
                if(a.questionnaire.questionnaire_code==="PARe"){ // PARe เก่า
                  const groupData:any = [];
                  questionsList.length=0;
                  groupQuestionsList.length=0;
                  answerList.length=0;
                  for (let key in answer) {
                    const match = key.match(/PARe_(parent\d+)_/);
                    if (match) {
                      const parentKey = match[1];
                      if (!groupData[parentKey]) {
                        groupData[parentKey] = {};
                        for (let otherKey in answer) {
                          if (!otherKey.includes("parent")) {
                            if(!otherKey.includes("date")){
                              groupData[parentKey][otherKey] = answer[otherKey].replace(/-/g,"");
                            }else{
                              groupData[parentKey][otherKey] = answer[otherKey];
                            }
                          }
                        }
                      }
                      if(!key.includes("date")){
                        groupData[parentKey][key] = answer[key].replace(/-/g,"");
                      }else{
                        groupData[parentKey][key] = answer[key];
                      }
                    }
                  }
                  let focusGroup:any = {};
                  Object.values(groupData).forEach((item:any)=>{
                    for(let key in item){
                      if(key.includes("children_cid")){
                        if(item[key]===m.cid){
                          focusGroup=item;
                        }
                      }
                    }
                  });
                  for( const key in focusGroup){
                    answerList.push(focusGroup[key]);
                    for(const row of ql){
                      if(!row.groupQuestionsList){
                        if(row.inputName===key){
                          questionsList.push({
                            question: row.question,
                            inputName: row.inputName,
                            answer: answer[row.inputName]
                          });
                        }
                      }else{
                        for(const group of row.groupQuestionsList){
                          if(group.inputName===key){
                            questionsList.push({
                              question: group.question,
                              inputName: group.inputName,
                              answer: answer[group.inputName]
                            });
                          }
                        }
                      }
                    }
                  }
                }else{ // Form อื่นๆ
                  questionsList.length=0;
                  groupQuestionsList.length=0;
                  answerList.length=0;
                  for( const key in answer){
                    answerList.push(answer[key]);
                    for(const row of ql){
                      if(!row.groupQuestionsList){
                        if(row.inputName===key){
                          questionsList.push({
                            question: row.question,
                            inputName: row.inputName,
                            answer: answer[row.inputName]
                          });
                        }
                      }else{
                        for(const group of row.groupQuestionsList){
                          if(group.inputName===key){
                            questionsList.push({
                              question: group.question,
                              inputName: group.inputName,
                              answer: answer[group.inputName]
                            });
                          }
                        }
                      }
                    }
                  }
                }

                excelCols = (a.questionnaire.questionnaire_code!=="PARe") ? 
                  genExcelColumn(questionsList) :
                  genExcelColumn2([...questionsList,...groupQuestionsList],true);
                const caseInfoArr=Object.values(caseInfo).map((m:any)=>m);
                let newAnwser: any = [...caseInfoArr,...answerList];
                data.push({...newAnwser});
              }
            }else{
              console.log("หา questionnaire_code ไม่เจอในคำตอบแบบสอบถาม");
            }
          });
          // console.log(JSON.stringify(excelCols,null,2));
          // console.log(JSON.stringify(data,null,2));
          exportToExcel(questionnaire_code,data,excelCols);
        }else if(groupQM.includes(questionnaire_code)){
          allMember.forEach((m:any) => {
            let caseInfo = {caseName : "",cid: "",sex: "",age: "",risk: "",fa: ""};
            caseInfo = {
              caseName : m.fname +" "+ m.lname,
              cid: m.cid,
              sex: m.sex,
              // birthdate: m.birthdate||"",
              age: m.age.toString(),
              risk: riskLevel.filter((r:any)=>r.value===m.riskLevel).map((l:any)=>l.label)[0]||"",
              fa: row.member1+"|"+row.member2
            };
            // console.log("caseInfo",caseInfo);
            if(typeof qAnswer[0]!=="undefined"){
              // console.log("qAnswer",qAnswer);
              qAnswer.forEach((a:any) => {
                const ql = a.questionnaire.questionsList;
                const answer = a.answer;
                // console.log("a.answer",a.answer);
                const newAnswer = combindAnswer(ql,answer);
                newAnswer.forEach((a:any) => {
                  data.push({...caseInfo,...a});
                });
              });
            }else{
              // console.log(groupData.questionnaire_answer);
              console.log("หา questionnaire_code ไม่เจอในคำตอบแบบสอบถาม");
            }
          });
          // console.log(excelCols);
          // console.log(data);
          exportToExcel(questionnaire_code,data,excelCols);
        }else{
          alert("อยู่ระหว่างพัฒนา");
          console.log(questionnaire_code);
        }
      }else{
        console.log("allMember : ",allMember.length);
      }
    }else{
      console.log("หา teamCode ไม่เจอในคำตอบแบบสอบถาม");
    }
  }

  useEffect(() => {
    getTeamsData();
  },[getTeamsData]);

  useEffect(() => { // เฝ้าดูการเปลี่ยนแปลงข้อมูล options ตัวเลือกรุ่นที่
    // if(reportDaTa.length===0){ // ถ้าไม่มีข้อมูล report
      // if(teamMembers.length>0){
        // if(optionsValue.generation!==""){
          // console.log("reportDaTa.length===0 -> generationHandleChange");
          // console.log("optionsValue",optionsValue);
          generationHandleChange(optionsValue); // เรียกฟังก์ชั่น generationHandleChange
        // }
      // }
    // }
    setLoading(false);
    //teamMembers
  },[optionsValue]);

  const columns: GridColDef[] = [
    {
      field: 'id', 
      headerName: '#', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      minWidth: 50,
      flex: 0.03
    },
    {
      field: 'gen', 
      headerName: 'รุ่น', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      minWidth: 50,
      flex: 0.03
    },
    { 
      field: 'member1',
      headerName: 'FA คนที่ 1', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'left',
      minWidth: 150,
      flex: 0.07
    },
    { 
      field: 'member2',
      headerName: 'FA คนที่ 2', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'left',
      minWidth: 150,
      flex: 0.07
    },
    // { 
    //   field: 'area',
    //   headerName: 'พื้นที่', 
    //   headerClassName:'apm-header', 
    //   sortable: false, 
    //   headerAlign:'center', 
    //   align:'left',
    //   minWidth: 120,
    //   flex: 0.07
    // },
    { 
      field: 'groupName',
      headerName: 'พื้นที่ / กลุ่ม', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'left',
      minWidth: 200,
      flex: 0.08
    },
    { 
      field: 'familyCount',
      headerName: 'ครอบครัว', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      minWidth: 90,
      flex: 0.04
    }
  ];
  QC.forEach((q:any)=>{
    let columnTemplate: GridColDef = { 
      field: q,
      headerName: q, 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      minWidth: 70,
      flex: 0.03
    };
    if(QC.includes(q)){
      columnTemplate["renderCell"] = (params: GridCellParams)=> {
        if(params.row[q]>0){
          if(QC.includes(q)){
            return <Button size="small" color="primary" onClick={()=>prepareAnswerData(params.row,q)}>{params.row[q]}</Button>;
          }
        }else{
          return params.row[q];
        }
      }
    }

    columns.push(columnTemplate);
  });
  columns.push({ 
    field: "total",
    headerName: "รวม", 
    headerClassName:'apm-header', 
    sortable: false, 
    headerAlign:'center', 
    align:'center',
    minWidth: 70,
    flex: 0.03
  });
  const excelColumn: Array<string>=[
    "ลำดับ","รุ่นที่","FA คนที่ 1","FA คนที่ 2","พื้นที่","กลุ่ม","ครอบครัว",
    "PSu1","PCP","PARe","CRF","FF1","FF2","FF3","FF4","FF5","FF6","FF7","FF8","FSR","L&M","PCRe","PSu2",
    "รวม","หมายเหตุ","วันที่สรุป"
  ];
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" >รายงานสรุปการบันทึกข้อมูลในระบบ Childshield</Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
            <Autocomplete
              id="generation"
              fullWidth
              freeSolo={true}
              value={optionsValue||null}
              options={optionsGeneration||[]}
              getOptionLabel={(option: any) => option.label}
              size="small"
              style={{ height: "100%" }}
              disabled={false}
              onChange={(_:any, value: any) => {
                if(value!==null){
                  setLoading(true);
                  generationHandleChange(value)
                }
              }}
              renderInput={
                (params) => 
                <TextField {...params} 
                  fullWidth
                  label="ดูข้อมูลของรุ่นที่"
                  variant="outlined"
                  inputProps={{ ...params.inputProps, readOnly: true }}
                  />
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <Button fullWidth variant="contained" color="primary" style={{height:"100%"}}
              startIcon={<ArrowBackIosIcon />}
              onClick={ ()=>history.push({pathname:"/report"})}>
              กลับ
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={8} lg={8} xl={8}>
            <ExcelExport type="button" data={reportDaTa} column={excelColumn} title="ส่งออก Excel"/>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <DataGrid
          rows={(loading)?[]:reportDaTa}
          columns={columns}
          loading={loading}
          headerHeight={80}
          autoHeight
          hideFooter
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
          disableDensitySelector
      />
      </Grid>
    </Grid>
  );
}

export default Report1;