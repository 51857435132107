export const inform = [
  {header:"inform.PLHA1", detail:"inform.PLHAD1", value:{id:"PLHA1",informed:null}},
  {header:"inform.PLHA2", detail:"inform.PLHAD2", value:{id:"PLHA2",informed:null}},
  {header:"inform.PLHA3", detail:"inform.PLHAD3", value:{id:"PLHA3",informed:null}},
  {header:"inform.PLHA4", detail:"inform.PLHAD4", value:{id:"PLHA4",informed:null}},
  {header:"inform.PLHA5", detail:"inform.PLHAD5", value:{id:"PLHA5",informed:null}},
  {header:"inform.PLHA6", detail:"inform.PLHAD6", value:{id:"PLHA6",informed:null}},
  {header:"inform.PLHA7", detail:"inform.PLHAD7", value:{id:"PLHA7",informed:null}},
  {header:"inform.PLHA8", detail:"inform.PLHAD8", value:{id:"PLHA8",informed:null}},
  {header:"inform.PLHA9", detail:"inform.PLHAD9", value:{id:"PLHA9",informed:null}},
  {header:"inform.PLHA10", detail:"inform.PLHAD10", value:{id:"PLHA10",informed:null}},
  {header:"inform.PLHA11", detail:"inform.PLHAD11", value:{id:"PLHA11",informed:null}},
  {header:"inform.PLHA12", detail:"inform.PLHAD12", value:{id:"PLHA12",informed:null}},
  {header:"inform.PLHA13", detail:"inform.PLHAD13", value:{id:"PLHA13",informed:null}},
  {header:"inform.PLHA14", detail:"inform.PLHAD14", value:{id:"PLHA14",informed:null}},
  {header:"inform.PLHA15", detail:"inform.PLHAD15", value:{id:"PLHA15",informed:null}}
];