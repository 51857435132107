import { GET_GROUPLIST_WITH_TEAMS } from "../../actions";
import { AnyAction } from "redux";
import { GroupList } from "../../types/activity";

const initialState:GroupList = {
  isFetching: false,
  isSuccess: false,
  count: 0,
  data: []
};

export default function getGroupListTeams(
  state = initialState,
  action: AnyAction
): GroupList {
  switch (action.type) {
    case GET_GROUPLIST_WITH_TEAMS.REQUEST:{
      return {
        ...state,
        isFetching: true,
        isSuccess: false
      };
    }
    case GET_GROUPLIST_WITH_TEAMS.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        count: action.data.rows.length,
        data: action.data.rows
      };
    }
    case GET_GROUPLIST_WITH_TEAMS.FAILURE:
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
        data: [],
      };
    default:
      return state;
  }
}
