import { put, takeEvery, call, fork } from 'redux-saga/effects';
import { persistor } from '../../store/redux';
import { push } from 'connected-react-router';
import { LOGOUT } from '../../actions/users';

function* callAction() {
  try {
    yield call([localStorage, 'removeItem'], 'OSCC');
    yield call([persistor, 'purge']);
    yield put({ type: LOGOUT.SUCCESS });
    yield put(push('/login'));
  } catch (error) {
    console.error('Error during logout:', error);
  }
}

function* watchAction() {
  yield takeEvery(LOGOUT.REQUEST, callAction);
}

export default function* root() {
  yield fork(watchAction);
}