import { combineReducers } from "redux";
import newCase from "./new";
import assigned from "./assigned";
import inProgress from "./inprogress";
import waitConclusion from "./waitconclusion";
import finish from "./finish";
import renew from "./renew";

export default combineReducers({
  newCase,
  assigned,
  inProgress,
  waitConclusion,
  finish,
  renew
});
