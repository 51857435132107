/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getCurrentUser } from "./auth.service";
import * as API from "./UniversalAPI";
import { useHistory } from "react-router-dom";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    CircularProgress,
    TextField,
    IconButton,
    ListItemText,
    FormControl,
    FormControlLabel,
    Checkbox,
    MenuItem,
    Select,
    InputLabel,
    OutlinedInput,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import "moment/locale/th";
import { useTranslation } from "react-i18next";
import {
    MdAdd as MdAddIcon,
    MdClear as MdClearIcon,
} from '@material-ui/icons/Dvr';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiTextField-root': {
            width: '100%',
        },
        '& .MuiInputLabel-outlined': {
            zIndex: 1,
            transform: 'translate(15px, 12px) scale(1)',
            pointerEvents: 'none'
        },
        '& .MuiInputLabel-shrink': {
            transform: 'translate(15px, -17px) scale(0.75)',
        },
        '& .MuiInputBase-input': { textAlign: 'center' },
    }
}));

export default function CaseFilter(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
    const userinfo = getCurrentUser();

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [questionnaireData, setQuestionnaireData] = useState([]);

    const [changwatData, setChangwatData] = useState([]);
    const [ampurData, setAmpurData] = useState([]);
    const [tambonData, setTambonData] = useState([]);
    const [mooData, setMooData] = useState([]);

    const [ampurFocus, setAmpurFocus] = useState([]);
    const [tambonFocus, setTambonFocus] = useState([]);
    const [mooFocus, setMooFocus] = useState([]);

    const [defaultChangwat, setDefaultChangwat] = useState(null);
    const [defaultAmpur, setDefaultAmpur] = useState(null);

    const [filterDataFunctionEnable, setFilterDataFunctionEnable] = useState(false);

    const caseStatusCode = (x) => {
        let r = [];
        switch (x) {
            case 'รายใหม่':
                r.push('new');
                r.push('renew');
                break;
            case 'มอบทีม':
                r.push('assignedTeam');
                break;
            case 'มอบ จนท.':
                r.push('assignedOfficer');
                break;
            case 'รับเคส':
                r.push('recievedCase');
                break;
            case 'ส่งCM':
                r.push('sentCM');
                break;
            case 'เสร็จสิ้น':
                r.push('completed');
                break;
            default:
                break;
        }
        return r;
    }

    const caseStatusName = (x) => {
        let r = [];
        switch (x) {
            case 'new':
                r.push('รายใหม่');
                break;
            case 'renew':
                r.push('รายใหม่');
                break;
            case 'assignedTeam':
                r.push('มอบทีม');
                break;
            case 'assignedOfficer':
                r.push('มอบ จนท.');
                break;
            case 'recievedCase':
                r.push('รับเคส');
                break;
            case 'sentCM':
                r.push('ส่งCM');
                break;
            case 'completed':
                r.push('เสร็จสิ้น');
                break;
            default:
                break;
        }
        return r;
    }

    const cleanFilter = () => {
        setFilterData({
            changwat: defaultChangwat || null,
            ampur: defaultAmpur || null,
            tambon: null,
            moo: null,
            caseStatus: caseStatusName(props.location.state.casestatus) || [],
            severityType: [],
            riskLevel: [],
            nearDeadline: false,
        });
    }

    const executeFilter = () => {
        let villagecode_where = '';
        if (filterData.moo) {
            villagecode_where = { villagecode: filterData.moo.villagecode };
        }
        else if (filterData.tambon) {
            villagecode_where = { villagecode: { like: filterData.tambon.chwpart + filterData.tambon.amppart + filterData.tambon.tmbpart + '.*' } };
        }
        else if (filterData.ampur) {
            villagecode_where = { villagecode: { like: filterData.ampur.amphur_id + '.*' } };
        }
        else if (filterData.changwat) {
            villagecode_where = { villagecode: { like: filterData.changwat.changwatcode + '.*' } };
        }

        let caseStatus_where_array = [];
        if (filterData.caseStatus.length > 0) {
            filterData.caseStatus.forEach(i => {
                caseStatusCode(i).forEach(w => {
                    caseStatus_where_array.push({ caseStatus: w });
                });
            });
        }

        let riskLevel_where_array = [];
        if (filterData.riskLevel.length > 0) {
            filterData.riskLevel.forEach(i => {
                riskLevel_where_array.push({ riskLevel: i });
            });
        }

        let severityType_where = {};
        if (filterData.severityType.length > 0) {
            severityType_where = { severityType: { in: filterData.severityType } };
        }

        let query_where = {};
        let query_where_temp = {};
        let query_where_and = [];
        if (userinfo.user.role === 'CM') {
            query_where_temp = { amphur_id: userinfo.user.amphur.amphur_id };
        }
        else if (userinfo.user.role === 'AdminTeams') {
            let team_query = [];
            if (userinfo.user.teams.length > 0) {
                userinfo.user.teams.forEach(i => {
                    team_query.push({ osccTeamId: { like: i.id, options: "i" } });
                });
                query_where_temp = { or: team_query };
            }
        }
        else if (userinfo.user.role === 'Teams') {
            query_where_temp = { officerId: { like: userinfo.user.id, options: "i" } };
        }

        if (typeof userinfo.user.coach !== 'undefined') {
            if (userinfo.user.coach === 'OSCC') {
                query_where_temp = {};
            }
        }

        if (villagecode_where !== '') {
            query_where_and.push(villagecode_where);
        }
        if (caseStatus_where_array.length > 0) {
            query_where_and.push({ or: caseStatus_where_array });
        }
        if (riskLevel_where_array.length > 0) {
            query_where_and.push({ or: riskLevel_where_array });
        }
        if (Object.keys(severityType_where).length > 0) {
            query_where_and.push(severityType_where);
        }

        if (query_where_and.length > 0) {
            if (Object.keys(query_where_temp).length > 0) {
                query_where = { and: [query_where_temp, { and: query_where_and }] };
            }
            else {
                query_where = { and: query_where_and };
            }
        }
        else {
            query_where = query_where_temp;
        }

        // setCollectionData({ ...collectionData, ...{ query_where: query_where } });
    }


    const filterChangeCheckBox = (event, var_name) => {
        const {
            target: { checked },
        } = event;
        setFilterData({
            ...filterData, ...{
                [var_name]: checked,
            }
        });
        if (var_name === 'nearDeadline') {
            if (checked) {
                // console.log('A--');
                setFilterDataFunctionEnable(true);
            }
            else {
                // console.log('B--');
                setFilterDataFunctionEnable(false);
            }
        }
    }

    const filterChangeCheckList = (event, var_name) => {
        const {
            target: { value },
        } = event;
        setFilterData({
            ...filterData, ...{
                [var_name]: typeof value === 'string' ? value.split(',') : value,
            }
        });
    }


    const filterChange = (e) => {
        let v = null;
        if (typeof e.value !== 'undefined') {
            if (e.value) {
                v = e.value
            }
        }
        if (e.name === 'changwat') {
            setFilterData({ ...filterData, ...{ changwat: v, ampur: null, tambon: null, moo: null } });
            if (v) {
                let x = [];
                ampurData.forEach(i => {
                    if (e.value.changwatcode === i.province_id) {
                        x.push(i);
                    }
                });
                setAmpurFocus(x);
                setTambonFocus([]);
                setMooFocus([]);
            }
        }

        if (e.name === 'ampur') {
            setFilterData({ ...filterData, ...{ ampur: v, tambon: null, moo: null } });
            if (v) {
                let x = [];
                tambonData.forEach(i => {
                    if (e.value.amphur_id === i.addressid.substr(0, 4)) {
                        x.push(i);
                    }
                });
                setTambonFocus(x);
                setMooFocus([]);
            }
        }

        if (e.name === 'tambon') {
            setFilterData({ ...filterData, ...{ tambon: v, moo: null } });
            if (v) {
                let x = [];
                mooData.forEach(i => {
                    if (e.value.addressid.substr(0, 6) === i.tmbpart) {
                        x.push(i);
                    }
                });
                setMooFocus(x);
            }
        }

        if (e.name === 'moo') {
            setFilterData({ ...filterData, ...{ moo: v } });
        }

    }

    const [filterData, setFilterData] = useState({
        changwat: null,
        ampur: null,
        tambon: null,
        moo: null,
        // caseStatus: caseStatusName(props.location.state.casestatus) || [],
        caseStatus: [],
        severityType: [],
        riskLevel: [],
        nearDeadline: false,
    });

    const [caseStatusData, setCaseStatusData] = useState([
        { id: 1, name: 'รายใหม่' },
        { id: 2, name: 'มอบทีม' },
        { id: 3, name: 'มอบ จนท.' },
        { id: 4, name: 'รับเคส' },
        { id: 5, name: 'ส่งCM' },
        { id: 6, name: 'เสร็จสิ้น' },
    ]);
    const [severityTypeData, setSeverityTypeData] = useState([
        { id: 1, name: 'ร่างกาย' },
        { id: 2, name: 'เพศ' },
        { id: 3, name: 'จิตใจ' },
        { id: 4, name: 'ทอดทิ้ง' },
        { id: 5, name: 'ล่อลวง' },
    ]);
    const [riskLevelData, setRiskLevelData] = useState([
        { id: 1, name: 'Low' },
        { id: 2, name: 'medium' },
        { id: 3, name: 'high' },
        { id: 4, name: 'victim' },
        { id: 5, name: 'other' },
    ]);

    const filterBlock = useRef();
    const filterShowBtn = useRef();
    const filterHideBtn = useRef();

    const OPTIONS_LIMIT = 100;
    const defaultFilterOptions = createFilterOptions();
    const filterOptions = (options, state) => {
        return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
    };

    const toogleFilter = (x) => {
        if (x === 'show') {
            filterShowBtn.current.style.display = 'none';
            filterHideBtn.current.style.display = 'inline-block';
            filterBlock.current.style.display = 'block';
        }
        if (x === 'hide') {
            filterShowBtn.current.style.display = 'inline-block';
            filterHideBtn.current.style.display = 'none';
            filterBlock.current.style.display = 'none';
        }
    }

    const getChangwatData = async () => {
        let filter = {
            filter: {
                where: {
                    or: [
                        { changwatcode: "47" }, { changwatcode: "41" }, { changwatcode: "48" }, { changwatcode: "43" }, { changwatcode: "42" }, { changwatcode: "39" }, { changwatcode: "38" }
                    ]
                },
                order: ["changwatname ASC"]
            }
        };
        let response = await API.getAll(filter, "cchangwats");
        if (response.status === 200) {
            let rd = response.data;
            setChangwatData(rd);
            getAmpurData(rd);
        }
    }

    const getAmpurData = async (param_changwatData) => {
        let filter = {
            filter: {
                where: {
                    or: [
                        { province_id: "47" }, { province_id: "41" }, { province_id: "48" }, { province_id: "43" }, { province_id: "42" }, { province_id: "39" }, { province_id: "38" }
                    ]
                },
                order: ["province_id ASC", "amphur_name ASC"]
            }
        };
        let response = await API.getAll(filter, "amphurs");
        if (response.status === 200) {
            let rd = response.data;
            setAmpurData(rd);

            let currentChangwat = null;
            let currentAmpur = null;
            if (userinfo.user.role === "CM" || userinfo.user.role === "AdminTeams" || userinfo.user.role === "Teams") {
                if (typeof param_changwatData !== 'undefined') {
                    param_changwatData.forEach(i => {
                        if (i.changwatcode.toString() === userinfo.user.changwat.changwatcode.toString()) {
                            currentChangwat = i;
                        }
                    });
                }

                let x = [];
                rd.forEach(i => {
                    if (currentChangwat.changwatcode === i.province_id) {
                        x.push(i);
                    }
                });
                setAmpurFocus(x);

                rd.forEach(i => {
                    if (i.amphur_id.toString() === userinfo.user.amphur.amphur_id.toString()) {
                        currentAmpur = i;
                    }
                });

                setFilterData({ ...filterData, ...{ changwat: currentChangwat, ampur: currentAmpur } });
            }
            setDefaultChangwat(currentChangwat);
            setDefaultAmpur(currentAmpur);
            getTambonData(currentAmpur);
        }
    }

    const getTambonData = async (currentAmpur) => {
        let filter = {
            filter: {
                where: {
                    or: [
                        { chwpart: "47" }, { chwpart: "41" }, { chwpart: "48" }, { chwpart: "43" }, { chwpart: "42" }, { chwpart: "39" }, { chwpart: "38" }
                    ]
                },
                order: ["chwpart ASC", "amppart ASC", "name ASC"]
            }
        };
        let response = await API.getAll(filter, "tambols");
        if (response.status === 200) {
            let rd = response.data;
            setTambonData(rd);
            if (userinfo.user.role === "CM" || userinfo.user.role === "AdminTeams" || userinfo.user.role === "Teams") {
                let x = [];
                rd.forEach(i => {
                    if (currentAmpur.amphur_id === i.addressid.substr(0, 4)) {
                        x.push(i);
                    }
                });
                setTambonFocus(x);
            }
            getMooData();
        }
    }

    const getMooData = async () => {
        let filter = {
            filter: {
                where: {
                    or: [
                        { villagecode: { like: "47.*", options: "i" } },
                        { villagecode: { like: "41.*", options: "i" } },
                        { villagecode: { like: "48.*", options: "i" } },
                        { villagecode: { like: "43.*", options: "i" } },
                        { villagecode: { like: "42.*", options: "i" } },
                        { villagecode: { like: "39.*", options: "i" } },
                        { villagecode: { like: "38.*", options: "i" } }
                    ]
                },
                order: ["villagecode ASC"]
            }
        };
        let response = await API.getAll(filter, "villages");
        if (response.status === 200) {
            let x = response.data;
            x.forEach(i => {
                i['moo_no'] = parseInt(i.villagecode.substr(-2)).toString();
                i['chwpart'] = i.villagecode.substr(0, 2);
                i['amppart'] = i.villagecode.substr(0, 4);
                i['tmbpart'] = i.villagecode.substr(0, 6);
            });
            setMooData(x);
            // console.log(x);
        }
    }


    useEffect(() => {
        // console.log(filterData);
        // console.log(props.setFilterData);
        if (Object.keys(filterData).length>0) {
            props.setFilterData(filterData);
        }
        // eslint-disable-next-line 
    }, [filterData]); 

    useEffect(() => {
        getChangwatData();
        // eslint-disable-next-line 
    }, []);    

    return (
        <div>

            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"th"}>

                <Grid container>
                    <Grid item xs={12} sm={12}>

                        <Grid container>

                            <div ref={filterBlock} style={{ width: '100%' }}>
                                <Grid container>
                                    <Grid item xs={6} sm={3} style={{ paddingRight: 3 }}>
                                        <Autocomplete
                                            // disabled={['CM', 'AdminTeams', 'Teams'].indexOf(userinfo.user.role) > -1 ? true : false}
                                            disabled={['AdminR8'].indexOf(userinfo.user.role) > -1 || userinfo.user.coach === 'OSCC' ? false : true}
                                            style={{ marginTop: 3 }}
                                            filterOptions={filterOptions}
                                            size="small"
                                            fullWidth
                                            options={changwatData}
                                            value={filterData.changwat}
                                            getOptionSelected={(option, value) => value.changwatname === option.changwatname}
                                            getOptionLabel={(option) => option.changwatname || ''}
                                            onChange={(_, newValue) => {
                                                filterChange({ name: 'changwat', value: newValue });
                                            }}
                                            ListboxProps={{ style: { maxHeight: '200px' } }}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    name="changwat"
                                                    label="จังหวัด"
                                                    variant="outlined"
                                                    onChange={(e) => filterChange(e)}
                                                    style={{ textAlign: 'left' }}
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{ paddingRight: 3 }}>
                                        <Autocomplete
                                            // disabled={['CM', 'AdminTeams', 'Teams'].indexOf(userinfo.user.role) > -1 ? true : false}
                                            disabled={['AdminR8'].indexOf(userinfo.user.role) > -1 || userinfo.user.coach === 'OSCC' ? false : true}
                                            style={{ marginTop: 3 }}
                                            filterOptions={filterOptions}
                                            size="small"
                                            fullWidth
                                            options={ampurFocus}
                                            value={filterData.ampur}
                                            getOptionSelected={(option, value) => value.amphur_name === option.amphur_name}
                                            getOptionLabel={(option) => option.amphur_name || ''}
                                            onChange={(_, newValue) => {
                                                filterChange({ name: 'ampur', value: newValue });
                                            }}
                                            ListboxProps={{ style: { maxHeight: '200px' } }}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    name="ampur"
                                                    label="อำเภอ"
                                                    variant="outlined"
                                                    onChange={(e) => filterChange(e)}
                                                    style={{ textAlign: 'left' }}
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{ paddingRight: 3 }}>
                                        <Autocomplete
                                            style={{ marginTop: 3 }}
                                            filterOptions={filterOptions}
                                            size="small"
                                            fullWidth
                                            options={tambonFocus}
                                            value={filterData.tambon}
                                            getOptionSelected={(option, value) => value.name === option.name}
                                            getOptionLabel={(option) => option.name || ''}
                                            onChange={(_, newValue) => {
                                                filterChange({ name: 'tambon', value: newValue });
                                            }}
                                            ListboxProps={{ style: { maxHeight: '200px' } }}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    name="tambon"
                                                    label="ตำบล"
                                                    variant="outlined"
                                                    onChange={(e) => filterChange(e)}
                                                    style={{ textAlign: 'left' }}
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{ paddingRight: 3 }}>
                                        <Autocomplete
                                            style={{ marginTop: 3 }}
                                            filterOptions={filterOptions}
                                            size="small"
                                            fullWidth
                                            options={mooFocus}
                                            value={filterData.moo}
                                            getOptionSelected={(option, value) => value.moo_no === option.moo_no}
                                            getOptionLabel={(option) => option.moo_no || ''}
                                            onChange={(_, newValue) => {
                                                filterChange({ name: 'moo', value: newValue });
                                            }}
                                            ListboxProps={{ style: { maxHeight: '200px' } }}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    name="moo"
                                                    label="หมู่"
                                                    variant="outlined"
                                                    onChange={(e) => filterChange(e)}
                                                    style={{ textAlign: 'left' }}
                                                />
                                            }
                                        />
                                    </Grid>

                                    {/* <Grid item xs={6} sm={3} style={{ paddingRight: 3 }}>
                                        <FormControl size="small" fullWidth style={{ marginTop: 3, width: isDesktop ? 137 : document.documentElement.clientWidth / 2 - 25 }}>
                                            <InputLabel id="caseStatus_label">สถานะ</InputLabel>
                                            <Select
                                                fullWidth
                                                labelId="caseStatus_label"
                                                multiple
                                                value={filterData.caseStatus}
                                                onChange={(e) => filterChangeCheckList(e, 'caseStatus')}
                                                input={<OutlinedInput label="caseStatus" />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 30 * caseStatusData.length + 20,
                                                            width: 'auto',
                                                        }
                                                    }
                                                }}
                                            >
                                                {caseStatusData.length > 0 && (
                                                    caseStatusData.map((i) => (
                                                        <MenuItem key={i.id} value={i.name} style={{ height: 30 }}>
                                                            <Checkbox
                                                                checked={filterData.caseStatus.indexOf(i.name) > -1}
                                                            />
                                                            <ListItemText primary={i.name} />
                                                        </MenuItem>
                                                    ))
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{ paddingRight: 3 }}>
                                        <FormControl size="small" fullWidth style={{ marginTop: 3, width: isDesktop ? 137 : document.documentElement.clientWidth / 2 - 25 }}>
                                            <InputLabel id="riskLevel_label">ระดับความรุนแรง</InputLabel>
                                            <Select
                                                labelId="riskLevel_label"
                                                multiple
                                                value={filterData.riskLevel}
                                                onChange={(e) => filterChangeCheckList(e, 'riskLevel')}
                                                input={<OutlinedInput label="riskLevel" />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 30 * riskLevelData.length + 20,
                                                            width: 'auto',
                                                        }
                                                    }
                                                }}
                                            >
                                                {riskLevelData.length > 0 && (
                                                    riskLevelData.map((i) => (
                                                        <MenuItem key={i.id} value={i.name} style={{ height: 30 }}>
                                                            <Checkbox
                                                                checked={filterData.riskLevel.indexOf(i.name) > -1}
                                                            />
                                                            <ListItemText primary={i.name} />
                                                        </MenuItem>
                                                    ))
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{ paddingRight: 3 }}>
                                        <FormControl size="small" fullWidth style={{ marginTop: 3, width: isDesktop ? 137 : document.documentElement.clientWidth / 2 - 25 }}>
                                            <InputLabel id="severityType_label">ประเภทความรุนแรง</InputLabel>
                                            <Select
                                                labelId="severityType_label"
                                                multiple
                                                value={filterData.severityType}
                                                onChange={(e) => filterChangeCheckList(e, 'severityType')}
                                                input={<OutlinedInput label="severityType" />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 30 * severityTypeData.length + 20,
                                                            width: 'auto',
                                                        }
                                                    }
                                                }}
                                            >
                                                {severityTypeData.length > 0 && (
                                                    severityTypeData.map((i) => (
                                                        <MenuItem key={i.id} value={i.name} style={{ height: 30 }}>
                                                            <Checkbox
                                                                checked={filterData.severityType.indexOf(i.name) > -1}
                                                            />
                                                            <ListItemText primary={i.name} />
                                                        </MenuItem>
                                                    ))
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{ paddingRight: 3 }}>
                                        <div style={{ whiteSpace: 'nowrap' }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={filterData.nearDeadline}
                                                        onChange={(e) => filterChangeCheckBox(e, 'nearDeadline')}
                                                        style={{ marginRight: -10 }}
                                                    />
                                                }
                                                label="ใกล้หมดเวลา"
                                            />
                                        </div>
                                    </Grid> */}

                                </Grid>

                                {/* <Grid item xs={12} sm={12}>
                                    <Grid container justify="flex-end" style={{ marginTop: 3 }}>
                                        <Button color="primary" variant="outlined" style={{ margin: 0, padding: 0, minWidth: 50, marginRight: 3 }} onClick={() => cleanFilter()}>
                                            ล้าง
                                        </Button>
                                        <Button color="primary" variant="contained" style={{ margin: 0, padding: 0 }} onClick={() => executeFilter()}>
                                            ค้นหา
                                        </Button>
                                    </Grid>
                                </Grid>
                                 */}
                            </div>

                        </Grid>

                    </Grid>
                </Grid>

            </MuiPickersUtilsProvider>

        </div>
    );
}