/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { getCurrentUser } from "../../services/auth.service";
import UDataTable from "../../services/universal/UniversalDataTable";
import {
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import useGlobal from "../../store";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Grid,
  Button,
  ButtonGroup,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import * as API from "../../services/UniversalAPI";
import {
  MdAdd as MdAddIcon,
  MdClear as MdClearIcon,
} from "react-icons/md";
import moment from "moment";
import "moment/locale/th";

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 'auto',
    },
  },
};

export default function Cases(props) {
  const notificationDays = 10;
  const history = useHistory();
  const [globalState, globalActions] = useGlobal();
  const userinfo = getCurrentUser();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [pageHeadText, setPageHeadText] = useState('');
  // const [coachTambonData, setCoachTambonData] = useState([]);
  // const [selecedTambon, setSelecedTambon] = useState({});
  // const [formData, setFormData] = useState({ tambon: null, });
  // const [displayTambon, setDisplayTambon] = useState('none');
  const [collectionData, setCollectionData] = useState({});
  const [xFactor, setXFactor] = useState(0);
  
  const [changwatData, setChangwatData] = useState([]);
  const [ampurData, setAmpurData] = useState([]);
  const [tambonData, setTambonData] = useState([]);
  const [mooData, setMooData] = useState([]);

  const [ampurFocus, setAmpurFocus] = useState([]);
  const [tambonFocus, setTambonFocus] = useState([]);
  const [mooFocus, setMooFocus] = useState([]);

  const [defaultChangwat, setDefaultChangwat] = useState(null);
  const [defaultAmpur, setDefaultAmpur] = useState(null);

  const caseStatusName = (x) => {
    let r = [];
    switch (x) {
      case 'new':
        r.push('รายใหม่');
        break;
      case 'renew':
        r.push('รายใหม่');
        break;
      case 'assignedTeam':
        r.push('มอบทีม');
        break;
      case 'assignedOfficer':
        r.push('มอบ จนท.');
        break;
      case 'recievedCase':
        r.push('รับเคส');
        break;
      case 'sentCM':
        r.push('ส่งCM');
        break;
      case 'completed':
        r.push('เสร็จสิ้น');
        break;
      default:
        break;
    }
    return r;
  }

  const [filterData, setFilterData] = useState({
    changwat: null,
    ampur: null,
    tambon: null,
    moo: null,
    caseStatus: caseStatusName(props.location.state.casestatus) || [],
    severityType: [],
    riskLevel: [],
    nearDeadline: false,
  });

  const [caseStatusData, setCaseStatusData] = useState([
    { id: 1, name: 'รายใหม่' },
    { id: 2, name: 'มอบทีม' },
    { id: 3, name: 'มอบ จนท.' },
    { id: 4, name: 'รับเคส' },
    { id: 5, name: 'ส่งCM' },
    { id: 6, name: 'เสร็จสิ้น' },
  ]);
  const [severityTypeData, setSeverityTypeData] = useState([
    { id: 1, name: 'ร่างกาย' },
    { id: 2, name: 'เพศ' },
    { id: 3, name: 'จิตใจ' },
    { id: 4, name: 'ทอดทิ้ง' },
    { id: 5, name: 'ล่อลวง' },
  ]);
  const [riskLevelData, setRiskLevelData] = useState([
    { id: 1, name: 'Low' },
    { id: 2, name: 'medium' },
    { id: 3, name: 'high' },
    { id: 4, name: 'victim' },
    { id: 5, name: 'other' },
  ]);

  const filterBlock = useRef();
  const filterShowBtn = useRef();
  const filterHideBtn = useRef();

  const OPTIONS_LIMIT = 200;
  const defaultFilterOptions = createFilterOptions();
  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  const [filterDataFunctionEnable, setFilterDataFunctionEnable] = useState(false);

  const getCollectionData = () => {
    let query_where = {};
    let forward_path = "/caseemr";
    if (typeof props.location !== 'undefined') {
      if (typeof props.location.state !== 'undefined') {
        if (typeof props.location.state.status !== 'undefined') {

          if (props.location.state.status === 'newCase') {
            if (userinfo.user.role === 'CM') {
              forward_path = "/caseplan";
              query_where = {
                and: [
                  { amphur_id: userinfo.user.amphur.amphur_id },
                  {
                    or: [
                      { caseStatus: 'new' },
                      { caseStatus: 'renew' }
                    ]
                  }
                ]
              };
            }
            else if (userinfo.user.role === 'AdminTeams') {
              forward_path = "/caseplanteam";
              let team_query = [];
              if (userinfo.user.teams.length > 0) {
                userinfo.user.teams.forEach(i => {
                  team_query.push({ osccTeamId: { like: i.id, options: "i" } });
                });
                query_where = { and: [{ or: team_query }, { caseStatus: 'assignedTeam' }] };
              }
            }
            else if (userinfo.user.role === 'Teams') {
              forward_path = "/caseplanlist";
              query_where = { and: [{ officerId: { like: userinfo.user.id, options: "i" } }, { caseStatus: 'assignedOfficer' }] };
            }
          }
          else if (props.location.state.status === 'sentMember') {
            if (userinfo.user.role === 'AdminTeams') {
              forward_path = "/caseplanteam";
              let team_query = [];
              if (userinfo.user.teams.length > 0) {
                userinfo.user.teams.forEach(i => {
                  team_query.push({ osccTeamId: { like: i.id, options: "i" } });
                });
                query_where = { and: [{ or: team_query }, { caseStatus: 'assignedOfficer' }] };
              }
            }
          }
          else if (props.location.state.status === 'onWork') {
            if (userinfo.user.role === 'CM') {
              forward_path = "/caseplan";
              query_where = { and: [{ amphur_id: userinfo.user.amphur.amphur_id }, { or: [{ caseStatus: 'assignedTeam' }, { caseStatus: 'assignedOfficer' }, { caseStatus: 'recievedCase' }] }] };
            }
            else if (userinfo.user.role === 'AdminTeams') {
              forward_path = "/caseplanlist";
              let team_query = [];
              if (userinfo.user.teams.length > 0) {
                userinfo.user.teams.forEach(i => {
                  team_query.push({ osccTeamId: { like: i.id, options: "i" } });
                });
                query_where = { and: [{ or: team_query }, { caseStatus: 'recievedCase' }] };
              }
            }
            else if (userinfo.user.role === 'Teams') {
              forward_path = "/caseplanlist";
              query_where = { and: [{ officerId: { like: userinfo.user.id, options: "i" } }, { caseStatus: 'recievedCase' }] };
            }
          }
          else if (props.location.state.status === 'wait') {
            if (userinfo.user.role === 'CM') {
              forward_path = "/caseconclude";
              query_where = { and: [{ amphur_id: userinfo.user.amphur.amphur_id }, { caseStatus: 'sentCM' }] };
            }
            else if (userinfo.user.role === 'AdminTeams') {
              forward_path = "/caseplanlist";
              let team_query = [];
              if (userinfo.user.teams.length > 0) {
                userinfo.user.teams.forEach(i => {
                  team_query.push({ osccTeamId: i.id });
                });
                query_where = { and: [{ or: team_query }, { caseStatus: 'sentCM' }] };
              }
            }
            else if (userinfo.user.role === 'Teams') {
              forward_path = "/caseplanlist";
              query_where = { and: [{ officerId: { like: userinfo.user.id, options: "i" } }, { caseStatus: 'sentCM' }] };
            }
          }
          else if (props.location.state.status === 'completed') {
            if (userinfo.user.role === 'CM') {
              forward_path = "/caseconclude";
              query_where = { and: [{ amphur_id: userinfo.user.amphur.amphur_id }, { caseStatus: 'completed' }] };
            }
            else if (userinfo.user.role === 'AdminTeams') {
              forward_path = "/caseconclude";
              let team_query = [];
              if (userinfo.user.teams.length > 0) {
                userinfo.user.teams.forEach(i => {
                  team_query.push({ osccTeamId: i.id });
                });
                query_where = { and: [{ or: team_query }, { caseStatus: 'completed' }] };
              }
            }
            else if (userinfo.user.role === 'Teams') {
              forward_path = "/caseconclude";
              query_where = { and: [{ officerId: { like: userinfo.user.id, options: "i" } }, { caseStatus: 'completed' }] };
            }
          }
          else if (props.location.state.status === 'nearDeadLine') {
            console.log('nearDeadLine------A');
            setFilterData({ ...filterData, ...{ nearDeadline: true } });
            setFilterDataFunctionEnable(true);
            if (userinfo.user.role === 'CM') {
              forward_path = "/caseplan";
              query_where = { and: [{ amphur_id: userinfo.user.amphur.amphur_id }, { or: [{ caseStatus: 'assignedTeam' }, { caseStatus: 'assignedOfficer' }, { caseStatus: 'recievedCase' }] }] };
            }
            else if (userinfo.user.role === 'AdminTeams') {
              forward_path = "/caseplanlist";
              let team_query = [];
              if (userinfo.user.teams.length > 0) {
                userinfo.user.teams.forEach(i => {
                  team_query.push({ osccTeamId: i.id });
                });
                query_where = { and: [{ or: team_query }, { or: [{ caseStatus: 'assignedTeam' }, { caseStatus: 'assignedOfficer' }, { caseStatus: 'recievedCase' }] }] };
              }
            }
            else if (userinfo.user.role === 'Teams') {
              forward_path = "/caseplanlist";
              query_where = { and: [{ officerId: { like: userinfo.user.id, options: "i" } }, { or: [{ caseStatus: 'assignedTeam' }, { caseStatus: 'assignedOfficer' }, { caseStatus: 'recievedCase' }] }] };
            }
          }

        }

        // if (typeof props.location.state.selected_amphur_id !== 'undefined') {
        //   if (typeof query_where.and !== 'undefined') {
        //     query_where.and.forEach(i => {
        //       if (typeof i.amphur_id !== 'undefined') {
        //         // console.log(i.amphur_id);
        //         // console.log(props.location.state.selected_amphur_id);
        //         i.amphur_id = props.location.state.selected_amphur_id;
        //       }
        //     });
        //   }
        // }

        console.log(props.location.state);
        console.log(JSON.stringify(query_where));
      }
    }

    const collection = {
      person_cases: {
        collection_name: 'osccpeople',
        collection_include: 'planservice',
        title: 'ตาราง person_test',
        order_by: 'fname desc',
        create_document: false,
        update_document: false,
        delete_document: false,
        forward_path: forward_path,
        forward_props: 'document',
        primary_key: 'id',
        query_where: query_where,
        rows_per_page: 10, // 10 20 30 40 50 60 70 80 90 100
        search_field: ['cid', 'fname', 'lname'],
        fields: {
          cid: { show: true, title: 'CID', data_type: 'string', format: null, input_type: 'textbox', validation: 'number', value_length_type: 'fix', value_length_count: 13, value_length_min: null, value_length_max: null },
          fname: { show: true, title: 'ชื่อ', data_type: 'string', format: null, input_type: 'textbox', validation: 'string', value_length_type: 'range', value_length_count: null, value_length_min: 3, value_length_max: 5 },
          lname: { show: true, title: 'สกุล', data_type: 'string', format: null, input_type: 'textbox' },
          sex: { show: true, title: 'เพศ', data_type: 'string', format: null, input_type: 'select', input_select_source_type: 'json', input_select_source_name: 'sex', input_select_source_json: [{ sex_id: 1, sex_name: 'ชาย' }, { sex_id: 2, sex_name: 'หญิง' }], input_select_source_key: 'sex_id', input_select_source_value: 'sex_name' },
          age: { show: true, title: 'อายุ', data_type: 'string', input_type: 'none', calculate_date_diff: true, date_start_field: 'birthday', date_end_field: 'today' },
          // severityType: { show: true, title: 'ประเภท', data_type: 'string', input_type: 'none', calculate_date_diff: true, date_start_field: 'birthday', date_end_field: 'today' },
          riskLevel: { show: true, title: 'ระดับ', data_type: 'string', input_type: 'none', calculate_date_diff: true, date_start_field: 'birthday', date_end_field: 'today' },
          // caseStatus: { show: true, title: 'สถานะ', data_type: 'string', input_type: 'none', calculate_date_diff: true, date_start_field: 'birthday', date_end_field: 'today' },
        }
      },
    };
    setCollectionData(collection.person_cases);
    // console.log(collection);
  }


  // console.log(props);
  // console.log(props.location.state);

  // const getTambon = async () => {
  //   let filter = {
  //     filter: {
  //       where: {
  //         or: [
  //           // สกลนคร 47
  //           // อุดรธานี 41
  //           // นครพนม 48
  //           // หนองคาย 43
  //           // เลย 42
  //           // หนองบัวลำภู 39
  //           // บึงกาฬ 38
  //           { chwpart: "47" },
  //           { chwpart: "41" },
  //           { chwpart: "48" },
  //           { chwpart: "43" },
  //           { chwpart: "42" },
  //           { chwpart: "39" },
  //           { chwpart: "38" }
  //         ]
  //       },
  //       order: ["addressid ASC"]
  //     }
  //   };
  //   let response = await API.getAll(filter, "tambols");
  //   if (response.status === 200) {
  //     setCoachTambonData(response.data);
  //     // console.log(response.data);
  //   }
  // };

  // const ATCChange = (e) => {
  //   if (typeof e.value !== 'undefined') {
  //     if (e.value) {
  //       if (e.name === 'tambon') {
  //         setFormData({ ...formData, ...{ tambon: e.value } });
  //         // console.log(collectionData);
  //         let cd = JSON.parse(JSON.stringify(collectionData));
  //         let qw = cd.query_where;
  //         qw.and.forEach(i => {
  //           if (typeof i.amphur_id !== 'undefined') {
  //             i.amphur_id = e.value.chwpart + e.value.amppart;
  //           }
  //         });
  //         let stateData = props.location.state;
  //         stateData['selected_amphur_id'] = e.value.chwpart + e.value.amppart;
  //         stateData['selected_amphur_data'] = e.value;
  //         history.replace('/cases', stateData);
  //         history.go(0);
  //       }
  //     }
  //   }
  // }

  const filterChange = (e) => {
    let v = null;
    if (typeof e.value !== 'undefined') {
      if (e.value) {
        v = e.value
      }
    }
    if (e.name === 'changwat') {
      setFilterData({ ...filterData, ...{ changwat: v, ampur: null, tambon: null, moo: null } });
      if (v) {
        let x = [];
        ampurData.forEach(i => {
          if (e.value.changwatcode === i.province_id) {
            x.push(i);
          }
        });
        setAmpurFocus(x);
        setTambonFocus([]);
        setMooFocus([]);
      }
    }

    if (e.name === 'ampur') {
      setFilterData({ ...filterData, ...{ ampur: v, tambon: null, moo: null } });
      if (v) {
        let x = [];
        tambonData.forEach(i => {
          if (e.value.amphur_id === i.addressid.substr(0, 4)) {
            x.push(i);
          }
        });
        setTambonFocus(x);
        setMooFocus([]);
      }
    }

    if (e.name === 'tambon') {
      setFilterData({ ...filterData, ...{ tambon: v, moo: null } });
      if (v) {
        let x = [];
        mooData.forEach(i => {
          if (e.value.addressid.substr(0, 6) === i.tmbpart) {
            x.push(i);
          }
        });
        setMooFocus(x);
      }
    }

    if (e.name === 'moo') {
      setFilterData({ ...filterData, ...{ moo: v } });
    }

  }

  const getChangwatData = async () => {
    let filter = {
      filter: {
        where: {
          or: [
            { changwatcode: "47" }, { changwatcode: "41" }, { changwatcode: "48" }, { changwatcode: "43" }, { changwatcode: "42" }, { changwatcode: "39" }, { changwatcode: "38" }
          ]
        },
        order: ["changwatname ASC"]
      }
    };
    let response = await API.getAll(filter, "cchangwats");
    if (response.status === 200) {
      let rd = response.data;
      setChangwatData(rd);
      getAmpurData(rd);
    }
  }

  const getAmpurData = async (param_changwatData) => {
    let filter = {
      filter: {
        where: {
          or: [
            { province_id: "47" }, { province_id: "41" }, { province_id: "48" }, { province_id: "43" }, { province_id: "42" }, { province_id: "39" }, { province_id: "38" }
          ]
        },
        order: ["province_id ASC", "amphur_name ASC"]
      }
    };
    let response = await API.getAll(filter, "amphurs");
    if (response.status === 200) {
      let rd = response.data;
      setAmpurData(rd);

      let currentChangwat = null;
      let currentAmpur = null;
      if (userinfo.user.role === "CM" || userinfo.user.role === "AdminTeams" || userinfo.user.role === "Teams") {
        if (typeof param_changwatData !== 'undefined') {
          param_changwatData.forEach(i => {
            if (i.changwatcode.toString() === userinfo.user.changwat.changwatcode.toString()) {
              currentChangwat = i;
            }
          });
        }

        let x = [];
        rd.forEach(i => {
          if (currentChangwat.changwatcode === i.province_id) {
            x.push(i);
          }
        });
        setAmpurFocus(x);

        rd.forEach(i => {
          if (i.amphur_id.toString() === userinfo.user.amphur.amphur_id.toString()) {
            currentAmpur = i;
          }
        });

        setFilterData({ ...filterData, ...{ changwat: currentChangwat, ampur: currentAmpur } });
      }
      setDefaultChangwat(currentChangwat);
      setDefaultAmpur(currentAmpur);
      getTambonData(currentAmpur);
    }
  }

  const getTambonData = async (currentAmpur) => {
    let filter = {
      filter: {
        where: {
          or: [
            { chwpart: "47" }, { chwpart: "41" }, { chwpart: "48" }, { chwpart: "43" }, { chwpart: "42" }, { chwpart: "39" }, { chwpart: "38" }
          ]
        },
        order: ["chwpart ASC", "amppart ASC", "name ASC"]
      }
    };
    let response = await API.getAll(filter, "tambols");
    if (response.status === 200) {
      let rd = response.data;
      setTambonData(rd);
      if (userinfo.user.role === "CM" || userinfo.user.role === "AdminTeams" || userinfo.user.role === "Teams") {
        let x = [];
        rd.forEach(i => {
          if (currentAmpur.amphur_id === i.addressid.substr(0, 4)) {
            x.push(i);
          }
        });
        setTambonFocus(x);
      }
      getMooData();
    }
  }

  const getMooData = async () => {
    let filter = {
      filter: {
        where: {
          or: [
            { villagecode: { like: "47.*", options: "i" } },
            { villagecode: { like: "41.*", options: "i" } },
            { villagecode: { like: "48.*", options: "i" } },
            { villagecode: { like: "43.*", options: "i" } },
            { villagecode: { like: "42.*", options: "i" } },
            { villagecode: { like: "39.*", options: "i" } },
            { villagecode: { like: "38.*", options: "i" } }
          ]
        },
        order: ["villagecode ASC"]
      }
    };
    let response = await API.getAll(filter, "villages");
    if (response.status === 200) {
      let x = response.data;
      x.forEach(i => {
        i['moo_no'] = parseInt(i.villagecode.substr(-2)).toString();
        i['chwpart'] = i.villagecode.substr(0, 2);
        i['amppart'] = i.villagecode.substr(0, 4);
        i['tmbpart'] = i.villagecode.substr(0, 6);
      });
      setMooData(x);
      // console.log(x);
    }
  }

  const filterChangeCheckBox = (event, var_name) => {
    const {
      target: { checked },
    } = event;
    setFilterData({
      ...filterData, ...{
        [var_name]: checked,
      }
    });
    if (var_name === 'nearDeadline') {
      if (checked) {
        // console.log('A--');
        setFilterDataFunctionEnable(true);
      }
      else {
        // console.log('B--');
        setFilterDataFunctionEnable(false);
      }
    }
  }

  const filterChangeCheckList = (event, var_name) => {
    const {
      target: { value },
    } = event;
    setFilterData({
      ...filterData, ...{
        [var_name]: typeof value === 'string' ? value.split(',') : value,
      }
    });
  }

  const caseStatusCode = (x) => {
    let r = [];
    switch (x) {
      case 'รายใหม่':
        r.push('new');
        r.push('renew');
        break;
      case 'มอบทีม':
        r.push('assignedTeam');
        break;
      case 'มอบ จนท.':
        r.push('assignedOfficer');
        break;
      case 'รับเคส':
        r.push('recievedCase');
        break;
      case 'ส่งCM':
        r.push('sentCM');
        break;
      case 'เสร็จสิ้น':
        r.push('completed');
        break;
      default:
        break;
    }
    return r;
  }

  const executeFilter = () => {
    let villagecode_where = '';
    if (filterData.moo) {
      villagecode_where = { villagecode: filterData.moo.villagecode };
    }
    else if (filterData.tambon) {
      villagecode_where = { villagecode: { like: filterData.tambon.chwpart + filterData.tambon.amppart + filterData.tambon.tmbpart + '.*' } };
    }
    else if (filterData.ampur) {
      villagecode_where = { villagecode: { like: filterData.ampur.amphur_id + '.*' } };
    }
    else if (filterData.changwat) {
      villagecode_where = { villagecode: { like: filterData.changwat.changwatcode + '.*' } };
    }

    let caseStatus_where_array = [];
    if (filterData.caseStatus.length > 0) {
      filterData.caseStatus.forEach(i => {
        caseStatusCode(i).forEach(w => {
          caseStatus_where_array.push({ caseStatus: w });
        });
      });
    }

    let riskLevel_where_array = [];
    if (filterData.riskLevel.length > 0) {
      filterData.riskLevel.forEach(i => {
        riskLevel_where_array.push({ riskLevel: i });
      });
    }

    let severityType_where = {};
    if (filterData.severityType.length > 0) {
      severityType_where = { severityType: { in: filterData.severityType } };
    }

    let query_where = {};
    let query_where_temp = {};
    let query_where_and = [];
    if (userinfo.user.role === 'CM') {
      query_where_temp = { amphur_id: userinfo.user.amphur.amphur_id };
    }
    else if (userinfo.user.role === 'AdminTeams') {
      let team_query = [];
      if (userinfo.user.teams.length > 0) {
        userinfo.user.teams.forEach(i => {
          team_query.push({ osccTeamId: { like: i.id, options: "i" } });
        });
        query_where_temp = { or: team_query };
      }
    }
    else if (userinfo.user.role === 'Teams') {
      query_where_temp = { officerId: { like: userinfo.user.id, options: "i" } };
    }

    if (typeof userinfo.user.coach !=='undefined') {
      if (userinfo.user.coach ==='OSCC') {
        query_where_temp = {};
      }
    }

    if (villagecode_where !== '') {
      query_where_and.push(villagecode_where);
    }
    if (caseStatus_where_array.length > 0) {
      query_where_and.push({ or: caseStatus_where_array });
    }
    if (riskLevel_where_array.length > 0) {
      query_where_and.push({ or: riskLevel_where_array });
    }
    if (Object.keys(severityType_where).length > 0) {
      query_where_and.push(severityType_where);
    }

    if (query_where_and.length > 0) {
      if (Object.keys(query_where_temp).length > 0) {
        query_where = { and: [query_where_temp, { and: query_where_and }] };
      }
      else {
        query_where = { and: query_where_and };
      }
    }
    else {
      query_where = query_where_temp;
    }

    setCollectionData({ ...collectionData, ...{ query_where: query_where } });
  }

  const cleanFilter = () => {
    setFilterData({
      changwat: defaultChangwat || null,
      ampur: defaultAmpur || null,
      tambon: null,
      moo: null,
      caseStatus: caseStatusName(props.location.state.casestatus) || [],
      severityType: [],
      riskLevel: [],
      nearDeadline: false,
    });
  }

  const toogleFilter = (x) => {
    if (x === 'show') {
      filterShowBtn.current.style.display = 'none';
      filterHideBtn.current.style.display = 'inline-block';
      filterBlock.current.style.display = 'block';
    }
    if (x === 'hide') {
      filterShowBtn.current.style.display = 'inline-block';
      filterHideBtn.current.style.display = 'none';
      filterBlock.current.style.display = 'none';
    }
  }

  const filterDataFunction = (x, xLimit, xSkip) => {
    // console.log('FilterDataFunction------B');
    let dataFiltered = [];
    let seq = 0;
    if (typeof x !== 'undefined') {
      if (x) {
        if (x.length > 0) {
          x.forEach(i1 => {
            let activity = 0;
            if (typeof i1.planservice !== 'undefined') {
              i1.planservice.forEach(i2 => {
                if (typeof i2.serviceslotList !== 'undefined') {
                  i2.serviceslotList.forEach(i3 => {
                    let remainingDays = moment(i3.endDate).diff(moment(), 'days');
                    if (remainingDays <= notificationDays) {
                      activity++;
                    }
                  });
                }
              });
            }
            if (activity > 0) {
              if (seq >= xSkip && seq < xSkip + xLimit) {
                dataFiltered.push(i1);
              }
              ++seq;
            }
          });
        }
      }
    }
    if (dataFiltered.length > 0) {
      return dataFiltered;
    }
    else {
      return x;
    }
  }

  useEffect(() => {
    if (isDesktop) {
      filterShowBtn.current.style.display = 'none';
      filterHideBtn.current.style.display = 'none';
      filterBlock.current.style.display = 'block';
    }
    else {
      filterShowBtn.current.style.display = 'none';
      filterHideBtn.current.style.display = 'inline-block';
      filterBlock.current.style.display = 'block';
    }
  }, [isDesktop]);

  useEffect(() => {
    // console.log(userinfo.user.coach);
    filterShowBtn.current.style.display = 'none';
    if (typeof props.location !== 'undefined') {
      if (typeof props.location.state !== 'undefined') {
        if (typeof props.location.state.status !== 'undefined') {
          getCollectionData();
          globalActions.setFocusedCaseStatus(props.location.state.status);
          localStorage.setItem("focusedCaseStatus", props.location.state.status);
          if (props.location.state.status === 'newCase') {
            setPageHeadText('รายใหม่');
          }
          if (props.location.state.status === 'sentMember') {
            setPageHeadText('รอเจ้าหน้าที่รับเคส');
          }
          if (props.location.state.status === 'onWork') {
            setPageHeadText('ระหว่างดูแล');
          }
          if (props.location.state.status === 'wait') {
            setPageHeadText('รอสรุป');
          }
          if (props.location.state.status === 'completed') {
            setPageHeadText('เสร็จสิ้น');
          }
          // --- สถานะเพิ่มเติมไม่ใช่สถานะจริง
          if (props.location.state.status === 'nearDeadLine') {
            setPageHeadText('กิจกรรมใกล้หมดเวลา');
          }

          getChangwatData();
        }
      }
    }

    // if (typeof userinfo.user.coach !== 'undefined') {
    //   if (userinfo.user.coach === 'OSCC') {
    //     getTambon();
    //     setDisplayTambon('block');
    //   }
    // }
  }, []); 

  return (
    <div style={{ marginBottom: 100 }}>
      <Grid container>

        <Grid item xs={12} sm={12} style={{ marginBottom: 5 }}>
          <Grid container direction={isDesktop ? "row" : "column-reverse"}>
            <Grid item xs={12} sm={6}>
              <h4>เคส : {pageHeadText}</h4>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container justify="flex-end">
                <ButtonGroup size={isDesktop ? 'large' : 'medium'} style={{ marginTop: 0, marginBottom: 0, borderRadius: 8, border: 'solid 4px #CDCDCD', padding: 0 }}>
                  <Button color="primary" style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
                    onClick={() => {
                      history.push({ pathname: '/' });
                    }}
                  >
                    กลับ
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Grid container direction={isDesktop ? "row" : "column-reverse"}>

            <Grid item xs={12} sm={10}>

              {/* <Grid container style={{ marginTop: -10, marginBottom: 10 }} justify="center" direction={isDesktop ? "row" : "column-reverse"}>
                <Grid item xs={12} sm={12} style={{ marginBottom: 5, display: displayTambon }}>
                  <Autocomplete
                    filterOptions={filterOptions}
                    id="tambon"
                    size="medium"
                    fullWidth
                    options={coachTambonData}
                    value={formData.tambon}
                    getOptionSelected={(option, value) => value.full_name === option.full_name}
                    getOptionLabel={(option) => option.full_name || ''}
                    onChange={(_, newValue) => {
                      ATCChange({ name: 'tambon', value: newValue });
                    }}
                    ListboxProps={{ style: { maxHeight: '200px' } }}
                    renderInput={(params) =>
                      <TextField {...params}
                        id="tambon_texfield"
                        name="tambon"
                        label="ตำบล อำเภอ จังหวัด"
                        variant="outlined"
                        onChange={(e) => ATCChange(e)}
                        style={{ textAlign: 'left' }}
                      />
                    }
                  />
                </Grid>
              </Grid> */}

              <Grid container justify="center">
                <Grid item xs={12} sm={12}>
                  {Object.keys(collectionData).length > 0 && (
                    <UDataTable structure={collectionData} xFactor={xFactor} filterDataFunction={filterDataFunction} filterDataFunctionEnable={filterDataFunctionEnable} />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={2} style={{ paddingLeft: isDesktop ? 10 : 0, marginBottom: 10, minWidth: isDesktop ? 160 : 'auto', maxWidth: isDesktop ? 160 : 'auto' }}>
              <Grid container style={{ border: 'solid 1px #CDCDCD', borderRadius: 4, padding: 5 }}>
                <Grid item xs={12} sm={12} style={{ background: '#E2E2E2', padding: 2, borderRadius: 4, textAlign: 'left', paddingLeft: 10, paddingRight: 10 }}>
                  <Grid container>
                    <Grid item xs={6} sm={10}>
                      กรองข้อมูล
                      {typeof userinfo.user.coach?(userinfo.user.coach==='OSCC'?<span style={{fontSize:14}}><br />(Coach)</span>:''):''}
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <div style={{ textAlign: 'right' }}>
                        <IconButton color="default" ref={filterShowBtn} onClick={() => toogleFilter('show')} style={{ display: 'inline-block', padding: 0, minWidth: 20, maxWidth: 20, minHeight: 20, maxHeight: 20 }}>
                          <MdAddIcon style={{ marginTop: -5 }} />
                        </IconButton>
                        <IconButton color="default" ref={filterHideBtn} onClick={() => toogleFilter('hide')} style={{ display: 'inline-block', padding: 0, minWidth: 20, maxWidth: 20, minHeight: 20, maxHeight: 20 }}>
                          <MdClearIcon style={{ marginTop: -5 }} />
                        </IconButton>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <div ref={filterBlock}>
                  <Grid container>
                    <Grid item xs={6} sm={12} style={{ paddingRight: isDesktop ? 0 : 3 }}>
                      <Autocomplete
                        // disabled={['CM', 'AdminTeams', 'Teams'].indexOf(userinfo.user.role) > -1 ? true : false}
                        disabled={['AdminR8'].indexOf(userinfo.user.role) > -1||userinfo.user.coach==='OSCC' ? false : true}
                        style={{ marginTop: 3 }}
                        filterOptions={filterOptions}
                        size="small"
                        fullWidth
                        options={changwatData}
                        value={filterData.changwat}
                        getOptionSelected={(option, value) => value.changwatname === option.changwatname}
                        getOptionLabel={(option) => option.changwatname || ''}
                        onChange={(_, newValue) => {
                          filterChange({ name: 'changwat', value: newValue });
                        }}
                        ListboxProps={{ style: { maxHeight: '200px' } }}
                        renderInput={(params) =>
                          <TextField {...params}
                            name="changwat"
                            label="จังหวัด"
                            variant="outlined"
                            onChange={(e) => filterChange(e)}
                            style={{ textAlign: 'left' }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={12}>
                      <Autocomplete
                        // disabled={['CM', 'AdminTeams', 'Teams'].indexOf(userinfo.user.role) > -1 ? true : false}
                        disabled={['AdminR8'].indexOf(userinfo.user.role) > -1||userinfo.user.coach==='OSCC' ? false : true}
                        style={{ marginTop: 3 }}
                        filterOptions={filterOptions}
                        size="small"
                        fullWidth
                        options={ampurFocus}
                        value={filterData.ampur}
                        getOptionSelected={(option, value) => value.amphur_name === option.amphur_name}
                        getOptionLabel={(option) => option.amphur_name || ''}
                        onChange={(_, newValue) => {
                          filterChange({ name: 'ampur', value: newValue });
                        }}
                        ListboxProps={{ style: { maxHeight: '200px' } }}
                        renderInput={(params) =>
                          <TextField {...params}
                            name="ampur"
                            label="อำเภอ"
                            variant="outlined"
                            onChange={(e) => filterChange(e)}
                            style={{ textAlign: 'left' }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={12} style={{ paddingRight: isDesktop ? 0 : 3 }}>
                      <Autocomplete
                        style={{ marginTop: 3 }}
                        filterOptions={filterOptions}
                        size="small"
                        fullWidth
                        options={tambonFocus}
                        value={filterData.tambon}
                        getOptionSelected={(option, value) => value.name === option.name}
                        getOptionLabel={(option) => option.name || ''}
                        onChange={(_, newValue) => {
                          filterChange({ name: 'tambon', value: newValue });
                        }}
                        ListboxProps={{ style: { maxHeight: '200px' } }}
                        renderInput={(params) =>
                          <TextField {...params}
                            name="tambon"
                            label="ตำบล"
                            variant="outlined"
                            onChange={(e) => filterChange(e)}
                            style={{ textAlign: 'left' }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={12}>
                      <Autocomplete
                        style={{ marginTop: 3 }}
                        filterOptions={filterOptions}
                        size="small"
                        fullWidth
                        options={mooFocus}
                        value={filterData.moo}
                        getOptionSelected={(option, value) => value.moo_no === option.moo_no}
                        getOptionLabel={(option) => option.moo_no || ''}
                        onChange={(_, newValue) => {
                          filterChange({ name: 'moo', value: newValue });
                        }}
                        ListboxProps={{ style: { maxHeight: '200px' } }}
                        renderInput={(params) =>
                          <TextField {...params}
                            name="moo"
                            label="หมู่"
                            variant="outlined"
                            onChange={(e) => filterChange(e)}
                            style={{ textAlign: 'left' }}
                          />
                        }
                      />
                    </Grid>

                    <Grid item xs={6} sm={12} style={{ paddingRight: isDesktop ? 0 : 3 }}>
                      <FormControl size="small" fullWidth style={{ marginTop: 3, width: isDesktop ? 137 : document.documentElement.clientWidth / 2 - 25 }}>
                        <InputLabel id="caseStatus_label">สถานะ</InputLabel>
                        <Select
                          fullWidth
                          labelId="caseStatus_label"
                          multiple
                          value={filterData.caseStatus}
                          onChange={(e) => filterChangeCheckList(e, 'caseStatus')}
                          input={<OutlinedInput label="caseStatus" />}
                          renderValue={(selected) => selected.join(', ')}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 30 * caseStatusData.length + 20,
                                width: 'auto',
                              }
                            }
                          }}
                        >
                          {caseStatusData.length > 0 && (
                            caseStatusData.map((i) => (
                              <MenuItem key={i.id} value={i.name} style={{ height: 30 }}>
                                <Checkbox
                                  checked={filterData.caseStatus.indexOf(i.name) > -1}
                                />
                                <ListItemText primary={i.name} />
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={12}>
                      <FormControl size="small" fullWidth style={{ marginTop: 3, width: isDesktop ? 137 : document.documentElement.clientWidth / 2 - 25 }}>
                        <InputLabel id="riskLevel_label">ระดับความรุนแรง</InputLabel>
                        <Select
                          labelId="riskLevel_label"
                          multiple
                          value={filterData.riskLevel}
                          onChange={(e) => filterChangeCheckList(e, 'riskLevel')}
                          input={<OutlinedInput label="riskLevel" />}
                          renderValue={(selected) => selected.join(', ')}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 30 * riskLevelData.length + 20,
                                width: 'auto',
                              }
                            }
                          }}
                        >
                          {riskLevelData.length > 0 && (
                            riskLevelData.map((i) => (
                              <MenuItem key={i.id} value={i.name} style={{ height: 30 }}>
                                <Checkbox
                                  checked={filterData.riskLevel.indexOf(i.name) > -1}
                                />
                                <ListItemText primary={i.name} />
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={12} style={{ paddingRight: isDesktop ? 0 : 3 }}>
                      <FormControl size="small" fullWidth style={{ marginTop: 3, width: isDesktop ? 137 : document.documentElement.clientWidth / 2 - 25 }}>
                        <InputLabel id="severityType_label">ประเภทความรุนแรง</InputLabel>
                        <Select
                          labelId="severityType_label"
                          multiple
                          value={filterData.severityType}
                          onChange={(e) => filterChangeCheckList(e, 'severityType')}
                          input={<OutlinedInput label="severityType" />}
                          renderValue={(selected) => selected.join(', ')}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 30 * severityTypeData.length + 20,
                                width: 'auto',
                              }
                            }
                          }}
                        >
                          {severityTypeData.length > 0 && (
                            severityTypeData.map((i) => (
                              <MenuItem key={i.id} value={i.name} style={{ height: 30 }}>
                                <Checkbox
                                  checked={filterData.severityType.indexOf(i.name) > -1}
                                />
                                <ListItemText primary={i.name} />
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={12}>
                      <div style={{ whiteSpace: 'nowrap' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filterData.nearDeadline}
                              onChange={(e) => filterChangeCheckBox(e, 'nearDeadline')}
                              style={{ marginRight: -10 }}
                            />
                          }
                          label="ใกล้หมดเวลา"
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Grid container justify="flex-end" style={{ marginTop: 3 }}>
                      <Button color="primary" variant="outlined" style={{ margin: 0, padding: 0, minWidth: 50, marginRight: 3 }} onClick={() => cleanFilter()}>
                        ล้าง
                      </Button>
                      <Button color="primary" variant="contained" style={{ margin: 0, padding: 0 }} onClick={() => executeFilter()}>
                        ค้นหา
                      </Button>
                    </Grid>
                  </Grid>
                </div>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
