import { fork, call, takeEvery } from 'redux-saga/effects';
import actions from '../../actions/activity';
import { GET_ASSIGNCASES } from '../../actions';
import { getAssignList } from '../../services/api';
import { fetchEntity } from '../saga';

const getEntity = fetchEntity.bind(null, actions.GetAssignCases, getAssignList);

function* callAction(action: any) {
  yield call(getEntity, { ...action });
}

function* watchAction() {
  yield takeEvery(GET_ASSIGNCASES.REQUEST, callAction);
}

export default function* root() {
  yield fork(watchAction);
}
