/* eslint-disable @typescript-eslint/no-unused-vars */
import { INSERT_CONCLUSION,UPDATE_OSCCPERSON } from '../../actions';
import Actions from '../../actions/activity';
import { snackbarFailure,snackbarSuccess } from '../../actions/notification';

import * as Api from '../../services/api';
import { fetchEntity } from '../saga';
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

const insertIntervention = fetchEntity.bind(null, Actions.InsertIntervention, Api.insertIntervention);
const insertConclusion = fetchEntity.bind(null, Actions.InsertConclusion, Api.insertConclusion);
const updateOsccperson = fetchEntity.bind(null,Actions.UpdateOsccperson, Api.updateOsccperson)
const getCases = fetchEntity.bind(null, Actions.GetCases, Api.getCases);

function* firstAction(action: any) {
  yield call(
    insertIntervention, action.data.insertIntervention.params,
    {
      onSuccess: (success: any) => {
        action.data.insertConclusion.params.interventionId=success.id
        return call(actioninsertConclusion, action);
      },
      onFailure: () => put(snackbarFailure('insertIntervention failed',1500))
    }
  );
}

function* actioninsertConclusion(action: any) {
  yield call(
    insertConclusion, action.data.insertConclusion.params,
    {
      onSuccess: (success: any) => {
        return call(actionUpdateOsccperson, action);
      },
      onFailure: () => put(snackbarFailure('insertConclusion failed',1500))
    }
  );
}

function* actionUpdateOsccperson(action: any) {
  yield call(
    updateOsccperson, { data: action.data.updateOsccperson.params },
    {
      onSuccess: (success: any) => {    
        return call(actionGetCases, action);
        // return put(snackbarSuccess(action.data.insertIntervention.successMessage,3000))
      },
      onFailure: () => put(snackbarFailure('updateOsccperson failed',1500))
    }
  );
}

function* actionGetCases(action: any) {
  // console.log("actionGetCases",action.data.getCases);
  yield call(
    getCases, action.data.getCases ,
    {
      onSuccess: (success: any) => {    
        // console.log("success",success);
        return put(snackbarSuccess(action.data.insertIntervention.successMessage,3000))
      },
      onFailure: (error:any) => {
        // console.log("onFailure",error);
        return put(snackbarFailure('getCases failed',1500));
      }
    }
  );
}


// firstAction
function* watchFirstRequest() {
  yield takeLatest(INSERT_CONCLUSION.REQUEST, firstAction);
}

export default function* root() {
  yield fork(watchFirstRequest);
}
