/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  makeStyles
} from '@material-ui/core/styles';
import * as UAPI from "../../services/UniversalAPI";
import * as ICONS from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { getCurrentUser } from "../../services/auth.service";
import {
  Button,
  TextField,
  CircularProgress,
  Backdrop,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  // const useStyles = makeStyles({
  TextField: {
    minHeight: 30,
    padding: 0,
    paddingLeft: 5,
  },
  TextFieldScore: {
    minHeight: 30,
    padding: 0,
    paddingLeft: 0,
    textAlign: 'center'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  tdQDetail: {
    verticalAlign: 'top',
  },
  thead: {
    backgroundColor: '#cdedff'
  },
  trA: {
    backgroundColor: 'transparent'
  },
  trB: {
    backgroundColor: '#efefef'
  }
}));

export default function TeamLists(props) {
  const userinfo = getCurrentUser();
  const history = useHistory();
  const classes = useStyles();
  const [data, setData] = useState({});

  const [qCount, setQCount] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [focusMemberIndex, setFocusMemberIndex] = useState(null);

  const [memberList, setMemberList] = useState([]);
  const [officerData, setOfficerData] = useState(null);
  const [newMemberRole, setNewMemberRole] = useState('');
  const [newMemberFullName, setNewMemberFullName] = useState('');
  const [newMemberHosName, setNewMemberHosName] = useState('');
  const [newMemberId, setNewMemberId] = useState('');
  const [newMemberHcode, setNewMemberHcode] = useState('');
  

  const checkObjectData = (x) => {
    let r = [];
    if (typeof x !== 'undefined') {
      if (x !== null) {
        r = x;
      }
    }
    return r;
  }

  const checkArrayData = (x) => {
    let r = [];
    if (typeof x !== 'undefined') {
      if (x !== null) {
        r = x;
      }
    }
    return r;
  }

  const clickSave = () => {
    setOpenBackdrop(true);
    let x = data;
    x['memberList'] = memberList;
    // console.log(x);
    handleSave(x);
  }

  const handleSave = async (x) => {
    let response = await UAPI.update(x.id, x, 'osccteams', '3009');
    if (response.status === 200) {
      if (response.data) {
        setData(response.data);
        setTimeout(() => {
          setOpenBackdrop(false);
        }, 1000);
      }
    }
  }

  const closeBackdrop = () => {
    setOpenBackdrop(false);
  }

  const findRoleName = (x) => {
    let n = parseInt(x);
    let r = '';
    switch (n) {
      case 1:
        r = 'หัวหน้าทีม';
        break;

      case 2:
        r = 'สมาชิก';
        break;

      default:
        break;
    }
    return r;
  }

  const DynIcon = (props) => {
    const { icon, ...other } = props;
    const X = ICONS[icon];
    return (
      <span {...other}>
        <X size={20} />
      </span>
    );
  }

  const clickDeleteMember = (e, i) => {
    let x = [...memberList];
    let index = x.indexOf(i);
    if (index > -1) {
      x.splice(index, 1);
    }
    setMemberList(x);
    setQCount(x.length);
  }

  const clickEditMember = (e, i) => {
    let x = [...memberList];
    let index = x.indexOf(i);
    setFocusMemberIndex(index);

    let z = JSON.parse(JSON.stringify(i)); // การ CLONE ที่ดีกว่า {...i}
    setNewMemberId(z.id);
    setNewMemberRole(z.memberRole);
    changeNewMember(z.id);
  }

  const mkMemberList = () => {
    let q = [...memberList];
    let qList = [];
    qList.push(<tr key={0}><td colSpan={6} style={{ height: 0 }}></td></tr>);
    let n = 0;
    q.forEach(i => {
      ++n;
      let tr = [];
      let trClass = (n % 2 === 0 ? classes.trA : classes.trB);
      tr.push(<td key={'l'} style={{ borderTopLeftRadius: 20, borderBottomLeftRadius: 20, width: 20 }}></td>);
      tr.push(<td className={classes.tdQDetail} key={'n'}>{n}</td>);
      tr.push(<td className={classes.tdQDetail} key={'member'}>
        {i.fullname}
        {typeof i.department != 'undefined'? ' ('+i.department.hos_name+')':''}
      </td>);
      tr.push(<td className={classes.tdQDetail} key={'role'}>{findRoleName(i.memberRole)}</td>);
      tr.push(
        <td className={classes.tdQDetail} key={'action'}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white' }}
            startIcon={<DynIcon icon='MdEdit' style={{ marginLeft: 11, marginTop: -2 }} />}
            onClick={(e) => clickEditMember(e, i)}
          />
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white' }}
            startIcon={<DynIcon icon='MdDelete' style={{ marginLeft: 11, marginTop: -2 }} />}
            onClick={(e) => clickDeleteMember(e, i)}
          />
        </td>
      );
      tr.push(<td key={'r'} style={{ borderTopRightRadius: 20, borderBottomRightRadius: 20, width: 20 }}></td>);
      qList.push(<tr key={n} className={trClass}>{tr}</tr>);
    });
    qList.push(<tr key={n + 1}><td colSpan={6} style={{ height: 0 }}></td></tr>);
    qList.push(<tr key={n + 2}><td colSpan={6} style={{ textAlign: 'right', padding: 0, paddingRight: 10, paddingBottom: 15, borderTop: 'solid 1px #A0A0A0' }}>สมาชิกทั้งหมด {qCount} คน</td></tr>);
    return qList;
  }

  const clickAddMember = () => {
    if (focusMemberIndex === null) {
      let n = {
        id: newMemberId,
        fullname: newMemberFullName,
        memberRole: newMemberRole,
        memberRoleName: findRoleName(newMemberRole),
        hcode: newMemberHcode,
        hos_name: newMemberHosName,
      };
      let x = memberList;
      x.push(n);
      setMemberList(x);
      setQCount(x.length);
    }
    else {
      let x = [...memberList];
      x[focusMemberIndex].id = newMemberId;
      x[focusMemberIndex].fullname = newMemberFullName;
      x[focusMemberIndex].memberRole = newMemberRole;
      x[focusMemberIndex].memberRoleName = findRoleName(newMemberRole);
      x[focusMemberIndex].hcode = newMemberHcode;
      x[focusMemberIndex].hos_name = newMemberHosName;
      setMemberList(x);
    }
    setNewMemberId('');
    setNewMemberFullName('');
    setNewMemberRole('');
    setNewMemberHcode('');
    setNewMemberHosName('');
    setFocusMemberIndex(null);
  }

  const changeNewMember = (id) => {
    officerData.forEach(i => {
      if (i.id===id) {
        let hcode='';
        let hos_name='';
        if (typeof i.department != 'undefined') {
          hcode=i.department.hcode;
          hos_name=i.department.hos_name;
        }
        setNewMemberId(i.id);
        setNewMemberFullName(i.fullname);
        setNewMemberHcode(hcode);
        setNewMemberHosName(hos_name);
      }
    });
  }

  const mkOfficerSelect = () => {
    return(<select
      style={{ width: 400 }}
      value={newMemberId}
      onChange={(e)=>changeNewMember(e.target.value)}
    >
      {mkOfficerOption()}
    </select>);
  }

  const mkOfficerOption = () => {
    let r=[];
    r.push(<option key={''} value={''}>--เลือก--</option>);
    let n=0;
    if (officerData) {
      officerData.forEach(i => {
        ++n;
        r.push(
          <option key={n} value={i.id}>
            {i.fullname} 
            {typeof i.department != 'undefined'? ' ('+i.department.hos_name+')':''}
          </option>
        );
      });
    }
    return r;
  }

  const getOfficers = async () => {
    let xParams = {
      filter: {
        limit: 20,
        where: { 'amphur.amphur_id': userinfo.user.amphur.amphur_id },
        // skip: xSkip,
        // include: ["hospital", "person"],
        // where: {
        //   and: andQuery
        // },
      }
    };
    let response = await UAPI.getAll(xParams, 'teamusers', '3009');
    if (response.status === 200) {
      if (response.data) {
        setOfficerData(response.data);
        // console.log(response.data);
      }
    }
  }

  const changeNewMemberRole = (e) => {
    setNewMemberRole(e.target.value);
  }

  useEffect(() => {
    getOfficers();
  }, []); 

  useEffect(() => {
    let x = checkObjectData(props.location.state);
    setData(x);
    let z = checkArrayData(x.memberList);
    setMemberList(z);
    setQCount(z.length);
  }, [props]); 

  return (
    <div style={{ marginBottom: 100 }}>

      <Backdrop className={classes.backdrop} open={openBackdrop} onClick={closeBackdrop}>
        {/* <Backdrop className={classes.backdrop} open={true}> */}
        <CircularProgress color="inherit" />
      </Backdrop>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
        <div>
          <h4>แก้ไขสมาฃิกทีม : {props.location.state.questionnaire_name ? props.location.state.questionnaire_name : ''}</h4>
        </div>
        <div>
          <Button variant="contained" color="primary" style={{ marginRight: 5 }} onClick={() => { history.goBack() }}>
            กลับ
          </Button>
          <Button variant="contained" color="primary" onClick={clickSave}>
            บันทึก
        </Button>
        </div>
      </div>

      <div>
        <table cellSpacing={0} cellPadding={4} border={0}>
          <thead>
            <tr className={classes.thead}>
              <td style={{ borderTopLeftRadius: 20, borderBottomLeftRadius: 20, width: 20 }}></td>
              <td>ลำดับ</td>
              <td>สมาชิก</td>
              <td>หน้าที่</td>
              <td><br /></td>
              <td style={{ borderTopRightRadius: 20, borderBottomRightRadius: 20, width: 20 }}></td>
            </tr>
          </thead>
          <tbody>
            {mkMemberList()}
            <tr>
              <td><br /></td>
              <td><br /></td>
              <td style={{ verticalAlign: 'top' }}>
                <div>เจ้าหน้าที่</div>
                {mkOfficerSelect()}
              </td>
              <td style={{ verticalAlign: 'top' }}>
                <div>หน้าที่</div>
                <select
                  style={{ width: 200 }}
                  // value={getYear(date)} 
                  value={newMemberRole}
                  onChange={changeNewMemberRole}
                >
                  <option value={''}>--เลือก--</option>
                  <option value={1}>หัวหน้าทีม</option>
                  <option value={2}>สมาชิก</option>
                </select>
              </td>
              <td style={{ verticalAlign: 'top' }}>
                <div><br /></div>
                <Button variant="contained" color="primary"
                  onClick={clickAddMember}
                >
                  เพิ่ม/แก้ไข
                </Button>
              </td>
              <td><br /></td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  )
}
