import { combineReducers } from "redux";
import teamsList from "./getTeams";
import usersList from "./getTeamsUsers";
import insertTeams from "./insertTeams";
import updateTeams from "./updateTeams";
import deleteTeams from "./deleteTeams";
import updateTeamusers from "./updateTeamusers";
import deleteToken from "./deleteToken";
import usersListTambon from "./getTeamsUsersTambon";
export default combineReducers({
  teamsList,
  usersListTambon,
  usersList,
  insertTeams,
  updateTeams,
  deleteTeams,
  updateTeamusers,
  deleteToken
});
