/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Button,
  Grid,
  IconButton
} from '@material-ui/core';
import {
  MdCheck as MdCheckIcon,
  MdClose as MdCloseIcon,
  MdCheckBox as MdCheckBoxIcon,
  MdCheckBoxOutlineBlank as MdCheckBlankIcon
} from "react-icons/md";
import { useHistory } from "react-router-dom";
import * as UAPI from "../../services/UniversalAPI";

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
    '& .MuiInputLabel-outlined': {
      zIndex: 1,
      transform: 'translate(15px, 4px) scale(1)',
      pointerEvents: 'none'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(15px, -18px) scale(0.75)',
    },
    '& .Mui-error .MuiInputBase-input': { color: '#f44336' }
  },
  p: {
    textIndent: 50,
  }

}));

const ConsentServiceSlot = (props) => {
  const { t } = props;
  const redirect = useHistory();
  const classes = useStyles();
  const [CLstatus, setCLstatus] = useState({});
  const [CLDetailwatched, setCLDetailwatched] = useState({});
  const [countAllConsent, setCountAllConsent] = useState(0);
  const [countConsented, setCountConsented] = useState(0);
  const [disabledButtonAccept, setDisabledButtonAccept] = useState(true);
  const [informConsentList, setInformConsentList] = useState([]);


  const showConsentList = useMemo(() => {
    const changeCLstatus = (n, x) => {
      let c = JSON.parse(JSON.stringify(CLstatus));
      if (x === 1) {
        if (c[n] === null || c[n] === false) {
          setCLstatus({ ...c, ...{ [n]: true } });
        }
        else {
          setCLstatus({ ...c, ...{ [n]: false } });
        }
      }
      else {
        setCLstatus({ ...c, ...{ [n]: false } });
      }
    }
    const toggleDetailWatched = (n) => {
      let c = JSON.parse(JSON.stringify(CLDetailwatched));
      if (typeof c[n] === 'undefined') {
        setCLDetailwatched({ ...c, ...{ [n]: true } });
      }
      else {
        if (c[n] === false) {
          setCLDetailwatched({ ...c, ...{ [n]: true } });
        }
        else {
          setCLDetailwatched({ ...c, ...{ [n]: false } });
        }
      }
    }

    let x = [];
    let informConsentList = [];

    let editing = false;
    if (typeof props.consentData !== 'undefined') {
      if (props.consentData) {
        if (typeof props.consentData.result !== 'undefined') {
          editing = true;
        }
      }
    }

    let informData_heading = [];
    let informData_detail = [];
    if (typeof props.informData !== 'undefined') {
      if (props.informData) {
        if (typeof props.informData.heading !== 'undefined') {
          if (!editing) {
            informData_heading = props.informData.heading;
            informData_detail = props.informData.detail;
          }
          else {
            for (const [k, v] of Object.entries(props.informData.heading)) {
              informData_heading.push(k);
            };
            for (const [k, v] of Object.entries(props.informData.detail)) {
              informData_detail.push(k);
            };
          }
        }
      }
    }

    let consentData_heading = [];
    let consentData_detail = [];
    if (typeof props.consentData !== 'undefined') {
      if (props.consentData) {
        if (typeof props.consentData.heading !== 'undefined') {
          if (!editing) {
            consentData_heading = props.consentData.heading;
            consentData_detail = props.consentData.detail;
          }
          else {
            for (const [k, v] of Object.entries(props.consentData.heading)) {
              consentData_heading.push(k);
            };
            for (const [k, v] of Object.entries(props.consentData.detail)) {
              consentData_detail.push(k);
            };
          }
        }
      }
    }

    if (informData_heading.length > 0) {
      let i_h = 0;
      informData_heading.forEach(o_h => {
        let i_d = 0;
        informData_detail.forEach(o_d => {
          if (i_h === i_d) {
            informConsentList.push({ type: 'inform', heading: o_h, detail: o_d });
          }
          ++i_d;
        });
        ++i_h;
      });
    }

    if (consentData_heading.length > 0) {
      let i_hc = 0;
      consentData_heading.forEach(o_hc => {
        let i_dc = 0;
        consentData_detail.forEach(o_dc => {
          if (i_hc === i_dc) {
            informConsentList.push({ type: 'consent', heading: o_hc, detail: o_dc });
          }
          ++i_dc;
        });
        ++i_hc;
      });
    }

    setInformConsentList(informConsentList);

    if (informConsentList.length > 0) {
      informConsentList.forEach(i => {
        let k = i.heading;
        let headingText = t(i.heading);
        let detailText = t(i.detail);
        let borderColor = '#dd4a4a';
        let colorAccept = '#cecece';
        let colorRefuse = '#cecece';
        if (typeof CLstatus[k] !== 'undefined') {
          if (CLstatus[k] === null) {
            borderColor = '#dd4a4a';
            colorAccept = '#cecece';
            colorRefuse = '#cecece';
          }
          else if (CLstatus[k] === true) {
            borderColor = '#00f017';
            colorAccept = '#00f017';
            colorRefuse = '#cecece';
          }
          else if (CLstatus[k] === false) {
            borderColor = '#dd4a4a';
            colorAccept = '#cecece';
            colorRefuse = '#dd4a4a';
          }
        }

        x.push(
          <Grid key={UAPI.genKey()} container justify="space-evenly"
            style={{ border: 'solid 2px #EEEEEE', borderRadius: 5, marginBottom: 10, padding: 15, borderColor: borderColor }}
          >
            <Grid item xs={12} sm={10}>
              <div dangerouslySetInnerHTML={{ __html: headingText }} />
              <div>
                <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => toggleDetailWatched(k)}>
                  {CLDetailwatched[k] === true ? '[' + t('Hide details') + ']' : '[' + t('Show details') + ']'}
                </span>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: detailText }}
                style={{
                  display: CLDetailwatched[k] === true ? 'block' : 'none'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <Grid container justify="flex-end">
                {(CLstatus[k] === null && CLDetailwatched[k] === false) && (
                  <Button variant="outlined"
                    style={{ height: 40, marginLeft: 5, minWidth: 70, maxWidth: 70, border: 'solid 2px ' + colorAccept, color: colorAccept, fontSize: 25 }}
                    onClick={() => toggleDetailWatched(k)}
                  >
                    {'\u{1F441}'}
                  </Button>
                )}
                {(CLstatus[k] !== null || CLDetailwatched[k] === true) && (
                  <Button variant="outlined"
                    style={{ height: 40, marginLeft: 5, minWidth: i.type === 'inform' ? 70 : 30, maxWidth: i.type === 'inform' ? 70 : 30, border: 'solid 2px ' + colorAccept, color: colorAccept, fontSize: 25 }}
                    onClick={() => changeCLstatus(k, 1)}
                  >
                    {'\u{2713}'}
                  </Button>
                )}
                {(CLstatus[k] !== null || CLDetailwatched[k] === true) && (
                  <Button variant="outlined"
                    style={{ height: 40, marginLeft: 5, minWidth: 30, maxWidth: 30, display: i.type === 'inform' ? 'none' : 'button', border: 'solid 2px ' + colorRefuse, color: colorRefuse, fontSize: 25 }}
                    onClick={() => changeCLstatus(k, -1)}
                  >
                    {'\u{2717}'}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        );
      });
      return x;
    }
  }, [props.informData, props.consentData, CLstatus, CLDetailwatched])

  const changeResult = async (x) => {
    let result = x === true ? 'consented' : 'notConsent';
    let xCLstatus = JSON.parse(JSON.stringify(CLstatus));
    let xInformConsentList = JSON.parse(JSON.stringify(informConsentList));
    let inform = {};
    let inform_heading = {};
    let inform_detail = {};
    let consent = {};
    let consent_heading = {};
    let consent_detail = {};
    xInformConsentList.forEach(i => {
      if (i.type === 'inform') {
        inform_heading[i.heading] = xCLstatus[i.heading];
        inform_detail[i.detail] = xCLstatus[i.heading];
      }
      if (i.type === 'consent') {
        consent_heading[i.heading] = xCLstatus[i.heading];
        consent_detail[i.detail] = xCLstatus[i.heading];
      }
    });
    inform['heading'] = inform_heading;
    inform['detail'] = inform_detail;
    consent['heading'] = consent_heading;
    consent['detail'] = consent_detail;
    consent['result'] = result;
    console.log(inform);
    console.log(consent);
    let editing=false;
    // console.log(props);
    if (typeof props.consentData !=='undefined') {
      if (typeof props.consentData.result !=='undefined') {
        editing=true;
      }
    }
    // console.log(props.activityData.serviceslotId);
    if (editing) {
      let interventionData = props.activityData.interventionData;
      interventionData.activities.homeservice.forEach(i1 => {
        if (i1.serviceType==="oscc") {
          i1.questionnaire.forEach(i2 => {
            if (i2.planServiceId===props.activityData.planServiceId && i2.sequenceId===props.activityData.sequenceId) {
              i2['inform']=inform;
              i2['consent']=consent;
            }
          });
        }
      });
      // console.log(interventionData.id);
      // console.log(interventionData);
      let responseUpdate = await UAPI.update(interventionData.id, interventionData, 'interventions', '3009');
      if (responseUpdate.status === 200) { 
        if (responseUpdate.data) {
          // console.log(responseUpdate.data);
          // console.log('------------A01');
          closeConsent();
        }
      }
    }else {
      // console.log('------------A02');
      props.goQuestionnaire(inform, consent);
      closeConsent();
    }
  }

  const closeConsent = () => {
    props.setDialogOpen(false);
    setCLstatus({});
    setCLDetailwatched({});
    setCountAllConsent(0);
    setCountConsented(0);
    setDisabledButtonAccept(true);
  }

  const getUserConsentData = async () => {
    let filter = {
      filter: {
        where: {
          id: props.formData.id
        }
      }
    };
    let response = await UAPI.getAll(filter, "teamusers");
    if (response.status === 200) {
      console.log(response.data);
      if (response.data.length > 0) {
        if (typeof response.data[0].consent !== 'undefined') {
          if (typeof response.data[0].consent.detail !== 'undefined') {
            setCLstatus(response.data[0].consent.detail);
          }
        }
      }
    }
  }

  useEffect(() => {
    setCLstatus({});
    setCLDetailwatched({});
    setDisabledButtonAccept(true);
  }, []);

  useEffect(() => {
    // console.log(props);
    if (props.informData && props.consentData) {
      if (Object.keys(props.informData).length > 0 || Object.keys(props.consentData).length > 0) {
        // console.log(props.informData);
        // console.log(props.consentData);
        // let c = JSON.parse(JSON.stringify(CLstatus));
        // let w = JSON.parse(JSON.stringify(CLDetailwatched));
        let c = {};
        let w = {};
        if (typeof props.consentData.result !== 'undefined') {
          if (Object.keys(props.informData).length > 0) {
            for (const [k, v] of Object.entries(props.informData.heading)) {
              c[k] = v;
              w[k] = false;
            };
          }
          if (Object.keys(props.consentData).length > 0) {
            for (const [k, v] of Object.entries(props.consentData.heading)) {
              c[k] = v;
              w[k] = false;
            };
          }
        }
        else {
          if (Object.keys(c).length === 0) {
            if (Object.keys(props.informData).length > 0) {
              props.informData.heading.forEach(i => {
                c[i] = null;
                w[i] = false;
              });
            }
            if (Object.keys(props.consentData).length > 0) {
              props.consentData.heading.forEach(i => {
                c[i] = null;
                w[i] = false;
              });
            }
          }
        }
        setCLstatus(c);
        setCLDetailwatched(w);
        setCountAllConsent(Object.keys(c).length);
      }
    }
  }, [props]);

  useEffect(() => {
    let c = JSON.parse(JSON.stringify(CLstatus));
    let cCountAll = Object.keys(c).length;
    if (cCountAll > 0) {
      let cCountConsented = 0;
      for (const [k, v] of Object.entries(c)) {
        if (v === true) {
          ++cCountConsented;
        }
      }
      setCountConsented(cCountConsented);
      if (cCountAll === cCountConsented) {
        // console.log(cCountAll, 'PASS!');
        setDisabledButtonAccept(false);
      }
      else {
        setDisabledButtonAccept(true);
      }
    }
    setCountAllConsent(cCountAll);
  }, [CLstatus]);

  return (
    <div>
      <Dialog fullWidth={true} maxWidth={"lg"} open={props.dialogOpen}>
        <DialogTitle disableTypography>
          <div style={{ textAlign: 'right', padding: 0, margin: -20 }}>
            <IconButton aria-label="close" color="default" onClick={closeConsent} >
              <MdCloseIcon />
            </IconButton>
          </div>
          <h3>
            {t('Inform and consent')}
          </h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>
            <div>
              <Grid container justify="space-between">
                <Grid item xs={12} sm={12}>
                  {showConsentList}
                </Grid>
              </Grid>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Grid container justify="center">
              <Grid item xs={12} sm={2} style={{ padding: 5 }}>
                <Button variant="contained" color="primary" fullWidth
                  startIcon={<MdCheckIcon />}
                  onClick={() => changeResult(true)}
                  disabled={disabledButtonAccept}
                >
                  {t('Accept')} ({countConsented}/{countAllConsent})
                </Button>
              </Grid>
              <Grid item xs={12} sm={2} style={{ padding: 5 }}>
                <Button variant="contained" fullWidth
                  startIcon={<MdCloseIcon />}
                  onClick={() => changeResult(false)}
                >
                  {t('Not accept')}
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConsentServiceSlot;
