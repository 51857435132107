/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { UPDATE_CASES } from "../index";

const updateCase = {
  request: (payload:any) => action(UPDATE_CASES.REQUEST, {where: payload.where, data:payload.data}),
  success: (response: any) => action(UPDATE_CASES.SUCCESS, { data: response }),
  failure: (error: any) => action(UPDATE_CASES.FAILURE, { error }),
};

export default updateCase;