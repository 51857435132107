import { combineReducers } from "redux";
import questionaireList from "./getQuestionaire";
import personList from "./getPerson";
import caseList from "./getCases";
import caseListExcel from "./getCasesExcel";
import assignCasesList from "./getAssignCase";
import assignGroupList from "./getAssignGroup";
import groupList from "./getGroupList";
import insertGroup from "./insertGroup";
import updateGroup from "./updateGroup";
import deleteGroup from "./deleteGroup";
import insertAssign from "./insertAssign";
import updateAssign from "./udpateAssign";
import deleteAssign from "./deleteAssign";
import deleteAssignGroup from "./deleteAssignGroup";
import returnCaseGroup from "./returnCaseGroup";
import updateCase from "./updateCase";
import updateCases from "./updateCases";
import getAnswer from "./getAnswer";
import insertAnswer from "./insertAnswer";
import updateAnswer from "./updateAnswer";
import deleteAnswer from "./deleteAnswer";
import sendCM from "./sendCM";
import groupListTeams from "./getGroupListTeams";
import insertConclusion from "./insertConclusion";
import updateConclusion from "./updateConclusion";
import deleteConclusion from "./deleteConclusion";
import insertIntervention from "./insertIntervention";
import updateIntervention from "./updateIntervention";
import deleteIntervention from "./deleteIntervention";
import updateOsccperson from "./updateOsccperson";
import insertOsccperson from "./insertOsccperson";

export default combineReducers({
  questionaireList,
  personList,
  caseList,
  caseListExcel,
  updateCase,
  updateCases,
  assignCasesList,
  assignGroupList,
  groupList,
  groupListTeams,
  insertGroup,
  updateGroup,
  deleteGroup,
  insertAssign,
  updateAssign,
  deleteAssign,
  deleteAssignGroup,
  returnCaseGroup,
  getAnswer,
  insertAnswer,
  updateAnswer,
  deleteAnswer,
  sendCM,
  insertConclusion,
  updateConclusion,
  deleteConclusion,
  insertIntervention,
  updateIntervention,
  deleteIntervention,
  updateOsccperson,
  insertOsccperson
});
