/* eslint-disable react-hooks/exhaustive-deps */
import React,{FC, useEffect,useState,useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../reducers";
import Actions from '../../actions/activity';
import { Button, Grid,Typography, useTheme } from "@material-ui/core";
import CaseStatus from './caseStatus';
import QuestionaireList from './questionairList';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { log } from '../../utils';
interface IProps {
  state: {
    data: Record<string,any>;
    group: boolean;
    readOnly: boolean;
  }
}
type TProps = IProps;

interface stateType {
  group: boolean;
  data: Record<string,any>;
  caseGroupId?: string;
  userRoles?: string[];
  groupData?:any;
}

const Activity: FC<TProps> = (props) => {
  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const { state } = useLocation<stateType>();
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
  const [startEffect,setStartEffect] = useState(true);

  const caseTeamCode = state.data.osccperson_assign[0]?.teams.teamCode;
  const userInfo = globalState.auth.user.response.user;
  const userTeams = userInfo?.teams;
  const role = userTeams.filter((f:any)=>f.teamCode===caseTeamCode).map((m:any)=>m.role)[0];

  const getQN = useCallback(()=>{
    const mixState = state;
    const QT = mixState.data.groupQuestionnaireType || "PLH2";
    let param: any = {
      "fields":{
        "id":true,
        "questionnaire_type": true,
        "questionnaire_group": true,
        "questionnaire_repeat": true,
        "questionnaire_view": true,
        "questionnaire_manage": true,
        "questionnaire_code":true, 
        "questionnaire_name":true, 
        "questionsList":true,
        "questionnaire_datagrid_column":true,
        "questionnaire_layout": true,
        "disabled":true,
      },
      "where":{
        "id": { "inq":  (mixState.group) ? 
          mixState.data.osccperson_assign.filter((f:any)=>f.type==="group")[0].questionaire.map((m:any)=>m.id): 
          mixState.data.osccperson_assign[0].questionaire.map((m:any)=>m.id)}
      },
      "include":{
        "relation": "questionnaire_answer",
        "scope": {
          "where": {
            [(!mixState.group)?"case_id":"group_id"] : mixState.data.id
          }
        }
      }
    }
    if(!["Coach","Assessor","Organization","AdminR8"].includes(role as string)){
      if(mixState.group){
        if(mixState.data.caseStatus && mixState.data.caseStatus!=="waitConclusion"){ // กรณีไม่ใช่หน้าสรุปเคส
          param.where["questionnaire_group"]=mixState.group;
          param.where["questionnaire_manage"]={"inq":[role]};
        }else{
          param.include.scope.where = {or: []};
          if (mixState.data.groupId) {
            param.include.scope.where.or.push({ group_id: mixState.data.groupId });
          }
          param.include.scope.where.or.push({ case_id: mixState.data.id });
          param.where["questionnaire_manage"]={"inq":[role]};
          // param.where["questionnaire_manage"]={"inq":[role]};
        }
      }else{
        if(mixState.data.caseStatus!=="waitConclusion"){
          param.where["questionnaire_group"]=mixState.group;
          param.where["questionnaire_view"]={"inq":[role]};
        }else{
          param.include.scope.where = {
            or: [
              {case_id: mixState.data.id},
              {group_id: mixState.data.groupId}
            ]
          }
        }
      }
    }else if(role==="AdminR8"){
      param.where["questionnaire_group"]=mixState.group;
      param.where["questionnaire_manage"]={"inq":[role]};
    }else{
      param.where["questionnaire_group"]=mixState.group;
      param.where["questionnaire_view"]={"inq":[role]};
    }
    // console.log(role,param);
    dispatch(Actions.GetQuestionnaire.request(param));
  },[dispatch, role, state, props.state]);

  useEffect(() => {
    if(startEffect){
      getQN();
    }
    return ()=>{setStartEffect(false)}
  },[getQN,startEffect]);
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Typography variant="h4">รายการแบบสอบถามที่ต้องดำเนินการ</Typography>
      </Grid>
      <Grid item xs={12} md={6} style={{display:'flex',justifyContent:'end'}}>
        <Button variant="outlined" color="primary" 
          onClick={ ()=>history.push({
            pathname:(state.group)?"/activity/groups":"/activity/case",
            state
          })}>
          กลับ
        </Button>
      </Grid>
      <Grid item xs={12}>
        <QuestionaireList refresh={getQN}/>
      </Grid>
    </Grid>
  );
}

export default Activity;