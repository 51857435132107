/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { logout } from "../../services/auth.service";
import useGlobal from "../../store";
import { logoutAction } from '../../actions/users';

const Logout = () => {
  const [globalState, globalActions] = useGlobal();
  const dispatch = useDispatch();
  const redirect = useHistory();
  useEffect(() => {
    const handleLogout = async () => {
      try {
        const logoutResponse = await logout();
        if(logoutResponse.status===204) {
          globalActions.resetGlobalState();
          dispatch(logoutAction.request());
        }
      } catch (error) {
        globalActions.resetGlobalState();
        dispatch(logoutAction.request());
      }
    };
    if(globalState.loginStatus===true){
      handleLogout();
    }
  }, [globalActions, globalState.loginStatus, redirect]);

  return <></>;
};
export default Logout;