/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { DELETE_ASSIGN_GROUP } from "../index";

const deleteAssignGroup = {
  request: (payload:any) => action(DELETE_ASSIGN_GROUP.REQUEST, payload),
  success: (response: any) => action(DELETE_ASSIGN_GROUP.SUCCESS, { data: response }),
  failure: (error: any) => action(DELETE_ASSIGN_GROUP.FAILURE, { error }),
};

export default deleteAssignGroup;