export const ConsentRegisterDataSet = {
    child: {
        consent: {
            heading: [],
            detail: []
        },
        inform: {
            heading: [
                "CRCH1",
                "CRCH2",
                "CRCH3",
                "CRCH4",
                "CRCH5",
                "CRCH6",
                "CRCH7",
                "CRCH8",
                "CRCH9",
                "CRCH10"
            ],
            detail: [
                "CRCD1",
                "CRCD2",
                "CRCD3",
                "CRCD4",
                "CRCD5",
                "CRCD6",
                "CRCD7",
                "CRCD8",
                "CRCD9",
                "CRCD10"
            ]
        }
    },
    parent: {
        consent: {
            heading: [],
            detail: []
        },
        inform: {
            heading: [
                "CRCH1",
                "CRCH2",
                "CRCH3",
                "CRCH4",
                "CRCH5",
                "CRCH6",
                "CRCH7",
                "CRCH8",
                "CRCH9",
                "CRCH10"
            ],
            detail: [
                "CRCD1",
                "CRCD2",
                "CRCD3",
                "CRCD4",
                "CRCD5",
                "CRCD6",
                "CRCD7",
                "CRCD8",
                "CRCD9",
                "CRCD10"
            ]
        }
    },
    officer: {
        consent: {
            heading: [],
            detail: []
        },
        inform: {
            heading: [
                "CROH1",
                "CROH2",
                "CROH3",
                "CROH4",
                "CROH5",
                "CROH6"
            ],
            detail: [
                "CROD1",
                "CROD2",
                "CROD3",
                "CROD4",
                "CROD5",
                "CROD6"
            ]
        }
    }
}