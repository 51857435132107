export const innerSchema = {
  type: "object",
  required: [
    "cid",
    "hcode",
    "fname",
    "lname",
    "hn",
    "sex",
    "age",
    "province_name",
    "amphur_id",
    "amphur_name",
    "tambol_id",
    "tambol_name",
    "villagecode",
    "physical",
    "mental",
    "sexual",
    "riskLevel",
  ],
  allOf: [
    {
      properties: {
        cid: {
          type: ["number", "string"],
          maxLength: 13,
          minLength: 13,
          format: "cidcheck",
        },
        hcode: {
          type: "string",
          maxLength: 5,
          minLength: 5,
        },
        fname: {
          type: "string",
          minLength: 1,
        },
        lname: {
          type: "string",
          minLength: 1,
        },
        hn: {
          type: "string",
          minLength: 1,
        },
        sex: {
          type: "string",
          minLength: 1,
        },
        age: {
          type: "number",
          minLength: 1,
        },
        province_name: {
          type: "string",
          minLength: 1,
        },
        amphur_id: {
          type: "string",
          maxLength: 4,
          minLength: 4,
        },
        amphur_name: {
          type: "string",
          minLength: 1,
        },
        tambol_id: {
          type: "string",
          maxLength: 6,
          minLength: 6,
        },
        tambol_name: {
          type: "string",
          minLength: 1,
        },
        villagecode: {
          type: "string",
          maxLength: 8,
          minLength: 8,
        },
        physical: {
          type: "string",
          maxLength: 1,
          minLength: 1,
          enum: ["Y", "N"],
        },
        mental: {
          type: "string",
          maxLength: 1,
          minLength: 1,
          enum: ["Y", "N"],
        },
        sexual: {
          type: "string",
          maxLength: 1,
          minLength: 1,
          enum: ["Y", "N"],
        },
        riskLevel: { type: "string", enum: ["low", "medium", "high"] },
      },
      additionalProperties: {},
    },
  ],

  errorMessage: {
    type: "should be an object", // will not replace internal "type" error for the property "foo"
    properties: {
      cid: "เลขบัตรประจำตัวประชาชนไม่ถูกต้อง?",
      hcode: "รหัสสถานพยาบาลไม่ถูกต้อง!",
      fname: "กรุณาระบุชื่อ!",
      lname: "กรุณาระบุนามสกุล!",
      hn: "กรุณาระบุเลขประจำตัวผู้ป่วย!",
      sex: "กรุณาระบุเพศ!",
      age: "อายุใช้ตัวเลขเท่านั้น!",
      province_name: "กรุณาระบุชื่อจังหวัด",
      amphur_id: "กรุณาระบุเลข 4 หลัก",
      amphur_name: "กรุณาระบุชื่ออำเภอ",
      tambol_id: "กรุณาระบุเลข 6 หลัก",
      tambol_name: "กรุณาระบุชื่อตำบล",
      villagecode: "กรุณาระบุเลข 8 หลัก",
      physical: "Y / N เท่านั้น!",
      mental: "Y / N เท่านั้น!",
      sexual: "Y / N เท่านั้น!",
      riskLevel: "ระดับความรุนแรงไม่ถูกต้อง [low, medium, high] เท่านั้น!",
    },
  },
};
