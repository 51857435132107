import { useEffect, useMemo, useState } from "react"

export default function useScrollEnd(ref: any) {

  const [isIntersecting, setIntersecting] = useState(false)

  const observer = useMemo(() => new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  ), [])

  useEffect(() => {
    if(ref && ref !== null ){
      observer.observe(ref);
    }
    return () => observer.disconnect()
  }, [observer,ref])

  return isIntersecting
}