/* eslint-disable @typescript-eslint/no-unused-vars */
import { UPDATE_ANSWER } from '../../actions';
import Actions from '../../actions/activity';
import { snackbarFailure,snackbarSuccess } from '../../actions/notification';

import * as api from '../../services/api';
import { fetchEntity } from '../saga';
import { all, call, fork, put, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

const updateAnswer = fetchEntity.bind(null, Actions.UpdateAnswer, api.updateAnswer);
const getQuestionaire = fetchEntity.bind(null, Actions.GetQuestionnaire, api.getQuestionaire);

function* callFirstAction(action: any) {
  yield call(
    updateAnswer,{data: action.data.update},
    {
      onSuccess: (success: any) => {
        return all([
          // call(callSecondAction, { param: action.data.get.param, state: action.data.get.state }),
          put(push('/questionnaire_list',action.data.get.state)),
          put(snackbarSuccess(action.data[(action.data.insert)?"insert":"update"].successMsg,2000)),
        ]);
      },
      onFailure: (error: any) => {
        return put(snackbarFailure('insertAnswer FAILURE',1500));
      },
    }
  );
}

function* callSecondAction(action: any) {
  yield call(
    getQuestionaire,{data: action.param},
    {
      onSuccess: (success: any) => {        
        return all([
          put(push('/questionnaire_list',action.state)),
          (action.param.successMsg)&&put(snackbarSuccess(action.param.successMsg,2000))
        ]);
      },
      onFailure: () => {
        return put(snackbarFailure('getQuestionaire FAILURE',1500));
      },
    }
  );
}


function* watchFirstRequest() {
  yield takeLatest(UPDATE_ANSWER.REQUEST, callFirstAction);
}

export default function* root() {
  yield fork(watchFirstRequest);
}
