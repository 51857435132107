/* eslint-disable @typescript-eslint/no-unused-vars */
import { FIRST_ACTION } from '../../actions';
import { firstAction, secondAction, thirdAction, fourthAction } from '../../actions/example';
import { snackbarFailure } from '../../actions/notification';

import * as api from '../../services/api';
import { fetchEntity } from '../saga';
import { all, call, fork, put, take, takeEvery, takeLatest } from 'redux-saga/effects';

const firstEntity = fetchEntity.bind(null, firstAction, api.exampleFirstEndpoint);
const secondEntity = fetchEntity.bind(null, secondAction, api.exampleSecondEndpoint);
const thirdEntity = fetchEntity.bind(null, thirdAction, api.exampleThirdEndpoint);
const fourthEntity = fetchEntity.bind(null, fourthAction, api.exampleFourthEndpoint);

function* callFirstAction(action: any) {
  yield call(
    firstEntity,{data: action.data},
    {
      onSuccess: (success: any) => {

      },
      onFailure: () => {
        return all([
          call(callSecondAction, { data: action.data }),
          put(snackbarFailure('FIRST FAILURE : ' + action.data)),
        ]);
      },
    }
  );
}

function* callSecondAction(action: any) {
  yield call(
    secondEntity,{data: action.data},
    {
      onSuccess: (success: any) => {

      },
      onFailure: () => {
        return all([
          call(callThirdAction, { data: action.data }),
          put(snackbarFailure('SECOND FAILURE : ' + action.data)),
        ]);
      },
    }
  );
}

function* callThirdAction(action: any) {
  yield call(
    thirdEntity,{data: action.data},
    {
      onSuccess: (success: any) => {
        
      },
      onFailure: () => {
        return all([put(snackbarFailure('THIRD FAILURE : ' + action.data))]);
      },
    }
  );
}

function* watchFirstRequest() {
  yield takeLatest(FIRST_ACTION.REQUEST, callFirstAction);
}

export default function* root() {
  /// self
  yield fork(watchFirstRequest);
}
