/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {  getCount } from "../services/UniversalAPI";
import { getCurrentUser } from '../services/auth.service';
// import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { red } from '@material-ui/core/colors';

import NotificationsIcon from '@material-ui/icons/Notifications';
import moment from "moment";
import "moment/locale/th";
import useGlobal from "../store";
import useScreenType from "react-screentype-hook";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  popupMenuLink: {
    "& a": { color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none' },
    "& a:hover": { textDecoration: 'none' }
  },
  orange: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: red[50],
    backgroundColor: red["A400"],
    fontSize: '0.8rem',
    margin: '0 8px 0 0'
  },
}));
export default function DashBoard(props) {
  // const screenType = useScreenType();
  // const history = useHistory();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const openMenu = Boolean(globalState.anchorNotification);
  const handleMenu = (event) => { globalActions.setAnchorNotification(event.currentTarget) };
  const handleClose = () => { globalActions.setAnchorNotification(null) };
  const currentUser = getCurrentUser();
  const officerId = (currentUser != null) ? currentUser.userId : "";
  const [notificationBadge, setNotificationBadge] = useState(0);
  const [notificationTopic, setNotificationTopic] = useState({
    planNearExpiration: { 
      title: "", count: 0 , to : {}
    }
  });

  // กำหนดวันใกล้หมดเวลา 10 วัน
  const notificationDays = 10;
  useEffect(() => {
    let unmounted = false;
    const getData = async () => {

      // วันที่ปัจจุบัน -10 วัน ตั้งค่าที่ตัวแปร notificationDays
      let notificationDay = moment().subtract(notificationDays, 'days').startOf('day').format('YYYY-MM-DD');
      const params = {
        "where": {
          "and": [
            { "serviceslotList.officerId": { in: [officerId] } },
            // วันสิ้นสุดแผนมากกว่า >  วันที่ใกล้หมดเวลา [คือวันปัจจุบันลบวันออกตามที่ตั้งค่า]
            { "serviceslotList.endDate": { gt: notificationDay } }
          ]
        }
      };
      try {
        // const response = await getCount(params, "planservices");
        const response={status:400};
        if (response.status === 200) {
          // let activity = 0;
          // response.data.map(function (planservices, index) {
          //   let serviceslotLists = planservices.serviceslotList;
          //   serviceslotLists.map(function (serviceslotList, index) {
          //     // console.log(serviceslotList);
          //     if (serviceslotList.officerId === officerId) {
          //       let remainingDays = moment(serviceslotList.endDate).diff(moment(), 'days');
          //       if (remainingDays <= notificationDays) {
          //         activity++;
          //         // console.log(serviceslotList,remainingDays);
          //       }
          //     }
          //     return null
          //   });
          //   return null;
          // });
          setNotificationTopic({
            ...notificationTopic,
            planNearExpiration: { 
              title: t("Notificaion.actExp"), 
              count: response.data.count, 
              to : { pathname: '/cases', state: { status: 'nearDeadLine' }} 
            }
          });
          setNotificationBadge(response.data.count);
        }
      } catch (error) { console.log('error', error) }
    };
    getData();
    return () => { unmounted = true; }
  }, []);
  useEffect(() => {
    // console.log(globalState.language,notificationTopic);
    // i18n.changeLanguage(globalState.language);
    setNotificationTopic({
      ...notificationTopic,
      planNearExpiration: { 
        title: t("Notificaion.actExp"), 
        count: notificationTopic.planNearExpiration.count,
        to: notificationTopic.planNearExpiration.to 
      }
    });
  }, [globalState.language]);

  return (
    <div style={{ display: 'inline-block' }}>
      <Button
        color="inherit"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        style={{ padding: 0, justifyContent: 'flex-end', minWidth: 'fit-content' }}
      >
        <Badge badgeContent={(notificationBadge != null) && notificationBadge} color="secondary">
          <NotificationsIcon color="inherit" />
        </Badge>
      </Button>
      {(notificationBadge > 0) && (
        <Menu
          id="menu-appbar"
          anchorEl={globalState.anchorNotification}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right', }}
          open={openMenu}
          // onClose={(_event, reason)=>{if (reason === "backdropClick") {return;}handleClose();}}
          onClose={handleClose}
          className={classes.popupMenuLink}
        >
          {
            Object.keys(notificationTopic).map(function (key, index) {
              if (notificationTopic[key].count > 0) {
                // console.log(notificationTopic[key]);
                return (
                  <Link key={index} to={notificationTopic[key].to}>
                    <MenuItem onClick={handleClose}>
                      <Avatar className={classes.orange} >{notificationTopic[key].count}</Avatar>
                      {notificationTopic[key].title}
                    </MenuItem>
                  </Link>
                );
              } else {
                return null;
              }
            })
          }
        </Menu>
      )}
    </div>
  )
}