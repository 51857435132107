/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React,{ FC, useEffect, useState } from 'react';
import { Box, Button, createStyles, FormControl, IconButton, InputLabel, makeStyles, MenuItem, Select, Theme } from '@material-ui/core';
import { DataGrid, GridColDef, GridCellParams, GridToolbar, GridFilterModel } from "@material-ui/data-grid";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import Actions from '../../../actions/activity';
import { Link } from 'react-router-dom';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HealingIcon from '@material-ui/icons/Healing';
import FindInPageIcon from '@material-ui/icons/FindInPage';
interface IProps{
  type: string
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: 0,
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);
const CasesList:FC<IProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
  // const casesList = globalState.activity.caseList.data;
  const [casesList,setCaseList] = useState<any>([]);
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [{ columnField: 'cid', value: '', operatorValue: 'equals' }],
  });
  const members = (label:string,data:Record<any,any>)=>{
    return <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id={label+data.id}>{label}</InputLabel>
      <Select
        labelId={label+data.id}
        id={data.id}
        label={label}
        fullWidth
        variant="standard"
      >
        {
          data.map((item:any,key:number)=>{
            return <MenuItem key={key}>{item.fname+" "+item.lname}</MenuItem>
          })
        }
      </Select>
    </FormControl>
  }
  const questionaire = (label:string,data:Record<any,any>)=>{
    return <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id={label+data.questionnaire_id}>{label}</InputLabel>
      <Select
        labelId={label+data.questionnaire_id}
        id={data.questionnaire_id}
        label={label}
        fullWidth
        variant="standard"
      >
        {
          data.map((item:any,key:number)=>{
            return <MenuItem key={key}>{item.questionnaire_name}</MenuItem>
          })
        }
      </Select>
    </FormControl>
  }
  const columnsGroup: GridColDef[] = [
    { 
      field: 'groupName', 
      headerName: 'ชื่อกลุ่ม', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'left',
      flex: 0.12
    },
    { 
      field: 'questionaire', 
      headerName: 'แบบสอบถาม', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.07,
      renderCell:(params: GridCellParams)=> {
        return questionaire("แบบสอบถาม",params.row.questionaire);
      }
    },
    { 
      field: 'member', 
      headerName: 'สมาชิก', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.07,
      renderCell:(params: GridCellParams)=> {
        return members("สมาชิก",params.row.member);
      }
    },
    { 
      field: 'manage', 
      headerName: 'รายละเอียด', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center', 
      flex: 0.05,
      renderCell:(params: GridCellParams)=> {
        return (
          <>
            <IconButton 
              // component={Link}
              // to={"/questionaire/"+params.row.id} 
              onClick={()=>{}}>
              <FindInPageIcon />
            </IconButton>
          </>
        )
      }
    }
  ];
  const columnsCase: GridColDef[] = [
    { 
      field: 'cid', 
      headerName: 'เลชบัตรประชาชน', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.12
    },
    { 
      field: 'fullname', 
      headerName: 'ชื่อ-สกุล / กลุ่ม', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'left',
      flex: 0.12
    },
    { 
      field: 'sex', 
      headerName: 'เพศ', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.07
    },
    { 
      field: 'age', 
      headerName: 'อายุ', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.07
    },
    { 
      field: 'risk', 
      headerName: 'ระดับความรุนแรง', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.1
    },
    { 
      field: 'manage', 
      headerName: 'รายละเอียด', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center', 
      flex: 0.1,
      renderCell:(params: GridCellParams)=> {
        return (
          <>
            <IconButton 
              // component={Link}
              // to={"/questionaire/"+params.row.id} 
              onClick={()=>{}}>
              <FindInPageIcon />
            </IconButton>
          </>
        )
      }
    }
  ];
  useEffect(() => {
    const teamCode = globalState.auth.user.response?.user.osccteams[0].teamCode;
    if(teamCode && teamCode!==""){
      const params = {
        type:props.type,
        teamCode:teamCode
      }
      if(props.type==="group"){
        dispatch(Actions.GetAssignGroup.request(params));
      }else{
        dispatch(Actions.GetAssignCases.request(params));
      }
    }
  },[props.type]);
  useEffect(() => {
    if(props.type==="group"){
      setCaseList(globalState.activity.assignGroupList.data);
    }else{
      setCaseList(globalState.activity.assignCasesList.data);
    }
  },[globalState.activity.assignCasesList,globalState.activity.assignGroupList]);
  return (
    <Box style={{
      marginTop:3,
      width: '100%',
      // '& .apm-header': {
      //   backgroundColor: 'rgb(66 66 66)',
      //   color: 'white'
      // }
    }}>
      <DataGrid
        rows={casesList}
        columns={(props.type==="group")?columnsGroup:columnsCase}
        autoHeight
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        disableDensitySelector
        filterMode='client'
        components={{
          // NoRowsOverlay: ()=>(
          //   <Box height="100%" width="100%" alignItems="center" justifyContent="center">ไม่พบข้อมูล</Box>
          // ),
          Toolbar: GridToolbar,
        }}
        filterModel={filterModel}
        onFilterModelChange={(model) => setFilterModel(model)}
      />
    </Box>
  );
}

export default CasesList;