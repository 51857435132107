import { GET_TEAMSUSERSTAMBON } from "../../actions";
import { AnyAction } from "redux";
import { TeamsUsersData } from "../../types/teams";
// import _ from "lodash";

const initialState:TeamsUsersData = {
  isFetching: false,
  isSuccess: false,
  count: 0,
  data: []
};

export default function getTeamsUsersTambon(
  state = initialState,
  action: AnyAction
): TeamsUsersData {
  switch (action.type) {
    case GET_TEAMSUSERSTAMBON.REQUEST:{
      return {
        ...state,
        isFetching: true,
        isSuccess: false
      };
    }
    case GET_TEAMSUSERSTAMBON.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        count: parseInt(action.data.count),
        data: action.data.rows
      };
    }
    case GET_TEAMSUSERSTAMBON.FAILURE:
      return {
        ...state,
        isFetching: false,
        isSuccess: false
      };
    default:
      return state;
  }
}
