import { UPDATE_INFORM } from "../../actions";
import { AnyAction } from "redux";
import { Inform } from "../../types/inform";

const initialState:Inform = {
  isFetching: false,
  isSuccess: false,
  data: []
};

export default function updateInform(
  state = initialState,
  action: AnyAction
): Inform {
  switch (action.type) {
    case UPDATE_INFORM.REQUEST:{
      return {
        ...state,
        isFetching: true,
      };
    }
    case UPDATE_INFORM.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        data: action.data
      };
    }
    case UPDATE_INFORM.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    default:
      return state;
  }
}
