/* eslint-disable react-hooks/exhaustive-deps */
import React,{useEffect,useState,useCallback,FC, useMemo} from 'react';
import structuredClone from '@ungap/structured-clone';
import { AppBar, Button, Card, CardContent, createStyles, Dialog, DialogContent, Grid, IconButton,makeStyles,Slide,TextField,Theme,Toolbar,Typography, useTheme} from "@material-ui/core";
import { GridRowData } from '@material-ui/data-grid';
import { Autocomplete } from '@material-ui/lab';
import { useForm, SubmitHandler, Controller, ControllerRenderProps, ControllerFieldState, FormState } from "react-hook-form";
import { TransitionProps } from '@material-ui/core/transitions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../reducers";
import Actions from '../../actions/activity';
import CustomButton from '../../components/custom_components/CustomButtons/Button';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getQuestionaireConclusion, getPeople } from '../../services/api';
import moment from 'moment';
import {thaiYear} from '../../reducers/selectors/thaiYear';
import _ from 'lodash';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    buttonGroup:{
      // gridTemplateColumns: "1fr max-content max-content max-content max-content",
      // display: "grid"
      display: "flex",
      columnGap: "0.5rem",
      justifyContent: "end"
    },
    buttonGroupMobile: {
      display: "grid",
      gridGap: "0 1rem",
      gridTemplateColumns:"1fr 1fr"
    },
    card:{
      border:"1px solid #ccc",
      borderRadius:"10px",
      // margin:"8px",
      // padding:"1rem"
    },
    dashedCard:{
      border:"1px dashed #ccc",
      borderRadius:"10px",
      margin:"1rem 0 0 0",
      padding:"1rem"
    },
    appBar: {
      // top: 'auto',
      // bottom: 0,
      position: 'relative',
    }
  }),
);
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface IProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  caseData: GridRowData;
  conclusion: Record<string, any>;
  filltered: Record<string, any>;
  currentPage: number;
  pageSize: number;
  getCases: (filter: Record<string, any>) => Promise<void>;
}
type TProps = IProps;

const InformConsent: FC<TProps> = ((props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, reset, control} = useForm<any>();
  const globalState = useSelector((state: RootState) => state);
  const userinfo = globalState.auth.user.response.user;
  const token: string = globalState.auth.user.response.id;
  // const teamCodes = useMemo(()=>userinfo.osccteams.map((item:any)=>item.teamCode),[userinfo.osccteams]);
  const groupList = globalState.activity.groupListTeams.data;
  const {caseData,setOpen} = props;

  const riskLevel = [
    {id:1, label:'ต่ำ',value:'low'},
    {id:2, label:'ปานกลาง',value:'medium'},
    {id:3, label:'สูง',value:'high'},
    {id:4, label:'เหยื่อ',value:'victim'},
    {id:5, label:'ปกติ',value:'normal'},
    {id:6, label:'อื่นๆ',value:'other'}
  ];

  const improveLevel = [
    {id:1, label:'ดีขึ้น',value:'improved'},
    {id:2, label:'ไม่เปลี่ยนแปลง',value:'unchanged'},
    {id:3, label:'แย่ลง',value:'worse'}
  ];

  const handleClose = useCallback(() => {setOpen(false)},[setOpen]);

  const getMemberName = useMemo(()=>{
    let teamMemberName: Array<any> = [];
    groupList.filter((f:any)=>f.id===props.caseData.groupId).forEach((m:any)=>{
      teamMemberName = m.teamMembers.map((t:any)=>t.fullname);
    });
    return teamMemberName.reduce( (p:any, c:any) =>{
      if(!isMobile){
        return [p," | ",c]
      }else{
        return [p,<br key="br"/>,<span>&nbsp;&emsp;{c}</span>]
      }
    })
  },[groupList,props.caseData.groupId,isMobile]);

  const getQN = useCallback(async(group_id:string)=>{
    const {success, response, error} = await getQuestionaireConclusion(token,group_id);
    if(error){return null;}
    if(success){return response.data;}
    return null;
  },[token]);
  const getPersonId = useCallback( async(cid: string) => {
    const {success, response, error} = await getPeople(token, cid );
    if(error){return null;}
    if(success){return response.data[0].id;}
    return null;
  },[token]);

  const combindIntervention = async(cid:string, personId:string,answer:any) => {
    const template = {
      "hcode": userinfo.department.hcode,
      "hname": userinfo.department.hos_fullname,
      "cid": cid,
      "personId": personId,
      "vn": thaiYear(props.caseData.osccperson_assign[0].assignDatetime,"YYMMDDHHmmss"),
      "ovstist": "oscc",
      "ovstist_name": "เยี่ยมบ้าน OSCC",
      "ovstost": null,
      "ovstost_name": null,
      "doctor": null,
      "doctor_name": null,
      "pttype": "0100",
      "pttype_name": "เด็ก 0 - 12 ปี",
      "pttype_no": null,
      "hospmain": null,
      "hospmain_name": null,
      "hospsub": null,
      "hospsub_name": null,
      "spclty": null,
      "spclty_name": null,
      "vstdate": moment(props.caseData.osccperson_assign[0].assignDatetime).format("YYYY-MM-DD"),
      "vsttime": moment(props.caseData.osccperson_assign[0].assignDatetime).format("HH:mm:ss"),
      "planserviceIds": [],
      "program_provider": "oscc",
      "version": null,
      "activities": {
        "homeservice": [answer]
      }
    }
    return template;
  }
  const combindAnswer = (QN:any,ANSWER:any)=>{
    // console.log(QN,ANSWER);
    if(!_.isArray(ANSWER)){
      QN.forEach((q:any)=>{
        if(typeof q.groupQuestionsList === "undefined"){ // คำถามปกติไม่ใช่แบบ group
          if(ANSWER[q.inputName]!==null){q.answers = ANSWER[q.inputName];}
        }else{
          if(_.isArray(q.groupQuestionsList)){
            q.groupQuestionsList.forEach((g:any)=>{
              if(typeof g.answersList !== "undefined" && _.isArray(g.answersList)){
                g.answersList.filter((l:any)=>l.score===ANSWER[g.inputName]).forEach((e:any)=>{
                  e.checked = true;
                  g.answers = e.answer;
                });
              }else{
                console.log("Not have g.answersList",g);
                g.answers=ANSWER[g.inputName];
              }
            });
          }else{
            console.log("groupQuestionsList is not array : ",q);
            // g.answers=ANSWER[g.inputName];
          }
        }
      });
      return QN;
    }else{
      let qnArray: any = [];
      ANSWER.forEach((a:any)=>{
        let newQN = structuredClone(QN);
        newQN.forEach((q:any)=>{
          if(typeof q.groupQuestionsList === "undefined"){ // คำถามปกติไม่ใช่แบบ group
            if(a[q.inputName]!==null){q.answers = a[q.inputName];}
          }else{
            if(_.isArray(q.groupQuestionsList)){
              q.groupQuestionsList.forEach((g:any)=>{
                if(typeof g.answersList !== "undefined" && _.isArray(g.answersList)){
                  g.answersList.filter((l:any)=>l.score===a[g.inputName]).forEach((e:any)=>{
                    e.checked = true;
                    g.answers = e.answer;
                  });
                }else{
                  // console.log("Not have g.answersList",g);
                  g.answers=a[g.inputName];
                }
              });
            }else{
              // console.log("groupQuestionsList is not array : ",q);
              // g.answers=ANSWER[g.inputName];
            }
          }
        });
        // console.log("newQN",newQN);
        qnArray.push(newQN);
      });
      return qnArray;
    }
  };
  const refresh = () =>{
    console.log(props.filltered);
    props.getCases(props.filltered);
  }
  const onSubmit: SubmitHandler<any> = async(data:any) => {
    // console.log(props);
    const conclusion_datetime = moment().format("YYYY-MM-DD HH:mm:ss");
    const QN = await getQN(props.caseData.groupId);
    const teamsData = props.caseData.osccperson_assign[0].teams;
    // const informData    = props.caseData.osccperson_assign[0].osccperson_groups.inform||[];
    // const consentData    = props.caseData.osccperson_assign[0].osccperson_groups.consent||[];
    const questionnaire = QN.map( (q:any)=>{
      const answer  = combindAnswer(q.questionnaire.questionsList,q.answer);
      return {
        "questionnaire_id": q.questionnaire.id,
        "questionnaire_type": q.questionnaire.questionnaire_type,
        "questionnaire_code": q.questionnaire.questionnaire_code,
        "questionnaire_name": q.questionnaire.questionnaire_name,
        "questionnaire_answer": answer,
        "questionnaire_answer_datetime": q.answer_datetime,
        "questionnaire_answer_respondent": {
          "id": q.answer_respondent.userid,
          "fullname": q.answer_respondent.fullname
        },
        "questionnaire_score": null
      };
    });
    // Data for intervention.activity.homeservice
    const result = {
      "serviceType": "oscc",
      "sequence_id": 1,
      "questionnaire_data": questionnaire,
      // "inform": (informData.length>0)?Object.assign({}, ...informData):null,
      // "consent": (consentData.length>0)?Object.assign({}, ...consentData):null,
      "osccteam": {
        "teamCode": teamsData.teamCode,
        "teamName": teamsData.teamName
      },
      "conclusion_riskLevel": riskLevel.filter((f:any)=>f.value===data.riskLevel).map((m:any)=>m.label)[0],
      "conclusion_improveLevel": improveLevel.filter((f:any)=>f.value===data.improveLevel).map((m:any)=>m.label)[0],
      "conclusion_comment": data.comment,
      "conclusion_userid": userinfo.id,
      "conclusion_fullname": userinfo.fullname,
      "conclusion_datetime": conclusion_datetime
    };

    const personId = await getPersonId(props.caseData.cid);
    const interventionData = await combindIntervention(props.caseData.cid,personId,result);
    const conclusionData = {
      assignId: props.caseData.osccperson_assign[0].id,
      groupId: props.caseData.groupId,
      teamId: props.caseData.osccTeamId[0],
      cid: props.caseData.cid,
      personId: personId,
      osccpersonId: props.caseData.id,
      interventionId: null,
      conclusion_riskLevel: data.riskLevel,
      conclusion_improveLevel: data.improveLevel,
      conclusion_comment: (data.comment!=="")?data.comment:null,
      conclusion_userid: userinfo.id,
      conclusion_fullname: userinfo.fullname,
      conclusion_datetime: conclusion_datetime
    }
    // console.log(interventionData);
    // console.log(conclusionData);
    const params = {
      insertIntervention: {
        params: interventionData,
        successMessage:"บันทึกสรุปผลการประเมินเรียบร้อย"
      },
      insertConclusion: {
        params: conclusionData
      },
      updateOsccperson: {
        params: {
          id: props.caseData.id,
          data:{
            caseStatus: "finish", // สรุปแล้ว assessed
            // improveLevel: data.improveLevel,
            // riskLevel: data.riskLevel,
            // createDatetime: moment().format("YYYY-MM-DD hh:mm")
          }
        }
      },
      getCases: {
        data: { 
          where: {groupId:props.filltered.group_id}, 
          pagination: {
            limit: props.pageSize, 
            skip: props.currentPage * props.pageSize
          }
        }
      }
    };

    console.log("onSubmit",params);
    /* 
      - (พร้อม) เพิ่มข้อมูลใน intervention.activity.homeservice
      - (พร้อม) เพิ่มข้อมูลใน conclusion
      - เปลี่ยน osccpeople.caseStatus เป็น assessed (สรุปแล้ว)
      - หน้าเคสกลุ่ม เพิ่มการเช็ค caseStatus รายคน หากครบทุกคนให้เปลี่ยน caseStatus เป็น assessed (สรุปแล้ว)
    */
    // refresh();
    dispatch(Actions.InsertConclusion.request(params));
  }
  const [disable,setDisable] = useState(false);
  useEffect(()=>{
    if(props.caseData.caseStatus==="finish"){
      setDisable(true);
    }
    if (props.conclusion) {
      const r = riskLevel.filter((f:any)=>f.value===props.conclusion.conclusion_riskLevel)[0];
      const i = improveLevel.filter((f:any)=>f.value===props.conclusion.conclusion_improveLevel)[0];
      const c = props.conclusion.conclusion_comment;
      setValue("riskLevel",r);
      setValue("improveLevel",i);
      setValue("comment",c);
    }
    return ()=>{
      reset({
        riskLevel: null,
        improveLevel: null,
        comment: ""
      });
    }
  },[props.conclusion]);

  return (
    <div>
      <Dialog fullScreen open onClose={(_event, reason)=>{if (reason === "backdropClick") {return;}handleClose();}}  TransitionComponent={Transition}>
        <AppBar color="primary" className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" style={{marginLeft:"1rem"}}>สรุปผลการประเมิน</Typography>
          </Toolbar>
        </AppBar>
        <DialogContent style={{padding:"24px"}}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} {...(!isMobile&& {justify:"flex-end"})}>
              <Grid item xs={12} md={(isMobile)?12:3} className={(isMobile)?classes.buttonGroupMobile:classes.buttonGroup}>
                <CustomButton type="submit" variant="contained" color="success" fullWidth
                  disabled={true}
                  onClick={()=>{}}>
                  {/* บันทึกการสรุปผล */}
                  อยู่ระหว่างดำเนินการแก้ไขข้อผิดพลาด
                </CustomButton>
              </Grid>
            </Grid> 
            <Grid container>
              <Grid item xs={12} md={12}>
                <Card variant="outlined" className={classes.dashedCard} style={{padding:(isMobile)?"0.5rem":"1rem"}}>
                  <CardContent>
                    <Grid container spacing={3} >
                      <Grid item xs={12} md={6} >
                        <Grid container spacing={3} >
                          <Grid item xs={12} md={12} >
                            {/* <Typography variant="h6">ข้อมูลเคส</Typography> */}
                            <Typography variant="h6" style={{color:'red'}}>อยู่ระหว่างดำเนินการแก้ไขข้อผิดพลาด</Typography>
                          </Grid>
                          <Grid item xs={12} md={12} >
                            <Grid container spacing={1} >
                              <Grid item xs={4} md={3} >ชื่อ-สกุล</Grid>
                              <Grid item xs={8} md={9} > :&emsp;{props.caseData.fname+" "+props.caseData.lname}</Grid>
                              <Grid item xs={4} md={3} >เลขบัตร ปชช.</Grid>
                              <Grid item xs={8} md={9} > :&emsp;{props.caseData.cid}</Grid>
                              <Grid item xs={4} md={3} >เพศ</Grid>
                              <Grid item xs={8} md={9} > :&emsp;{props.caseData.sex}</Grid>
                              <Grid item xs={4} md={3} >อายุ</Grid>
                              <Grid item xs={8} md={9} > :&emsp;{props.caseData.ageYM}</Grid>
                              <Grid item xs={4} md={3} >ความรุนแรง</Grid>
                              <Grid item xs={8} md={9} > :&emsp;{ riskLevel.filter((f:any)=>f.value===props.caseData.riskLevel).map((m:any)=>m.label)}</Grid>
                              <Grid item xs={4} md={3} >กลุ่มเคส</Grid>
                              <Grid item xs={8} md={9} > :&emsp;{props.caseData.osccperson_assign[0].osccperson_groups.groupName}</Grid>
                              <Grid item xs={4} md={3} >ทีมที่ดูแล</Grid>
                              <Grid item xs={8} md={9} > :&emsp;{props.caseData.osccperson_assign[0].teams.teamName}</Grid>
                              <Grid item xs={4} md={3} >สมาชิกทีม</Grid>
                              <Grid item xs={8} md={9} > :&emsp;{getMemberName}</Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={3} >
                          <Grid item xs={12} md={12} style={{marginBottom:"3px"}}>
                            {/* <Typography variant="h6">สรุปผล</Typography> */}
                            <Typography variant="h6" style={{color:'red'}}>อยู่ระหว่างดำเนินการแก้ไขข้อผิดพลาด</Typography>
                          </Grid>
                          <Grid item xs={12} md={6} >
                            <Controller
                              name="riskLevel"
                              control={control}
                              rules={{
                                required: {value: true, message: "กรุณาเลือกสรุปสถานะความเสี่ยง"},
                              }}
                              render={ ({ 
                                field : { onChange, value },
                                fieldState: { error },
                              }: any) => {
                                return ( 
                                  <Autocomplete
                                    id="riskLevel"
                                    freeSolo={true}
                                    fullWidth
                                    options={riskLevel}
                                    disabled={disable}
                                    getOptionLabel={(option: any) =>{
                                      if(typeof option.label !== "undefined"){
                                        return option.label;
                                      }else{
                                        return riskLevel.filter((f:any)=>f.value===option)[0].label;
                                      }
                                    }}
                                    size="small"
                                    style={{ height: "100%" }}
                                    value={value||null}
                                    onChange={(_,v:any) => {
                                      const value = (v!==null)?v.value:"";
                                      return onChange(value);
                                    }}
                                    renderInput={(params:any) => (
                                      <TextField
                                        {...params}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                        label="สรุปสถานะความเสี่ยง"
                                        variant="outlined"
                                        fullWidth
                                      />
                                    )}
                                  />
                                )
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} >
                            <Controller
                              name="improveLevel"
                              control={control}
                              rules={{
                                required: {value: true, message: "กรุณาเลือกสรุปผลการดูแล"},
                              }}
                              render={ ({ 
                                field : { onChange,value },
                                fieldState: { error },
                              }: any) => {
                                return (
                                  <Autocomplete
                                    id="improveLevel"
                                    freeSolo={true}
                                    fullWidth
                                    disabled={disable}
                                    options={improveLevel}
                                    getOptionLabel={(option: any) =>{
                                      if(typeof option.label !== "undefined"){
                                        return option.label;
                                      }else{
                                        return improveLevel.filter((f:any)=>f.value===option)[0].label;
                                      }
                                    }}
                                    size="small"
                                    style={{ height: "100%" }}
                                    value={value||null}
                                    onChange={(_,v:any) => {
                                      const value = (v!==null)?v.value:"";
                                      return onChange(value);
                                    }}
                                    renderInput={(params:any) => (
                                      <TextField
                                        {...params}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                        label="สรุปผลการดูแล"
                                        variant="outlined"
                                        fullWidth
                                      />
                                    )}
                                  />
                                )
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12} >
                            <TextField
                              fullWidth
                              multiline
                              rows={10}
                              disabled={disable}
                              id="comment"
                              variant="outlined"
                              label="ความคิดเห็น/คำแนะนำ/ข้อเสนอแนะ"
                              style={{height:"250px"}}
                              inputProps={{
                                style: {
                                  height:"198px"
                                }
                              }}
                              {...register("comment")}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
})

export default InformConsent;