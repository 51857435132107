import { INSERT_GROUP } from "../../actions";
import { AnyAction } from "redux";
import { InsertGroup } from "../../types/activity";

const initialState:InsertGroup = {
  isFetching: false,
  data: {
    createDatetime: "",
    groupName: "",
    member: [],
    teams: {},
    inform: []
  }
};

export default function getGroupList(
  state = initialState,
  action: AnyAction
): InsertGroup {
  switch (action.type) {
    case INSERT_GROUP.REQUEST:{
      return {
        ...state,
        isFetching: true,
      };
    }
    case INSERT_GROUP.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    }
    case INSERT_GROUP.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    default:
      return state;
  }
}
