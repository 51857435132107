/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Action from "../../actions";
import { AnyAction } from "redux";
import { filter,filterVLG } from "../../types/filter";
import _ from "lodash";
const initialState:filter = {
  isFetching: false,
  count: 0,
  data: []
};

export default function village(
  state = initialState,
  action: AnyAction
): filter {
  switch (action.type) {
    case Action.GET_VILLAGE.REQUEST:{
      return {
        ...state,
        isFetching: true,
        data:[]
      };
    }
    case Action.GET_VILLAGE.SUCCESS: {
      const newData:filterVLG[] = action.data.rows.map((item:any)=>{
        return {
          id: item.villagecodefull,
          label: 'หมู่ที่ '+parseInt(item.villagecode)+' : '+item.villagename,
          value: {villagecode: item.villagecodefull, villagename: item.villagename}
        }
      });
      const newD:filterVLG[] = _.uniqBy(newData, 'id');
      return {
        ...state,
        isFetching: false,
        data: newD
      };
    }
    case Action.GET_VILLAGE.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: [],
      };
    default:
      return state;
  }
}
