/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { GET_TEAMSUSERS } from "../index";

const getTeamsUsers = {
  request: (payload:Record<string,any>) => action(GET_TEAMSUSERS.REQUEST, payload),
  success: (response: any) => action(GET_TEAMSUSERS.SUCCESS, { data: response }),
  failure: (error: any) => action(GET_TEAMSUSERS.FAILURE, { error }),
};

export default getTeamsUsers;