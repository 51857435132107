/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Button,
  Grid,
  IconButton
} from '@material-ui/core';
import {
  MdCheck as MdCheckIcon,
  MdClose as MdCloseIcon,
  MdCheckBox as MdCheckBoxIcon,
  MdCheckBoxOutlineBlank as MdCheckBlankIcon
} from "react-icons/md";
import { useHistory } from "react-router-dom";
import { useDialog } from '../../services/dialog/ModalProvider';
import * as UAPI from "../../services/UniversalAPI";
import { ConsentRegisterDataSet as CRDS } from "./ConsentRegisterDataSet";


const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
    '& .MuiInputLabel-outlined': {
      zIndex: 1,
      transform: 'translate(15px, 4px) scale(1)',
      pointerEvents: 'none'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(15px, -18px) scale(0.75)',
    },
    '& .Mui-error .MuiInputBase-input': { color: '#f44336' }
  },
  p: {
    textIndent: 50,
  }

}));

const ConsentRegister = (props) => {
  const { t } = props;
  const redirect = useHistory();
  const classes = useStyles();
  const [CLstatus, setCLstatus] = useState({});
  const [CLDetailwatched, setCLDetailwatched] = useState({});
  const [countAllConsent, setCountAllConsent] = useState(0);
  const [countConsented, setCountConsented] = useState(0);
  const [disabledButtonAccept, setDisabledButtonAccept] = useState(true);
  const [informConsentList, setInformConsentList] = useState([]);
  const [initialInformConsent, setInitialInformConsent] = useState({});
  const [userInformConsentData, setUserInformConsentData] = useState({});
  const [informCount, setInformCount] = useState(0);
  const [consentCount, setConsentCount] = useState(0);


  const changeResult = async (x) => {
    let result = x === true ? 'consented' : 'notConsent';
    let xCLstatus = JSON.parse(JSON.stringify(CLstatus));
    // let consent = { result: result, language: selectedLanguage, detail: cl }
    // console.log(xCLstatus);
    // console.log(informConsentList);
    let inform = {};
    inform.heading = {};
    inform.detail = {};
    let consent = {};
    consent.heading = {};
    consent.detail = {};
    informConsentList.forEach(i => {
      if (i.type === 'inform') {
        inform.heading[i.heading] = xCLstatus[i.heading] === null ? false : xCLstatus[i.heading];
        inform.detail[i.heading] = xCLstatus[i.heading] === null ? false : xCLstatus[i.heading];
      }
      if (i.type === 'consent') {
        inform.consent[i.heading] = xCLstatus[i.heading] === null ? false : xCLstatus[i.heading];
        inform.consent[i.heading] = xCLstatus[i.heading] === null ? false : xCLstatus[i.heading];
      }
    });
    // console.log(inform);
    // console.log(consent);
    let informConsent = {
      OSCC: {
        result: result,
        inform: inform,
        consent: consent
      }
    }
    // console.log(informConsent);

    if (props.editConsent===false) {
      if (x === true) {
        props.setFormdata({
          ...props.formData,
          informConsent: informConsent
        });
        closeConsent();
      } else {
        redirect.push({ pathname: "/login" });
      }
    }
    else {
      // console.log(props.formData);
      // console.log(informConsent);
      let response = await UAPI.patch(props.formData.id, {informConsent}, "teamusers");
      if (response.status === 200) {
        if (response.data) {
          // console.log(response.data);
          closeConsent();
        }
      }

    }
  }

  const closeConsent = () => {
    props.setDialogOpen(false);
    setCLstatus({});
    setCLDetailwatched({});
    setCountAllConsent(0);
    setCountConsented(0);
    setDisabledButtonAccept(true);
  }

  const showConsentList = useMemo(() => {
    // console.log('showConsentList-------');
    const changeCLstatus = (n, x) => {
      let c = JSON.parse(JSON.stringify(CLstatus));
      if (x === 1) {
        if (c[n] === null || c[n] === false) {
          setCLstatus({ ...c, ...{ [n]: true } });
        }
        else {
          setCLstatus({ ...c, ...{ [n]: false } });
        }
      }
      else {
        setCLstatus({ ...c, ...{ [n]: false } });
      }
    }
    const toggleDetailWatched = (n) => {
      let c = JSON.parse(JSON.stringify(CLDetailwatched));
      if (typeof c[n] === 'undefined') {
        setCLDetailwatched({ ...c, ...{ [n]: true } });
      }
      else {
        if (c[n] === false) {
          setCLDetailwatched({ ...c, ...{ [n]: true } });
        }
        else {
          setCLDetailwatched({ ...c, ...{ [n]: false } });
        }
      }
    }

    let x = [];
    let informConsentList = [];
    let editing = false;
    if (typeof props.editConsent !== 'undefined') {
      if (props.editConsent === true) {
        editing = true;
      }
    }

    let informData_heading = [];
    let informData_detail = [];
    let consentData_heading = [];
    let consentData_detail = [];

    if (editing===false) {
      let IIC = JSON.parse(JSON.stringify(initialInformConsent));
      // console.log(IIC);
      if (Object.keys(IIC).length>0) {
        if (Object.keys(IIC.inform).length>0 || Object.keys(IIC.consent).length>0) {
          informData_heading = IIC.inform.heading;
          informData_detail = IIC.inform.detail;
          consentData_heading = IIC.consent.heading;
          consentData_detail = IIC.consent.detail;
      // console.log(informData_heading);
          if (informData_heading.length > 0) {

            let i_h = 0;
            informData_heading.forEach(o_h => {
              let i_d = 0;
              informData_detail.forEach(o_d => {
                if (i_h === i_d) {
                  informConsentList.push({ type: 'inform', heading: o_h, detail: o_d });
                }
                ++i_d;
              });
              ++i_h;
            });
            setInformCount(i_h);
          }
      
          if (consentData_heading.length > 0) {
            let i_hc = 0;
            consentData_heading.forEach(o_hc => {
              let i_dc = 0;
              consentData_detail.forEach(o_dc => {
                if (i_hc === i_dc) {
                  informConsentList.push({ type: 'consent', heading: o_hc, detail: o_dc });
                }
                ++i_dc;
              });
              ++i_hc;
            });
            setConsentCount(i_hc);
          }
        }
      }
    }
    else {
      let xUIC = JSON.parse(JSON.stringify(userInformConsentData));
      if (Object.keys(userInformConsentData).length>0) {
        if (Object.keys(xUIC.inform.heading).length > 0) {
          let i_h = 0;
          for (const [k_h, v_h] of Object.entries(xUIC.inform.heading)) {
            let i_d = 0;
            for (const [k_d, v_d] of Object.entries(xUIC.inform.detail)) {
              if (i_h === i_d) {
                informConsentList.push({ type: 'inform', heading: k_h, detail: k_d });
              }
              ++i_d;
            };
            ++i_h;
          };
          setInformCount(i_h);
        }
        if (Object.keys(xUIC.consent.heading).length > 0) {
          let i_hc = 0;
          for (const [k_h, v_h] of Object.entries(xUIC.consent.heading)) {
            let i_dc = 0;
            for (const [k_d, v_d] of Object.entries(xUIC.consent.detail)) {
              if (i_hc === i_dc) {
                informConsentList.push({ type: 'consent', heading: k_h, detail: k_d });
              }
              ++i_dc;
            };
            ++i_hc;
          };
          setConsentCount(i_hc);
        }
      }
    }

    // console.log(informConsentList);

    setInformConsentList(informConsentList);

    if (informConsentList.length > 0) {
      informConsentList.forEach(i => {
        let k = i.heading;
        let headingText = t(i.heading);
        let detailText = t(i.detail);
        let borderColor = '#dd4a4a';
        let colorAccept = '#cecece';
        let colorRefuse = '#cecece';
        if (typeof CLstatus[k] !== 'undefined') {
          if (CLstatus[k] === null) {
            borderColor = '#dd4a4a';
            colorAccept = '#cecece';
            colorRefuse = '#cecece';
          }
          else if (CLstatus[k] === true) {
            borderColor = '#00f017';
            colorAccept = '#00f017';
            colorRefuse = '#cecece';
          }
          else if (CLstatus[k] === false) {
            borderColor = '#dd4a4a';
            colorAccept = '#cecece';
            colorRefuse = '#dd4a4a';
          }
        }

        x.push(
          <Grid key={UAPI.genKey()} container justify="space-evenly"
            style={{ border: 'solid 2px #EEEEEE', borderRadius: 5, marginBottom: 10, padding: 15, borderColor: borderColor }}
          >
            <Grid item xs={12} sm={10}>
              <div dangerouslySetInnerHTML={{ __html: headingText }} />
              <div>
                <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => toggleDetailWatched(k)}>
                  {CLDetailwatched[k] === true ? '[' + t('Hide details') + ']' : '[' + t('Show details') + ']'}
                </span>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: detailText }}
                style={{
                  display: CLDetailwatched[k] === true ? 'block' : 'none'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <Grid container justify="flex-end">
                {(CLstatus[k] === null && CLDetailwatched[k] === false) && (
                  <Button variant="outlined"
                    style={{ height: 40, marginLeft: 5, minWidth: 70, maxWidth: 70, border: 'solid 2px ' + colorAccept, color: colorAccept, fontSize: 25 }}
                    onClick={() => toggleDetailWatched(k)}
                  >
                    {'\u{1F441}'}
                  </Button>
                )}
                {(CLstatus[k] !== null || CLDetailwatched[k] === true) && (
                  <Button variant="outlined"
                    style={{ height: 40, marginLeft: 5, minWidth: i.type === 'inform' ? 70 : 30, maxWidth: i.type === 'inform' ? 70 : 30, border: 'solid 2px ' + colorAccept, color: colorAccept, fontSize: 25 }}
                    onClick={() => changeCLstatus(k, 1)}
                  >
                    {'\u{2713}'}
                  </Button>
                )}
                {(CLstatus[k] !== null || CLDetailwatched[k] === true) && (
                  <Button variant="outlined"
                    style={{ height: 40, marginLeft: 5, minWidth: 30, maxWidth: 30, display: i.type === 'inform' ? 'none' : 'button', border: 'solid 2px ' + colorRefuse, color: colorRefuse, fontSize: 25 }}
                    onClick={() => changeCLstatus(k, -1)}
                  >
                    {'\u{2717}'}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        );
      });
      return x;
    }
  }, [props.formData, CLstatus, CLDetailwatched])

  useEffect(async () => {
    // console.log('useEffect--------');
    // console.log(props.formData.id);
    let c = {};
    let w = {};
    if (props.editConsent === true) {
      if (typeof props.formData.id !== 'undefined') {
        let response = await UAPI.get(props.formData.id, "teamusers");
        if (response.status === 200) {
          if (typeof response.data.informConsent !== 'undefined') {
            let UICD = response.data.informConsent.OSCC;
            setUserInformConsentData(UICD);
            // console.log(UICD);
            // ทำ setCLstatus(c) setCLDetailwatched(w) setCountAllConsent(Object.keys(c).length)
            if (Object.keys(UICD.inform).length > 0) {
              for (const [k, v] of Object.entries(UICD.inform.heading)) {
                c[k] = v;
                w[k] = true;
              };
            }
            if (Object.keys(UICD.consent).length > 0) {
              for (const [k, v] of Object.entries(UICD.consent.heading)) {
                c[k] = v;
                w[k] = true;
              };
            }
          }
        }
      }
    }
    else {
      let informData = {};
      let consentData = {};
      // console.log(props.userTypeId);
      // console.log(props);
      if (parseInt(props.userTypeId) === 1) {
        informData = CRDS.child.inform;
        consentData = CRDS.child.consent;
      }
      if (parseInt(props.userTypeId) === 2) {
        informData = CRDS.parent.inform;
        consentData = CRDS.parent.consent;
      }
      if (parseInt(props.userTypeId) === 3) {
        informData = CRDS.officer.inform;
        consentData = CRDS.officer.consent;
      }
      setInitialInformConsent({ inform: informData, consent: consentData });

      if (Object.keys(informData).length > 0) {
        informData.heading.forEach(i => {
          c[i] = null;
          w[i] = false;
        });
      }
      if (Object.keys(consentData).length > 0) {
        consentData.heading.forEach(i => {
          c[i] = null;
          w[i] = false;
        });
      }
    }
    setCLstatus(c);
    setCLDetailwatched(w);
    setCountAllConsent(Object.keys(c).length);
  }, [props]);

  useEffect(() => {
    let c = JSON.parse(JSON.stringify(CLstatus));
    let cCountAll = Object.keys(c).length;
    if (cCountAll > 0) {
      let cCountConsented = 0;
      for (const [k, v] of Object.entries(c)) {
        if (v === true) {
          ++cCountConsented;
        }
      }
      setCountConsented(cCountConsented);
      if (cCountAll === cCountConsented) {
        // console.log(cCountAll, 'PASS!');
        setDisabledButtonAccept(false);
      }
      else {
        // setDisabledButtonAccept(true);
        if (consentCount>0) {
          setDisabledButtonAccept(true);
        }
        else {
          setDisabledButtonAccept(false);
        }
      }
    }
    setCountAllConsent(cCountAll);
  }, [CLstatus]);

  return (
    <div>
      <Dialog fullWidth={true} maxWidth={"lg"} open={props.dialogOpen}>
        <DialogTitle disableTypography>
          <div style={{ textAlign: 'right', padding: 0, margin: -20, display: props.editConsent ? 'block' : 'none' }}>
            <IconButton aria-label="close" color="default" onClick={closeConsent} >
              <MdCloseIcon />
            </IconButton>
          </div>
          <h3>
            {t('Inform and consent')}
          </h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>
            <div>
              <Grid container justify="space-between">
                <Grid item xs={12} sm={12}>
                  {showConsentList}
                </Grid>
              </Grid>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Grid container justify="center">
              {consentCount === 0 && (
                <Grid item xs={12} sm={2} style={{ padding: 5 }}>
                  <Button variant="contained" color="primary" fullWidth
                    startIcon={<MdCheckIcon />}
                    onClick={() => changeResult(true)}
                    disabled={disabledButtonAccept}
                  >
                    {t('Save')} ({countConsented}/{countAllConsent})
                  </Button>
                </Grid>
              )}
              {consentCount > 0 && (
                <Grid item xs={12} sm={2} style={{ padding: 5 }}>
                  <Button variant="contained" color="primary" fullWidth
                    startIcon={<MdCheckIcon />}
                    onClick={() => changeResult(true)}
                    disabled={disabledButtonAccept}
                  >
                    {t('Accept')} ({countConsented}/{countAllConsent})
                  </Button>
                </Grid>
              )}
              {consentCount > 0 && (
                <Grid item xs={12} sm={2} style={{ padding: 5 }}>
                  <Button variant="contained" fullWidth
                    startIcon={<MdCloseIcon />}
                    onClick={() => changeResult(false)}
                  >
                    {t('Not accept')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConsentRegister;
