/* eslint-disable no-unused-vars */
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import styles from "../../../assets/jss/material-dashboard-react/components/buttonStyle";

const useStyles = makeStyles(styles);

export default function RegularButton(props) {
  const classes = useStyles();
  const {
    color,
    round,
    children,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    muiClasses,
    variant,
    loading,
    onClick,
    type,
    style,
    fullWidth,
    ...rest
  } = props;
  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className,
  });
  if (loading) {
    return (
      <Button 
        {...rest}
        variant={variant}
        disabled 
        fullWidth={fullWidth}
        type={(type && type!=="")?type:"button"}
        classes={muiClasses} 
        className={btnClasses}
        style={style}
        startIcon={<CircularProgress size={18}/>}
        >
        {children}
      </Button>
    );
  } else {
    return (
      <Button 
        variant={variant}
        disabled={disabled}
        fullWidth={fullWidth}
        type={(type && type!=="")?type:"button"}
        onClick={onClick}
        style={style}
        classes={muiClasses} 
        className={btnClasses}
        {...rest} >
        {children}
      </Button>
    );
  }
}

RegularButton.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "transparent",
  ]),
  size: PropTypes.oneOf(["sm", "lg"]),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
  style: PropTypes.object,
  fullWidth: PropTypes.bool,
  // use this to pass the classes props from Material-UI
  muiClasses: PropTypes.object,
  children: PropTypes.node,
};
