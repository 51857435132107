import { Button, Grid, makeStyles } from "@material-ui/core";
import { DataGrid, GridCellParams, GridColDef, GridRowData } from "@material-ui/data-grid";
import React from "react"
import { QC } from "./hooks/types";

interface Props {
  prepareAnswerData?: (row: GridCellParams["row"], questionnaire_code: string) => void;
  loading: boolean;
  data: GridRowData[];
}

const useStyles = makeStyles({
  root: {
    '& .MuiDataGrid-columnHeader': {
      padding: 0,
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0
    },
    '& .MuiDataGrid-cell': {
      padding: '0',
    },
  },
  cellValue: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minHeight: '24px'
  }
});

const ReportDataGrid: React.FC<Props> = ({loading,data,prepareAnswerData}) => {
  const classes = useStyles();
  const columns: GridColDef[] = [
    {
      field: 'id', 
      headerName: '#', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      minWidth: 50,
      flex: 0.03,
    },
    { 
      field: 'member1',
      headerName: 'วิทยากร', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'left',
      minWidth: 150,
      flex: 0.07,
      renderCell: (params: GridCellParams)=> {
        return <Grid container direction="column" spacing={1} style={{lineHeight:'1rem',justifyContent:'center'}}>
          <Grid item xs={12} className={classes.cellValue}>{params.row.member1}</Grid>
          <Grid item xs={12} className={classes.cellValue}>{params.row.member2}</Grid>
        </Grid>
      }
    },
    { 
      field: 'teamName',
      headerName: 'พื้นที่ / กลุ่ม', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'left',
      minWidth: 200,
      flex: 0.13,
      renderCell: (params: GridCellParams)=> {
        return <Grid container direction="column" spacing={1} style={{lineHeight:'1rem',justifyContent:'center'}}>
          <Grid item xs={12} className={classes.cellValue}>ทีม : {params.row.teamName.replace('ทีมพื้นที่','')}</Grid>
          {(params.row.groupName) && <Grid item xs={12} className={classes.cellValue}>กลุ่ม : {params.row.groupName.replace('ทีมพื้นที่','').replace('กลุ่ม ','')}</Grid>}
        </Grid>
      }
    },
    { 
      field: 'familyCount',
      headerName: 'ครอบครัว', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      minWidth: 70,
      flex: 0.04
    },
    ...(QC.map(q => (
        { 
          field: q,
          headerName: q, 
          headerClassName: 'apm-header', 
          sortable: false, 
          headerAlign: 'center', 
          align: "center",
          minWidth: 40,
          flex: 0.03,
          renderCell: params => (params.row[q]) ? 
            (<Button size="small" color="primary" onClick={()=>prepareAnswerData?.(params.row,q)}>{params.row[q]}</Button>) :
            params.row[q]
        } as GridColDef
      ))
    ),
    { 
      field: "total",
      headerName: "รวม", 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      minWidth: 70,
      flex: 0.03
    }
  ];

  return (
    <div className={classes.root}>
      <DataGrid
        rows={data||[]}
        columns={columns}
        loading={loading}
        headerHeight={80}
        autoHeight
        hideFooter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        disableDensitySelector
        style={{minHeight:'50dvh'}}
      />
    </div>
  )
}

export default ReportDataGrid;