/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import useGlobal from "../../store";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import * as UAPI from "../../services/UniversalAPI";
import * as SI from "../../services/SaveIntervention";
import UCard from "../../services/universal/UniversalCard";
import CaseInfoBar from "./CaseInfoBar";
import ConsentServiceSlot from "../consent/ConsentServiceSlot";

import { useTranslation } from "react-i18next";

import {
  Grid,
  Button,
  ButtonGroup,
  CircularProgress,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  TextField,
} from "@material-ui/core";

import {
  MdRemoveRedEye as MdRemoveRedEyeIcon,
  MdAssignment as MdAssignmentIcon,
  MdUndo as MdUndoIcon
} from 'react-icons/md';
import { setFocusedCaseStatus } from '../../store/actions';

const useStyles = makeStyles((theme) => ({
  // const useStyles = makeStyles({
  TextField: {
    minHeight: 30,
    padding: 0,
    paddingLeft: 5,
  },
  TextFieldScore: {
    minHeight: 30,
    padding: 0,
    paddingLeft: 0,
    textAlign: 'center'
  },
  // backdrop: {
  //   zIndex: theme.zIndex.drawer + 1,
  //   color: '#fff',
  // },
  tdQDetail: {
    verticalAlign: 'top',
  },
  thead: {
    backgroundColor: '#cdedff'
  },
  trA: {
    backgroundColor: 'transparent'
  },
  trB: {
    backgroundColor: '#efefef'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 2000,
    color: "#fff",
  },
}));


export default function Cases(props) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [personData, setPersonData] = useState({});
  const [planService, setPlanService] = useState([]);
  const [evaluationData, setEvaluationData] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [globalState, globalActions] = useGlobal();
  const [thisFocusedCaseStatus, setThisFocusedCaseStatus] = useState(globalState.focusedCaseStatus);
  const [thisFocusedCaseData, setThisFocusedCaseData] = useState({});
  const [hcode, setHcode] = useState(globalState.currentUser.user.department.hcode);
  const [hname, setHname] = useState(globalState.currentUser.user.department.hos_name);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [dialogSet, setDialogSet] = useState('');
  const [openMatchDialog, setOpenMatchDialog] = useState(false);
  const [matchUserId, setMatchUserId] = useState('');
  const [matchRelativeUserId, setMatchRelativeUserId] = useState('');
  const [matchRelativeTypeId, setMatchRelativeTypeId] = useState('');
  const [userCaseData, setUserCaseData] = useState([]);
  const [userRelativeData, setUserRelativeData] = useState([]);
  const [relativeData, setRelativeData] = useState([
    { relativeId: 1, relativeName: 'แม่ผู้ให้กำเนิด' },
    { relativeId: 2, relativeName: 'พ่อผู้ให้กำเนิด' },
    { relativeId: 3, relativeName: 'แม่เลี้ยง/พ่อเลี้ยง' },
    { relativeId: 4, relativeName: 'พี่หรือน้อง/พี่บุญธรรมหรือน้องบุญธรรม' },
    { relativeId: 5, relativeName: 'ปู่ ตา / ย่า ยาย' },
    { relativeId: 6, relativeName: 'ทวด' },
    { relativeId: 7, relativeName: 'ลุง / ป้า / น้า / อา' },
    { relativeId: 8, relativeName: 'ญาติ' },
    { relativeId: 9, relativeName: 'พ่อแม่อุปถัมภ์' },
    { relativeId: 10, relativeName: 'อื่นๆ' }
  ]);

  const [openConsent, setOpenConsent] = useState(false);
  const [informData, setInformData] = useState({});
  const [consentData, setConsentData] = useState({});
  const [focusedActivity, setFocusedActivity] = useState({});

  const [careGiverData, setCareGiverData] = useState({
    cid : null,
    fname: null,
    lname : null,
    nameCalledByCase : null,
    relation : '',
    relationSpecify : null,
  });
  const [disableSaveButton, setDisableSaveButton] = useState(true);



  const getPlanServiceData = async (param_personData) => {
    // console.log('getPlanServiceData----');
    let filter = {
      filter: {
        include: {
          relation: 'serviceslot'
        },
        where: {
          // osccPersonId: personId
          osccPersonId: props.location.state.id
        }
      }
    };
    let response = await UAPI.getAll(filter, "planservices");
    console.log("planservices",response);
    if (response.status === 200) {
      if (response.data) {
        // console.log(response.data);
        setPlanService(response.data);
        let x = response.data;
        let allSS = [];
        let evaluation = [];

        let filterB = {
          filter: {
            where: {
              hcode: globalState.currentUser.user.department.hcode,
            }
          }
        };
        let responseB = await UAPI.getAll(filterB, "serviceslots");
        console.log("serviceslots",responseB);
        if (responseB.status === 200) {
          if (responseB.data) {
            allSS = responseB.data;
            let interventionIdList = [];
            let personId="";
            x.forEach((i) => {
              i.serviceslotList.forEach( (ev) => {
                if (typeof ev.interventionId !== 'undefined') {
                  if (ev.interventionId !== null && ev.interventionId !== '') {
                    interventionIdList.push(ev.interventionId);
                  }
                }
                let ed = ev;
                let questionnaireId = null;
                let questionnaire = null;
                let inform = null;
                let consent = null;
                allSS.forEach(s => {
                  if (s.id === ev.serviceslotId) {
                    questionnaireId = s.resultTemplate[0].id;
                    questionnaire = s.resultTemplate[0];
                    inform = s.inform;
                    consent = s.consent;
                  }
                });
                ed['planServiceId'] = i.id;
                ed['questionnaireId'] = questionnaireId;
                ed['questionnaire'] = questionnaire;
                ed['inform'] = inform;
                ed['consent'] = consent;
                evaluation.push(ed);
              });
            });
            console.log("interventionIdList",interventionIdList);
            if (interventionIdList.length > 0) {
              let filterC_or = [];
              interventionIdList.forEach(iil => {
                filterC_or.push({ id: iil });
              });
              let filterC = {
                filter: {
                  where: {
                    or: filterC_or
                  }
                }
              };
              let responseC = await UAPI.getAll(filterC, "interventions");
              console.log("interventions",responseC);
              if (responseC.status === 200) {
                if (responseC.data) {
                  responseC.data.forEach(i1 => {
                    i1.activities.homeservice.forEach(i2 => {
                      if (i2.serviceType==="oscc") {
                        i2.questionnaire.forEach(i3 => {
                          evaluation.forEach(i4 => {
                            if (i4.interventionId===i1.id && i4.planServiceId===i3.planServiceId && i4.sequenceId===i3.sequenceId) {
                              i4.inform=i3.inform;
                              i4.consent=i3.consent;
                              i4['interventionData']=i1;
                            }
                          });
                        });
                      }
                    });
                  });
                  // console.log(evaluation);
                  setEvaluationData(evaluation);
                }
              }
            }else {
              
              setEvaluationData(evaluation);
            }

            if (typeof props.location.state.answerData != 'undefined') {
              if (Object.keys(props.location.state.answerData).length > 0) {
                let temp_personData = personData;
                if (typeof param_personData !== 'undefined') {
                  temp_personData=param_personData;
                }
                clickSave(temp_personData, response.data);
              }
            }

          }
        }
      }
    }
    return null;
  }

  const clickSave = async (person_data, planservice_data) => {
    setOpenBackdrop(true);
    // SI.saveInterventions(personData, planservice_data,param_answerData,param_userId,param_userFullName, param_hcode, param_hname)
    await SI.saveInterventions(
      // props.location.state,
      person_data,
      planservice_data,
      props.location.state.answerData,
      globalState.currentUser.user.id,
      globalState.currentUser.user.fullname,
      globalState.currentUser.user.department.hcode,
      globalState.currentUser.user.department.hos_name,
    );

    // console.log(props.location.state);
    // console.log(planservice_data);
    // console.log(props.location.state.answerData);
    // console.log(globalState.currentUser.user.id);
    // console.log(globalState.currentUser.user.fullname);
    // console.log(globalState.currentUser.user.department.hcode);
    // console.log(globalState.currentUser.user.department.hos_name);

    let stateData = props.location.state;
    delete stateData['answerData'];
    // history.replace('/caseplanlist', stateData);
    // getPlanServiceData();
    closeBackdrop();
  }

  const getPersonData = async (cid) => {
    let xParams = {
      filter: {
        limit: 1,
        where: { cid: cid },
      }
    };
    let response = await UAPI.getAll(xParams, 'people', null);
    if (response.status === 200) {
      if (response.data.length > 0) {
        let d = response.data[0];
        let tambolInfo = "";
        let paramTambol = {
          filter: {
            limit: 1,
            where: { addressid: d.chwpart + d.amppart + d.tmbpart }
          }
        };
        let responseTambol = await UAPI.getAll(paramTambol, "tambols");
        if (responseTambol.status === 200) {
          if (responseTambol.data.length > 0) {
            // console.log(responseTambol.data);
            tambolInfo = responseTambol.data[0].full_name;
          }
        }
        d['addressInfo'] = d.addrpart + ' ม.' + parseInt(d.moopart) + ' ' + tambolInfo;
        d['riskLevel'] = props.location.state['riskLevel'];
        d['severityType'] = props.location.state['severityType'];
        // console.log(d);
        setPersonData(d);
        getPlanServiceData(d);
      }
    }
  }

  const getCaseUserData = async (cid) => {
    let xParams = {
      filter: {
        where: {
          and: [
            { application: { in: ['OSCC'] } },
            { "userType.userTypeId": { in: [1, 2] } }
          ]
        },
      }
    };
    let response = await UAPI.getAll(xParams, 'teamusers', null);
    if (response.status === 200) {
      let udCase = [];
      let udRelative = [];
      if (response.data.length > 0) {
        response.data.forEach(i => {
          if (i.userType.userTypeId === 1) {
            udCase.push(i);
          }
          if (i.userType.userTypeId === 2) {
            udRelative.push(i);
          }
        });
        setUserCaseData(udCase);
        setUserRelativeData(udRelative);
        saveAutoMatchUser(udCase);
      }
    }
  }

  const currentDate = () => {
    let d = new Date();
    let dY = d.getFullYear().toString();
    let dM = addZero(d.getMonth() + 1, 2);
    let dD = addZero(d.getDate(), 2);
    return dY + '-' + dM + '-' + dD;
  }

  const currentTime = () => {
    let d = new Date();
    let dHH = addZero(d.getHours(), 2);
    let dMM = addZero(d.getMinutes(), 2);
    let dSS = addZero(d.getSeconds(), 2);
    return dHH + ':' + dMM + ':' + dSS;
  }

  const calVN = () => {
    let d = new Date();
    let dY = (d.getFullYear() + 543).toString().substr(-2);
    let dM = addZero(d.getMonth() + 1, 2);
    let dD = addZero(d.getDate(), 2);
    let dHH = addZero(d.getHours(), 2);
    let dMM = addZero(d.getMinutes(), 2);
    let dSS = addZero(d.getSeconds(), 2);
    return dY + dM + dD + dHH + dMM + dSS;
  }

  const addZero = (n, z) => {
    let r = n.toString();
    for (var i = (parseInt(z) - n.toString().length); i > 0; i--) {
      r = '0' + r;
    }
    return r;
  }

  const closeBackdrop = () => {
    setTimeout(() => {
      setOpenBackdrop(false);
    }, 100);
  }

  const rowServiceSlot = () => {
    let r = [];
    if (evaluationData.length > 0) {
      let n = 0;
      let rowColor = "#FFFFFF";
      let status = false;
      let score = null;
      // console.log(globalState.currentUser.user.userType.userTypeId);
      let ss = evaluationData;
      ss.sort(function (a, b) {
        // b-a เรียงมากไปน้อย
        // a-b เรียงน้อยไปมาก
        let dateA = a.startDate;
        let dateB = b.startDate;
        dateA = (typeof dateA === 'undefined'?'0000-00-00':dateA);
        dateB = (typeof dateB === 'undefined'?'0000-00-00':dateB);
        dateA = (dateA === null|dateA===''?'0000-00-00':dateA);
        dateB = (dateB === null|dateB===''?'0000-00-00':dateB);
        let ax = parseInt((new Date(dateA).getTime()).toString());
        let bx = parseInt((new Date(dateB).getTime()).toString());
        ax = (isNaN(ax) ? 0 : ax);
        bx = (isNaN(bx) ? 0 : bx);
        return ax - bx;
        // return bx - ax;
      });
      // evaluationData.forEach(i => {
      // console.log(ss);
      ss.forEach(i => {
        console.log(i);
        let userTypeVerified = false;
        if (globalState.currentUser.user.userType.userTypeId === 3 && [1, 2, 3].indexOf(parseInt(i.answererId)) > -1) {
          // console.log(i.answererId, [1,2,3].indexOf(parseInt(i.answererId)));
          userTypeVerified = true;
        }
        if (globalState.currentUser.user.userType.userTypeId === 4 && [4].indexOf(parseInt(i.answererId)) > -1) {
          // console.log(i.answererId, [1,2,3].indexOf(parseInt(i.answererId)));
          userTypeVerified = true;
        }

        if (userTypeVerified === true) {
          ++n;
          if (n % 2 === 0) {
            rowColor = "#EEEEEE";
          }else {
            rowColor = "#FFFFFF";
          }

          if (typeof i.status != 'undefined') {
            if (i.status === 'completed') {
              status = i.status
            }
          }

          if (typeof i.score !== 'undefined') {
            if (i.score !== null && i.score.toString().trim() !== '') {
              score = parseInt(i.score);
            }
          }

          let acceptConsent = false;
          let consented = false;
          if (typeof i.consent !== 'undefined') {
            if (i.consent) {
              if (typeof i.consent.result !== 'undefined') {
                if (i.consent.result === 'consented') {
                  acceptConsent = true;
                  consented = true;
                } 
              } 
            }
          }
          
          let workStatus = "กำลังดำเนินการ";
          let workStatusColor = "#FF7A00";
          if (typeof i.interventionId !== 'undefined') {
            if (i.interventionId !== '' && i.interventionId !== null) {
              workStatus = "ดำเนินการแล้ว";
              workStatusColor = "#47C10D";
              if (acceptConsent===false) {
                workStatus = "ไม่ยอมรับConsent";
                workStatusColor = "red";
              }
            }
          }
          // console.log(i.consent.result,acceptConsent,workStatus);
          r.push(
            <Grid key={n} item xs={12} sm={12} style={{ border: 'solid 1px #BBBBBB', borderRadius: 7, marginTop: 10, padding: 5, backgroundColor: rowColor }}>
              <Grid container justify="flex-start" direction={isDesktop ? "row" : "column-reverse"}>
                <Grid item xs={12} sm={11} style={{ marginTop: isDesktop ? 0 : -25 }}>
                  <Grid container>
                    <Grid item xs={12} sm={1}>
                      {isDesktop ? <b>&nbsp;&nbsp;</b> : <b>ลำดับ: </b>}
                      {n}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      {isDesktop ? '' : <b>แบบสอบถาม/กิจกรรม: </b>}
                      {i.serviceslotName}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      {isDesktop ? '' : <b>ผู้บันทึกคำตอบ: </b>}
                      {i.answererName}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      {isDesktop ? '' : <b>เริ่ม: </b>}
                      {UAPI.dateTHs(i.startDate)}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      {isDesktop ? '' : <b>สิ้นสุด: </b>}
                      {UAPI.dateTHs(i.endDate)}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      {isDesktop ? '' : <b>สถานะ: </b>}
                      <div style={{ backgroundColor: workStatusColor, color: '#FFFFFF', borderRadius: 15, display: 'inline-block', paddingLeft: 10, paddingRight: 10, height: 29 }}>{workStatus}</div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={1}>
                  <Grid container justify="flex-end">
                    {(workStatus === "กำลังดำเนินการ" || workStatus === "ไม่ยอมรับConsent") ? (
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<MdAssignmentIcon style={{ marginLeft: 11, marginTop: -2 }} />}
                        style={{ height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white', marginRight: 0 }}
                        onClick={() => {                      
                          if(consented){
                            goQuestionnaire(i.inform, i.consent, i);
                          }else{
                            setInformData(i.inform);
                            setConsentData(i.consent);
                            setOpenConsent(true);
                            setFocusedActivity(i);
                          }
                        }}
                      />
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<MdAssignmentIcon style={{ marginLeft: 11, marginTop: -2 }} />}
                          style={{ height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white', marginRight: 0 }}
                          onClick={() => {
                            // console.log(i);
                            setInformData(i.inform);
                            setConsentData(i.consent); 
                            // คอมโพเน้น ConsentServiceSlot จะเช็คถ้ามี consent.result จะแสดงหน้าต่าง consent ในโหลดแก้ไข
                            setOpenConsent(true);
                            setFocusedActivity(i);
                          }}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<MdRemoveRedEyeIcon style={{ marginLeft: 11, marginTop: -2 }} />}
                          style={{ height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white', marginRight: 0 }}
                          onClick={() => {
                            // console.log(i);
                            setFocusedActivity(i);
                            goQuestionnaire(i.inform, i.consent, i);
                          }}
                        />
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<MdUndoIcon style={{ marginLeft: 11, marginTop: -2 }} />}
                          style={{ height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white', marginRight: 0 }}
                          onClick={() => {
                            // console.log(i);
                            setDialogSet('resetAnswer');
                            setDialogText('ต้องการลบคำตอบที่เคยบันทึกไว้ ใช่หรือไม่!?');
                            setOpenDialog(true);
                            setFocusedActivity(i);
                            // goQuestionnaire(i.inform, i.consent, i);
                          }}
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        }
      });
    }
    return r;
  }

  const confirmResetAnswer = async () => {
    setOpenBackdrop(true);
    let responseI = await UAPI.get(focusedActivity.interventionId, "interventions", '3009');
    if (responseI.status === 200) {
      if (responseI.data) {
        let x = responseI.data;
        let z = x.activities.homeservice;
        let temp_z = [];
        z.forEach(Zi => {
          if (parseInt(Zi.sequenceId)!==parseInt(focusedActivity.sequenceId)) {
            temp_z.push(Zi);
          }
        });
        x.activities.homeservice=temp_z;

        let responseG = await UAPI.update(focusedActivity.interventionId, x, "interventions", '3009');
        // let responseG = await API.remove(focusedActivity.interventionId, "interventions", '3009');
        if (responseG.status === 200) {
          if (responseG.data) {
            // console.log(responseG.data);
            // console.log(evaluationData);
            // console.log(planService);
            let thisPlan = {};
            planService.forEach(i => {
              if (i.id === focusedActivity.planServiceId) {
                // console.log(i);
                thisPlan = i;
              }
            });
            if (Object.keys(thisPlan).length>0) {
              thisPlan.serviceslotList.forEach(i => {
                if (i.sequenceId===focusedActivity.sequenceId) {
                  // console.log(i);
                  delete i['interventionData'];
                  delete i['interventionDate'];
                  delete i['interventionId'];
                }
              });
            }
            // console.log(thisPlan);
            let responseU = await UAPI.update(thisPlan.id, thisPlan, "planservices", '3009');
            if (responseU.status === 200) {
              if (responseU.data) {
                // console.log(responseU.data);
                setOpenBackdrop(false);
                setOpenDialog(false);
                getPlanServiceData();
              }
            }
          }
        }
      }
    }
  }

  const confirmRecieveCase = async () => {
    let x = JSON.parse(JSON.stringify(props.location.state));
    if (x.caseStatus === 'assignedOfficer') {
      delete x['focusedCaseStatus'];
      x['caseStatus'] = 'recievedCase';
      let responseUpdate = await UAPI.update(x.id, x, 'osccpeople', '3009');
      if (responseUpdate.status === 200) {
        if (responseUpdate.data) {
          // เปลี่ยนเป็นรับเคสอัตโนมัติตามคอมเม้นต์ อ.ชาญวิทย์
          // history.push({ pathname: '/cases', state: { status: thisFocusedCaseStatus } });
          history.push({ pathname: '/caseplanlist', state: x });
        }
      }
    }
  }

  const confirmCancelSentCM = async () => {
    let x = JSON.parse(JSON.stringify(props.location.state));
    x.caseStatus = 'recievedCase';
    // console.log(x);
    let responseUpdate = await UAPI.update(x.id, x, 'osccpeople', '3009');
    if (responseUpdate.status === 200) {
      if (responseUpdate.data) {
        history.push({ pathname: '/cases', state: { status: thisFocusedCaseStatus } });
        // history.push({ pathname: '/cases', state: x });
      }
    }
  }

  const confirmCancelCase = async () => {
    let x = JSON.parse(JSON.stringify(props.location.state));
    x.caseStatus = 'assignedOfficer';
    // console.log(x);
    let responseUpdate = await UAPI.update(x.id, x, 'osccpeople', '3009');
    if (responseUpdate.status === 200) {
      if (responseUpdate.data) {
        history.push({ pathname: '/cases', state: { status: thisFocusedCaseStatus } });
      }
    }
  }

  const confirmSentCM = async () => {
    // console.log('confirmSentCM ---');
    let x = JSON.parse(JSON.stringify(props.location.state));
    x.caseStatus = 'sentCM';
    // console.log(x);
    let responseUpdate = await UAPI.update(x.id, x, 'osccpeople', '3009');
    if (responseUpdate.status === 200) {
      if (responseUpdate.data) {
        history.push({ pathname: '/cases', state: { status: thisFocusedCaseStatus } });
      }
    }
  }

  const changeSelect = (e) => {
    if (e.target.name === 'matchUserId') {
      setMatchUserId(e.target.value);
    }
    if (e.target.name === 'matchRelativeUserId') {
      setMatchRelativeUserId(e.target.value);
    }
    if (e.target.name === 'matchRelativeTypeId') {
      setMatchRelativeTypeId(e.target.value);
    }
  }

  const saveAutoMatchUser = async (uData) => {
    let x = JSON.parse(JSON.stringify(props.location.state));
    if (x.caseStatus === 'assignedOfficer' || x.caseStatus === 'recievedCase') {
      delete x['focusedCaseStatus'];
      if (x.caseStatus === 'assignedOfficer') {
        x['caseStatus'] = 'recievedCase';
      }
      if (typeof x.caseUserId !== 'undefined') {
        setMatchUserId(x.caseUserId);
      }
      if (typeof x.caseUserId === 'undefined') {
        setOpenBackdrop(true);
        let matchedUser = {};
        uData.forEach(i => {
          if (i.cid === x.cid) {
            matchedUser = i;
            setMatchUserId(i.id);
          }
        });
        x.caseUserId = matchedUser.id;
        let responseUpdate = await UAPI.update(x.id, x, 'osccpeople', '3009');
        if (responseUpdate.status === 200) {
          if (responseUpdate.data) {
            setTimeout(() => {
              setOpenBackdrop(false);
            }, 100);
          }
        }
      }
    }
  }

  const clickSaveMatchUser = async () => {
    // console.log('clickSaveMatchUser');
    setOpenBackdrop(true);
    let x = JSON.parse(JSON.stringify(props.location.state));
    delete x['focusedCaseStatus'];
    if (matchUserId !== '') {
      x.caseUserId = matchUserId;
    }
    else {
      delete x['caseUserId'];
    }
    let responseUpdate = await UAPI.update(x.id, x, 'osccpeople', '3009');
    if (responseUpdate.status === 200) {
      if (responseUpdate.data) {
        setTimeout(() => {
          setOpenBackdrop(false);
        }, 100);
      }
    }
  }

  const clickSaveMatchRelativeUser = async () => {
    // console.log('clickSaveMatchRelativeUser');
    setOpenBackdrop(true);
    let x = JSON.parse(JSON.stringify(props.location.state));
    delete x['focusedCaseStatus'];
    if (matchRelativeUserId !== '') {
      let relativeName = '';
      let relativeCid = '';
      let relativeFullName = '';
      relativeData.forEach(i => {
        if (parseInt(i.relativeId) === parseInt(matchRelativeTypeId)) {
          relativeName = i.relativeName;
        }
      });
      userRelativeData.forEach(i => {
        if (i.id === matchRelativeUserId) {
          relativeCid = i.cid;
          relativeFullName = i.fullname;
        }
      });
      x.careGiverUser = {
        userId: matchRelativeUserId,
        relativeId: matchRelativeTypeId,
        relativeName: relativeName,
        cid: relativeCid,
        fullname: relativeFullName
      };
    }
    else {
      delete x['careGiverUser'];
    }
    let responseUpdate = await UAPI.update(x.id, x, 'osccpeople', '3009');
    if (responseUpdate.status === 200) {
      if (responseUpdate.data) {
        setTimeout(() => {
          setOpenBackdrop(false);
        }, 100);
      }
    }
  }
  const saveConsent = async (consentData,focusedActivity)=>{
    if(focusedActivity.serviceslotId){
      const params = {
        consent : consentData
      };
      UAPI.patch(focusedActivity.serviceslotId, params,'serviceslots', '3009');
    }
  }
  const goQuestionnaire = async (informData, consentData, thisActivity) => {
    // ********* ถ้า notConsent ไม่ต้องทำแบบสอบถาม แต่บันทึก ผล consent และกระดาษคำตอบเปล่า ลง invertention ไปเลย
    // ********* ถ้า ยอมรับ consent ก็ redirect ไปทำแบบสอบถามเลย
    let isNotConsent=false;
    if (typeof thisActivity === 'undefined') {
      if (consentData.result==="notConsent") { 
        isNotConsent=true;
      }
      else {
        isNotConsent=false;
      }
    }
    else {
      isNotConsent=false;
    }

    if (isNotConsent) {
      let a = JSON.parse(JSON.stringify(focusedActivity));
      let answerData_blank={};
      answerData_blank['answerer']={ answererId: a.answererId, answererName: a.answererName };
      answerData_blank['consent']=consentData;
      answerData_blank['id']=a.questionnaireId;
      answerData_blank['inform']=informData;
      answerData_blank['oscctem']={ teamCode: a.teamCode, teamName: a.teamName };
      answerData_blank['planServiceId']=a.planServiceId;
      answerData_blank['questionnaire_code']=a.questionnaire.questionnaire_code;
      answerData_blank['questionnaire_name']=a.questionnaire.questionnaire_name;
      answerData_blank['questionnaire_type']=a.questionnaire.questionnaire_type;
      answerData_blank['questionsList']=a.questionnaire.questionsList;
      answerData_blank['sequenceId']=a.sequenceId;
      answerData_blank['sumScore']=0;
      // console.log(props.location.state);
      // console.log(planService);
      // console.log(answerData_blank);
      // console.log(globalState.currentUser.user.id);
      // console.log(globalState.currentUser.user.fullname);
      // console.log(globalState.currentUser.user.department.hcode);
      // console.log(globalState.currentUser.user.department.hos_name);
      await SI.saveInterventions(
        // props.location.state, 
        personData,
        planService,
        answerData_blank,
        globalState.currentUser.user.id,
        globalState.currentUser.user.fullname,
        globalState.currentUser.user.department.hcode,
        globalState.currentUser.user.department.hos_name,
        getPlanServiceData()
      );
      getPlanServiceData();
      // window.location.reload();
      // planService.forEach(aps => {
      //   if (aps.id===answerData_blank.planServiceId) {
      //     // console.log(aps);
      //     clickPlanDetail(aps);
      //   }
      // });
    }else {
      let i = JSON.parse(JSON.stringify(focusedActivity));
      if (typeof thisActivity !== 'undefined') {
        i = thisActivity;
      }
      let questionData = {
        returnAnswerTo: '/caseplanlist',
        veiwType: props.location.state.caseStatus === "assignedOfficer" || props.location.state.caseStatus === "sentCM" ? 'readonly' : 'recordable',
        id: i.questionnaireId,
        questionnaire: i.questionnaire,
        planServiceId: i.planServiceId,
        interventionId: (typeof i.interventionId === 'undefined' ? null : (i.interventionId === '' ? null : i.interventionId)),
        inform: informData,
        consent: consentData,
        sequenceId: i.sequenceId
      };
      console.log("questionData",questionData);
      // Save Consent Here.
      await saveConsent(consentData,focusedActivity);
      history.push({
        pathname: '/questionnaireview',
        state: {
          questionData: questionData,
          caseData: props.location.state,
          personData: personData
        }
      });
    }
  }

  const changeCareGiverData = (e) => {
    if (e.target.name==='cid') {
      const onlyNums = e.target.value.replace(/[^0-9]/g, '');
      if (onlyNums.length <= 13) {
        setCareGiverData({...careGiverData,...{cid:onlyNums}});
        if (onlyNums.length === 13) {
          setDisableSaveButton(false);
        }
        else {
          setDisableSaveButton(true);
        }
      }
    }
    else {
      setCareGiverData({...careGiverData,...{[e.target.name]:e.target.value}});
      if (careGiverData.cid.length === 13) {
        setDisableSaveButton(false);
      }
      else {
        setDisableSaveButton(true);
      }
    }
  }

  const clickSaveCareGiver = async () => {
    // console.log(careGiverData);
    // console.log(props.location.state);
    setOpenBackdrop(true);
    let data = {
      careGiver : careGiverData
    };
    let x = props.location.state;

    UAPI.patch(x.id, data, "osccpeople").then((responseU) => {
      if (responseU.status === 200) {
        // console.log(responseU.data);
        setOpenBackdrop(false);

        x['careGiver'] = careGiverData;
        history.replace('/caseplanlist', x);
      }
    });
  }

  useEffect(() => {
    if (typeof props.location.state != 'undefined') {

      if (typeof props.location.state.careGiver != 'undefined') {
        setCareGiverData({
          cid : props.location.state.careGiver.cid||'',
          fname: props.location.state.careGiver.fname||'',
          lname : props.location.state.careGiver.lname||'',
          nameCalledByCase : props.location.state.careGiver.nameCalledByCase||'',
          relation : props.location.state.careGiver.relation||'',
          relationSpecify : props.location.state.careGiver.relationSpecify||'',
        });
      }

      if (typeof props.location.state.cid != 'undefined') {
        getPersonData(props.location.state.cid);
        getCaseUserData(props.location.state.cid);
      }
      let x = props.location.state;
      if (globalState.focusedCaseStatus) {
        x['focusedCaseStatus'] = globalState.focusedCaseStatus;
      }
      setThisFocusedCaseData(x);
      // console.log(x);
      history.replace('/caseplanlist', x);
      setThisFocusedCaseStatus(props.location.state.focusedCaseStatus);
      confirmRecieveCase();
    }
  }, []); 

  const collection = {
    infoA: {
      fields: {
        fname: { show: true, title: 'ชื่อ', data_type: 'string', format: null },
        lname: { show: true, title: 'สกุล', data_type: 'string', format: null },
        cid: { show: true, title: 'เลขบัตรประชาชน', data_type: 'string', format: null },
        severityType: { show: true, title: 'ประเภทความรุนแรง', data_type: 'string', format: null },
        riskLevel: { show: true, title: 'ระดับความรุนแรง', data_type: 'string', format: null },
      }
    },
    infoB: {
      fields: {
        fullname: { show: true, title: 'ชื่อสกุล', data_type: 'string', format: null },
        cid: { show: true, title: 'เลขบัตรประชาชน', data_type: 'string', format: null },
        mobile: { show: true, title: 'ประเภทความรุนแรง', data_type: 'string', format: null },
      }
    },
  }
  // console.log(props);
  // console.log(props.location.state);

  return (
    <div style={{ marginBottom: 100 }}>

      <Backdrop
        className={classes.backdrop}
        open={openBackdrop}
      // onClick={closeBackdrop}
      >
        {/* <Backdrop className={classes.backdrop} open={true}> */}
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container style={{ marginTop: -10, marginBottom: 10 }} justify="center" direction={isDesktop ? "row" : "column-reverse"}>
        <Grid item xs={12} sm={6} >
          <h4>แบบสอบถาม/กิจกรรม</h4>
        </Grid>
        <Grid item xs={12} sm={6} >
          <Grid container justify="flex-end">
            <ButtonGroup size={isDesktop ? 'large' : 'medium'} fullWidth style={{ marginTop: 0, marginBottom: 0, borderRadius: 8, border: 'solid 4px #CDCDCD', padding: 0 }}>
              <Button color="primary" style={{ margin: 0, padding: 0 }}
                onClick={() => {
                  history.push({ pathname: '/cases', state: { status: thisFocusedCaseStatus } });
                }}
              >
                กลับ
              </Button>
              <Button color="primary" style={{ margin: 0, padding: 0 }} onClick={() => { history.push({ pathname: '/caseemr', state: props.location.state }); }}>
                EMR
              </Button>
              <Button color="primary" style={{ margin: 0, padding: 0 }} onClick={() => { setOpenMatchDialog(true); }}>
                ผู้ดูแล
              </Button>

              {/* {(props.location.state.caseStatus === "assignedOfficer" || props.location.state.caseStatus === "recievedCase") && (
                <Button color="primary" style={{ margin: 0, padding: 0 }} onClick={() => { setOpenMatchDialog(true); }}>
                  ผู้ดูแล
                </Button>
              )} */}

              {/* {props.location.state.caseStatus==="assignedOfficer"?(
                <Button color="primary" style={{ marginRight: 0 }} onClick={() => { setDialogSet('recieveCase'); setDialogText('ยืนยันรับเคสนี้ ใช่หรือไม่!?'); setOpenDialog(true); }}>
                  รับเคส
                </Button>
              ):(
                <Button color="secondary" style={{ marginRight: 0 }} onClick={() => { setDialogSet('cancelCase'); setDialogText('ยืนยันยกเลิกการรับเคสนี้ ใช่หรือไม่!?'); setOpenDialog(true); }}>
                  ยกเลิก
                </Button>
              )} */}

              {props.location.state.caseStatus === "recievedCase" ? (
                <Button color="secondary" style={{ margin: 0, padding: 0 }} onClick={() => { setDialogSet('cancelCase'); setDialogText('ยืนยันการปฏิเสธเคสนี้ ใช่หรือไม่!?'); setOpenDialog(true); }}>
                  ปฏิเสธเคส
                </Button>
              ) : (
                <Button color="secondary" style={{ margin: 0, padding: 0 }} disabled>
                  ปฏิเสธเคส
                </Button>
              )}

              {props.location.state.caseStatus === "recievedCase" && (
                <Button color="primary" style={{ margin: 0, padding: 0 }} onClick={() => { setDialogSet('sentCM'); setDialogText('ยืนยันส่งเคสนี้ให้ CM ใช่หรือไม่!?'); setOpenDialog(true); }}>
                  ส่ง CM
                </Button>
              )}

              {props.location.state.caseStatus === "sentCM" && (
                <Button color="secondary" style={{ margin: 0, padding: 0 }} onClick={() => { setDialogSet('cancelSentCM'); setDialogText('ยืนยัน ยกเลิกส่งเคสนี้ให้ CM ใช่หรือไม่!?'); setOpenDialog(true); }}>
                  ยกเลิกส่ง CM
                </Button>
              )}
            </ButtonGroup>
          </Grid>
        </Grid>
      </Grid>

      {/* {Object.keys(thisFocusedCaseData).length>0&&(
        <Grid container style={{ marginBottom: 10 }}>
          <Grid item xs={12} sm={12}>
            <UCard structure={collection.infoA} display_style="inline" data={thisFocusedCaseData} />
          </Grid>
        </Grid>
      )} */}
      {Object.keys(personData).length > 0 && (
        <CaseInfoBar data={personData} />
      )}

      <Grid container style={{ marginBottom: 10 }}>
        {/* TABLE HEAD */}
        {isDesktop ? (
          <Grid item xs={12} sm={12} style={{ border: 'solid 0px #666666', borderRadius: 7, backgroundColor: '#00a8ff', color: '#FFFFFF' }}>
            <Grid container justify="flex-start">
              <Grid item xs={12} sm={11}>
                <Grid container justify="flex-start">
                  <Grid item xs={12} sm={1}>
                    &nbsp;&nbsp;ลำดับ
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    รายการแบบสอบถาม/กิจกรรม
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    ผู้บันทึกคำตอบ
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    เริ่ม
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    สิ้นสุด
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    สถานะ
                  </Grid>
                  {/* <Grid item xs={12} sm={1}>
                    คะแนน
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={1}>
                &nbsp;&nbsp;
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} style={{ marginTop: 20, border: 'solid 0px #666666', borderRadius: 5, backgroundColor: '#00a8ff', color: '#FFFFFF' }}>
            &nbsp;&nbsp;รายการแบบสอบถาม/กิจกรรม
          </Grid>
        )}

        {/* TABLE ROWS */}
        {rowServiceSlot()}

      </Grid>

      <Dialog open={openDialog}>
        {/* <DialogTitle id="alert-dialog-title">เพิ่ม : </DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component={'div'}>
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* {dialogSet==='recieveCase'&&(
            <Button variant="contained" color="primary" onClick={confirmRecieveCase}>
              ยืนยัน
            </Button>
          )} */}
          {dialogSet === 'resetAnswer' && (
            <Button variant="contained" color="primary" onClick={confirmResetAnswer}>
              ยืนยัน
            </Button>
          )}          
          {dialogSet === 'cancelCase' && (
            <Button variant="contained" color="primary" onClick={confirmCancelCase}>
              ยืนยัน
            </Button>
          )}
          {dialogSet === 'sentCM' && (
            <Button variant="contained" color="primary" onClick={confirmSentCM}>
              ยืนยัน
            </Button>
          )}
          {dialogSet === 'cancelSentCM' && (
            <Button variant="contained" color="primary" onClick={confirmCancelSentCM}>
              ยืนยัน
            </Button>
          )}
          <Button variant="contained" autoFocus
            onClick={() => setOpenDialog(false)}
          >
            ปิด
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openMatchDialog} fullWidth={true} maxWidth={'md'}>
        <DialogTitle style={{ paddingBottom: 0 }}>บันทึกข้อมูลผู้ดูแล</DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>

            <Grid container style={{ marginBottom: 10 }}>
              <Grid item xs={12} sm={12} style={{ marginBottom: 5 }}>
                {/* เชื่อมโยง Case กับ User เพื่อให้ Case/ญาติ(ผู้ดูแล)สามารถตอบแบบสอบถามได้เอง */}
                บันทึกข้อมูลผู้ดูแลของผู้ที่มีความเสี่ยงรายนี้ เพื่อให้ผู้ดูแลสามารถตอบแบบสอบถามในระบบได้ด้วยตนเอง หลังจากผู้ดูแลลงทะเบียนและได้รับการอนุมัติ Username โดย เจ้าหน้าที่ (FA/รพ./รพ.สต.)
              </Grid>

              {/* <Grid item xs={12} sm={12}>
                <div style={{ border: 'solid 0px #E2E2E2', borderRadius: 7, marginBottom: 2, padding: 3, backgroundColor: '#006ce5', color: '#FFFFFF', paddingLeft: 15 }}>
                  ข้อมูลเคส
                </div>
                <UCard structure={collection.infoA} display_style="inline" data={thisFocusedCaseData} />
              </Grid>

              <Grid item xs={12} sm={12} style={{ marginTop: 10 }}>
                <Grid container>
                  
                  <Grid item xs={12} sm={12} div style={{ border: 'solid 0px #E2E2E2', borderRadius: 7, marginBottom: 2, padding: 3, backgroundColor: '#00bc35', color: '#FFFFFF', paddingLeft: 15 }}>
                    USER ของเคส
                  </Grid>

                  <Grid item xs={12} sm={11} style={{ paddingRight: 5, paddingBottom: 5 }}>
                    <Select fullWidth={true} variant="outlined" native
                      onChange={(e) => changeSelect(e)}
                      inputProps={{ name: "matchUserId" }}
                      value={matchUserId}
                    >
                      <option value={''}>-เลือก USER-</option>
                      {userCaseData.map((u) => (
                        <option key={u.id} value={u.id}>
                          ({u.cid}) {u.fullname}
                        </option>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={12} sm={1}>
                    <Grid container justify="flex-end">
                      <Button variant="contained" color="primary" fullWidth style={{ height: 55}} onClick={clickSaveMatchUser} >
                        บันทึก
                      </Button>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid> */}

              <Grid item xs={12} sm={12} style={{ marginTop: 10 }}>
                <Grid container>
                  <Grid item xs={12} sm={12} style={{ border: 'solid 0px #E2E2E2', borderRadius: 7, marginBottom: 2, padding: 3, backgroundColor: '#038000', color: '#FFFFFF', paddingLeft: 15 }}>
                    ข้อมูลผู้ดูแล
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Grid container>

                      <Grid item xs={12} sm={3} style={{ paddingRight: 5, paddingBottom: 5 }}>
                        <TextField
                          // size="small"
                          label="เลขบัตรประจำตัวประชาชน"
                          variant="outlined"
                          fullWidth
                          inputProps={{ name: "cid", style: { textAlign: 'left' } }}
                          value={careGiverData.cid||''}
                          onChange={changeCareGiverData}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2} style={{ paddingRight: 5, paddingBottom: 5 }}>
                        <TextField
                          // size="small"
                          label="ชื่อ"
                          variant="outlined"
                          fullWidth
                          inputProps={{ name: "fname", style: { textAlign: 'left' } }}
                          value={careGiverData.fname||''}
                          onChange={changeCareGiverData}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2} style={{ paddingRight: 5, paddingBottom: 5 }}>
                        <TextField
                          // size="small"
                          label="สกุล"
                          variant="outlined"
                          fullWidth
                          inputProps={{ name: "lname", style: { textAlign: 'left' } }}
                          value={careGiverData.lname||''}
                          onChange={changeCareGiverData}
                        />
                      </Grid>

                      <Grid item xs={12} sm={3} style={{ paddingRight: 5, paddingBottom: 5 }}>
                        <TextField
                          // size="small"
                          label="ชื่อที่เด็กเรียก"
                          variant="outlined"
                          fullWidth
                          inputProps={{ name: "nameCalledByCase", style: { textAlign: 'left' } }}
                          value={careGiverData.nameCalledByCase||''}
                          onChange={changeCareGiverData}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2} style={{ paddingRight: 5, paddingBottom: 5 }}>
                        <Select fullWidth={true} variant="outlined" native
                          onChange={(e) => changeCareGiverData(e)}
                          inputProps={{ name: "relation" }}
                          // value={matchRelativeTypeId}
                          value={careGiverData.relation}
                        >
                          <option value={''}>-เลือกความสัมพันธ์-</option>
                          {relativeData.map((u) => (
                            <option key={u.relativeId} value={u.relativeId}>
                              {u.relativeName}
                            </option>
                          ))}
                        </Select>
                      </Grid>

                      <Grid item xs={12} sm={2} style={{ paddingRight: 5, paddingBottom: 5 }}>
                        <TextField
                          // size="small"
                          label="ระบุความสัมพันธ์"
                          variant="outlined"
                          fullWidth
                          inputProps={{ name: "relationSpecify", style: { textAlign: 'left' } }}
                          value={careGiverData.relationSpecify||''}
                          onChange={changeCareGiverData}
                        />
                      </Grid>

                      <Grid item xs={12} sm={1}>
                        <Grid container justify="flex-end">
                          <Button variant="contained" color="primary" fullWidth style={{ height: 55}} disabled={disableSaveButton} onClick={clickSaveCareGiver} >
                            บันทึก
                          </Button>
                        </Grid>
                      </Grid>
                      
                    </Grid>
                  </Grid>

                  {/* <Grid item xs={12} sm={11} div style={{ border: 'solid 0px #E2E2E2', borderRadius: 7, marginBottom: 2, padding: 3, backgroundColor: '#038000', color: '#FFFFFF', paddingLeft: 15 }}>
                    USER ของผู้ดูแล
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Grid container justify="flex-end">
                      <Button variant="contained" color="primary" onClick={clickSaveMatchRelativeUser} >
                        บันทึก
                      </Button>
                    </Grid>
                  </Grid> */}

                  {/* <Grid item xs={12} sm={12}>
                    <Grid container>
                      <Grid item xs={12} sm={9}>
                        <Select 
                          fullWidth={true} 
                          variant="outlined" 
                          native
                          onChange={(e) => changeSelect(e)}
                          inputProps={{ name: "matchRelativeUserId" }}
                          value={matchRelativeUserId}
                        >
                          <option value={''}>-เลือก USER-</option>
                          {userRelativeData.map((u) => (
                            <option key={u.id} value={u.id}>
                              ({u.cid}) {u.fullname}
                            </option>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Select fullWidth={true} variant="outlined" native
                          onChange={(e) => changeSelect(e)}
                          inputProps={{ name: "matchRelativeTypeId" }}
                          value={matchRelativeTypeId}
                        >
                          <option value={''}>-เลือกความสัมพันธ์-</option>
                          {relativeData.map((u) => (
                            <option key={u.relativeId} value={u.relativeId}>
                              {u.relativeName}
                            </option>
                          ))}
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid> */}

                </Grid>
              </Grid>

            </Grid>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus
            onClick={() => setOpenMatchDialog(false)}
          >
            ปิด
          </Button>
        </DialogActions>
      </Dialog>

      <ConsentServiceSlot dialogOpen={openConsent} setDialogOpen={setOpenConsent} userTypeId={''} informData={informData} consentData={consentData} t={t} goQuestionnaire={goQuestionnaire} activityData={focusedActivity} />

    </div>
  )
}