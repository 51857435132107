import { DELETE_CONCLUSION } from "../../actions";
import { AnyAction } from "redux";
import { DeleteConclusion } from "../../types/activity";

const initialState:DeleteConclusion = {
  isFetching: false,
  data: {}
};

export default function deleteConclusion(
  state = initialState,
  action: AnyAction
): DeleteConclusion {
  switch (action.type) {
    case DELETE_CONCLUSION.REQUEST:{
      return {
        ...state,
        isFetching: true,
      };
    }
    case DELETE_CONCLUSION.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    }
    case DELETE_CONCLUSION.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    default:
      return state;
  }
}
