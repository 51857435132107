import { API_URL_FULL as API_URL }  from "../auth-header";
import axios, { AxiosResponse } from 'axios';

export default async function callGet(endpoint: string, token?: string | null, payload?: any) {
  const URL = API_URL + endpoint;
  const axiosInstance = axios.create({headers: { Authorization: `${token}` }, params: payload});
  try {
    axiosInstance.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          window.location.href = "/logout";
        }
        return { success: false, error: error.response };
      }
    );
    const axiosResponse: AxiosResponse = await axiosInstance.get(URL);
    return { success: true, response: axiosResponse, error:null };
  } catch (error:any) {
    return Promise.reject({ success: false, error: error.response });
  }
}
