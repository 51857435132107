/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { INSERT_OSCCPERSON } from "../index";

const insertOsccperson = {
  request: (payload:any) => action(INSERT_OSCCPERSON.REQUEST, {payload}),
  success: (response: any) => action(INSERT_OSCCPERSON.SUCCESS, { data: response }),
  failure: (error: any) => action(INSERT_OSCCPERSON.FAILURE, { error }),
};

export default insertOsccperson;