/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getCurrentUser } from "../../services/auth.service";
import {
  Grid,
  Button,
  DialogActions,
  DialogContentText,
  DialogContent,
  Dialog,
  DialogTitle
} from "@material-ui/core";
import {
  MdRemoveRedEye as MdRemoveRedEyeIcon,
  // MdDeleteForever as DeleteBin,
  // MdAddCircle as BtMdAddCircle,
} from "react-icons/md";
import "moment/locale/th";

import useGlobal from "../../store";
import UCard from "../../services/universal/UniversalCard";

export default function CasePlan(props) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const userinfo = getCurrentUser();
  const [globalState, globalActions] = useGlobal();
  const [thisFocusedCaseData, setThisFocusedCaseData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const collection = {
    infoA: {
      fields: {
        fname: { show: true, title: 'ชื่อ', data_type: 'string', format: null },
        lname: { show: true, title: 'สกุล', data_type: 'string', format: null },
        cid: { show: true, title: 'เลขบัตรประชาชน', data_type: 'string', format: null },
        addressInfo: { show: true, title: 'ที่อยู่', data_type: 'string', format: null },
      }
    },
    infoB: {
      fields: {
        // severityType: { show: true, title: 'ประเภทความรุนแรง', data_type: 'string', format: null },
        severityTypeAll: { show: true, title: 'ประเภทความรุนแรง', data_type: 'string', format: null },
        riskLevel: { show: true, title: 'ระดับความรุนแรง', data_type: 'string', format: null },
      }
    },
  }

  const showRiskDetail = () => {
    console.log('showRiskDetail');
    setOpenDialog(true);
  }

  useEffect(() => {
    let x = props.data;
    let severityType=typeof x.severityType !== 'undefined'?(typeof x.severityType==='string'?[x.severityType]:x.severityType):[];
    severityType=severityType===null?[]:severityType;
    let severityTypeAll=severityType.length>0?severityType.join(', '):'';
    x['severityTypeAll']=severityTypeAll;
    setThisFocusedCaseData(x);
  }, [props]);    // eslint-disable-next-line 

  return (
    <div>
      {Object.keys(thisFocusedCaseData).length > 0 && (
        <Grid container style={{ marginBottom: 10 }}>
          <Grid item xs={12} sm={12} style={{ marginTop: 0 }}>
            <UCard structure={collection.infoA} display_style="inline" data={thisFocusedCaseData} />
          </Grid>
          <Grid item xs={12} sm={12} style={{ marginTop: 1 }}>
            <Grid container style={{ marginBottom: 0 }}>
              <Grid item xs={12} sm={9}>
                <UCard structure={collection.infoB} display_style="inline" data={thisFocusedCaseData} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  fullWidth
                  onClick={() => showRiskDetail()}
                  variant="contained" color="default"
                  // startIcon={ <MdRemoveRedEyeIcon style={{ marginLeft: 11, marginTop: -2 }} /> }
                  style={{ marginLeft: 1, height: 30, borderRadius: 5 , color: "black" }}
                >
                  รายละเอียดความรุนแรง
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Dialog open={openDialog} fullWidth={true} maxWidth={"sm"}>
        <DialogContent>
          <DialogTitle>
            รายละเอียดความรุนแรง
          </DialogTitle>
          <DialogContentText id="alert-dialog-description" component={'div'}>
            <Grid container style={{ marginBottom: 10 }}>
              <Grid item xs={12} sm={12} style={{ marginTop: 0 }}>

                {/* <Grid container style={{ backgroundColor: '#666666', color: '#FFFFFF', borderRadius: 10 }}>
                  <Grid item xs={12} sm={3}>
                    <div style={{ textAlign: 'center' }}>
                      ตัวแปร
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <Grid container>
                          <Grid item xs={12} sm={12}>
                            <div style={{ textAlign: 'center' }}>
                              น้ำหนัก
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Grid container>
                          <Grid item xs={12} sm={2}>
                            ร่างกาย
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            จิตใจ
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            เพศ
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            ละเลย
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <div style={{ fontSize: 13, marginTop: 3 }}>
                              ผลประโยชน์
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <br />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container style={{ backgroundColor: '#FFFFFF', border: 'solid 1px #666666', borderRadius: 10, marginTop: 2 }}>
                  <Grid item xs={12} sm={3}>
                    <div style={{ textAlign: 'center' }}>
                      อายุ
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <Grid container>
                          <Grid item xs={12} sm={2}>
                            0
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            0
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            0
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            0
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <div style={{ fontSize: 13, marginTop: 3 }}>
                              0
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <br />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid> */}

              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus
            onClick={() => setOpenDialog(false)}
          >
            ปิด
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}