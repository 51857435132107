/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { GET_CASES } from "../index";

const getCase = {
  request: (payload:Record<string,any>) => action(GET_CASES.REQUEST, { data:payload, pagination: payload.pagination }),
  success: (response: any) => action(GET_CASES.SUCCESS, { data: response }),
  failure: (error: any) => action(GET_CASES.FAILURE, { error }),
};

export default getCase;