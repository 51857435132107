/* eslint-disable @typescript-eslint/no-unused-vars */
import React,{useCallback, useEffect,useMemo,useState} from 'react';
import { ExtendButtonBase, IconButton, IconButtonTypeMap, useTheme } from '@material-ui/core';
import HealingIcon from '@material-ui/icons/Healing';
import PostAddIcon from '@material-ui/icons/PostAdd';
import CancelIcon from '@material-ui/icons/Cancel';
import GetAppIcon from '@material-ui/icons/GetApp';
import Assignment from '@material-ui/icons/Assignment';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplyIcon from '@material-ui/icons/Reply';
import PublishIcon from '@material-ui/icons/Publish';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { useHistory } from 'react-router';
import { Button, createStyles, makeStyles, Theme, Tooltip } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CustomButton from '../../../components/custom_components/CustomButtons/Button';
import { stringify } from 'uuid';

const useStyles = makeStyles<Theme, "tooltip">((theme: Theme) =>
  createStyles({
    tooltip: {
      fontSize: ".8em"
    }
  }),
);

interface IProps {
  role:string;
  caseStatus:string;
  manage: Array<string>;
  handleClick: Record<string,() => void>;
  page?: string;
}

const ButtonsByRole=(props:IProps)=>{
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const history = useHistory();
  const [buttons,setButtons] = useState<Array<any>>([]);
  const page = (props.page && props.page!="")?props.page:history.location.pathname;

  const tooltip = useCallback((id:string, label:string, iconButton:any)=>{
    return <Tooltip arrow placement="top" 
            key={id} 
            style={{cursor:'pointer'}}
            classes={{ tooltip: classes.tooltip }}
            title={label}>
              {iconButton}
          </Tooltip>
  },[classes.tooltip]);

  const receiveCase = useMemo(()=>({
    name:"receiveCase",
    button: (handleClick:()=>void,title:string,key:string)=>{
      if(isMobile){
        return <CustomButton key={"receiveCase"+key} size="sm" variant="contained" color="success" onClick={handleClick}>
                รับเคสกลุ่ม
              </CustomButton>
      }else{
        return tooltip("receiveCase","รับเคสกลุ่มเพื่อดำเนินงาน",<IconButton key="receiveCase" onClick={handleClick}><GetAppIcon /></IconButton>)
      }
    },
    caseStatus: ["assigned"],
    view: ["AdminR8","CM","AdminTeams","Teams"],
    manage: ["AdminR8","CM","AdminTeams","Teams"],
    role: ["AdminR8","CM","AdminTeams","Teams"],
    route: ["/activity/groups"]
  }),[isMobile,tooltip]);

  const assignCase = useMemo(()=>({
    name:"assignCase",
    button: (handleClick:()=>void,title:string,key:string)=>{
      if(isMobile){
        return <CustomButton key={"assignCase"+key} size="sm" variant="contained" color="success" onClick={handleClick}>
                มอบหมายกลุ่ม
              </CustomButton>
      }else{
        return tooltip("assignCase","มอบหมายกลุ่มเพื่อดำเนินงาน",<IconButton key="assignCase" onClick={handleClick}><LibraryAddIcon /></IconButton>)
      }
    },
    caseStatus: ["new","renew"],
    view: ["CM","AdminTeams","AdminR8"],
    manage: ["CM","AdminTeams","AdminR8"],
    role: ["CM","AdminTeams","AdminR8"],
    route: ["/activity/groups"]
  }),[isMobile, tooltip]);

  const cancelAssignCase = useMemo(()=>({
    name:"cancelAssignCase",
    button: (handleClick:()=>void,title:string,key:string)=>{
      if(isMobile){
        return <CustomButton key={"cancelAssignCase"+key} size="sm" variant="contained" color="warning" onClick={handleClick}>
                ยกเลิกเคส
              </CustomButton>
      }else{
        return tooltip("cancelAssignCase","ยกเลิกมอบหมายเคส",<IconButton key="cancelAssignCase" onClick={handleClick}><CancelIcon /></IconButton>);
      }
    },
    caseStatus: ["assigned","inProgress"],
    view: ["CM","AdminR8"],
    manage: ["CM","AdminR8"],
    role: ["CM","AdminR8"],
    route: ["/activity/groups"]
  }),[isMobile, tooltip]);

  const returnCase = useMemo(()=>({
    name:"returnCase",
    button: (handleClick:()=>void,title:string,key:string)=>{
      if(isMobile){
        return <CustomButton key={"returnCase"+key} size="sm" variant="contained" color="warning" onClick={handleClick}>
                ยกเลิกส่งประเมินผล
              </CustomButton>
      }else{
        return tooltip("returnCase","ยกเลิกส่งประเมินผล",<IconButton key="returnCase" onClick={handleClick}><ReplyIcon /></IconButton>);
      }
    },
    caseStatus: ["sendCM","waitConclusion","finish"],
    view: ["CM","Coach","AdminR8"],
    manage: ["CM","Coach","AdminR8"],
    role: ["CM","Coach","AdminR8"],
    route: ["/activity/groups"]
  }),[isMobile, tooltip]);
  
  const makeQuestionnairOrganization = useMemo(()=>({
    name:"makeQuestionnairOrganization",
    button: (handleClick:()=>void,title:string,key:string)=>{
      console.log("title",title);
      if(isMobile){
        
        return <CustomButton key={"makeQuestionnairOrganization"+key} size="sm" variant="contained" color="primary" onClick={handleClick}>
                {title}
              </CustomButton>
      }else{
        return tooltip("makeQuestionnairOrganization",title,<IconButton key="makeQuestionnairOrganization" onClick={handleClick}><Assignment /></IconButton>)
      }
    },
    caseStatus: ["inProgress","waitConclusion","finish"],
    view: ["Organization"],
    manage: ["Organization"],
    role: ["Organization"],
    route: ["/activity/case","/activity/groups"]
  }),[isMobile,tooltip]);

  const makeQuestionnairAssessor = useMemo(()=>({
    name:"makeQuestionnairAssessor",
    button: (handleClick:()=>void,title:string,key:string)=>{
      if(isMobile){
        return <CustomButton key={"makeQuestionnairAssessor"+key} size="sm" variant="contained" color="primary" onClick={handleClick}>
                {title}
              </CustomButton>
      }else{
        return tooltip("makeQuestionnairAssessor",title,<IconButton key="makeQuestionnairAssessor" onClick={handleClick}><Assignment /></IconButton>)
      }
    },
    caseStatus: ["inProgress","waitConclusion","finish"],
    view: ["Assessor"],
    manage: ["Assessor"],
    role: ["Assessor"],
    route: ["/activity/case","/activity/groups"]
  }),[isMobile,tooltip]);

  const makeQuestionnairCoach = useMemo(()=>({
    name:"makeQuestionnairCoach",
    button: (handleClick:()=>void,title:string,key:string)=>{
      if(isMobile){
        return <CustomButton key={"makeQuestionnairCoach"+key} size="sm" variant="contained" color="primary" onClick={handleClick}>
                {title}
              </CustomButton>
      }else{
        return tooltip("makeQuestionnairCoach",title,<IconButton key="makeQuestionnairCoach" onClick={handleClick}><Assignment /></IconButton>)
      }
    },
    caseStatus: ["inProgress","waitConclusion","finish"],
    view: ["Coach"],
    manage: ["Coach"],
    role: ["Coach"],
    route: ["/teams-manage","/activity/case","/activity/groups"]
  }),[isMobile, tooltip]);

  const makeQuestionnairCM = useMemo(()=>({
    name:"makeQuestionnairCM",
    button: (handleClick:()=>void,title:string,key:string)=>{
      if(isMobile){
        return <CustomButton key={"makeQuestionnairCM"+key} size="sm" variant="contained" color="primary" onClick={handleClick}>
                {title}
              </CustomButton>
      }else{
        return tooltip("makeQuestionnairCM",title,<IconButton key="makeQuestionnairCM" onClick={handleClick}><Assignment /></IconButton>)
      }
    },
    caseStatus: ["inProgress","waitConclusion","finish"],
    view: ["AdminR8","CM"],
    manage: ["AdminR8","CM"],
    role: ["AdminR8","CM"],
    route: ["/activity/case","/activity/groups"]
  }),[isMobile, tooltip]);

  const makeQuestionnairTeams = useMemo(()=>({
    name:"makeQuestionnairTeams",
    button: (handleClick:()=>void,title:string,key:string)=>{
      if(isMobile){
        return <CustomButton key={"makeQuestionnairTeams"+key} size="sm" variant="contained" color="success" onClick={handleClick}>
                {title}
              </CustomButton>
      }else{
        return tooltip("makeQuestionnairTeams",title,<IconButton key="makeQuestionnairTeams" onClick={handleClick}><Assignment /></IconButton>)
      }
    },
    caseStatus: ["inProgress"],
    view: ["AdminTeams","Teams"],
    manage: ["AdminTeams","Teams"],
    role: ["AdminTeams","Teams"],
    route: ["/activity/case","/activity/groups"]
  }),[isMobile,tooltip]);

  const emr = useMemo(()=>({
    name:"emr",
    button: (handleClick:()=>void,title:string,key:string)=>{
      if(isMobile){
        return <CustomButton key={"emr"+key} size="sm" variant="contained" color="rose" onClick={handleClick}>
                EMR
              </CustomButton>
      }else{
        return tooltip("emr"+key,"ดูประวัติการรักษาพยาบาล",<IconButton key={"emr"+key} onClick={handleClick}><HealingIcon /></IconButton>);
      }
    },
    caseStatus: ["new","assigned","inProgress","waitConclusion","finish","renew"],
    view: ["Organization","Assessor","Coach","CM","AdminTeams","Teams","AdminR8"],
    manage: ["Assessor","Coach","CM","AdminTeams","Teams","AdminR8"],
    role: ["Organization","Assessor","Coach","CM","AdminTeams","Teams","AdminR8"],
    route: ["/activity/case"]
  }),[isMobile,tooltip]);

  const editGroup = useMemo(()=>({
    name:"editGroup",
    button: (handleClick:()=>void,title:string,key:string)=>{
      if(isMobile){
        return <CustomButton key={"editGroup"+key} size="sm" variant="contained" color="warning" onClick={handleClick}>
                แก้ไขข้อมูลกลุ่ม
              </CustomButton>
      }else{
        return tooltip("editGroup","แก้ไขข้อมูลกลุ่ม",<IconButton key="editGroup" onClick={handleClick}><CreateIcon /></IconButton>)
      }
    },
    caseStatus: ["new","inProgress","assigned"],
    view: ["CM","AdminTeams","AdminR8"],
    manage: ["CM","AdminTeams","AdminR8"],
    role: ["CM","AdminTeams","AdminR8"],
    route: ["/activity/groups"]
  }),[isMobile, tooltip]);

  const deleteGroup = useMemo(()=>({
    name:"deleteGroup",
    button: (handleClick:()=>void,title:string,key:string)=>{
      if(isMobile){
        return <CustomButton key={"deleteGroup"+key} size="sm" variant="contained" color="danger" onClick={handleClick}>
                ลบกลุ่ม
              </CustomButton>
      }else{
        return tooltip("deleteGroup","ลบกลุ่ม",<IconButton key="deleteGroup" onClick={handleClick}><DeleteIcon /></IconButton>)
      }
    },
    caseStatus: ["new"],
    view: ["CM","AdminR8"],
    manage: ["CM","AdminR8"],
    role: ["CM","AdminR8"],
    route: ["/activity/groups"]
  }),[isMobile, tooltip]);

  const sendCM = useMemo(()=>({
    name:"sendCM",
    button: (handleClick:()=>void,title:string,key:string)=>{
      if(isMobile){
        return <CustomButton key={"sendCM"+key} size="sm" variant="contained" color="info" onClick={handleClick}>
                ส่งประเมินผล
              </CustomButton>
      }else{
        return tooltip("sendCM","ส่งประเมินผล",<IconButton key="sendCM" onClick={handleClick}><PublishIcon /></IconButton>)
      }
    },
    caseStatus: ["inProgress"],
    view: ["AdminR8","CM","AdminTeams","Teams"],
    manage: ["AdminR8","CM","AdminTeams","Teams"],
    role: ["AdminR8","CM","AdminTeams","Teams"],
    route: ["/activity/groups"]
  }),[isMobile, tooltip]);

  const confirmFinish = useMemo(()=>({
    name:"confirmFinish",
    button: (handleClick:()=>void,title:string,key:string)=>{
      if(isMobile){
        return <CustomButton key={"confirmFinish"+key} size="sm" variant="contained" color="success" onClick={handleClick}>
                สิ้นสุดกระบวนการ
              </CustomButton>
      }else{
        return tooltip("confirmFinish","ยืนยันสิ้นสุดกระบวนการ",<IconButton key="confirmFinish" onClick={handleClick}><DoneAllIcon /></IconButton>)
      }
    },
    caseStatus: ["finish"],
    view: ["Coach","AdminR8"],
    manage: ["Coach","AdminR8"],
    role: ["Coach","AdminR8"],
    route: ["/activity/groups"]
  }),[isMobile,tooltip]);

  const conclusion = useMemo(()=>({
    name:"conclusion",
    button: (handleClick:()=>void,title:string,key:string)=>{
      if(isMobile){
        return <CustomButton key={"conclusion"+key} size="sm" variant="contained" color="success" onClick={handleClick}>
                สรุปผล
              </CustomButton>
      }else{
        return tooltip("conclusion","สรุปผลการดำเนินงาน",<IconButton key="conclusion" onClick={handleClick}><VerifiedUserIcon /></IconButton>)
      }
    },
    caseStatus: ["waitConclusion","finish"],
    view: ["AdminR8","CM","Coach"],
    manage: ["AdminR8","CM"],
    role: ["AdminR8","CM"],
    route: ["AdminR8","/activity/case"]
  }),[isMobile,tooltip]);

  const removeGroupCase = useMemo(()=>({
    name:"removeGroupCase",
    button: (handleClick:()=>void,title:string,key:string)=>{
      if(isMobile){
        return <CustomButton key={"removeGroupCase"+key} size="sm" variant="contained" color="danger" onClick={handleClick}>
                ลบเคสออกจากกลุ่ม
              </CustomButton>
      }else{
        return tooltip("removeGroupCase","ลบเคสออกจากกลุ่ม",<IconButton key="removeGroupCase" onClick={handleClick}><DeleteIcon /></IconButton>)
      }
    },
    caseStatus: [""],
    view: ["CM","AdminTeams","AdminR8"],
    manage: ["CM","AdminTeams","AdminR8"],
    role: ["CM","AdminTeams","AdminR8"],
    route: ["/groupDialogAdd","/groupDialogEdit"]
  }),[isMobile, tooltip]);

  const viewAnswer = useMemo(()=>({
    name:"viewAnswer",
    button: (handleClick:()=>void,title:string,key:string)=>{
      if(isMobile){
        return <CustomButton key={"viewAnswer"+key} size="sm" variant="contained" color="success" onClick={handleClick}>
                ดูคำตอบ
              </CustomButton>
      }else{
        return tooltip("viewAnswer","ดูคำตอบ",<IconButton key="viewAnswer" onClick={handleClick}><VisibilityIcon /></IconButton>)
      }
    },
    caseStatus: [""],
    view: ["Organization","Coach","AdminR8"],
    manage: ["Organization","Coach","AdminR8"],
    role: ["Organization","Coach","AdminR8"],
    route: ["/questionnaire_list","/questionair_repeat"]
  }),[isMobile, tooltip]);

  const editAnswer = useMemo(()=>({
    name:"editAnswer",
    button: (handleClick:()=>void,title:string,key:string)=>{
      if(isMobile){
        return <CustomButton key={"editAnswer"+key} size="sm" variant="contained" color="warning" onClick={handleClick}>
                แก้ไข
              </CustomButton>
      }else{
        return tooltip("editAnswer","แก้ไขคำตอบ",<IconButton key="editAnswer" onClick={handleClick}><EditIcon /></IconButton>)
      }
    },
    caseStatus: [""],
    view: ["Organization","CM","AdminTeams","AdminR8"],
    manage: ["Coach","Assessor","CM","AdminTeams","AdminR8"],
    role: ["Coach","Assessor","CM","AdminTeams","AdminR8"],
    route: ["/questionnaire_list","/questionair_repeat"]
  }),[isMobile, tooltip]);

  const deleteAnswer = useMemo(()=>({
    name:"deleteAnswer",
    button: (handleClick:()=>void,title:string,key:string)=>{
      if(isMobile){
        return <CustomButton key={"deleteAnswer"+key} size="sm" variant="contained" color="danger" onClick={handleClick}>
                ลบ
              </CustomButton>
      }else{
        return tooltip("deleteAnswer","ลบคำตอบ",<IconButton key="deleteAnswer" onClick={handleClick}><DeleteIcon /></IconButton>)
      }
    },
    caseStatus: [""],
    view: ["CM","AdminTeams","AdminR8"],
    manage: ["Coach","Assessor","CM","AdminTeams","AdminR8"],
    role: ["Coach","Assessor","CM","AdminTeams","AdminR8"],
    route: ["/questionnaire_list","/questionair_repeat"]
  }),[isMobile, tooltip]);

  const button_all = useMemo(()=>[
    receiveCase, 
    conclusion,
    makeQuestionnairOrganization,
    makeQuestionnairAssessor,
    makeQuestionnairCoach,
    makeQuestionnairCM, 
    makeQuestionnairTeams,
    confirmFinish,
    assignCase, 
    cancelAssignCase, 
    returnCase,
    editGroup,
    deleteGroup,
    sendCM,
    emr,
    removeGroupCase,
    viewAnswer,
    editAnswer,
    deleteAnswer
  ],[viewAnswer, editAnswer, deleteAnswer, assignCase, cancelAssignCase, returnCase, conclusion, confirmFinish, deleteGroup, editGroup, emr, makeQuestionnairOrganization, makeQuestionnairAssessor, makeQuestionnairCM, makeQuestionnairCoach, makeQuestionnairTeams, receiveCase, sendCM, removeGroupCase]);

  const actionByRole = useCallback((role:string, caseStatus:string, manage:Array<string>, handleClick:Record<string,() => void>) => {
    // console.log(role,caseStatus,page);
    if(role && role!==""){
      const buttons = button_all.map((item:any,key:number)=>{
        if(item.route.includes(page)){ //&& item.view.includes(role) && item.manage.includes(role)
          if(item.role.includes(role)){
            if(manage.includes(role) || item.view.includes(role)){
              if(item.caseStatus.includes(caseStatus)){
                if(["waitConclusion","finish"].includes(caseStatus)){
                  const customTitle = (["makeQuestionnairCM","makeQuestionnairTeams","makeQuestionnairCoach","makeQuestionnairAssessor","makeQuestionnairOrganization"].includes(item.name))?"ดูคำตอบแบบประเมิน":"";
                  return item.button(handleClick[item.name],customTitle,key);
                }else{
                  const customTitle = (["makeQuestionnairCM","makeQuestionnairTeams","makeQuestionnairCoach","makeQuestionnairAssessor","makeQuestionnairOrganization"].includes(item.name))?"ทำแบบประเมิน":"";
                  return item.button(handleClick[item.name],customTitle,key);
                }
              }
            }
          }
        }
        return null;
      })
      const finalButtons = buttons.filter((item:any)=>item!==null);
      // console.log(finalButtons);
      setButtons(finalButtons);
    }
  },[button_all, page]);
  
  useEffect(()=>{
    actionByRole(props.role, props.caseStatus, props.manage, props.handleClick);
  },[props.role, props.caseStatus, props.handleClick, props.manage, actionByRole]);
  return (<div>{buttons}</div>)
}

export default ButtonsByRole;