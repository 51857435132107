/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Form from 'react-validation/build/form';
// import CheckButton from 'react-validation/build/button';
import { login, getRegisterType } from '../../services/auth.service';
import uuid from 'react-uuid';
import { useDispatch } from 'react-redux';
import LineLogin from '../../services/line-login/reactjs-line-login';
import '../../services/line-login/reactjs-line-login.css';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import logo from '../../images/password-edit4.png';
import { useDialog } from '../../services/dialog/ModalProvider';
import useGlobal from '../../store';
import validation from '../../services/validation';
import { loginAction } from '../../actions/users';
// import * as AST from "../../services/AutoSetting";
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, TextField, CircularProgress } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { BrowserRouter } from 'react-router-dom';
import useScreenType from 'react-screentype-hook';
import CustomButton from '../../components/custom_components/CustomButtons/Button';
import ThaidDialog from '../custom_components/Dialog/thaidDialog';

// import { LoginResponse } from '../../types';

const useStyles = makeStyles((theme) => ({
  root: {
    '& ._RU-K2': {
      margin: 0,
      width: '100%',
      backgroundSize: 'contain',
    },
    '& .MuiDialogTitle-root': {
      flex: '0 0 auto',
      margin: 0,
      padding: '8px 16px',
    },
    // '& .MuiInputLabel-outlined': {
    //   zIndex: 1,
    //   transform: 'translate(15px, 4px) scale(1)',
    //   pointerEvents: 'none',
    // },
    // '& .MuiInputLabel-shrink': {
    //   transform: 'translate(15px, -18px) scale(0.75)',
    // },
  },
  loginCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    position: 'fixed',
    width: '100%',
    height: '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 2000,
    color: '#fff',
  },
}));
const Login = () => {
  const dispatch = useDispatch();
  const form = useRef();
  const { t } = useTranslation();
  const classes = useStyles();
  const redirect = useHistory();
  const { setDialog } = useDialog();
  const [globalState, globalActions] = useGlobal();
  const screenType = useScreenType();
  const [payload, setPayload] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [validator, setValidator] = useState({
    formElements: {
      email: {
        validator: { required: true, pattern: 'email' },
        error: { status: false, message: 'กรุณากรอกอีเมลล์' },
      },
      password: {
        validator: { required: true, minLength: 6 },
        error: { status: false, message: 'กรุณากรอกรหัสผ่าน' },
      },
    },
    formValid: false,
  });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const onHandleChange = (e) => {
    let result = validation(validator, e);

    if (typeof result != 'undefined') {
      const name = typeof e.target !== 'undefined' ? e.target.name : e.key;
      const value = typeof e.target !== 'undefined' ? e.target.value : e.val;
      switch (name) {
        case 'email':
          setEmail(value.toLowerCase());
          break;
        case 'password':
          setPassword(value);
          break;
        default:
          break;
      }
      setValidator({ ...validator, formElements: result.formElements, formValid: result.formValid });
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    onHandleChange({ key: 'email', val: email });
    onHandleChange({ key: 'password', val: password });
    if (validator.formValid) {
      try {
        let loginData = await login({ email: email, password: password });
        // console.log("loginData",loginData);

        // console.log('LLLL', loginData);
        if (typeof loginData.response !== undefined && loginData.isLoginError === false) {
          // AutoSetting ย้ายไปไว้ที่ App.js
          // if (parseInt(loginData.response.user.userType.userTypeId)===3) {
          //   setOpenBackdrop(true);
          //   let astResult = await AST.AutoSetting(loginData.response, redirect);
          // }
          dispatch(loginAction.success(loginData));
          globalActions.changeLoginStatus(true);
          globalActions.setCurrentUser(loginData.response);
          // globalActions.setUserRole(loginData.response.user.role);
          setLoading(false);
          redirect.push('/');
        } else {
          setLoading(false);
          if (loginData.err.response.status === 401) {
            let response = await getRegisterType(email);
            if (response.lineRegister) {
              setDialog({
                title: '',
                titleStyle: {},
                content: {
                  line1: t('pages.login.dialog_error.line1') + email,
                  line2: t('pages.login.dialog_error.line2'),
                  line3: t('pages.login.dialog_error.line3'),
                },
                contentStyle: { width: '400px', textAlign: 'center', color: 'red' },
                button: {
                  confirm: { enable: false, text: '', redirect: '/login' },
                  cancel: { enable: true, text: t('pages.login.dialog_error.close_btn') },
                },
              });
            } else {
              setDialog({
                title: '',
                titleStyle: {},
                content: {
                  line1: t('pages.login.dialog_error.line1'),
                  line2: t('pages.login.dialog_error.line2'),
                  line3: t('pages.login.dialog_error.line3'),
                },
                contentStyle: { width: '400px', textAlign: 'center', color: 'red' },
                button: {
                  confirm: { enable: false, text: '', redirect: '/login' },
                  cancel: { enable: true, text: t('pages.login.dialog_error.close_btn') },
                },
              });
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      setLoading(false);
    }
  };

  const [thaidOpen, setThaidOpen] = useState(false);
  const handleThaid = () => {
    const thaidParams = {
      client_id: (process.env.NODE_ENV==="development")?process.env.REACT_APP_DEV_THAID_CLIENT_ID:process.env.REACT_APP_PROD_THAID_CLIENT_ID,
      redirect_uri: (process.env.NODE_ENV==="development")? process.env.REACT_APP_DEV_THAID_CALLBACK:process.env.REACT_APP_PROD_THAID_CALLBACK,
      scope: process.env.REACT_APP_THAID_SCOPE,
      state: process.env.REACT_APP_THAID_STATE 
    }
    const thaidAuthURL = `https://imauth.bora.dopa.go.th/api/v2/oauth2/auth/?response_type=code&client_id=${thaidParams.client_id}&redirect_uri=${thaidParams.redirect_uri}&scope=${thaidParams.scope}&state=${thaidParams.state}`
    window.location.replace(thaidAuthURL);
  };

  // useEffect(()=>{
  //   globalActions.resetGlobalState();
  //   localStorage.removeItem("OSCC");
  // },[]);
  
  return (
    <BrowserRouter basename='/'>
    <div className={`col-md-12 ${classes.loginCard}`}>
      <div className='card card-container'>
        <img src={logo} alt='profile-img' className='password-change-logo' />
        <Form className={classes.root} onSubmit={handleLogin}>
          <div className='form-group' style={{ marginTop: '40px' }}>
            <TextField
              id='email'
              name='email'
              label={t('pages.login.email')}
              type='text'
              size='small'
              variant='outlined'
              value={email}
              onChange={onHandleChange}
              fullWidth
              error={validator.formElements.email.error.status}
              helperText={validator.formElements.email.error.status && validator.formElements.email.error.message}
            />
          </div>
          <div className='form-group' style={{ marginTop: '20px' }}>
            <TextField
              id='password'
              name='password'
              label={t('pages.login.password')}
              type='password'
              size='small'
              variant='outlined'
              value={password}
              onChange={onHandleChange}
              fullWidth
              error={validator.formElements.password.error.status}
              helperText={validator.formElements.password.error.status && validator.formElements.password.error.message}
            />
          </div>
          <div className='form-group' style={{ marginTop: '40px' }}>
            <CustomButton fullWidth variant="contained" color="primary" loading={loading} style={{ margin: 0 }} onClick={(e) => handleLogin(e)}>
              {t('pages.login.btn_login')}
            </CustomButton>
          </div>
          <div className='form-group'>
            <CustomButton fullWidth variant="contained" color="info" loading={false} style={{ margin: 0 }} onClick={handleThaid}>
              {t('pages.login.btn_login_thaid')}
            </CustomButton>
          </div>
          <div className='form-group'>
            <LineLogin
              clientID={process.env.REACT_APP_LINE_CLIENT_ID}
              clientSecret={process.env.REACT_APP_LINE_CLIENT_SECRET}
              redirectURI={
                process.env.NODE_ENV === 'development'
                  ? process.env.REACT_APP_LINE_REDIRECT_URI_DEV
                  : process.env.REACT_APP_LINE_REDIRECT_URI_PROD
              }
              state={uuid()}
              prompt=''
              nonce=''
              maxAge={process.env.REACT_APP_LINE_MAX_AGE}
              scope={process.env.REACT_APP_LINE_SCOPE}
              setPayload={setPayload}
              setIdToken={setIdToken}
              buttonLabel={t('pages.login.btn_linelogin')}
            />
          </div>
          
          {screenType.isMobile && (
            <>
              <div className='form-group'><Divider/></div>
              <div className='form-group'>
                <CustomButton fullWidth variant="outlined" color="info" style={{ margin: 0 }} onClick={()=>redirect.push('/register')}>
                  {t('pages.login.btn_register')}
                </CustomButton>
              </div>
            </>
          )}
          <div className='form-group' style={{ marginBottom: 0, textAlign: 'right', fontSize: '.8rem', color: '#ccc' }}>
            <Link to={'/requestresetpassword'}>** {t('pages.login.forgot_password')}</Link>
          </div>
        </Form>
      </div>
      <Dialog onClose={(_event, reason)=>{if (reason === "backdropClick") {return;}}} className={classes.backdrop} open={openBackdrop}>
        <DialogContent>
          <div style={{ textAlign: 'center', padding: 10 }}>
            <CircularProgress color='inherit' />
            <div>กำลังตั้งค่าข้อมูลสำหรับหน่วยบริการ กรุณารอ...</div>
          </div>
        </DialogContent>
      </Dialog>
      <ThaidDialog open={thaidOpen} setOpen={()=>setThaidOpen()}/>
    </div>
    </BrowserRouter>
  );
};

export default Login;
