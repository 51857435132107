
import getTeams from "./getTeams";
import getTeamsUsers from "./getTeamsUsers";
import insertTeams from "./insertTeams";
import updateTeams from "./updateTeams";
import deleteTeams from "./deleteTeams";
import updateTeamusers from "./updateTeamusers";
import deleteToken from "./deleteToken";
import getHospitalInfo from "./getHospitalInfo";
import getTeamsUsersTambon from "./getTeamsUsersTambon";
export default{
  getHospitalInfo,
  getTeams,
  getTeamsUsers,
  insertTeams,
  updateTeams,
  deleteTeams,
  updateTeamusers,
  deleteToken,
  getTeamsUsersTambon
}