/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import TextField from '@material-ui/core/TextField';
import { changePassword } from '../../services/auth.service';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core';
import logo from '../../images/password-edit4.png';
import validation from "../../services/validation";
import { useDialog } from '../../services/dialog/ModalProvider';
// import useGlobal from "../../store";
const useStyles = makeStyles(theme => ({
  root: {
    '& ._RU-K2': {
      margin: '0',
      width: '100%',
      backgroundSize: 'contain'
    },
    '& .MuiInputLabel-outlined': {
      zIndex: 1,
      transform: 'translate(15px, 4px) scale(1)',
      pointerEvents: 'none'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(15px, -18px) scale(0.75)',
    }
  }
}));
const ChangePassword = () => {
  // const [globalState, globalActions] = useGlobal();
  const { setDialog } = useDialog();
  const classes = useStyles();
  // const redirect = useHistory();
  const form = useRef();
  const checkBtn = useRef();
  const [idToken, setIdToken] = useState(null);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  
  const [loading, setLoading] = useState(false);
  // const [message, setMessage] = useState("");
  const [validator, setValidator] = useState({
    formElements: {
      oldPassword: {
        validator: { required: true, minLength: 6 },
        error: { status: false, message: 'กรุณากรอกรหัสผ่านเดิม' }
      },
      newPassword: {
        validator: { required: true, minLength: 6 },
        error: { status: false, message: 'กรุณากรอกรหัสผ่านใหม่' }
      },
      confirmPassword: {
        validator: { required: true, confirmMatch : '' },
        error: { status: false, message: 'กรุณายืนยันรหัสผ่านใหม่' }
      }
    },
    formValid: false
  });

  const onHandleChange = (e) => {
    let result = validation(validator, e);
    if (typeof result !== 'undefined') {
      const name = (typeof e.target !== 'undefined') ? e.target.name : e.key;
      const value = (typeof e.target !== 'undefined') ? e.target.value : e.val;
      switch(name){
        case 'oldPassword' : setOldPassword(value); break;
        case 'newPassword' : 
          setNewPassword(value); 
          setValidator((prevState) => {
              prevState.formElements.confirmPassword.validator.confirmMatch = value;
              return({...prevState})
            }
          );
          break;
        case 'confirmPassword' : 
          setConfirmPassword(value); 
          break;
        default : break;
      }
      setValidator({ ...validator, formElements: result.formElements, formValid: result.formValid });
    }
  };
  
  const handleChangePassword = async (e, res) => {
    e.preventDefault();
    onHandleChange({ key: "oldPassword", val: oldPassword });
    onHandleChange({ key: "newPassword", val: newPassword });
    onHandleChange({ key: "confirmPassword", val: confirmPassword });
    if (validator.formValid) {
      try {
        let params = {
          "oldPassword":oldPassword,
          "newPassword":confirmPassword
        }
        let result = await changePassword(params);
        if (typeof result !== undefined){
          if(result.status===204){
            setDialog({
              title: 'ผลการเปลี่ยนรหัสผ่าน',
              titleStyle:{padding:'8px 16px',color: '#383838', borderBottom:'1px solid #ccc'},
              content: 'เปลี่ยนรหัสผ่านสำเร็จ คุณจำเป็นต้องเข้าสู่ระบบใหม่อีกครั้ง',
              contentStyle: { width: '400px', textAlign: 'center', color: 'green' },
              button: {
                confirm: { enable: true, text: 'ตกลง', redirect: '/logout'  },
                cancel: { enable: false, text: ''},
              }
            });
          }else if(result.status===400){
            setDialog({
              title: 'ผลการเปลี่ยนรหัสผ่าน',
              titleStyle:{padding:'8px 16px',color: '#383838', borderBottom:'1px solid #ccc'},
              content: 'คุณกรอกรหัสผ่านเดิมไม่ถูกต้อง',
              contentStyle: { width: '400px', textAlign: 'center', color: 'red' },
              button: {
                confirm: { enable: false, text: ''},
                cancel: { enable: true, text: 'ปิด'},
              }
            });
          }else{
            console.log(result);
          }
        } else {
          console.log(result);
        }
      } catch (err) {
        console.log("เปลี่ยนรหัสผ่านไม่สำเร็จ", err);
      }
    }
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img src={logo} alt="profile-img" className="password-change-logo" />
        <Form className={classes.root} onSubmit={handleChangePassword} ref={form}>
          <div className="form-group" style={{ marginTop: '20px' }}>
            <TextField
              id="oldPassword"
              name="oldPassword"
              label="รหัสผ่านเดิม"
              type="password"
              size="small"
              variant="outlined"
              value={oldPassword}
              onChange={onHandleChange}
              fullWidth
              error={validator.formElements.oldPassword.error.status}
              helperText={
                (validator.formElements.oldPassword.error.status)&&validator.formElements.oldPassword.error.message
              }
            />
          </div>
          <div className="form-group" style={{ marginTop: '20px' }}>
            <TextField
              id="newPassword"
              name="newPassword"
              label="รหัสผ่านใหม่"
              type="password"
              size="small"
              variant="outlined"
              value={newPassword}
              onChange={onHandleChange}
              fullWidth
              error={validator.formElements.newPassword.error.status}
              helperText={
                (validator.formElements.newPassword.error.status)&&validator.formElements.newPassword.error.message
              }
            />
          </div>
          <div className="form-group" style={{ marginTop: '20px' }}>
            <TextField
              id="confirmPassword"
              name="confirmPassword"
              label="ยืนยันรหัสผ่านใหม่"
              type="password"
              size="small"
              variant="outlined"
              value={confirmPassword}
              onChange={onHandleChange}
              fullWidth
              error={validator.formElements.confirmPassword.error.status}
              helperText={
                (validator.formElements.confirmPassword.error.status)&&validator.formElements.confirmPassword.error.message
              }
            />
          </div>
          <div className="form-group" style={{ marginTop: '40px' }}>
            <button className="btn btn-primary btn-block" disabled={loading}>
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>เปลี่ยนรหัสผ่าน</span>
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ChangePassword;
