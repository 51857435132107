import { QueryObserver, useMutation, useQuery, useQueryClient, useInfiniteQuery } from '@tanstack/react-query';
import { getTeams,getUsers } from './api';

export const useGetTeams = (limit:number ,skip:number, searchText="" ) => {
  const payload:any = {
    where: { id:"659a496e9a6ae7543599a131"},
    pagination: {
      limit: limit, 
      skip: limit * skip
    }
  };
  if(searchText){
    payload.where = {name:{regexp: searchText}}
  }
  const cacheKey = `TEAMS-LIST->limit:${limit}|skip:${skip}|searchText:${searchText}`;
  return useQuery(
    [cacheKey],
    async ()=> await getTeams(payload),
    {keepPreviousData: true}
  );
}

export const useGetUsers = (data:any) => {
  const payload:any = {where:{}};
  if(data.where){
    payload.where["and"]=[{"amphur.amphur_id": data.where.amphurID}];
  }
  if(data.pagination){
    payload["pagination"] = {
      limit: data.pagination.limit, 
      skip: data.pagination.limit * data.pagination.skip
    };
  }
  console.log(payload);
  const cacheKey = `TEAMS-LISTUSERS->limit:${data.pagination.limit}|skip:${data.pagination.skip}`;
  return useQuery(
    [cacheKey],
    async ()=> await getUsers(payload)
  );
}