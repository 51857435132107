/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, RuseState } from "react";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  MdRemoveRedEye as MdRemoveRedEyeIcon,
  MdDeleteForever as DeleteBin,
  MdAddCircle as BtMdAddCircle,
} from "react-icons/md";
import { useHistory } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Backdrop,
  CircularProgress,
  Select,
  TextField,
} from "@material-ui/core";

import CaseInfoBar from "./CaseInfoBar";
import useGlobal from "../../store";
import * as UAPI from "../../services/UniversalAPI";
import { getCurrentUser } from "../../services/auth.service";

const useStyles = makeStyles((theme) => ({
  // const useStyles = makeStyles({
  TextField: {
    minHeight: 30,
    padding: 0,
    paddingLeft: 5,
  },
  TextFieldScore: {
    minHeight: 30,
    padding: 0,
    paddingLeft: 0,
    textAlign: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: "#fff",
  },
  tdQDetail: {
    verticalAlign: "top",
  },
  thead: {
    backgroundColor: "#cdedff",
  },
  trA: {
    backgroundColor: "transparent",
  },
  trB: {
    backgroundColor: "#efefef",
  },
}));

export default function CasePlan(props) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const history = useHistory();
  const classes = useStyles();
  const userinfo = getCurrentUser();
  const [globalState, globalActions] = useGlobal();
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [planService, setPlanService] = useState([]);
  const [focusedPlanService, setFocusedPlanService] = useState({});

  const [getTeams, setGetTeams] = useState([]);
  const [getQuestionnaires, setGetQuestionnaires] = useState([]);

  const [planAllActivity, setPlanAllActivity] = useState([]);
  const [planAddActivity, setPlanAddActivity] = useState([]);

  const [openPlan, setOpenPlan] = useState(false);
  const [openActivity, setOpenActivity] = useState(false);
  const [personData, setPersonData] = useState([]);
  const [osccPerson, setOsccPerson] = useState([]);

  const [selectedTeamMember, setSelectedTeamMember] = useState({});

  const [statePlan, setStatePlan] = useState({
    personId: personData.id,
    osccPersonId: props.location.state.id,
    cause: "",
    planDate: "",
  });

  const [overrideTeam, setOverrideTeam] = useState({});
  const [overrideTeamOptions, setOverrideTeamOptions] = useState([]);

  const [openMatchDialog, setOpenMatchDialog] = useState(false);
  const [relativeData, setRelativeData] = useState([
    { relativeId: 1, relativeName: 'แม่ผู้ให้กำเนิด' },
    { relativeId: 2, relativeName: 'พ่อผู้ให้กำเนิด' },
    { relativeId: 3, relativeName: 'แม่เลี้ยง/พ่อเลี้ยง' },
    { relativeId: 4, relativeName: 'พี่หรือน้อง/พี่บุญธรรมหรือน้องบุญธรรม' },
    { relativeId: 5, relativeName: 'ปู่ ตา / ย่า ยาย' },
    { relativeId: 6, relativeName: 'ทวด' },
    { relativeId: 7, relativeName: 'ลุง / ป้า / น้า / อา' },
    { relativeId: 8, relativeName: 'ญาติ' },
    { relativeId: 9, relativeName: 'พ่อแม่อุปถัมภ์' },
    { relativeId: 10, relativeName: 'อื่นๆ' }
  ]);
  const [careGiverData, setCareGiverData] = useState({
    cid : null,
    fname: null,
    lname : null,
    nameCalledByCase : null,
    relation : '',
    relationSpecify : null,
  });
  const [disableSaveButton, setDisableSaveButton] = useState(true);


  const handleClickAddPlan = () => {
    // console.log("Plan")
    setOpenPlan(true);
  };

  const updateOsccPerson = (e) => {
    console.log(planAllActivity);
    let teamIdList = [];
    if (typeof osccPerson.teamId !== "undefined") {
      teamIdList = osccPerson.teamId;
    }

    planAllActivity.serviceslotList.forEach((i) => {
      if (teamIdList.indexOf(i.teamId) < 0) {
        teamIdList.push(i.teamId);
      }
    });

    let x = osccPerson;
    x["caseStatus"] = "assignedTeam";
    x["osccTeamId"] = teamIdList;
    setPlanAddActivity({ ...osccPerson, ...x });

    console.log(osccPerson);
    UAPI.update(osccPerson.id, osccPerson, "osccpeople").then((response) => {
      if (response.status === 200) {
        // alert("สำเร็จ");
        // handleClose();
        // getPlanService();
      }
    });
  };

  const handleClose = () => {
    setOpenPlan(false);
    setOpenActivity(false);
  };

  const handleCloseAddNewActivity = () => {
    // console.log("object");
    // setStateActivity(false);
    setOpenActivity(false);
  };

  const getPlanService = async () => {
    let filter = { filter: { where: { osccPersonId: props.location.state.id } } };
    let response = await UAPI.getAll(filter, "planservices");
    if (response.status === 200) {
      setPlanService(response.data);
      // console.log(response.data);
    }
  };

  const getPerson = async () => {
    let response = await UAPI.getAll(
      { filter: { where: { cid: props.location.state.cid } } },
      "people"
    );
    if (response.status === 200) {
      if (response.data.length > 0) {
        let d = response.data[0];
        let tambolInfo = "";
        let paramTambol = {
          filter: {
            limit: 1,
            where: { addressid: d.chwpart + d.amppart + d.tmbpart }
          }
        };
        let responseTambol = await UAPI.getAll(paramTambol, "tambols");
        if (responseTambol.status === 200) {
          if (responseTambol.data.length > 0) {
            // console.log(responseTambol.data);
            tambolInfo = responseTambol.data[0].full_name;
          }
        }
        d['addressInfo'] = d.addrpart + ' ม.' + parseInt(d.moopart) + ' ' + tambolInfo;
        d['riskLevel'] = props.location.state['riskLevel'];
        d['severityType'] = props.location.state['severityType'];
        // console.log(d);
        setPersonData(d);
        let x = statePlan;
        x["personId"] = response.data[0].id;
        setStatePlan({ ...statePlan, ...x });
      }
    }
  };

  const getOsccPerson = async () => {
    let response = await UAPI.getAll(
      // { filter: { where: { personId: personData.id } } },
      { filter: { where: { id: props.location.state.id } } },
      "osccpeople"
    );
    //  console.log(JSON.stringify({ filter: { where: { osccPersonId: props.location.state.id} } }));
    // console.log(response.data[0]);
    setOsccPerson(response.data[0]);
  };

  const getTeam = async () => {
    // let response = await UAPI.getAll({},"osccteams");
    // setGetTeams(response.data);

    let teamCodeList = [];
    if (typeof userinfo.user.osccteams != 'undefined') {
      userinfo.user.osccteams.forEach(i => {
        teamCodeList.push(i.teamCode);
      });
    }
    let xParams = {
      filter: {
        where: { "osccteams.teamCode": { in: teamCodeList } },
      }
    };
    let responseOsccTeam = await UAPI.getAll(xParams, "teamusers");
    if (responseOsccTeam.status === 200) {

      let users = responseOsccTeam.data;
      if (users.length > 0) {
        let teams = [];
        users.forEach(u => {
          if (typeof u.osccteams !== 'undefined') {
            u.osccteams.forEach(t => {
              let n = 0;
              teams.forEach(i => {
                if (i.id === t.teamCode) {
                  n = 1;
                }
              });
              if (n === 0) {
                teams.push(
                  {
                    id: t.teamCode,
                    team_name: t.teamName,
                    memberList: [],
                  }
                );
              }
            });
          }
        });

        teams.forEach(i => {
          users.forEach(u => {
            if (typeof u.osccteams !== 'undefined') {
              u.osccteams.forEach(t => {
                if (i.id === t.teamCode) {
                  i.memberList.push(
                    {
                      id: u.id,
                      fullname: u.fullname,
                      memberRole: t.memberTypeId,
                      memberRoleName: t.memberTypeName,
                      hcode: u.department.hcode,
                      hos_name: u.department.hos_name,
                    }
                  );
                }
              });
            }
          });
        });
        setGetTeams(teams);
      }
    }
  };

  const getQuestionnaire = async () => {
    let response = await UAPI.getAll({}, "questionnaires");
    // console.log(response.data);
    setGetQuestionnaires(response.data);
  };

  const changeTeamMember = (e, serviceslotId) => {
    // console.log('changeTeamMember-- ');
    // console.log(e.target.value);
    let x = selectedTeamMember;
    x[serviceslotId] = e.target.value;
    setSelectedTeamMember({ ...selectedTeamMember, ...x });
  }

  const mkSelectTeamMember = (teamId, officerId, serviceslotId) => {
    let thisTeam = null;
    getTeams.forEach(t => {
      if (t.id === teamId) {
        thisTeam = t;
      }
    });

    let options = [];
    let headId = null;
    let n = 0;
    options.push(<option key={0} value={''}>-ผู้ตอบ-</option>);
    if (thisTeam) {
      if (typeof thisTeam.memberList !== 'undefined') {
        thisTeam.memberList.forEach(i => {
          ++n;
          options.push(<option key={n} value={i.id}>{i.fullname}</option>);
          if (i.memberRole === "1") {
            headId = i.id;
          }
        });
      }
    }

    return (
      <select style={{ width: 140 }}
        value={selectedTeamMember[serviceslotId]}
        onChange={(e) => changeTeamMember(e, serviceslotId)}
        // disabled={headId === userinfo.user.id ? false : true}
      >
        {options}
      </select>
    );
  }

  const rowsPlan = () => {
    let r = [];
    if (planService.length > 0) {
      let n = 0;
      let rowColor = "#FFFFFF";
      planService.forEach((i) => {
        // console.log(i);
        ++n;
        if (n % 2 === 0) {
          rowColor = "#EEEEEE";
        }
        else {
          rowColor = "#FFFFFF";
        }

        let countOfficerId=0;
        let countAllSlot=0;
        if (typeof i.serviceslotList !== 'undefined') {
          if (i.serviceslotList) {
            countAllSlot=i.serviceslotList.length;
            i.serviceslotList.forEach(ssl => {
              if (typeof ssl.officerId !== 'undefined') {
                if (ssl.officerId) {
                  if (ssl.officerId!=='') {
                    ++countOfficerId;
                  }
                }
              }              
            });
          }
        }

        let allSlotsAssigned =false;
        if (countAllSlot>0 && countAllSlot===countOfficerId) {
          allSlotsAssigned = true;
        }
        // console.log('countOfficerId- ',countOfficerId);
        // console.log('countAllSlot- ',countAllSlot);
        // console.log('allSlotsAssigned- ',allSlotsAssigned);

        r.push(
          <Grid key={n} item xs={12} sm={12} 
            style={{ 
              border: "solid 1px #E2E2E2", 
              borderRadius: isDesktop?20:5, 
              marginTop: 3, 
              padding: 2, 
              backgroundColor: rowColor 
            }}
          >
            {/* <Grid container justify="flex-start"> */}
            <Grid container style={{ padding: 5 }} direction={isDesktop ? "row" : "column-reverse"} >
              <Grid item xs={12} sm={10}>
                <Grid container style={{ marginTop: isDesktop?0:-32 }} justify="flex-start">
                  <Grid item xs={12} sm={1}>
                    {isDesktop ? <b>&nbsp;&nbsp;</b> : <b>ลำดับ: </b>}
                    {n}
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    {isDesktop ? "" : <b>ชื่อแผน: </b>}
                    {i.cause}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {isDesktop ? "" : <b>วันที่: </b>}
                    {UAPI.dateTHs(i.startDate)}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {isDesktop ? "" : <b>วันที่: </b>}
                    {UAPI.dateTHs(i.endDate)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={2} style={{ padding: 0, margon: 0 }}>
                <Grid container justify="flex-end" style={{ padding: 0, margon: 0 }}>
                  <Button
                    onClick={() => clickPlanDetail(i)}
                    variant="contained" color="primary"
                    startIcon={<MdRemoveRedEyeIcon style={{ marginLeft: 11, marginTop: -2 }} />}
                    style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: "40px", maxWidth: "40px", color: "white", }}
                  />
                  {localStorage.getItem("focusedCaseStatus") === 'newCase' && (
                    <Button
                      disabled={allSlotsAssigned?false:true}
                      onClick={() => clickSentTeamMember(i)}
                      variant="contained" color="primary"
                      style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: "100px", maxWidth: "100px", color: "white", }}
                    >
                      จ่ายงาน
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      });
    }
    return r;
  };

  const rowServiceSlot = () => {
    let r = [];
    if (Object.keys(focusedPlanService).length > 0) {
      // console.log(focusedPlanService);
      let act = [];
      if (typeof focusedPlanService.serviceslotList !== 'undefined') {
        if (focusedPlanService.serviceslotList.length > 0) {
          act = focusedPlanService.serviceslotList;
        }
      }
      if (act.length > 0) {
        let n = 0;
        let rowColor = '';
        act.sort(function (a, b) {
          // b-a เรียงมากไปน้อย
          // a-b เรียงน้อยไปมาก
          let dateA = a.startDate;
          let dateB = b.startDate;
          dateA = (typeof dateA === 'undefined'?'0000-00-00':dateA);
          dateB = (typeof dateB === 'undefined'?'0000-00-00':dateB);
          dateA = (dateA === null|dateA===''?'0000-00-00':dateA);
          dateB = (dateB === null|dateB===''?'0000-00-00':dateB);
          let ax = parseInt((new Date(dateA).getTime()).toString());
          let bx = parseInt((new Date(dateB).getTime()).toString());
          ax = (isNaN(ax) ? 0 : ax);
          bx = (isNaN(bx) ? 0 : bx);
          return ax - bx;
          // return bx - ax;
        });
        act.forEach((i) => {
          if (i) {
            // console.log(i);
            ++n;
            if (n % 2 === 0) {
              rowColor = "#EEEEEE";
            }
            else {
              rowColor = "#FFFFFF";
            }
            r.push(
              <Grid key={n} item xs={12} sm={12} style={{ border: "solid 1px #E2E2E2", borderRadius: isDesktop?20:5, marginTop: 3, padding: 2, backgroundColor: rowColor }} >
                <Grid container justify="flex-start">
                  <Grid item xs={12} sm={1}>
                    {isDesktop ? <b>&nbsp;&nbsp;</b> : <b>ลำดับ: </b>}
                    {n}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    {isDesktop ? "" : <b>กิจกรรม: </b>}
                    {i.serviceslotName}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {isDesktop ? "" : <b>ทีมที่รับมอบ: </b>}
                    {i.teamName}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {isDesktop ? "" : <b>วันเริ่ม: </b>}
                    {UAPI.dateTHs(i.startDate)}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {isDesktop ? "" : <b>วันสิ้นสุด: </b>}
                    {UAPI.dateTHs(i.endDate)}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Grid container justify="flex-end" style={{ paddingRight: 5 }}>
                      {osccPerson.caseStatus==='assignedTeam'?mkSelectTeamMember(i.teamCode, (typeof i.officerId !== 'undefined' ? i.officerId : null), i.serviceslotId):findOfficerName(i.officerId)}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          }
        });
      }
    }
    return r;
  };

  const findOfficerName = (x) => {
    let r='';
    getTeams.forEach(i => {
      i.memberList.forEach(z => {
        if (x===z.id) {
          r=z.fullname;
        }
      });
    });
    return r;
  }

  const clickSentTeamMember = (planServiceData) => {
    setOpenBackdrop(true);
    let officerIdList = [];
    if (typeof planServiceData.serviceslotList !== 'undefined') {
      planServiceData.serviceslotList.forEach(i => {
        if (typeof i.officerId !== 'undefined') {
          if (i.officerId !== '' && i.officerId !== null) {
            if (officerIdList.indexOf(i.officerId) < 0) {
              officerIdList.push(i.officerId);
            }
          }
        }
      });
    }

    let x = osccPerson;
    x['officerId'] = officerIdList;
    if (officerIdList.indexOf(userinfo.user.id)>-1) {
      x['caseStatus'] = 'recievedCase';
    }
    else {
      x['caseStatus'] = 'assignedOfficer';
    }
    // console.log(officerIdList);
    // console.log(x);
    UAPI.update(x.id, x, "osccpeople").then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setOpenBackdrop(false);
          history.goBack();
        }, 1000);
      }
    });
  }

  const clickCancelSentTeam = (planServiceData) => {
    // คงไม่มีกรณีที่ CM หลายคนมารุม assign เคสเดียวกัน หรอกเนอะ
    setOpenBackdrop(true);
    let x = osccPerson;
    x['caseStatus'] = 'assignedTeam';
    x['officerId'] = null;
    UAPI.update(x.id, x, "osccpeople").then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setOpenBackdrop(false);
          history.goBack();
        }, 1000);
      }
    });
  }


  const clickPlanDetail = (planServiceData) => {
    let x = JSON.parse(JSON.stringify(planServiceData));
    // console.log(x);
    if (typeof x.startDate === 'undefined') {
      // x.startDate='2021-05-21';
      x.startDate = '';
    }
    if (typeof x.endDate === 'undefined') {
      x.endDate = '';
    }

    let selectedTeamMember_temp = {};
    if (typeof x.serviceslotList !== 'undefined') {
      x.serviceslotList.forEach(i => {
        if (i) {
          if (typeof i.serviceslotId !== 'undefined') {
            if (typeof i.officerId !== 'undefined') {
              selectedTeamMember_temp[i.serviceslotId] = i.officerId;
            }
          }
        }
      });
    }
    setSelectedTeamMember(selectedTeamMember_temp);
    setFocusedPlanService(x);
    setOpenActivity(true);
    getOverrideTeam(x);
  }

  const clickSaveAssign = () => {
    if (typeof focusedPlanService.id !== 'undefined') {
      if (focusedPlanService.id !== null && focusedPlanService.id !== '') {

        setOpenBackdrop(true);
        let x = focusedPlanService;
        x.serviceslotList.forEach(i => {
          for (const [k, v] of Object.entries(selectedTeamMember)) {
            if (i.serviceslotId === k) {
              i['officerId'] = v;
            }
          }
        });

        UAPI.update(x.id, x, "planservices").then((response) => {
          if (response.status === 200) {
            getPlanService();
            setTimeout(() => {
              handleClose();
              setOpenBackdrop(false);
            }, 100);
          }
        });

      }
    }
  }

  const getOverrideTeam = (d) => {
    if (typeof d.serviceslotList !== 'undefined') {
      if (d.serviceslotList.length > 0) {
        let x = d.serviceslotList[0].teamCode;
        let z = {};
        let o = [];
        getTeams.forEach(t => {
          if (t.id === x) {
            z = t;
          }
        });
        setOverrideTeam(z);

        o.push(<option key={0} value={''}>-ผู้ตอบทั้งหมด-</option>);
        z.memberList.forEach(i => {
          o.push(<option key={genKey()} value={i.id}>{i.fullname}</option>);
        });
        setOverrideTeamOptions(o);
      }
    }
  }

  const genKey = () => {
    return Math.floor(Math.random() * 1000000000) + 1;
  }

  const changeOverrideTeam = (e) => {
    if (typeof focusedPlanService.serviceslotList !== 'undefined') {
      if (focusedPlanService.serviceslotList.length > 0) {
        let x = {};
        focusedPlanService.serviceslotList.forEach(i => {
          x[i.serviceslotId] = e.target.value;
        });
        setSelectedTeamMember(x);
      }
    }
  }
  
  const clickSaveCareGiver = async () => {
    // console.log(careGiverData);
    // console.log(props.location.state);
    setOpenBackdrop(true);
    let data = {
      careGiver : careGiverData
    };
    let x = props.location.state;

    UAPI.patch(x.id, data, "osccpeople").then((responseU) => {
      if (responseU.status === 200) {
        // console.log(responseU.data);
        setOpenBackdrop(false);

        x['careGiver'] = careGiverData;
        history.replace('/caseplanteam', x);
      }
    });
  }

  const changeCareGiverData = (e) => {
    if (e.target.name==='cid') {
      const onlyNums = e.target.value.replace(/[^0-9]/g, '');
      if (onlyNums.length <= 13) {
        setCareGiverData({...careGiverData,...{cid:onlyNums}});
        if (onlyNums.length === 13) {
          setDisableSaveButton(false);
        }
        else {
          setDisableSaveButton(true);
        }
      }
    }
    else {
      setCareGiverData({...careGiverData,...{[e.target.name]:e.target.value}});
      if (careGiverData.cid.length === 13) {
        setDisableSaveButton(false);
      }
      else {
        setDisableSaveButton(true);
      }
    }
  }

  useEffect(() => {
    getPlanService();
    getTeam();
    getQuestionnaire();
    getPerson();
    getOsccPerson();
    
    if (typeof props.location.state != 'undefined') {
      if (typeof props.location.state.careGiver != 'undefined') {
        setCareGiverData({
          cid : props.location.state.careGiver.cid||'',
          fname: props.location.state.careGiver.fname||'',
          lname : props.location.state.careGiver.lname||'',
          nameCalledByCase : props.location.state.careGiver.nameCalledByCase||'',
          relation : props.location.state.careGiver.relation||'',
          relationSpecify : props.location.state.careGiver.relationSpecify||'',
        });
      }
    }
  }, []);

  return (
    <div style={{ marginBottom: 100 }}>

      <Dialog className={classes.backdrop} open={openBackdrop}>
        <DialogContent>
          <CircularProgress color="inherit" />
        </DialogContent>
      </Dialog>

      <Grid container style={{ marginTop: -10, marginBottom: 10 }} justify="center" direction={isDesktop ? "row" : "column-reverse"}>
        <Grid item xs={12} sm={6} style={{ marginTop: 10 }}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <h4>มอบหมายงานให้สมาชิกทีม</h4>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container justify="flex-end">
            <ButtonGroup size={isDesktop?'large':'medium'} style={{ marginTop: 0, marginBottom: 0, borderRadius: 8, border: 'solid 4px #CDCDCD', padding: 0 }}>
              <Button color="primary" style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
                onClick={() => {
                  history.goBack();
                }}
              >
                กลับ
              </Button>
              <Button color="primary" style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
                onClick={() => {
                  history.push({
                    pathname: "/caseemr",
                    state: props.location.state,
                  });
                }}
              >
                EMR
              </Button>

              <Button color="primary" style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
                onClick={() => { 
                  setOpenMatchDialog(true); 
                }}
              >
                ผู้ดูแล
              </Button>

              {localStorage.getItem("focusedCaseStatus") === 'sentMember' && (
                <Button 
                  color="secondary" 
                  style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
                  onClick={clickCancelSentTeam}
                >
                  ยกเลิก
                </Button>
              )}
            </ButtonGroup>
          </Grid>
        </Grid>
      </Grid>

      {/* <CaseInfoBar data={props.location.state} /> */}
      {Object.keys(personData).length > 0 && (
        <CaseInfoBar data={personData} />
      )}

      <Grid container style={{ marginBottom: 10 }}>
        {/* TABLE HEAD */}
        {isDesktop ? (
          <Grid item xs={12} sm={12} style={{ border: "solid 0px #666666", borderRadius: 20, backgroundColor: "#00a8ff", color: "#FFFFFF" }}>
            <Grid container justify="flex-start">
              <Grid item xs={12} sm={10}>
                <Grid container justify="flex-start">
                  <Grid item xs={12} sm={1} style={{ padding: 4 }}>
                    &nbsp;&nbsp;ลำดับ
                  </Grid>
                  <Grid item xs={12} sm={5} style={{ padding: 4 }}>
                    รายการแผนการดูแล
                  </Grid>
                  <Grid item xs={12} sm={2} style={{ padding: 4 }}>
                    วันเริ่ม
                  </Grid>
                  <Grid item xs={12} sm={1} style={{ padding: 4 }}>
                    วันสิ้นสุด
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={2}>
                <br />
              </Grid>
            </Grid>
          </Grid>
        ):(
          <Grid item xs={12} sm={12} style={{ border: "solid 0px #666666", borderRadius: 5, backgroundColor: "#00a8ff", color: "#FFFFFF" }}>
            &nbsp;&nbsp;รายการแผนการดูแล
          </Grid>
        )}

        {/* TABLE ROWS */}
        {rowsPlan()}
      </Grid>

      <Dialog fullWidth={true} maxWidth={"lg"} open={openActivity} onClose={(_event, reason)=>{if (reason === "backdropClick") {return;}handleClose();}}>
        <DialogTitle disableTypography style={{ marginBottom: 0, paddingBottom: 2 }}>

          <Grid container>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <b>ชื่อแผน : </b> {focusedPlanService['cause']}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <b>วันที่เริ่ม : </b> {UAPI.dateTHs(focusedPlanService['startDate'])}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <b>วันที่สิ้นสุด : </b> {UAPI.dateTHs(focusedPlanService['endDate'])}
                </Grid>
              </Grid>  
            </Grid>

            {isDesktop?(
              <Grid item xs={12} sm={12} style={{ border: "solid 0px #666666", borderRadius: 20, backgroundColor: "#00a8ff", color: "#FFFFFF" }}>
                <Grid container justify="flex-start">
                  <Grid item xs={12} sm={1} style={{ padding: 4 }}>
                    &nbsp;&nbsp;ลำดับ
                  </Grid>
                  <Grid item xs={12} sm={3} style={{ padding: 4 }}>
                    กิจกรรม
                  </Grid>
                  <Grid item xs={12} sm={2} style={{ padding: 4 }}>
                    ทีมที่รับมอบ
                  </Grid>
                  <Grid item xs={12} sm={2} style={{ padding: 4 }}>
                    วันเริ่ม
                  </Grid>
                  <Grid item xs={12} sm={2} style={{ padding: 4 }}>
                    วันสิ้นสุด
                  </Grid>
                  <Grid item xs={12} sm={2} style={{ paddingTop: 4, paddingRight: 10 }}>
                    <Grid container justify="flex-end">
                      {(osccPerson.caseStatus === 'assignedTeam' && Object.keys(overrideTeam).length > 0) && (
                        <select style={{ width: 140 }}
                          onChange={(e) => changeOverrideTeam(e)}
                        >
                          {overrideTeamOptions}
                        </select>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ):(
              <Grid item xs={12} sm={12}>
                <Grid container justify="flex-start">
                  <Grid item xs={12} sm={1} style={{ border: "solid 0px #666666", borderRadius: 5, backgroundColor: "#00a8ff", color: "#FFFFFF",padding: 4 }}>
                    &nbsp;&nbsp;รายการกิจกรรม
                  </Grid>
                  <Grid item xs={12} sm={2} style={{ marginTop: 3, border: "solid 0px #666666", borderRadius: 5, backgroundColor: "#d2e8f4", color: "#FFFFFF",padding: 2 }}>
                    <Grid container justify="flex-end">
                      {(osccPerson.caseStatus === 'assignedTeam' && Object.keys(overrideTeam).length > 0) && (
                        <select style={{ width: 140 }}
                          onChange={(e) => changeOverrideTeam(e)}
                        >
                          {overrideTeamOptions}
                        </select>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          
        </DialogTitle>
        <DialogContent style={{ marginTop: 0, paddingTop: 0 }}>
          <form className={classes.form} noValidate>
            <Grid container style={{ marginBottom: 10 }}>

              {/* TABLE ROWS */}
              {rowServiceSlot()}

            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          {localStorage.getItem("focusedCaseStatus") === 'newCase' && (
            <Button variant="outlined" onClick={clickSaveAssign} color="primary" >
              บันทึก
            </Button>
          )}
          <Button variant="outlined" onClick={handleCloseAddNewActivity} color="primary">
            ปิด
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={openMatchDialog} fullWidth={true} maxWidth={'md'}>
        <DialogTitle style={{ paddingBottom: 0 }}>บันทึกข้อมูลผู้ดูแล</DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>

            <Grid container style={{ marginBottom: 10 }}>
              <Grid item xs={12} sm={12} style={{ marginBottom: 5 }}>
                บันทึกข้อมูลผู้ดูแลของผู้ที่มีความเสี่ยงรายนี้ เพื่อให้ผู้ดูแลสามารถตอบแบบสอบถามในระบบได้ด้วยตนเอง หลังจากผู้ดูแลลงทะเบียนและได้รับการอนุมัติ Username โดย เจ้าหน้าที่ (FA/รพ./รพ.สต.)
              </Grid>

              <Grid item xs={12} sm={12} style={{ marginTop: 10 }}>
                <Grid container>
                  <Grid item xs={12} sm={12} style={{ border: 'solid 0px #E2E2E2', borderRadius: 7, marginBottom: 2, padding: 3, backgroundColor: '#038000', color: '#FFFFFF', paddingLeft: 15 }}>
                    ข้อมูลผู้ดูแล
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Grid container>

                      <Grid item xs={12} sm={3} style={{ paddingRight: 5, paddingBottom: 5 }}>
                        <TextField
                          // size="small"
                          label="เลขบัตรประจำตัวประชาชน"
                          variant="outlined"
                          fullWidth
                          inputProps={{ name: "cid", style: { textAlign: 'left' } }}
                          value={careGiverData.cid||''}
                          onChange={changeCareGiverData}
                        />
                      </Grid>

                      <Grid item xs={12} sm={3} style={{ paddingRight: 5, paddingBottom: 5 }}>
                        <TextField
                          // size="small"
                          label="ชื่อ"
                          variant="outlined"
                          fullWidth
                          inputProps={{ name: "fname", style: { textAlign: 'left' } }}
                          value={careGiverData.fname||''}
                          onChange={changeCareGiverData}
                        />
                      </Grid>

                      <Grid item xs={12} sm={3} style={{ paddingRight: 5, paddingBottom: 5 }}>
                        <TextField
                          // size="small"
                          label="สกุล"
                          variant="outlined"
                          fullWidth
                          inputProps={{ name: "lname", style: { textAlign: 'left' } }}
                          value={careGiverData.lname||''}
                          onChange={changeCareGiverData}
                        />
                      </Grid>

                      <Grid item xs={12} sm={3} style={{ paddingRight: 5, paddingBottom: 5 }}>
                        <TextField
                          // size="small"
                          label="ชื่อที่เด็กเรียก"
                          variant="outlined"
                          fullWidth
                          inputProps={{ name: "nameCalledByCase", style: { textAlign: 'left' } }}
                          value={careGiverData.nameCalledByCase||''}
                          onChange={changeCareGiverData}
                        />
                      </Grid>

                    </Grid>
                    <Grid container>

                      <Grid item xs={12} sm={3} style={{ paddingRight: 5, paddingBottom: 5 }}>
                        <Select fullWidth={true} variant="outlined" native
                          onChange={(e) => changeCareGiverData(e)}
                          inputProps={{ name: "relation" }}
                          // value={matchRelativeTypeId}
                          value={careGiverData.relation}
                        >
                          <option value={''}>-เลือกความสัมพันธ์-</option>
                          {relativeData.map((u) => (
                            <option key={u.relativeId} value={u.relativeId}>
                              {u.relativeName}
                            </option>
                          ))}
                        </Select>
                      </Grid>

                      <Grid item xs={12} sm={3} style={{ paddingRight: 5, paddingBottom: 5 }}>
                        <TextField
                          // size="small"
                          label="ระบุความสัมพันธ์"
                          variant="outlined"
                          fullWidth
                          inputProps={{ name: "relationSpecify", style: { textAlign: 'left' } }}
                          value={careGiverData.relationSpecify||''}
                          onChange={changeCareGiverData}
                        />
                      </Grid>
                      
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

            </Grid>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" disabled={disableSaveButton} 
            onClick={clickSaveCareGiver} 
          >
            บันทึก
          </Button>
          <Button variant="contained" autoFocus
            onClick={() => setOpenMatchDialog(false)}
          >
            ปิด
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}