/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { DELETE } from "../index";

const deleteAction = {
  request: (payload:any) => action(DELETE.REQUEST, {endpoint:payload.endpoint, id:payload.id}),
  success: (response: any) => action(DELETE.SUCCESS, { data: response }),
  failure: (error: any) => action(DELETE.FAILURE, { error }),
};

export default deleteAction;