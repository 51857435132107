/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React,{useEffect,useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../../reducers";
import Actions from '../../../actions/activity';
import { Button, Grid,Typography } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import CasesList from '../casesList';
import AssignList from '../assign/assignList';
import AssignDialogAdd from './assignDialogAdd';

interface IProps {}
type TProps = IProps;

const Case=()=>{
  const history = useHistory();
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
  const [caseType, setCaseType] = useState("");
  const [showModalAssign, setShowModalAssign] = useState(false);

  useEffect(() => {
    const teamCode = globalState.auth.user.response?.user.osccteams[0].teamCode;
    if(teamCode && teamCode!==""){
      const params = {teamCode:teamCode};
      // dispatch(Actions.GetGroupList.request(params));
      dispatch(Actions.GetQuestionnaire.request({}));
    }
  },[]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} style={{display:'flex',justifyContent:'start'}}>
        <Typography variant="h4" style={{marginBottom:0}}>มอบหมายเคส</Typography>
      </Grid>
      <Grid item xs={6} style={{display:'flex',justifyContent:'end'}}>
        <Button variant="outlined" color="primary" onClick={history.goBack}>กลับ</Button>
        <Button variant="contained" color="primary" onClick={()=>history.push({ pathname: '/activity/groups' })}>จัดการกลุ่ม</Button>
        <Button variant="contained" color="primary" onClick={()=>setShowModalAssign(true)}>มอบหมายกลุ่ม</Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" style={{marginBottom:0}}>รายบุคคล</Typography>
      </Grid>
      <Grid item xs={12}>
        <AssignList type={"person"}/>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" style={{marginBottom:0}}>เคสกลุ่ม</Typography>
      </Grid>
      <Grid item xs={12}>
        <AssignList type={"group"}/>
      </Grid>
      {
        (showModalAssign)&&<AssignDialogAdd type="single" open={showModalAssign} setOpen={setShowModalAssign} />
      }
    </Grid>
  );
}

export default Case;