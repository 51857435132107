/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { UPDATE_ASSIGN } from "../index";

const updateAssign = {
  request: (payload:any) => action(UPDATE_ASSIGN.REQUEST, payload),
  success: (response: any) => action(UPDATE_ASSIGN.SUCCESS, { data: response }),
  failure: (error: any) => action(UPDATE_ASSIGN.FAILURE, { error }),
};

export default updateAssign;