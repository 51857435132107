import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { TRANSLATIONS_TH } from './translations/th/translations'
import { TRANSLATIONS_EN } from './translations/en/translations'
import moment from 'moment';
import 'moment/locale/th';

const resources = {
  en: {
    translation: TRANSLATIONS_EN,
  },
  th: {
    translation: TRANSLATIONS_TH,
  },
};

const thaiYear = (moment, format) => {
  const christianYear = moment.format('YYYY');
  const buddhishYear = (parseInt(christianYear) + 543).toString();
  return moment
    .format(format.replace('YYYY', buddhishYear).replace('YY', buddhishYear.substring(2, 4)))
    .replace(christianYear, buddhishYear);

};

i18n
	.use(initReactI18next)
	.init({
		resources,
		debug: false,
		fallbackLng: 'th',
		initImmediate: false,
		interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        if (value instanceof Date) {
          if (lng === "th") {
            moment.locale(["th", "th"]);
            return thaiYear(moment(value), format);
          } else {
            moment.locale(["en", "en"]);
            return moment(value).format(format);
          }
        }
        return value;
      }
    },
		react: {
      bindI18n: 'lang changed',
      bindI18nStore: 'LANG STORE',
      nsMode: 'default',
    }
});

export default i18n;