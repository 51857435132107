/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { UPDATE_OSCCPERSON } from "../index";

const udpateOsccperson = {
  request: (payload:any) => action(UPDATE_OSCCPERSON.REQUEST, {id:payload.id, data:payload.data}),
  success: (response: any) => action(UPDATE_OSCCPERSON.SUCCESS, { data: response }),
  failure: (error: any) => action(UPDATE_OSCCPERSON.FAILURE, { error }),
};

export default udpateOsccperson;