import { GET_GROUPLIST } from "../../actions";
import { AnyAction } from "redux";
import { GroupList } from "../../types/activity";
// import moment from 'moment';
// import 'moment/dist/locale/th';
// moment.locale(["th", "th"]);

const initialState:GroupList = {
  isFetching: false,
  isSuccess: false,
  count: 0,
  data: []
};

export default function getGroupList(
  state = initialState,
  action: AnyAction
): GroupList {
  switch (action.type) {
    case GET_GROUPLIST.REQUEST:{
      return {
        ...state,
        isFetching: true,
        isSuccess: false
      };
    }
    case GET_GROUPLIST.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        count: action.data.count,
        data: action.data.rows
      };
    }
    case GET_GROUPLIST.FAILURE:
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
        data: [],
      };
    default:
      return state;
  }
}
