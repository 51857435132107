import { persistStore } from 'redux-persist';
import configureStoreDev from './configureStore.dev';

const selectedConfigureStore = process.env.NODE_ENV === 'production' ? configureStoreDev : configureStoreDev;

export const { configureStore } = selectedConfigureStore;
export const store = configureStore();
export const persistor = persistStore(store);

export const { history } = selectedConfigureStore;
