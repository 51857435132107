/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { COUNT_ASSIGNED } from "../index";

const countCaseStatus = {
  request: (payload:Record<string,any>) => action(COUNT_ASSIGNED.REQUEST, payload),
  success: (response: any) => action(COUNT_ASSIGNED.SUCCESS, { data: response }),
  failure: (error: any) => action(COUNT_ASSIGNED.FAILURE, { error }),
};

export default countCaseStatus;