/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// npm install react-icons --save  // https://react-icons.github.io/react-icons
// npm install @material-ui/icons --save
// npm install @material-ui/lab --save

import React, { useState, useEffect, useRef } from 'react';
import {
  withStyles,
  makeStyles,
  useTheme,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom'
// import * as ICONS from '@material-ui/icons';
import * as ICONS from 'react-icons/md';
import useGlobal from "../../store";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { green, purple } from '@material-ui/core/colors';


import { getYear, getMonth, getDate } from "date-fns";
import
// DatePicker, 
{
  registerLocale,
  setDefaultLocale,
  // getDefaultLocale 
} from "react-datepicker";
// npm install react-datepicker --save
import th from 'date-fns/locale/th';

import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";

import * as UAPI from "../UniversalAPI";
import CRUD from "./UniversalCRUD";
import * as AuthService from "../auth.service";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableRowColumn,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';
import {
  // Autocomplete,
  Pagination
} from '@material-ui/lab';

const themeTable = createMuiTheme({
  overrides: {
    // Name of the component ⚛️ / style sheet
    MuiTableHead: {
      // Name of the rule
      root: {
        border: 'none',
        boxShadow: 'none',
      },
    },
    MuiTable: {
      root: {
        border: 'none',
        borderSpacing: '40 20',
      },
    },
    MuiTableRow: {
      root: {
        border: 'none',
        height: 30,
        '&:nth-of-type(odd)': {
          backgroundColor: '#EFEFEF',
        },
        // '&:nth-child(1)': {
        //   backgroundColor: 'red',
        // },
      },
    },
    MuiTableCell: {
      root: {
        border: 'none', height: 'auto', padding: 0, margin: 0,
      },
      head: {
        border: 'none', height: 40, padding: 0, margin: 0,
      },
      body: {
        border: 'none', height: 40, padding: 0, margin: 0,
      },
      footer: {
        border: 'none', height: 'auto', padding: 0, margin: 0,
      },
    },
  },
});

const useStyles = makeStyles({
  contentTitle: {
    display: 'inline',
    backgroundColor: '#CDEDFF',
    borderRadius: 10,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 5
  },
  contentText: {
    display: 'inline',
    marginRight: 10
  }
});

const themeBtnForward = createMuiTheme({
  palette: {
    primary: {
      main: '#007CEE',
    }
  },
});

const themeBtnDelete = createMuiTheme({
  palette: {
    primary: {
      main: '#F91D2A',
    }
  },
});

const themeBtnEdit = createMuiTheme({
  palette: {
    primary: {
      main: '#EEAB00',
    }
  },
});

const themeBtnAdd = createMuiTheme({
  palette: {
    primary: {
      main: '#05EE00',
    }
  },
});

export default function UniversalDataTable(props) {
  const history = useHistory();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [globalState, globalActions] = useGlobal();

  registerLocale('th', th);
  setDefaultLocale('th');
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [focusedPrimaryKey, setFocusedPrimaryKey] = useState(null);
  const [lookUp, setLookUp] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(typeof props.structure.rows_per_page != 'undefined' ? props.structure.rows_per_page : 20);
  const [allPages, setAllPages] = useState();
  const [searchTextFilter, setSearchTextFilter] = useState();
  const [forcePage, setForcePage] = useState(1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [showSelectRPP, setShowSelectRPP] = useState(false);
  const queryDataCount = useRef(0);


  // --- CRUD - basic required
  const [newData, setNewData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [returnedData, setReturnedData] = useState(null);
  const callBackParams = (x, returned_data) => {
    if (x === 'refresh') {
      getData();
    }
    if (x === 'close_dialog') {
      setOpenDialog(false);
    }
    if (x === 'return_data') {
      setReturnedData(returned_data);
    }
  }
  // --- CRUD - basic required END



  const makeHeaderRow = () => {
    let fields = props.structure.fields;
    let tr = [];
    let n = 0;

    tr.push(
      <TableCell key={'headLeftCol'} style={{ width: 30, borderTopLeftRadius: 30, borderBottomLeftRadius: 30, border: 'none' }}>
        <br />
      </TableCell>
    );

    for (const [field_key, field_config] of Object.entries(fields)) {
      n++;
      if (field_config.show === true) {
        let colIcon;
        if (typeof field_config.icon != 'undefined') {
          colIcon = <DynIcon icon={field_config.icon} />;
        }
        tr.push(
          <TableCell key={field_key}>
            {colIcon}
            {field_config.title}
          </TableCell>
        );
      }
    }

    let btn_count=0;
    let btn_width=40;
    if (props.structure.update_document) {
      btn_count++;
    }
    if (props.structure.delete_document) {
      btn_count++;
    }
    if (typeof props.structure.forward_path !== 'undefined') {
      if (props.structure.forward_path !== null && props.structure.forward_path !== '') {
        btn_count++;
      }
    }
    switch (btn_count) {
      case 2:
        btn_width=85;
        break;
    
      case 3:
        btn_width=130;
        break;

      default:
        // eslint-disable-next-line no-self-assign
        btn_width=btn_width;
    }

    if (props.structure.create_document) {
      tr.push(
        <TableCell key="btnAdd" style={{ borderTopRightRadius: 30, borderBottomRightRadius: 30 }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 10 }}>
            <MuiThemeProvider key="btnEdit" theme={themeBtnAdd}>
              <Button
                onClick={handleClickAdd}
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<DynIcon icon='MdAddCircle' style={{ marginLeft: 11, marginTop: -2 }} />}
                style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: btn_width+'px', maxWidth: btn_width+'px', color: 'white' }}
              />
            </MuiThemeProvider>
          </div>
        </TableCell>
      );
    }
    else {
      tr.push(
        <TableCell key="0" style={{ borderTopRightRadius: 30, borderBottomRightRadius: 30 }}>
          <br />
        </TableCell>
      );
    }
    return tr;
  }

  const convertSelectValue = (data_value, field_name, look_up, k, v) => {
    let r = data_value;
    if (typeof look_up != 'undefined') {
      look_up.forEach(i => {
        if (typeof i[k] != 'undefined' & typeof data_value != 'undefined') {
          if (i[k].toString() === data_value.toString()) {
            r = i[v];
          }
        }
      });
    }
    return r;
  }

  const dateThaiShort = (d) => {
    let r = d;
    if (d && typeof d != 'undefined') {
      r = parseInt(getDate(d)).toString() + ' ' + thaiMonth(parseInt(getMonth(d)) + 1, 'short') + ' ' + (parseInt(getYear(d)) + 543).toString();
    }
    else {
      r = '-';
    }
    return r;
  }

  const thaiMonth = (m, z) => {
    let x = parseInt(m);
    let r = m;
    if (z === 'short') {
      switch (x) {
        case 1: r = 'ม.ค.'; break;
        case 2: r = 'ก.พ.'; break;
        case 3: r = 'มี.ค.'; break;
        case 4: r = 'เม.ย.'; break;
        case 5: r = 'พ.ค.'; break;
        case 6: r = 'มิ.ย.'; break;
        case 7: r = 'ส.ค.'; break;
        case 8: r = 'ก.ค.'; break;
        case 9: r = 'ก.ย.'; break;
        case 10: r = 'ต.ค.'; break;
        case 11: r = 'พ.ย.'; break;
        case 12: r = 'ธ.ค.'; break;
        default: r = ''; break;
      }
    }
    if (z === 'long') {
      switch (x) {
        case 1: r = 'มกราคม'; break;
        case 2: r = 'กุมภาพันธ์'; break;
        case 3: r = 'มีนาคม'; break;
        case 4: r = 'เมษายน'; break;
        case 5: r = 'พฤษภาคม'; break;
        case 6: r = 'มิถุนายน'; break;
        case 7: r = 'สิงหาคม'; break;
        case 8: r = 'กรกฎาคม'; break;
        case 9: r = 'กันยายน'; break;
        case 10: r = 'ตุลาคม'; break;
        case 11: r = 'พฤศจิกายน'; break;
        case 12: r = 'ธันวาคม'; break;
        default: r = ''; break;
      }
    }
    return r;
  }

  const makeRows = () => {
    if (data) {
      let fields = props.structure.fields;
      let tbody = [];
      let n = 0;
      data.forEach(data_row => {
        ++n;
        let tds = [];
        let btns = [];

        tds.push(
          <TableCell key={'leftCol'} style={{ borderTopLeftRadius: 30, borderBottomLeftRadius: 30, border: 'none' }}>
            <br />
          </TableCell>
        );

        for (const [field_key, field_config] of Object.entries(props.structure.fields)) {
          if (field_config.data_type === 'date') {
            if (typeof data_row[field_key] != 'undefined') {
              data_row[field_key] = new Date(data_row[field_key]);
            }
          }
        };

        for (const [field_key, field_config] of Object.entries(fields)) {
          if (field_config.show === true) {
            let cv = data_row[field_key];
            if (field_config.input_type === 'select' | field_config.input_type === 'radio') {
              cv = convertSelectValue(data_row[field_key], field_key, lookUp[field_config.input_select_source_name], field_config.input_select_source_key, field_config.input_select_source_value);
            }
            if (field_config.data_type === 'date') {
              if (field_config.format === 'thai_short') {
                cv = dateThaiShort(data_row[field_key]);
              }
            }
            else if (field_config.data_type === 'calculate_year') {
              if (typeof field_config.calculate_date_diff !== 'undefined') {
                if (field_config.calculate_date_diff===true) {
                  let start=field_config.date_start_field;
                  let end=field_config.date_end_field;
                  if (typeof start !== 'undefined' && typeof end !== 'undefined') {
                    let d_start=(start==='today'?new Date():new Date(data_row[start]));
                    let d_end=(end==='today'?new Date():new Date(data_row[end]));
                    let age = d_end.getFullYear() - d_start.getFullYear();
                    let m = d_end.getMonth() - d_start.getMonth();
                    if (m < 0 || (m === 0 && d_end.getDate() < d_start.getDate())) {
                        age--;
                    }
                    cv=age;
                  }
                }
              }
            }

            tds.push(
              <TableCell key={field_key}>
                {cv}
              </TableCell>
            );
          }
        }

        if (props.structure.update_document) {
          btns.push(
            <MuiThemeProvider key="btnEdit" theme={themeBtnEdit}>
              <Button
                onClick={() => handleClickEdit(data_row[props.structure.primary_key])}
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<DynIcon icon='MdEdit' style={{ marginLeft: 11, marginTop: -2 }} />}
                style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white' }}
              />
            </MuiThemeProvider>
          );
        }

        if (props.structure.delete_document) {
          btns.push(
            <MuiThemeProvider key="btnDelete" theme={themeBtnDelete}>
              <Button
                onClick={() => handleClickDelete(data_row[props.structure.primary_key])}
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<DynIcon icon='MdDelete' style={{ marginLeft: 11, marginTop: -2 }} />}
                style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white' }}
              />
            </MuiThemeProvider>
          );
        }

        if (typeof props.structure.forward_path !== 'undefined') {
          if (props.structure.forward_path !== null && props.structure.forward_path !== '') {
            let forward_path = props.structure.forward_path;
            let forward_props = null;
            if (typeof props.structure.forward_props !== 'undefined') {
              if (props.structure.forward_props === 'document') {
                forward_props = data_row;
              }
            }
              btns.push(
                <MuiThemeProvider key="btnDetail" theme={themeBtnForward}>
                  <Button
                    onClick={() => handleClickDetail(forward_path, forward_props)}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<DynIcon icon='MdRemoveRedEye' style={{ marginLeft: 11, marginTop: -2 }} />}
                    style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white' }}
                  />
                </MuiThemeProvider>
              );
          }
        }

        if (btns.length > 0) {
          tds.push(
            <TableCell key="0" style={{ borderTopRightRadius: 30, borderBottomRightRadius: 30 }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 10 }}>
                { (["AdminR8","CM","AdminTeams","Team"].includes(globalState.userRole))&&btns}
              </div>
            </TableCell>
          );
        }else {
          tds.push(<TableCell key="0" style={{ borderTopRightRadius: 30, borderBottomRightRadius: 30 }}></TableCell>);
        }
        tbody.push(<TableRow key={n}>{tds}</TableRow>)
      })
      return tbody;
    }
  }

  const makeDivRows = () => {
    if (data) {
      let fields = props.structure.fields;
      let tbody = [];
      let n = 0;
      data.forEach(data_row => {
        ++n;
        let tds = [];
        let tdsCount=0;
        let btns = [];

        if (props.structure.update_document) {
          btns.push(
            <MuiThemeProvider key="btnEdit" theme={themeBtnEdit}>
              <Button
                onClick={() => handleClickEdit(data_row[props.structure.primary_key])}
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<DynIcon icon='MdEdit' style={{ marginLeft: 11, marginTop: -2 }} />}
                style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white' }}
              />
            </MuiThemeProvider>
          );
        }

        if (props.structure.delete_document) {
          btns.push(
            <MuiThemeProvider key="btnDelete" theme={themeBtnDelete}>
              <Button
                onClick={() => handleClickDelete(data_row[props.structure.primary_key])}
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<DynIcon icon='MdDelete' style={{ marginLeft: 11, marginTop: -2 }} />}
                style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white' }}
              />
            </MuiThemeProvider>
          );
        }

        if (typeof props.structure.forward_path !== 'undefined') {
          if (props.structure.forward_path !== null && props.structure.forward_path !== '') {
            let forward_path = props.structure.forward_path;
            let forward_props = null;
            if (typeof props.structure.forward_props !== 'undefined') {
              if (props.structure.forward_props === 'document') {
                forward_props = data_row;
              }
            }
            btns.push(
              <MuiThemeProvider key="btnDetail" theme={themeBtnForward}>
                <Button
                  onClick={() => handleClickDetail(forward_path, forward_props)}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<DynIcon icon='MdRemoveRedEye' style={{ marginLeft: 11, marginTop: -2 }} />}
                  style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: '40px', maxWidth: '40px', color: 'white' }}
                />
              </MuiThemeProvider>
            );
          }
        }

        if (btns.length > 0) {
          ++tdsCount;
          tds.push(<div key="0" style={{ textAlign: 'right' }}>{btns}</div>);
        }

        for (const [field_key, field_config] of Object.entries(props.structure.fields)) {
          if (field_config.data_type === 'date') {
            if (typeof data_row[field_key] != 'undefined') {
              data_row[field_key] = new Date(data_row[field_key]);
            }
          }
        };

        for (const [field_key, field_config] of Object.entries(fields)) {
          if (field_config.show === true) {
            let cv = data_row[field_key];
            if (field_config.input_type === 'select' | field_config.input_type === 'radio') {
              cv = convertSelectValue(data_row[field_key], field_key, lookUp[field_config.input_select_source_name], field_config.input_select_source_key, field_config.input_select_source_value);
            }
            if (field_config.data_type === 'date') {
              if (field_config.format === 'thai_short') {
                cv = dateThaiShort(data_row[field_key]);
              }
            }
            ++tdsCount;
            tds.push(
              <div key={field_key} style={{ display: 'block', marginTop: tdsCount===2?-30:0 }}>
                <div className={classes.contentTitle}>
                  {field_config.title}
                </div>
                <div className={classes.contentText}>
                  {cv}
                </div>
              </div>
            );
          }
        }

        tbody.push(<div key={n} style={{ border: 'solid 1px #d5d5d5', borderRadius: 5, padding: 5, marginBottom: 10 }}>{tds}</div>)
      })
      return tbody;
    }
  }

  const getData = async (page, rpp, filter_where) => {
    let haveAfterQueryFilter = false;
    if (typeof props.filterDataFunctionEnable !== 'undefined') {
      if (props.filterDataFunctionEnable) {
        haveAfterQueryFilter = true;
      }
    }

    queryDataCount.current=queryDataCount.current+1;
    let xSkip = 0;
    let xLimit = rowsPerPage;
    let xWhere = {};
    if (typeof rpp != 'undefined') {
      xLimit = rpp;
    }

    if (typeof page !== 'undefined') {
      xSkip = rowsPerPage * page;
    }

    if (typeof filter_where !== 'undefined') {
      xWhere = filter_where;
    }
    // console.log("query_where",props.structure.query_where);
    // console.log("xWhere",xWhere);
    let whereProps = null;
    
    if (props.structure.query_where) {
      if (typeof props.structure.query_where !== 'undefined') {
        if (Object.keys(props.structure.query_where).length > 0) {
          whereProps = props.structure.query_where;
        }
      }
    }
    // console.log("whereProps",whereProps);
    let whereAll = [];
    if (whereProps) {
      whereAll.push(whereProps);
    }
    if (Object.keys(xWhere).length > 0) {
      whereAll.push(xWhere);
    }
    // console.log("whereAll",whereAll);
    let whereAllQuery = whereProps;
    if (whereAll.length >= 2) {
      whereAllQuery = {
        and: whereAll
      };
    }
    if (whereAllQuery === null) {
      whereAllQuery = {};
    }
    // console.log("whereAllQuery",whereAllQuery);
    let f = {};
    if (haveAfterQueryFilter) {
      f = {
        where: whereAllQuery,
      };
    }
    else {
      f = {
        limit: xLimit,
        skip: xSkip,
        where: whereAllQuery,
      };
    }
    // console.log("f",f);
    if (typeof props.structure.collection_include !== 'undefined') {
      if (typeof props.structure.collection_include === 'object') {
        f['include']=props.structure.collection_include;
      }
      if (typeof props.structure.collection_include === 'string') {
        f['include']=[props.structure.collection_include];
      }
    }

    let xParams = {
      filter: f
    };
    // console.log(JSON.stringify(xParams));
    let port=null;
    if (typeof props.structure.port !=='undefined') {
      if (props.structure.port !==null && props.structure.port !=='') {
        port=props.structure.port;
      } 
    }
    // console.log(xParams);
    // console.log(props.structure.collection_name,JSON.stringify(xParams));

    let response = await UAPI.getLimit(xParams, props.structure.collection_name, port);
    if (response.status === 200) {
      if (response.data) {
        let rd = response.data;
        if (haveAfterQueryFilter) {
          let fdf = props.filterDataFunction(rd, xLimit, xSkip);
          setData(fdf);
        }
        else {
          setData(rd);
        }
      }
    }
  }

  const getLookUp = () => {
    const makeLookUpData = async (collection_name) => {
      let port=null;
      if (typeof props.structure.port !=='undefined') {
        if (props.structure.port !==null && props.structure.port !=='') {
          port=props.structure.port;
        } 
      }
      let response = await UAPI.getAll({}, collection_name, port);
      if (response.status === 200) {
        if (response.data) {
          lookUp[collection_name] = response.data;
          setLookUp(lookUp);
        }
      }
    }

    let fields = props.structure.fields;
    let x = [];
    for (const [k, v] of Object.entries(fields)) {
      if (v.input_select_source_type === 'db') {
        if (typeof v.input_select_source_name != 'undefined') {
          if (v.input_select_source_name.length > 0) {
            x.push(v.input_select_source_name);
          }
        }
      }
      else if (v.input_select_source_type === 'json') {
        if (typeof v.input_select_source_json != 'undefined') {
          if (v.input_select_source_json.length > 0) {
            lookUp[v.input_select_source_name] = v.input_select_source_json;
            setLookUp(lookUp);
          }
        }
      }
    }

    x.forEach(element => {
      makeLookUpData(element);
    });
  }

  const handleClickAdd = () => {
    setNewData({});
    setOpenDialog(true);
  }

  const handleClickEdit = (primary_key) => {
    setNewData([]);
    data.forEach(i => {
      if (i[props.structure.primary_key] === primary_key) {
        setNewData({ ...newData, ...i });
      }
    });
    setOpenDialog(true);
  }

  const handleClickDelete = (primary_key) => {
    setFocusedPrimaryKey(primary_key);
    setOpenConfirm(true);
  }

  const handleClickDetail = (forward_path, forward_props) => {
    history.push({
      pathname: forward_path,
      // search: '?query=abc',
      state: forward_props,
    });
  }
  const handleDelete = async () => {
    let port=null;
    if (typeof props.structure.port !=='undefined') {
      if (props.structure.port !==null && props.structure.port !=='') {
        port=props.structure.port;
      } 
    }
    let response = await UAPI.remove(focusedPrimaryKey, props.structure.collection_name, port);
    if (response.status === 200) {
      getData();
      setOpenConfirm(false);
      props.callBackParams('return_data', { id: focusedPrimaryKey });
    }
  }

  const ConfirmDialog = (props) => {
    // const { title, children, open, setOpen, onConfirm } = props;
    const { children, open, setOpen, onConfirm } = props;
    return (
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog"
      >
        {/* <DialogTitle id="confirm-dialog">{title}</DialogTitle> */}
        <DialogContent><div style={{ padding: 20 }}>{children}</div></DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpen(false)}
            color="default"
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
            color="secondary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const DynIcon = (props) => {
    const { icon, ...other } = props;
    // console.log(other);
    const X = ICONS[icon];
    return (
      <span {...other}>
        <X size={20} />
      </span>
    );
  }

  const onClickPage = (e, page) => {
    // getData(page - 1);
    getData(page - 1, rowsPerPage, searchTextFilter);
    setForcePage(page);
  }

  const onRppChange = (e) => {
    setRowsPerPage(e.target.value);
    getData(0, e.target.value);
    calAllPages(e.target.value);
  }

  const calAllPages = async (x, p) => {
    let xWhere = {};
    if (typeof p !== 'undefined') {
      xWhere = p;
    }

    let whereProps = null;
    if (props.structure.query_where) {
      if (typeof props.structure.query_where !== 'undefined') {
        if (Object.keys(props.structure.query_where).length > 0) {
          whereProps = props.structure.query_where;
        }
      }
    }

    let whereAll = [];
    if (whereProps) {
      whereAll.push(whereProps);
    }
    if (Object.keys(xWhere).length > 0) {
      whereAll.push(xWhere);
    }

    let whereAllQuery = whereProps;
    if (whereAll.length >= 2) {
      whereAllQuery = {
        and: whereAll
      };
    }

    let xParams = { where: whereAllQuery };
    if (whereAllQuery === null) {
      xParams = {};
    }
    // console.log(whereAllQuery);
    //     console.log(JSON.stringify(xParams));
    let port=null;
    if (typeof props.structure.port !=='undefined') {
      if (props.structure.port !==null && props.structure.port !=='') {
        port=props.structure.port;
      } 
    }
    let documentsCount = await UAPI.getCount(xParams, props.structure.collection_name, port);
    setAllPages(parseInt(Math.ceil(parseInt(documentsCount.data.count) / parseInt(x))));
  }

  const onchangeSearchText = (e) => {
    let text = e.target.value.trim();
    let filter_where = {};

    if (text.length > 0) {
      if (typeof props.structure.search_field != 'undefined') {
        let f = props.structure.search_field;
        let wf = [];
        if (f.length > 0) {
          f.map((d, i) => {
            wf.push({ [d]: { like: text } });
            // map function must return some value
            return "";
          });
        }
        filter_where = { or: wf };
      }
      setSearchTextFilter(filter_where);;
      calAllPages(rowsPerPage, filter_where);
      getData(0, rowsPerPage, filter_where);
      setForcePage(1);
    }
    else if (e.target.value.length === 0) {
      calAllPages(rowsPerPage);
      getData(0, rowsPerPage);
      setForcePage(1);
    }
  }

  useEffect(() => {
    if (typeof props.structure !== 'undefined') {
      if (parseInt(queryDataCount.current)>0) {
        calAllPages(rowsPerPage);
        getData();
      }
    }
  }, [props.structure]); 

  useEffect(() => {
    calAllPages(rowsPerPage);
    getData();
    getLookUp();
    if (typeof props.structure.search_field != 'undefined') {
      if (props.structure.search_field.length>0) {
        setShowSearchBox(true);
      }  
    }
    if (typeof props.structure.rows_per_page != 'undefined') {
      if (parseInt(props.structure.search_field)>0) {
        setShowSelectRPP(true);
      }  
    }
  }, []); 

  useEffect(() => {
    if (typeof props.callBackParams !== 'undefined') {
      if (returnedData) {
        // console.log(returnedData);
        props.callBackParams('return_data', returnedData);
      }
    }
  }, [returnedData]); 

  return (
    <>

      <div style={{ marginBottom: 8, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        
        {showSearchBox&&(
          <div style={{ width: '100%' }}>
            <OutlinedInput
              name="searchText"
              variant="outlined"
              placeholder="ค้นหา"
              style={{ width: '100%' }}
              onChange={(e) => onchangeSearchText(e)}
              startAdornment={
                <InputAdornment position="start">
                  <DynIcon icon="MdSearch" />
                </InputAdornment>
              }
            />
          </div>
        )}

        {showSelectRPP&&(
          <div style={{ whiteSpace: 'nowrap', marginLeft: 10 }}>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <Select value={rowsPerPage} onChange={onRppChange}>
                <MenuItem key={10} value={10}>{10} แถว</MenuItem>
                <MenuItem key={20} value={20}>{20} แถว</MenuItem>
                <MenuItem key={30} value={30}>{30} แถว</MenuItem>
                <MenuItem key={40} value={40}>{40} แถว</MenuItem>
                <MenuItem key={50} value={50}>{50} แถว</MenuItem>
                <MenuItem key={100} value={100}>{100} แถว</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}

      </div>

      {isDesktop &&
        <MuiThemeProvider theme={themeTable}>
          <TableContainer component={Paper} style={{ border: 'none', boxShadow: 'none' }}>
            <Table className={classes.table} aria-label="customized table">

              <TableHead>
                {/* <TableRow style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)' }}> */}
                <TableRow style={{ background: '#CDEDFF' }}>
                  {makeHeaderRow()}
                </TableRow>
              </TableHead>
              <TableHead><TableRow style={{ height: 5 }}></TableRow></TableHead>

              <TableBody>
                {makeRows()}
              </TableBody>
            </Table>
          </TableContainer>
        </MuiThemeProvider>
      }

      {(!isDesktop && props.structure.create_document) && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
          <MuiThemeProvider key="btnEdit" theme={themeBtnAdd}>
            <Button
              onClick={handleClickAdd}
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<DynIcon icon='MdAddCircle' style={{ marginLeft: 11, marginTop: -2 }} />}
              style={{ marginLeft: 5, height: 30, borderRadius: 20, minWidth: '100px', maxWidth: '100px', color: 'white' }}
            />
          </MuiThemeProvider>
        </div>
      )}

      {!isDesktop &&
        <div>
          {makeDivRows()}
        </div>
      }

      <div style={{ marginTop: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 3, borderRadius: 20, border: 'solid 1px #D0D0D0' }}>
        <Pagination count={allPages} page={forcePage} onChange={onClickPage} color="primary" />
      </div>

      <CRUD
        structure={props.structure}
        openDialog={openDialog}
        newData={newData}
        lookUp={lookUp}
        callBackUDT={callBackParams}
      />

      <ConfirmDialog
        // title="ลบข้อมูล"
        open={openConfirm}
        setOpen={setOpenConfirm}
        onConfirm={handleDelete}
      >
        ต้องการลบข้อมูลนี้ใช่หรือไม่!?
      </ConfirmDialog>

      <div style={{ height: 50 }}></div>
    </>
  );
}
