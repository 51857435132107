/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import Form from "react-validation/build/form";
import TextField from '@material-ui/core/TextField';
import axios from "axios";
import { API_URL,PORT,SYSTEM_TOKEN } from '../../services/auth-header';
import { v4 as uuidv4,v5 as uuidv5 } from 'uuid';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core';
import logo from '../../images/password-edit4.png';
import validation from "../../services/validation";
import { useDialog } from '../../services/dialog/ModalProvider';
import useGlobal from "../../store";
const useStyles = makeStyles(theme => ({
  root: {
    '& ._RU-K2': {
      margin: '0',
      width: '100%',
      backgroundSize: 'contain'
    },
    '& .MuiInputLabel-outlined': {
      zIndex: 1,
      transform: 'translate(15px, 4px) scale(1)',
      pointerEvents: 'none'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(15px, -18px) scale(0.75)',
    }
  }
}));
const ResetPassword = (props) => {
  // const [globalState, globalActions] = useGlobal();
  const { userId,tokenParam } = useParams();
  const { setDialog } = useDialog();
  const classes = useStyles();
  const redirect = useHistory();
  const form = useRef();
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [tokenValid, setTokenValid] = useState(false);
  const [newPassword,setNewPassword] = useState("");
  const [confirmPassword,setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [validator, setValidator] = useState({
    formElements: {
      newPassword : {
        validator: { required: true, minLength: 6 },
        error: { status: false, message: 'กรุณากรอกรหัสผ่านใหม่' }
      },
      confirmPassword : {
        validator: { required: true, confirmMatch : '' },
        error: { status: false, message: 'กรุณายืนยันรหัสผ่านใหม่' }
      },
    },
    formValid: false
  });
  const baseUrl = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_BASENAME_DEV : process.env.REACT_APP_BASENAME_PROD;
  useEffect( () => {
    let unmounted = false;
    const validateToken = async(url,params)=>{
      await axios.get(url,params).then((response) => {
        // console.log(response);
        if(response.status===200 && response.data.result!=="expired"){
          setTokenValid(true);
        }else{
          setTokenValid(false);
          setDialog({
            title: '',
            titleStyle:{},
            content : {
              line1:'ลิงค์กำหนดรหัสผ่านหมดอายุ',
              line2:'กรุณาดำเนินการใหม่ครับ',
            },
            contentStyle: { width: '400px', textAlign: 'center', color: 'red' },
            button: {
              confirm: { enable: false, text: '', redirect: ''},
              cancel: { enable: true, text: 'ปิด'},
            }
          });
        }
      }).catch(function (error) {
        if (error.response.status===500) {
          setDialog({
            title: '',
            titleStyle:{},
            content : {
              line1:'ลิงค์กำหนดรหัสผ่านหมดอายุ',
              line2:'กรุณาดำเนินการใหม่ครับ',
            },
            contentStyle: { width: '400px', textAlign: 'center', color: 'red' },
            button: {
              confirm: { enable: false, text: '', redirect: ''},
              cancel: { enable: true, text: 'ปิด',redirect: '/login'},
            }
          });
          // console.log({ status: error.response.status, message: error.response.data.error.message });
        }
      });
    }
    if(tokenParam!==undefined){
      setUser(userId);
      setToken(tokenParam);
      let url = API_URL + ":"+PORT+"/api/AccessTokens/validateAccessToken?access_token="+tokenParam;
      let params = {
        headers: { Authorization: tokenParam },
        accessToken:{
          id:tokenParam
        }
      };
      validateToken(url,params);
    }
    return () =>  unmounted = true ;
  }, [tokenParam]);

  const onHandleChange = (e) => {
    let result = validation(validator, e);
    if (typeof result != 'undefined') {
      const name = (typeof e.target !== 'undefined') ? e.target.name : e.key;
      const value = (typeof e.target !== 'undefined') ? e.target.value : e.val;
      switch(name){
        case 'newPassword' : 
          setNewPassword(value); 
          setValidator((prevState) => {
              prevState.formElements.confirmPassword.validator.confirmMatch = value;
              return({...prevState})
            }
          );
          break;
        case 'confirmPassword' : setConfirmPassword(value); break;
        default : break;
      }
      setValidator({ ...validator, formElements: result.formElements, formValid: result.formValid });
    }
  };
  
  const handleSubmit = async (e, res) => {
    e.preventDefault();
    setLoading(true);
    onHandleChange({ key: "newPassword", val: newPassword });
    onHandleChange({ key: "confirmPassword", val: confirmPassword });
    if (validator.formValid) {
      try {
        // RESET PASSWORD
        const url = API_URL + ":"+PORT+"/api/teamusers/reset-password?access_token="+token;
        const header = {
          headers: { Authorization: token }
        };  
        const data = {newPassword:newPassword};     
        await axios.post(url,data,header).then((response) => {
          if(response.status===200){
            setNewPassword("");
            setConfirmPassword("");
          }
        }).catch(function (error) {
          if (error.response) {
            console.log({ status: error.response.status, message: error.response.data.error.message });
          }
        });  
        // DELETE ALL TOKEN BY USERID
        const urlDelToken = API_URL + ":"+PORT+"/api/teamusers/"+user+"/accessTokens?access_token="+SYSTEM_TOKEN;
        const paramsDelToken = {
          headers: { Authorization: SYSTEM_TOKEN },
          params: {id:user}
        };  
        await axios.delete(urlDelToken,paramsDelToken).then((response) => {
          if(response.status===204){
            setDialog({
              title: '',
              titleStyle:{},
              content : {
                line1:'กำหนดรหัสผ่านใหม่สำเร็จ',
                line2:'คุณสามารถใช้รหัสผ่านใหม่เข้าสู่ระบบได้ทันที'
              },
              contentStyle: { width: '400px', textAlign: 'center', color: 'green' },
              button: {
                confirm: { enable: true, text: 'เข้าสู่ระบบ', redirect: '/login'},
                cancel: { enable: false, text: 'ปิด', redirect: ''},
              }
            });
          }
        }).catch(function (error) {
          if (error.response) {
            console.log({ status: error.response.status, message: error.response.data.error.message });
          }
        }); 
        
        setLoading(false);

      } catch (err) {
        console.log("เปลี่ยนรหัสผ่านไม่สำเร็จ", err);
      }
    }else{
      setLoading(false);
    }
  };

  return (
    <div className="col-md-12">
      { (tokenValid)&&(
      <div className="card card-container">
        <img src={logo} alt="profile-img" className="password-change-logo" />
          <Form className={classes.root} onSubmit={handleSubmit} ref={form}>
            <div className="form-group" style={{ marginTop: '20px' }}>
              <TextField
                id="newPassword"
                name="newPassword"
                label="รหัสผ่านใหม่"
                type="text"
                size="small"
                variant="outlined"
                value={newPassword}
                onChange={onHandleChange}
                fullWidth
                error={validator.formElements.newPassword.error.status}
                helperText={
                  (validator.formElements.newPassword.error.status)&&validator.formElements.newPassword.error.message
                }
              />
            </div>
            <div className="form-group" style={{ marginTop: '20px' }}>
              <TextField
                id="confirmPassword"
                name="confirmPassword"
                label="ยืนยันรหัสผ่านใหม่"
                type="text"
                size="small"
                variant="outlined"
                value={confirmPassword}
                onChange={onHandleChange}
                fullWidth
                error={validator.formElements.confirmPassword.error.status}
                helperText={
                  (validator.formElements.confirmPassword.error.status)&&validator.formElements.confirmPassword.error.message
                }
              />
            </div>
            <div className="form-group" style={{ marginTop: '0px' }}>
              <button className="btn btn-primary btn-block" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>บันทึกรหัสผ่านใหม่</span>
              </button>
            </div>
          </Form>
      </div>
      )}
    </div>
  );
};

export default ResetPassword;
