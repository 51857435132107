import { GET_ASSIGNGROUP } from "../../actions";
import { AnyAction } from "redux";
import { AssignGroup } from "../../types/activity";
// import moment from 'moment';
// import 'moment/dist/locale/th';
// moment.locale(["th", "th"]);

const initialState:AssignGroup = {
  isFetching:false,
  count: 0,
  data: []
};

export default function getAssignGroup(
  state = initialState,
  action: AnyAction
): AssignGroup {
  switch (action.type) {
    case GET_ASSIGNGROUP.REQUEST:{
      return {
        ...state,
        isFetching: true,
      };
    }
    case GET_ASSIGNGROUP.SUCCESS: {
      
      let groupData:any = [];

      action.data.rows.map((item:any)=>{
        if(item.osccperson_groups){
          groupData.push({
            id: item.id,
            groupName: item.osccperson_groups.groupName,
            groupStatus: item.osccperson_group.groupStatus,
            member: item.osccperson_groups.member,
            questionaire: item.questionaire
          });
        }
        return null;
      });

      return {
        ...state,
        isFetching: false,
        count: action.data.count,
        data: groupData
      };
    }
    case GET_ASSIGNGROUP.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: [],
      };
    default:
      return state;
  }
}
