/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { OSCCPERSON } from "../index";

const insertOsccPersonImport = {
  request: (payload: any) => {
    console.log("insertOsccPersonImport", payload);
    return action(OSCCPERSON.REQUEST, { data: payload });
  },
  success: (response: any) => {
    return action(OSCCPERSON.SUCCESS, { data: response });
  },
  failure: (error: any) => action(OSCCPERSON.FAILURE, { error }),
};

export default insertOsccPersonImport;
