/* eslint-disable @typescript-eslint/no-unused-vars */
import { RETURN_CASE_GROUP } from '../../actions';
import Actions from '../../actions/activity';
import { snackbarFailure,snackbarSuccess } from '../../actions/notification';

import * as Api from '../../services/api';
import { fetchEntity } from '../saga';
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _ from 'lodash';

// const returnCaseGroup = fetchEntity.bind(null, Actions.ReturnCaseGroup, Api.deleteData);
const updateGroup = fetchEntity.bind(null, Actions.UpdateGroup, Api.updateGroup);
const updateCases = fetchEntity.bind(null, Actions.UpdateCases, Api.updateCases);
const getGroups = fetchEntity.bind(null, Actions.GetGroupList, Api.getGroupList); 
const getTeams = fetchEntity.bind(null, Actions.getGroupListTeams, Api.getTeams);

// function* firstAction(action: any) {
//   yield call(
//     returnCaseGroup, action.ReturnCaseGroup.params,
//     {
//       onSuccess: (success: any) => {
//         return call(actionUpdateGroup, action);
//       },
//       onFailure: () => put(snackbarFailure('returnCaseGroup failed',1500))
//     }
//   );
// }

function* firstAction(action: any) {
  yield call(
    updateGroup, action.updateGroup.params,
    {
      onSuccess: (success: any) => {        
        return call(actionUpdateCases, action)
      },
      onFailure: () => put(snackbarFailure('updateGroup failed',1500))
    }
  );
}

function* actionUpdateCases(action: any) {
  yield call(
    updateCases, action.updateCases.params,
    {
      onSuccess: (success: any) => {        
        return call(actionGetGroups, action)
      },
      onFailure: () => put(snackbarFailure('updateCases failed',1500))
    }
  );
}

function* actionGetGroups(action: any) {
  yield call(
    getGroups, { data: action.getGroups.params },
    {
      onSuccess: (data: any) => {    
        return call(actionGetTeams, {data:data,action:action});
        // return put(snackbarSuccess(action.deleteAssignGroup.successMessage,2000))
      },
      onFailure: () => put(snackbarFailure('getGroups failed',1500))
    }
  );
}

function* actionGetTeams(action: any) {
  if(action.data && action.data.length>0){
    const teamCode = _.uniq(action.data.map((data:any)=>data.teams.teamCode));
    yield call(
      getTeams, { data: { 
        type: "merge",
        where : { id : { inq : teamCode } },
        groupData : action.data
      } },
      {
        onSuccess: (success: any) => {
          return put(snackbarSuccess(action.action.updateGroup.successMessage,2000))
        },
        onFailure: () => put(snackbarFailure('getTeams failed',1500))
      }
    );
  }else{
    yield put(snackbarFailure('getTeams failed',1500));
  }
}

function* watchFirstRequest() {
  yield takeLatest(RETURN_CASE_GROUP.REQUEST, firstAction);
}

export default function* root() {
  yield fork(watchFirstRequest);
}
