/* eslint-disable @typescript-eslint/no-unused-vars */
import { action } from "../const";
import { GET_ASSIGNCASES } from "../index";

const getAssignCases = {
  request: (payload:any) => action(GET_ASSIGNCASES.REQUEST, {data:payload}),
  success: (response: any) => action(GET_ASSIGNCASES.SUCCESS, { data: response }),
  failure: (error: any) => action(GET_ASSIGNCASES.FAILURE, { error }),
};

export default getAssignCases;