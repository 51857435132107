
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React,{useEffect,useState,useCallback,FC} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../../reducers";
import { AppBar, Button, createStyles, Dialog, Divider, IconButton,List,ListItem,ListItemText,makeStyles,Slide,Theme,Toolbar,Typography, useTheme} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import { inform } from './inform';
import { Trans,useTranslation } from "react-i18next";
import Actions from '../../../actions/activity';
import { GridRowData } from '@material-ui/data-grid';
import useScrollEnd from '../helper/scrollEnd';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    appBar: {
      top: 'auto',
      bottom: 0,
      "& .MuiToolbar-root" : {
        display: "grid",
        gridAutoFlow: "column",
        justifyContent: "space-between",
        gridTemplateColumns: "max-content 1fr max-content max-content"
      }
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    informed: {
      backgroundColor: '#d1ffd2',
      "&:hover": {
        backgroundColor: "#d1ffd2"
      }
    }
  }),
);
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface IProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  filltered?: Record<string, any>;
  currentPage?: number;
  pageSize?: number;
  caseData?: GridRowData;
  groupId?:string;
  state?:any;
}

// interface IRefs {
//   resetInform: React.Dispatch<React.SetStateAction<string[]>>;
// }

type TProps = IProps;

const InformConsent: FC<TProps> = ((props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))||false;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const globalState = useSelector((state: RootState) => state);
  const userinfo = globalState.auth.user.response?.user;
  const teamCodes = (userinfo?.osccteams)?userinfo.osccteams.map((item:any)=>item.teamCode):[];
  const classes = useStyles();
  const {setOpen} = props;
  const [informed, setInformed] = useState<Array<string>>([]);

  const [divRef, setDivRef]=useState<any>(null);
  const reachedBottom = useScrollEnd(divRef);

  // useImperativeHandle(ref,()=>{
  //   return {
  //     resetInform(){
  //       setInformed([]);
  //     }
  //   };
  // },[]);

  const handleClose = useCallback(() => {setOpen(false)},[setOpen]);

  const handleClick = (listItem:string, items:Record<string,string>) => {
    if(informed.includes(listItem)){
      setInformed(informed.filter(item => item !== listItem)); 
    }else{
      setInformed([...informed,listItem]); 
    }
  }

  const informConsentSave = () => {
    let informedData: Array<Record<string,any>> = [];
    inform.forEach((item:any)=>{
      if(informed.includes(item.value.id)){informedData.push({ [item.value.id]:true });}
    });
    const finalInformedData = {
      inform : informedData
    }

    let params: any = {
      updateGroup: {
        params: {
          id: (props.caseData)? props.caseData.groupId : props.groupId,
          data: finalInformedData
        },
        successMessage:"ให้ความยินยอมสำเร็จ"
      },
      history:{
        pathname: '/questionnaire_list', 
        state: props.state
      }
    };
    if(props.caseData){
      params["getCases"]={
        params: {
          where: props.filltered , 
          pagination: {
            limit: props.pageSize, 
            skip: (props.currentPage && props.pageSize) ? props.currentPage * props.pageSize : 0
          }
        }
      };
    }else{
      params["getGroups"]={
        params: {teamCodes: teamCodes}
      };
    }
    // console.log(props);
    // console.log(params);
    dispatch(Actions.UpdateGroup.request(params));
  }

  const informAll=()=>{ // ยอมรับ inform ทั้งหมด
    if(reachedBottom){
      const informData:Array<string> = inform.map((item:any,key:number)=>{
        return item.value.id;
      });
      setInformed(informData);
    }else{
      setInformed([]);
    }
  }

  useEffect(()=>{ // ถ้าเลื่อนลงไม่สุด reset inform
    if(!reachedBottom){setInformed([]);}
  },[reachedBottom]);

  return (
    <div>
      <Dialog fullScreen open onClose={(_event, reason)=>{if (reason === "backdropClick") {return;}handleClose();}} TransitionComponent={Transition}>
        <Typography variant="h4" className={classes.title} style={{marginTop:'2rem',marginBottom:'1rem'}}>ข้อตกลงและความยินยอม</Typography>
        <Divider />
        <List style={{paddingTop:0,marginBottom: '3.5rem'}}>
          {
            inform.map((item:any,key:number)=>{
              return (
                <div key={"div"+key} >
                  <ListItem button 
                    className={(informed.includes(item.value.id))?classes.informed:""}
                    key={item.value.id}  
                    onClick={(e)=>{handleClick(item.value.id,item)}}>
                    <ListItemText 
                      primary={
                        <span style={{fontWeight:'bold'}}>
                          <Trans components={{br: <br />}} t={t}>{item.header}</Trans>
                        </span>
                      }
                      secondary={
                        <span>
                          <Trans components={{br: <br />}} t={t}>{item.detail}</Trans>
                        </span>
                      } />
                  </ListItem>
                  <Divider />
                </div>
              )
            })
          }
          <div ref={node=>{setDivRef(node)}}style={{height:"0px"}}></div>
        </List>
        <AppBar position="fixed" color="primary" className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" style={{justifySelf: "flex-start"}}><CloseIcon /></IconButton>
            <Typography variant="h6" className={classes.title}>{(!isMobile)&&"ข้อตกลงและความยินยอม"}</Typography>
            <Button 
              variant="contained"
              style={{marginRight:"1rem",justifySelf: "flex-end"}} 
              onClick={handleClose}>
                ยกเลิก
            </Button>
            {(reachedBottom)&&
              <Button 
                variant="outlined"
                style={{marginRight:"1rem",color:'#ffffff', backgroundColor:'#05b500', borderColor:'#05b500', justifySelf: "flex-end"}} 
                onClick={informAll}>
                  ยอมรับทั้งหมด
              </Button>
            }
            <Button 
              variant="outlined"
              disabled={(informed.length!==inform.length)}
              style={
                (informed.length!==inform.length)?
                  {color:'#6f6f6f', backgroundColor:'#e3e3e3', justifySelf: "flex-end"}:
                  {color:'#ffffff', backgroundColor:'#05b500', borderColor:'#05b500', justifySelf: "flex-end"}
              } 
              onClick={informConsentSave}>
                ({informed.length}/{inform.length}) บันทึก
            </Button>
          </Toolbar>
        </AppBar>
      </Dialog>
    </div>
  );
})

export default InformConsent;