/* eslint-disable react-hooks/exhaustive-deps */
import React,{ FC, useEffect, useState } from 'react';
import { Box, Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Typography, useTheme } from '@material-ui/core';
import { DataGrid, GridColDef, GridCellParams, GridRowData } from "@material-ui/data-grid";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { Link, useHistory, useLocation} from 'react-router-dom';
import AssignmentIcon from '@material-ui/icons/Assignment';
import VisibilityIcon from '@material-ui/icons/Visibility';
// import CreateIcon from '@material-ui/icons/Create';
import moment from "moment";
import "moment/dist/locale/th";
import { thaiYear } from '../../reducers/selectors/thaiYear';
import RepeatQuestionairDialog from './questionair_crf';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';

const useStyles = makeStyles(() =>
  createStyles({
    dataGrid: { 
      "& .fullWH":{
        padding: "0 !important"
      },
      "& .MuiDataGrid-columnsContainer": {
        backgroundColor: '#4b4b4b',color:'#fff'
      }
    }
  }),
);

interface stateType {
  group: boolean;
  questionaire_id: string;
  data: Record<string,any>;
  userRoles: string[];
}
interface IProps{
  refresh: () => void;
}

const QuestionaireList:FC<IProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { state } = useLocation<stateType>();
  const history = useHistory();
  const globalState = useSelector((state: RootState) => state);
  const questionaireList = globalState.activity.questionaireList;
  const [showModal, setShowModal] = useState(false);
  const [focusQ, setFocusQ] = useState<GridRowData>({});
  
  const caseTeamCode = state.data.osccperson_assign[0]?.teams.teamCode;
  const userTeams = globalState.auth.user.response.user.teams;
  const role = userTeams.filter((f:any)=>f.teamCode===caseTeamCode).map((m:any)=>m.role)[0];
  // console.log('caseTeamCode',caseTeamCode,role);
  const columns: GridColDef[] = [
    { 
      field: 'questionnaire_name', 
      headerName: 'แบบสอบถาม', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:(isMobile)?'center':'left',
      flex: (isMobile)?0.4:0.7,
      renderCell:(params: GridCellParams)=> {
        return (
          <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            {(isMobile)?params.row.questionnaire_code.replace("_","&"):params.row.questionnaire_code.replace("_","&")+" : "+params.row.questionnaire_name}
          </span>
        )
      }
    },
    { 
      field: 'questionnaire_name.questionnaire_answer[0].answer_datetime', 
      headerName: (isMobile)?'วันที่':'วันที่ทำแบบสอบถาม', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: (isMobile)?0.4:0.25,
      renderCell:(params: GridCellParams)=> {
        if(params.row.questionnaire_answer && params.row.questionnaire_answer.length>0){
          return (isMobile)? thaiYear(params.row.questionnaire_answer[0]?.answer_datetime,'L LT') :thaiYear(params.row.questionnaire_answer[0]?.answer_datetime,'lll');
        }else{
          return "";
        }
      }
    },
    { 
      field: 'manage', 
      headerName: (isMobile)?"#":'ดำเนินการ', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center', 
      flex: (isMobile)?0.2:0.15,
      renderCell:(params: GridCellParams)=> {
        const userRoles = state.userRoles || [role] || [];
        const canManage = questionaireList?.data?.filter(f=>{
          return ( f.id===params.id && (_.intersectionBy(f.questionnaire_manage, userRoles)).length>0);
        }).length>0;
        // console.log('state.userRoles',state.userRoles);
        return (
          <>
            <IconButton 
              title={(state.data && state.data.readOnly) || !canManage ?'ดูคำตอบแบบสอบถาม':'ตอบแบบสอบถาม'}
              disabled={false}
              onClick={()=>{
                if(params.row.questionnaire_repeat===true){
                  let newParams = Object.assign(params.row);
                  newParams["questionnaire_manage"] = params.row.questionnaire_manage;
                  newParams["readOnly"] = (state.data.caseState==="waitConclusion"  || state.data.groupStatus==="waitConclusion");
                  setFocusQ(newParams);
                  setShowModal(true);
                }else{
                  let newLocationState: stateType = Object.assign(state);
                  newLocationState["questionaire_id"]=params.row.id;
                  newLocationState.group=params.row.questionnaire_group;
                  // console.log(newLocationState);
                  history.push({ 
                    pathname: '/questionaire', 
                    state: newLocationState
                  })
                }
              }}>
              {(state.data && state.data.readOnly) || !canManage ? <VisibilityIcon />: <AssignmentIcon />}
            </IconButton>
          </>
        )
      }
    }
  ];

  return (
    <Box style={{
      marginTop:3,
      width: '100%',
    }}>
      <DataGrid
        rows={(questionaireList.isFetching)?[]:questionaireList.data}
        columns={columns}
        className={classes.dataGrid}
        loading={questionaireList.isFetching}
        autoHeight
        pagination
        pageSize={20}
        rowsPerPageOptions={[20]}
        disableColumnMenu
        components={{
          NoRowsOverlay: ()=>(
            <Box height="100%" alignItems="center" justifyContent="center">ไม่พบข้อมูล</Box>
          )
        }}
      />
      {(showModal)&&<RepeatQuestionairDialog data={focusQ} open={showModal} setOpen={setShowModal} refresh={props.refresh} isMobile={isMobile}/>}
    </Box>
  );
}

export default QuestionaireList;