
import GetQuestionnaire from "./getQuestionaire";
import GetPerson from "./getPerson";
import GetCases from "./getCases";
import GetCasesExcel from "./getCasesExcel";
import UpdateCase from "./updateCase";
import UpdateCases from "./updateCases";
import GetAssignCases from "./getAssignCase";
import GetAssignGroup from "./getAssignGroup";
import GetGroupList from "./getGroupList";
import InsertGroup from "./insertGroup";
import UpdateGroup from "./updateGroup";
import DeleteGroup from "./deleteGroup";
import Delete from "./delete";
import InsertAssign from "./insertAssign";
import UpdateAssign from "./updateAssign";
import DeleteAssign from "./deleteAssign";
import DeleteAssignGroup from "./deleteAssignGroup";
import ReturnCaseGroup from "./returnCaseGroup";
import GetAnswer from "./getAnswer";
import InsertAnswer from "./insertAnswer";
import UpdateAnswer from "./updateAnswer";
import DeleteAnswer from "./deleteAnswer";
import InsertConclusion from "./insertConclusion";
import UpdateConclusion from "./udpateConclusion";
import DeleteConclusion from "./deleteConclusion";
import InsertIntervention from "./insertIntervention";
import UpdateIntervention from "./udpateIntervention";
import DeleteIntervention from "./deleteIntervention";
import UpdateOsccperson from "./udpateOsccperson";
import InsertOsccperson from "./insertOsccperson";
import SendCM from "./sendCM";
import getGroupListTeams from "./getGroupListTeams";
export default{
  GetQuestionnaire,
  GetPerson,
  GetCases,
  GetCasesExcel,
  UpdateCase,
  UpdateCases,
  GetAssignCases,
  GetAssignGroup,
  GetGroupList,
  getGroupListTeams,
  InsertGroup,
  UpdateGroup,
  DeleteGroup,
  InsertAssign,
  UpdateAssign,
  DeleteAssign,
  DeleteAssignGroup,
  ReturnCaseGroup,
  Delete,
  GetAnswer,
  InsertAnswer,
  UpdateAnswer,
  DeleteAnswer,
  SendCM,
  InsertConclusion,
  UpdateConclusion,
  DeleteConclusion,
  InsertIntervention,
  UpdateIntervention,
  DeleteIntervention,
  UpdateOsccperson,
  InsertOsccperson
}