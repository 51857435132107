/* eslint-disable no-unused-vars */
import * as UAPI from "./UniversalAPI";


const AutoSetting = async (teamUsersData, redirect, runFromSystemSetting) => {
  console.log("AutoSetting");
  let r = false;
  // console.log(teamUsersData);
  let filter = {
    filter: {
      where: {
        hos_id: teamUsersData.user.department.hcode
      }
    }
  };
  let response = await UAPI.getAll(filter, 'hospitals', '3009');
  if (response.status === 200) {
    if (response.data) {
      let res = response.data[0];
      let date = new Date();
      var d = date.getFullYear() + '-' + ('00' + (date.getMonth() + 1)).slice(-2) + '-' + ('00' + date.getDate()).slice(-2);

      let initAutoSettingToday = false;
      if (typeof res.autoSettingDate !== 'undefined') {
        if (res.autoSettingDate && res.autoSettingDate !== '') {
          if (d === res.autoSettingDate) {
            initAutoSettingToday = true;
          }
        }
      }

      // initAutoSettingToday = false;
      if (typeof runFromSystemSetting !== 'undefined') {
        if (runFromSystemSetting===true) {
          initAutoSettingToday = false;
        }
      }
      
      if (initAutoSettingToday === false) {
        // ถ้าวันนี้ยังไม่ได้รัน AutoSetting ก็ให้รันเลย (รันวันละครั้งต่อ1หน่วยบริการ)
        let hasTeam = false;
        if (typeof teamUsersData.user.osccteams !== 'undefined') {
          if (teamUsersData.user.osccteams.length > 0) {
            hasTeam = true;
          }
        }

        if (hasTeam === true) {
          r = await UpdateServiceSlotSINGLE(teamUsersData, teamUsersData.user.osccteams[0], redirect);
        }
        else {
          r = await CheckOsccTeam(teamUsersData, redirect);
        }
      }

      let id = res.id;
      let data = { autoSettingDate: d };

      UAPI.patch(id, data, "hospitals").then((responseU) => {
        if (responseU.status === 200) {
          // console.log(responseU.data);
        }
      });
    }
  }

  return r;
}

const CheckOsccTeam = async (teamUsersData, redirect) => {
  let r = false;
  let filter = {
    filter: {
      where: {
        "department.hcode": teamUsersData.user.department.hcode
      }
    }
  };
  let response = await UAPI.getAll(filter, 'teamusers', '3009');
  if (response.status === 200) {
    if (response.data) {
      let osccteamsData = {};
      response.data.forEach(i => {
        if (typeof i.osccteams !== 'undefined') {
          if (i.osccteams.length > 0) {
            osccteamsData = i.osccteams[0];
          }
        }
      });
      if (typeof osccteamsData === 'undefined') {
        r = await CreateOsccTeam(teamUsersData, redirect);
      }
      else {
        if (osccteamsData === null || Object.keys(osccteamsData).length === 0 || osccteamsData === "") {
          r = await CreateOsccTeam(teamUsersData, redirect);
        }
        else {
          r = await UpdateOsccTeam(teamUsersData, osccteamsData, redirect);
        }
      }
    }
  }
  return r;
}

const UpdateOsccTeam = async (teamUsersData, osccteamsData, redirect) => {
  let r = false;
  let id = teamUsersData.user.id;
  let data = { osccteams: [osccteamsData] };
  let response = await UAPI.patch(id, data, 'teamusers', '3009');
  if (response.status === 200) {
    if (response.data) {
      r = await UpdateServiceSlotSINGLE(teamUsersData, osccteamsData, redirect);
    }
  }
  return r;
}

const CreateOsccTeam = async (teamUsersData, redirect) => {
  let r = false;
  let teamCode = UAPI.generateTeamCode();
  let osccteam = {
    memberTypeId: "1",
    memberTypeName: "หัวหน้าทีม",
    teamCode: teamCode,
    teamName: teamUsersData.user.department.hos_name,
  };

  let id = teamUsersData.user.id;
  let data = { osccteams: [osccteam] };
  let response = await UAPI.patch(id, data, 'teamusers', '3009');
  if (response.status === 200) {
    if (response.data) {
      // console.log(response.data);
      r = await UpdateServiceSlotSINGLE(teamUsersData, osccteam, redirect);
    }
  }
  return r;
}


const UpdateServiceSlotSINGLE = async (teamUsersData, currentOsccTeam, redirect) => {
  console.log("UpdateServiceSlotSINGLE");
  let r = false;

  let filterS = {
    filter: {
      where: {
        hcode: teamUsersData.user.department.hcode,
        serviceSlotType: bluePrintServiceSlotOSCCPLH8wks2nd.serviceSlotType
      }
    }
  };

  let responseS = await UAPI.getAll(filterS, 'serviceslots', '3009');
  console.log("  -->  get serviceslots : ",filterS,responseS.data);
  if (responseS.status === 200) {
    if (responseS.data) {
      // ถ้าไม่มี serviceslot เลย ถึงค่อยสร้างใหม่ อัพเดทโดย เอกภาพ 2023-04-11
      if (responseS.data.length<=0) {
        let filterQ = {
          filter: {
            where: {
              questionnaire_type: bluePrintServiceSlotOSCCPLH8wks2nd.questionnaire_type
            }
          }
        };
        let responseQ = await UAPI.getAll(filterQ, 'questionnaires', '3009');
        console.log("  -->  get questionnaires : ",filterQ,responseQ.data);
        if (responseQ.status === 200) {
          if (responseQ.data) {
            let s = responseS.data;
            let q = responseQ.data;
            let updateList = [];
            let createList = [];
            q.forEach(qi => {
              let SSexists = false;
              s.forEach(si => {
                if (typeof si.serviceslotList === 'undefined') {
                  let sr = si.resultTemplate[0];
                  if (qi.questionnaire_code !== '') {
                    console.log("qi",qi);
                    console.log("sr",sr);
                    if (sr.questionnaire_code === qi.questionnaire_code) {
                      SSexists = true;
                      let blankUpdateVersion = true;
                      let mustUpdate = false;
                      if (typeof sr.updateVersion !== 'undefined') {
                        if (sr.updateVersion && sr.updateVersion !== '') {
                          blankUpdateVersion = false;
                          if (sr.updateVersion < qi.updateVersion) {
                            mustUpdate = true;
                          }
                        }
                      }
                      if (blankUpdateVersion === true || mustUpdate === true) {
                        sr.id = qi.id;
                        sr.questionnaire_code = qi.questionnaire_code;
                        sr.questionnaire_name = qi.questionnaire_name;
                        sr.questionnaire_type = qi.questionnaire_type;
                        sr.questionsList = qi.questionsList;
                        sr.analysisVersion = qi.analysisVersion;
                        sr.updateVersion = qi.updateVersion;
                        // sr.analysisVersion=null;
                        // sr.updateVersion=null;
                        si.resultTemplate[0] = sr;
                        si['serviceSlotType']=qi.questionnaire_type;
                        updateList.push(si);
                      }
                    }
                  }
                }
              });
              if (SSexists === false) {
                if (qi.id !== '') {
                  createList.push(qi);
                }
              }
            });

            if (updateList.length > 0) {
              console.log("updateList",updateList);
              let updateListMax = updateList.length;
              let updateListCount = 0;
              updateList.map(async (i) => {
                let responseU = await UAPI.update(i.id, i, 'serviceslots', '3009');
                if (responseU.status === 200) {
                  if (responseU.data) {
                    ++updateListCount;
                  }
                }
                if (updateListCount === updateListMax) {
                  r = await CheckServiceSlotSINGLE(createList, teamUsersData, currentOsccTeam, redirect);
                }
              });
            }else {
              // ถ้าไม่มี serviceslot ระบบมันจะเข้าไปที่นี่
              r = await CheckServiceSlotSINGLE(createList, teamUsersData, currentOsccTeam, redirect);
            }
          }
        }
      }else{
        r=true;
      }
    }
  }
  console.log("UpdateServiceSlotSINGLE : ",r);
  return r;
}

const CheckServiceSlotSINGLE = async (createList, teamUsersData, currentOsccTeam, redirect) => {
  console.log("CheckServiceSlotSINGLE");
  let r = false;
  if (createList.length > 0) {
    let bluePrint = {};
    let id = bluePrintServiceSlotOSCCPLH8wks2nd.id;
    let responseB = await UAPI.get(id, 'systemsettings', '3009');
    if (responseB.status === 200) {
      if (responseB.data) {
        bluePrint = responseB.data.value;
      }
    }

    let sssAll = [];
    createList.forEach(i => {
      let sss=[];
      if(i.questionnaire_type==="PLH1"){
        sss = JSON.parse(JSON.stringify(serviceSlotStructurePLH1));
      }else{
        sss = JSON.parse(JSON.stringify(serviceSlotStructurePLH2));
      }
      
      sss.hcode = teamUsersData.user.department.hcode;
      sss.serviceSlotType = i.questionnaire_type
      sss.serviceName = i.questionnaire_code + " " + i.questionnaire_name;
      sss.serviceLocation = teamUsersData.user.department.hos_name;
      sss.amphur = teamUsersData.user.amphur.amphur_id;
      let q = JSON.parse(JSON.stringify(i));
      q['osccteam'] = {
        teamCode: currentOsccTeam.teamCode,
        teamName: currentOsccTeam.teamName,
        hcode: teamUsersData.user.department.hcode
      };
      let thisAnswerer = {};
      bluePrint.serviceslotList.forEach(bi => {
        if (bi.questionnaire_code === i.questionnaire_code) {
          thisAnswerer = bi.answerer;
        }
      });
      q['answerer'] = thisAnswerer;
      sss.resultTemplate = [q];
      sssAll.push(sss);
    });
    r = await CreateServiceSlotSINGLE(sssAll, teamUsersData, currentOsccTeam, redirect);
  }else {
    r = await CheckServiceSlotGROUP(teamUsersData, currentOsccTeam, redirect);
  }
  console.log("CheckServiceSlotSINGLE : ",r);
  return r;
}

const CreateServiceSlotSINGLE = async (createList, teamUsersData, currentOsccTeam, redirect) => {
  console.log("CreateServiceSlotSINGLE");
  console.log("  --> createList ",createList);
  let r = false;
  if (createList.length > 0) {
    let responseC = await UAPI.create(createList, 'serviceslots', '3009');
    if (responseC.status === 200) {
      if (responseC.data) {
        // console.log(responseC.data);
        r = await CheckServiceSlotGROUP(teamUsersData, currentOsccTeam, redirect);
      }
    }
  }else {
    r = await CheckServiceSlotGROUP(teamUsersData, currentOsccTeam, redirect);
  }
  console.log("CreateServiceSlotSINGLE : ",r);
  return r;
}

const CheckServiceSlotGROUP = async (teamUsersData, currentOsccTeam, redirect) => {
  console.log("CheckServiceSlotGROUP");
  let r = false;

  let filter = {
    filter: {
      where: {
        and: [
          { hcode: teamUsersData.user.department.hcode },
          { serviceSlotType: bluePrintServiceSlotOSCCPLH8wks2nd.serviceSlotType },
          { serviceSlotCode: bluePrintServiceSlotOSCCPLH8wks2nd.serviceSlotCode }
        ]
      }
    }
  };
  // console.log(JSON.stringify(filter.filter));
  let SSGROUP = {};
  let response = await UAPI.getAll(filter, 'serviceslots', '3009');
  if (response.status === 200) {
    if (response.data) {
      if (response.data.length>0) {
        SSGROUP = response.data[0];
      }
    }
  }

  let bluePrint = {};
  let id = bluePrintServiceSlotOSCCPLH8wks2nd.id;
  let responseB = await UAPI.get(id, 'systemsettings', '3009');
  if (responseB.status === 200) {
    if (responseB.data) {
      bluePrint = responseB.data.value;
    }
  }

  let SSGROUPMustUpdate = true;
  console.log("SSGROUP",SSGROUP);
  console.log((Object.keys(SSGROUP).length > 0));
  if (Object.keys(SSGROUP).length > 0) {
    if (typeof SSGROUP.updateVersion !== 'undefined') {
      if (SSGROUP.updateVersion && SSGROUP.updateVersion !== '') {
        if (SSGROUP.updateVersion >= bluePrint.updateVersion) {
          SSGROUPMustUpdate = false;
        }
      }
    }
    if (SSGROUPMustUpdate === true) {
      r = await UpdateServiceSlotGROUP(SSGROUP, bluePrint, teamUsersData, currentOsccTeam, redirect);
    }else {
      r = await CheckPlanExists(teamUsersData, currentOsccTeam, redirect);
    }
  }else {
    // ปิดการสร้าง serviceslot group อัพเดทโดย เอกภาพ 2023-04-11
    // r = await CreateServiceSlotGROUP(bluePrint, teamUsersData, currentOsccTeam, redirect);
    r = true;
  }
  console.log("CheckServiceSlotGROUP : ",r);
  return r;
}

const CreateServiceSlotGROUP = async (bluePrint, teamUsersData, currentOsccTeam, redirect) => {
  console.log("CreateServiceSlotGROUP");
  let r = false;

  let filter = {
    filter: {
      where: {
        and: [
          { hcode: teamUsersData.user.department.hcode },
          { serviceSlotType: bluePrintServiceSlotOSCCPLH8wks2nd.serviceSlotType },
          { serviceSlotCode: { neq: bluePrintServiceSlotOSCCPLH8wks2nd.serviceSlotCode } }
        ]
      }
    }
  };
  let singleSlotList = [];
  let responseG = await UAPI.getAll(filter, 'serviceslots', '3009');
  if (responseG.status === 200) {
    if (responseG.data) {
      singleSlotList = responseG.data;
    }
  }

  let serviceslotList = JSON.parse(JSON.stringify(bluePrint.serviceslotList));
  serviceslotList.forEach(i => {
    singleSlotList.forEach(r => {
      if (typeof r.resultTemplate !== 'undefined') {
        if (i.questionnaire_code === r.resultTemplate[0].questionnaire_code) {
          i.serviceslotId = r.id;
          delete i['questionnaire_code'];
          delete i['answerer'];
        }
      }
    });
  });

  let mss = {};
  mss.hcode = teamUsersData.user.department.hcode;
  mss.amphur = teamUsersData.user.amphur.amphur_id;
  mss.serviceLocation = teamUsersData.user.department.hos_name;
  mss.serviceType = "OSCC";
  mss.serviceSlotCode = bluePrintServiceSlotOSCCPLH8wks2nd.serviceSlotCode;
  mss.serviceName = bluePrint.serviceName;
  mss.serviceDetail = ["evaluation"];
  mss.serviceslotList = serviceslotList;
  mss.updateVersion = bluePrint.updateVersion;
  mss.analysisVersion = bluePrint.analysisVersion;
  mss.serviceSlotType = bluePrintServiceSlotOSCCPLH8wks2nd.serviceSlotType;
  let response_mss = await UAPI.create(mss, 'serviceslots', '3009');
  if (response_mss.status === 200) {
    if (response_mss.data) {
      r = await CheckPlanExists(teamUsersData, currentOsccTeam, redirect);
    }
  }
  console.log("CreateServiceSlotGROUP : ",r);
  return r;
}

const UpdateServiceSlotGROUP = async (SSGROUP, bluePrint, teamUsersData, currentOsccTeam, redirect) => {
  let r = false;

  let filter = {
    filter: {
      where: {
        and: [
          { hcode: teamUsersData.user.department.hcode },
          { serviceSlotType: bluePrintServiceSlotOSCCPLH8wks2nd.serviceSlotType },
          { serviceSlotCode: { neq: bluePrintServiceSlotOSCCPLH8wks2nd.serviceSlotCode } }
        ]
      }
    }
  };
  let singleSlotList = [];
  let responseG = await UAPI.getAll(filter, 'serviceslots', '3009');
  if (responseG.status === 200) {
    if (responseG.data) {
      singleSlotList = responseG.data;
    }
  }

  let serviceslotList = JSON.parse(JSON.stringify(bluePrint.serviceslotList));
  serviceslotList.forEach(i => {
    singleSlotList.forEach(r => {
      if (i.questionnaire_code === r.resultTemplate[0].questionnaire_code) {
        i.serviceslotId = r.id;
        delete i['questionnaire_code'];
        delete i['answerer'];
      }
    });
  });

  let ssg = JSON.parse(JSON.stringify(SSGROUP));
  ssg.updateVersion = bluePrint.updateVersion;
  ssg.analysisVersion = bluePrint.analysisVersion;
  ssg.serviceName = bluePrint.serviceName;
  ssg.serviceSlotType = bluePrintServiceSlotOSCCPLH8wks2nd.serviceSlotType;
  ssg.serviceslotList = serviceslotList;

  let responseU = await UAPI.update(ssg.id, ssg, 'serviceslots', '3009');
  if (responseU.status === 200) {
    if (responseU.data) {
      // console.log(responseU.data);
      r = await CheckPlanExists(teamUsersData, currentOsccTeam, redirect);
    }
  }

  return r;
}

const CheckPlanExists = async (teamUsersData, currentOsccTeam, redirect) => {
  console.log("CheckPlanExists");
  let r = false;

  let hospitalsData = {};
  let villagesData = [];
  let assignedCases = [];
  let unassignedCases = [];
  let PLHGroup = [];
  let PLHSingle = [];

  let filterS = {
    filter: {
      where: {
        and: [
          { hcode: teamUsersData.user.department.hcode },
          { serviceSlotType: bluePrintServiceSlotOSCCPLH8wks2nd.serviceSlotType },
          { serviceSlotCode: bluePrintServiceSlotOSCCPLH8wks2nd.serviceSlotCode }
        ]
      }
    }
  };
  let responseS = await UAPI.getAll(filterS, 'serviceslots', '3009');
  
  if (responseS.status === 200) {
    if (responseS.data) {
      if(responseS.data.length>0){
        PLHGroup = responseS.data[0];
      }
    }
  }
  console.log("Step 1 : get serviceslots group",filterS,PLHGroup);
  let filterSSID = [];
  let maxPLHDays = 0;
  if(PLHGroup.length>0){
    PLHGroup.serviceslotList.forEach(s => {
      maxPLHDays = parseInt(s.endDays) > maxPLHDays ? parseInt(s.endDays) : maxPLHDays;
      filterSSID.push({ id: s.serviceslotId });
    });
  }

  let filterSS = {
    filter: {
      where: {
        and: [
          { serviceSlotType: bluePrintServiceSlotOSCCPLH8wks2nd.serviceSlotType }
        ]
      }
    }
  };
  if(PLHGroup.length>0){
    filterSS.filter.where["or"] = filterSSID;
  }

  
  let responseSS = await UAPI.getAll(filterSS, 'serviceslots', '3009');
  if (responseSS.status === 200) {
    if (responseSS.data) {
      // console.log(responseSS.data);
      PLHSingle = responseSS.data;
    }
  }
  console.log("Step 2 : get serviceslots single",filterSS,PLHSingle);

  let filterH = { filter: { where: { hos_id: teamUsersData.user.department.hcode } } };
  let responseH = await UAPI.getAll(filterH, 'hospitals', '3009');
  if (responseH.status === 200) {
    if (responseH.data) {
      hospitalsData = responseH.data[0];
    }
  }
  console.log("Step 3 : get hospitals",filterH,hospitalsData);

  // เฉพาะหน่วยบริการที่เป็น รพศ รพท รพช รพสต 
  if ([2, 3, 4, 6].indexOf(parseInt(hospitalsData.hos_type_id)) > -1) {
    let filterV = { filter: { where: { hcode: teamUsersData.user.department.hcode } } };
    // console.log(JSON.stringify(filterV.filter));
    let responseV = await UAPI.getAll(filterV, 'villages', '3009');
    if (responseV.status === 200) {
      if (responseV.data) {
        if (responseV.data.length > 0) {
          villagesData = responseV.data;
        }
      }
    }
    console.log("Step 4 : get villages",filterV,villagesData);
  }
  

  if (villagesData.length>0) {
    let filterC_or = [];
    villagesData.forEach(i => {
      filterC_or.push({ villagecode: i.villagecode });
    });
    let filterC = {
      filter: {
        where: {
          or: filterC_or
        }
      }
    };
    // console.log(filterC);
    let responseC = await UAPI.getAll(filterC, 'osccpeople', '3009');
    if (responseC.status === 200) {
      if (responseC.data) {
        if (responseC.data.length > 0) {
          // unassignedCases = responseC.data;
          let x = responseC.data;
          x.forEach(i => {
            if (typeof i.caseStatus !== 'undefined') {
              if (['new', 'renew'].indexOf(i.caseStatus) > -1) {
                unassignedCases.push(i);
              }
              else {
                assignedCases.push(i);
              }
            }
          });
        }
      }
    }
    console.log("Step 5 : get osccpeople",filterC);
    console.log("----> assignedCases : ",assignedCases);
    console.log("----> unassignedCases : ",unassignedCases);
  } else {
    r = true;
  }

  let assignCompleted = true;
  let planCompleted = true;

  // ปิดการ AutoAssign ให้ Assign เอง แก้ไขโดย เอกภาพ  2023-04-11
  // if (unassignedCases.length > 0) {
  //   let v = await AutoAssign(unassignedCases, PLHGroup, PLHSingle, teamUsersData, currentOsccTeam, redirect);
  //   if (v.status===false) {
  //     assignCompleted = false;
  //   }
  // }
  // if (assignedCases.length > 0) {
  //   let v = await CheckPlanVersion(assignedCases, PLHGroup, PLHSingle, teamUsersData, currentOsccTeam, redirect);
  //   if (v.status===false) {
  //     planCompleted= false;
  //   }
  // }

  if (assignCompleted===true && planCompleted===true) {
    r = true;
  }
  console.log("CheckPlanExists : ",r);
  return r;
}

const CheckPlanVersion = async (assignedCases, PLHGroup, PLHSingle, teamUsersData, currentOsccTeam, redirect) => {
  let r = false;
  let planList_temp = [];
  let planList = [];
  let filterOsccPersonId = [];
  let casesCount = 0;
  // assignedCases.forEach(i => {
  //   filterOsccPersonId.push({ osccPersonId: i.id });
  // });
  // let filter = {
  //   filter: {
  //     where: {
  //       and: [
  //         { updateVersion: { lt: PLHGroup.updateVersion } },
  //         { or: filterOsccPersonId }
  //       ]
  //     }
  //   }
  // };
  // let responseP = await UAPI.getAll(filter, 'planservices', '3009');
  // if (responseP.status === 200) {
  //   if (responseP.data) {
  //     planList = responseP.data;
  //   }
  // }

  let filter = {
    filter: {
      where: {
        and: [
          { updateVersion: { lt: PLHGroup.updateVersion } },
          { hcode: teamUsersData.user.department.hcode }
        ]
      }
    }
  };
  let responseP = await UAPI.getAll(filter, 'planservices', '3009');
  if (responseP.status === 200) {
    if (responseP.data) {
      planList_temp = responseP.data;
    }
  }

  
  assignedCases.forEach(ci => {
    planList_temp.forEach(pi => {
      if (ci.id===pi.osccPersonId) {
        planList.push(pi);
      }
    });
  });

  // console.log(planList);

  if (planList.length > 0) {
    let planListMustUpdate = [];
    planList.map(async (thisPlan) => {
      if (typeof thisPlan.updateVersion !== 'undefined') {
        if (thisPlan.updateVersion && thisPlan.updateVersion !== '') {
          if (thisPlan.updateVersion < PLHGroup.updateVersion) {
            planListMustUpdate.push(thisPlan);
          }
        }
      }
    });

    // console.log(planListMustUpdate);

    let maxPMD = planListMustUpdate.length;
    casesCount = planListMustUpdate.length;
    let updatedPMD = 0;
    if (maxPMD>0) {
      await Promise.all(planListMustUpdate.map(async (thisPlan) => {
        let c = await UpdatePlanVersion(thisPlan, PLHGroup, PLHSingle, teamUsersData, currentOsccTeam, redirect);
        if (c===true) {
          ++updatedPMD;
        }
        if (maxPMD===updatedPMD) {
          // console.log(maxPMD,'|',updatedPMD);
          r = true;
          // console.log(r);
        }
      }));
    }
    else {
      r = true;
    }
  }
  else {
    r = true;
  }
  // console.log(r);
  return { status: r, casesCount: casesCount };
}

const UpdatePlanVersion = async (thisPlan, PLHGroup, PLHSingle, teamUsersData, currentOsccTeam, redirect) => {
  // การอัพเดต plan คือการแก้ cause / serviceslotList 
  // การแก้ serviceslotList คือ เทียบ sequenceId เพื่อเอา SS-Single ที่ไม่ใช้แล้วออกไป(ไม่ลบจริงนะ ให้ใส่ฟิลด์ formOldVersion=true) 
  // เติมตัวที่เพิ่มใหม่เข้ามา แล้วก็ปรับข้อมูลของ SS-Single ให้ตรง คำนวณ startDate+endDate ใหม่ / ปรับ answererId+answererName+orderId+serviceslotName / ส่วน teamCode+teamName ก็เอาจากสคริปเดิม
  let r =false;
  thisPlan.cause = PLHGroup.serviceName;
  thisPlan.updateVersion = PLHGroup.updateVersion;
  thisPlan.analysisVersion = PLHGroup.analysisVersion;
  let newSS = [];
  thisPlan.serviceslotList.forEach(pi => {
    let matched = 0;
    PLHGroup.serviceslotList.forEach(mi => {
      if (parseInt(pi.sequenceId) === parseInt(mi.sequenceId)) {
        pi.updateData = mi;
        ++matched;
      }
    });
    if (matched === 0) {
      pi.fromOldVersion = true;
    }
    else {
      pi.fromOldVersion = false;
    }
  });

  PLHGroup.serviceslotList.forEach(mi => {
    let matched = 0;
    thisPlan.serviceslotList.forEach(pi => {
      if (parseInt(pi.sequenceId) === parseInt(mi.sequenceId)) {
        ++matched;
      }
    });
    if (matched === 0) {
      newSS.push(mi);
    }
  });

  thisPlan.serviceslotList.forEach(pi => {
    PLHSingle.forEach(si => {
      if (pi.updateData.serviceslotId === si.id) {
        let dateA = thisPlan.startDate.split('-');
        let dateB = dateA[1]+' '+dateA[2]+' '+dateA[0];
        let slotStartDate = new Date(dateB);
        slotStartDate.setDate(slotStartDate.getDate() + pi.updateData.startDays - 1);
        let slotEndDate = new Date(dateB);
        slotEndDate.setDate(slotEndDate.getDate() + pi.updateData.endDays - 1);

        pi.orderId = pi.updateData.orderId;
        pi.serviceslotId = si.id;
        pi.serviceslotName = si.serviceName;
        pi.teamName = currentOsccTeam.teamName;
        pi.teamCode = currentOsccTeam.teamCode;
        pi.answererId = si.resultTemplate[0].answerer.answererId;
        pi.answererName = si.resultTemplate[0].answerer.answererName;
        pi.startDate = dbDate(slotStartDate);
        pi.endDate = dbDate(slotEndDate);
      }
    });
  });

  thisPlan.serviceslotList.forEach(pi => {
    if (typeof pi.updateData !=='undefined') {
      delete pi['updateData'];
    }
  });

  newSS.forEach(ni => {
    PLHSingle.forEach(si => {
      if (ni.serviceslotId === si.id) {
        let dateA = thisPlan.startDate.split('-');
        let dateB = dateA[1]+' '+dateA[2]+' '+dateA[0];
        let slotStartDate = new Date(dateB);
        slotStartDate.setDate(slotStartDate.getDate() + ni.startDays - 1);
        let slotEndDate = new Date(dateB);
        slotEndDate.setDate(slotEndDate.getDate() + ni.endDays - 1);

        ni.serviceslotName = si.serviceName;
        ni.teamName = currentOsccTeam.teamName;
        ni.teamCode = currentOsccTeam.teamCode;
        ni.answererId = si.resultTemplate[0].answerer.answererId;
        ni.answererName = si.resultTemplate[0].answerer.answererName;
        ni.startDate = dbDate(slotStartDate);
        ni.endDate = dbDate(slotEndDate);

        delete ni['startDays'];
        delete ni['endDays'];
      }
    });
  });
  let temp_serviceslotList = thisPlan.serviceslotList;
  newSS.forEach(ni => { 
    temp_serviceslotList.push(ni);
  });
  thisPlan.serviceslotList = temp_serviceslotList;
  // console.log(newSS);
  // console.log(thisPlan);

  let responsePU = await UAPI.update(thisPlan.id, thisPlan, 'planservices', '3009');
  if (responsePU.status === 200) {
    if (responsePU.data) {
      // console.log(responsePU.data);
      r = true;
    }
  }
  return r;
}

const AutoAssign = async (unassignedCases, PLHGroup, PLHSingle, teamUsersData, currentOsccTeam, redirect) => {
  let r = false;

  let serviceslotList = [];
  let planCount = unassignedCases.length;
  let planCreated = 0;

  let maxPLHDays = 0;
  PLHGroup.serviceslotList.forEach(s => {
    maxPLHDays = parseInt(s.endDays) > maxPLHDays ? parseInt(s.endDays) : maxPLHDays;
  });

  let startDate = new Date();
  let endDate = new Date();
  endDate.setDate(endDate.getDate() + maxPLHDays);

  PLHGroup.serviceslotList.forEach(g => {
    PLHSingle.forEach(s => {
      if (g.serviceslotId === s.id) {
        let slotStartDate = new Date();
        slotStartDate.setDate(slotStartDate.getDate() + g.startDays - 1);
        let slotEndDate = new Date();
        slotEndDate.setDate(slotEndDate.getDate() + g.endDays - 1);
        serviceslotList.push(
          {
            sequenceId: g.sequenceId,
            orderId: s.orderId,
            serviceslotId: s.id,
            serviceslotName: s.serviceName,
            teamName: currentOsccTeam.teamName,
            teamCode: currentOsccTeam.teamCode,
            answererId: s.resultTemplate[0].answerer.answererId,
            answererName: s.resultTemplate[0].answerer.answererName,
            startDate: dbDate(slotStartDate),
            endDate: dbDate(slotEndDate),
          }
        );
      }
    });
  });

  // console.log(PLHGroup);
  // console.log(PLHSingle);
  // console.log(serviceslotList);

  await Promise.all(unassignedCases.map(async (ccc) => {
    let data = {
      caseStatus: "assignedTeam",
      osccTeamId: [
        currentOsccTeam.teamCode
      ]
    };
    let responseCP = await UAPI.patch(ccc.id, data, 'osccpeople', '3009');
    if (responseCP.status === 200) {
      if (responseCP.data) {
        // console.log(responseCP.data);
      }
    }

    // create planservice
    let planServiceData = {
      planDate: new Date(),
      osccPersonId: ccc.id,
      serviceslotId: PLHGroup.id,
      cause: PLHGroup.serviceName,
      startDate: dbDate(startDate),
      endDate: dbDate(endDate),
      plannerId: teamUsersData.user.id,
      planAt: "",
      place: "",
      intervention: [
        "evaluation"
      ],
      serviceslotList: serviceslotList,
      updateVersion: PLHGroup.updateVersion,
      analysisVersion: PLHGroup.analysisVersion,
      hcode: teamUsersData.user.department.hcode
    }
    // console.log(planServiceData);
    let responsePP = await UAPI.create(planServiceData, 'planservices', '3009');
    if (responsePP.status === 200) {
      if (responsePP.data) {
        // console.log(responsePP.data);
        planCreated = planCreated + 1;
        // console.log('planCount, planCreated-- ', planCount, planCreated);
      }
    }

  }));
  if (planCount === planCreated) {
    // console.log('##### planCount, planCreated-- ', planCount, planCreated);
    r = true;
  }
  return { status: r , casesCount: planCount };
}

// const bluePrintServiceSlotOSCCPLH8wks1nd = {
//   id: "6180e451683e29eaceb0affc",
//   key: "bluePrintServiceSlotOSCCPLH8wks1nd"
// };
const bluePrintServiceSlotOSCCPLH8wks2nd = {
  id: "642ed95efd2c74212ca26c2b",
  key: "bluePrintServiceSlotOSCCPLH8wks2nd",
  questionnaire_type:"PLH2",
  serviceSlotType:"PLH2",
  serviceSlotCode:"PLH8wks2nd"
};

const serviceSlotStructurePLH1 = {
  hcode: "",
  serviceName: "",
  serviceLocation: "",
  amphur: "",
  serviceType: "OSCC",
  serviceSlotType: "",
  consent: {
    heading: [
      "PLHC1",
      "PLHC2",
      "PLHC3",
      "PLHC4"
    ],
    detail: [
      "PLHCD1",
      "PLHCD2",
      "PLHCD3",
      "PLHCD4"
    ]
  },
  inform: {
    heading: [
      "PLHA1",
      "PLHA2",
      "PLHA3",
      "PLHA4",
      "PLHA5",
      "PLHA6",
      "PLHA7",
      "PLHA8",
      "PLHA9",
      "PLHA10",
      "PLHA11",
      "PLHA12",
      "PLHA13",
      "PLHA14",
      "PLHA15"
    ],
    detail: [
      "PLHAD1",
      "PLHAD2",
      "PLHAD3",
      "PLHAD4",
      "PLHAD5",
      "PLHAD6",
      "PLHAD7",
      "PLHAD8",
      "PLHAD9",
      "PLHAD10",
      "PLHAD11",
      "PLHAD12",
      "PLHAD13",
      "PLHAD14",
      "PLHAD15"
    ]
  },
  timeStart: "08:30",
  timeFinish: "16:30",
  period: "weekly",
  serviceDetail: [
    "evaluation"
  ],
  resultTemplate: [
  ],
  workDay: [
    "mon", "tue", "wed", "thu", "fri"
  ],
  capacity: {
    duration: "0",
    defaultLoad: "0",
    maxLoad: "0"
  }
};
const serviceSlotStructurePLH2 = {
  hcode: "",
  serviceName: "",
  serviceLocation: "",
  amphur: "",
  serviceType: "OSCC",
  serviceSlotType: "",
  consent: {
    heading: [
      "PLHC1",
      "PLHC2",
      "PLHC3",
      "PLHC4"
    ],
    detail: [
      "PLHCD1",
      "PLHCD2",
      "PLHCD3",
      "PLHCD4"
    ]
  },
  inform: {
    heading: [
      "PLHA1",
      "PLHA2",
      "PLHA3",
      "PLHA4",
      "PLHA5",
      "PLHA6",
      "PLHA7",
      "PLHA8",
      "PLHA9",
      "PLHA10",
      "PLHA11",
      "PLHA12",
      "PLHA13",
      "PLHA14",
      "PLHA15"
    ],
    detail: [
      "PLHAD1",
      "PLHAD2",
      "PLHAD3",
      "PLHAD4",
      "PLHAD5",
      "PLHAD6",
      "PLHAD7",
      "PLHAD8",
      "PLHAD9",
      "PLHAD10",
      "PLHAD11",
      "PLHAD12",
      "PLHAD13",
      "PLHAD14",
      "PLHAD15"
    ]
  },
  timeStart: "08:00",
  timeFinish: "12:00",
  period: "weekly",
  serviceDetail: [
    "evaluation"
  ],
  resultTemplate: [
  ],
  workDay: [
    "mon", "wed", "fri"
  ],
  capacity: {
    duration: "15",
    defaultLoad: "16",
    maxLoad: "16"
  }
};

const dbDate = (d) => {
  return d.getFullYear() + '-' + addZero(parseInt(d.getMonth()) + 1, 2) + '-' + addZero(d.getDate(), 2);
}

const currentDate = () => {
  let d = new Date();
  let dY = d.getFullYear().toString();
  let dM = addZero(d.getMonth() + 1, 2);
  let dD = addZero(d.getDate(), 2);
  return dY + '-' + dM + '-' + dD;
}

const currentTime = () => {
  let d = new Date();
  let dHH = addZero(d.getHours(), 2);
  let dMM = addZero(d.getMinutes(), 2);
  let dSS = addZero(d.getSeconds(), 2);
  return dHH + ':' + dMM + ':' + dSS;
}

const calVN = () => {
  let d = new Date();
  let dY = (d.getFullYear() + 543).toString().substr(-2);
  let dM = addZero(d.getMonth() + 1, 2);
  let dD = addZero(d.getDate(), 2);
  let dHH = addZero(d.getHours(), 2);
  let dMM = addZero(d.getMinutes(), 2);
  let dSS = addZero(d.getSeconds(), 2);
  return dY + dM + dD + dHH + dMM + dSS;
}

const addZero = (n, z) => {
  let r = n.toString();
  for (var i = (parseInt(z) - n.toString().length); i > 0; i--) {
    r = '0' + r;
  }
  return r;
}

export {
  AutoSetting
};
