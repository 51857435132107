import * as Action from "../../actions";
import { AnyAction } from "redux";
import { filter } from "../../types/filter";

const initialState:filter = {
  isFetching: false,
  count:0,
  data: []
};

export default function agency(
  state = initialState,
  action: AnyAction
): filter {
  switch (action.type) {
    case Action.GET_AGENCY.REQUEST:{
      return {
        ...state,
        isFetching: true,
        data:[]
      };
    }
    case Action.GET_AGENCY.SUCCESS: {
      const newData = action.data.rows.map((item:any)=>{
        return {
          id: item.hos_id,
          label: item.hos_name,
          value: item
        }
      });
      return {
        ...state,
        isFetching: false,
        data: newData
      };
    }
    case Action.GET_AGENCY.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: [],
      };
    default:
      return state;
  }
}
