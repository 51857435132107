import React from 'react';
import { Checkbox, createStyles, FormControlLabel, makeStyles, Radio, RadioGroup, Theme } from "@material-ui/core";
import { Control, Controller, UseFormHandleSubmit, UseFormRegister, UseFormSetValue } from "react-hook-form";

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    radio:{
      marginBottom: 0
    }
  })
);

interface IProps {
  data: {
    key: number;
    name: any;
    itemValue: any;
    label: string;
    answersList?: Array<Record<string,any>>;
    disable?: boolean;
    uForm: {
      register: UseFormRegister<any>;
      setValue: UseFormSetValue<any>;
      control: Control<any, any>;
    };
  }
}

export default function GenCheckbox(props: IProps){
  const classes = useStyles();
  const { key, name, answersList,label, itemValue, disable, uForm } = props.data;
  return (
    <Controller
      key={name+key}
      name={name}
      control={uForm.control}
      render={({ field : { onChange, value }}:any) => {
        return (
          <Checkbox 
            key={name+key}
            disabled={disable}
            value={(typeof value !== "undefined")?value:false}
            checked={(typeof value !== "undefined")?value:false}
            onChange={onChange}
            color="primary"/>
        )
      }}
    />
  )
}